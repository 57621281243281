<template>
  <div class="designPackSummaryContainer quotePanel">
    <div>
      <label>Opportunity Number:</label>{{ iQuoteStore_GetQuote.salesforceQuoteNumber }}
      <br>
      <label>Customer Account Number:</label>
      <input
        v-model="autotaskComponent.autotaskCustAccNo "
        :disabled ="isReadOnly"
        type="text"
        name=""
        @change="onCustomerAccNoChanged($event)">
    </div>
    <br><br>
    <Spinner v-if="autotaskComponent.isLoadingAutotaskEntries" />
    <table
      v-if="!autotaskComponent.isLoadingAutotaskEntries"
      id="AutotaskTable"
      class="iQuoteTable"
      style="display: block"
    >
      <tr>
        <th
          style="width:70px">Quantity</th>
        <th
          style="width:400px">Code</th>
        <th
          style="">Description</th>
        <th
          style="width:80px">Unit OO Price</th>
        <th
          style="width:80px">Unit 00 Cost</th>
        <th
          style="width:80px">Unit AR Price</th>
        <th
          style="width:80px">Unit AR Cost</th>
        <th
          style="width:80px">Unit MR Price</th>
        <th
          style="width:80px">Unit MR Cost</th>
        <th
          style="width:100px">Supplier</th>
        <th
          style="width:100px">QuoteRef</th>
      </tr>
      <tr v-if="autotaskData === null">
        <td colspan="11">ERROR</td>
      </tr>
      <tbody v-if="autotaskData !== null">
        <tr v-if="autotaskData.length === 0">
          <td colspan="11">No Autotask Entries found</td>
        </tr>
        <tr
          v-for="autotaskEntry in autotaskData"
          :key="autotaskEntry.id"
        >
          <td>{{ iQuoteFunctions_RoundToTwoDecimalPlaces(autotaskEntry.quantity) }}</td>
          <td>{{ autotaskEntry.code }}</td>
          <td>{{ autotaskEntry.description }}</td>
          <td><span v-if="autotaskEntry.unitOOPrice !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitOOPrice.toFixed(2)) }}</span></td>
          <td><span v-if="autotaskEntry.unitOOCost !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitOOCost.toFixed(2)) }}</span></td>
          <td><span v-if="autotaskEntry.unitARPrice !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitARPrice.toFixed(2)) }}</span></td>
          <td><span v-if="autotaskEntry.unitARCost !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitARCost.toFixed(2)) }}</span></td>
          <td><span v-if="autotaskEntry.unitMRPrice !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitMRPrice.toFixed(2)) }}</span></td>
          <td><span v-if="autotaskEntry.unitMRCost !== 0">£{{ iQuoteFunctions_ThousandSeparator(autotaskEntry.unitMRCost.toFixed(2)) }}</span></td>
          <td>{{ autotaskEntry.supplier }}</td>
          <td>{{ autotaskEntry.quoteRef }}</td>
        </tr>
      </tbody>
    </table>
    <br><br>
    <DownloadButton
      v-if="iQuoteStore_GetDesignPack.editedAutotaskEntries.length === 0"
      :download-func="() => iQuoteExportsAPIMixin_getQuoteDesignPackAutoTaskExportExcel(iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport())"
      :default-filename="'autotask_export_quote_' + iQuoteStore_GetDesignPack.quoteDesignPackId + '.xlsx'"
      :is-visible="!autotaskComponent.isLoadingAutotaskEntries"
      file-type="application/vnd.ms-excel"
      title="Download As Excel"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackExportMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackExport'
import Spinner from '../../common/spinner'
import DownloadButton from '../../common/downloadButton'

export default {
  name: 'Autotask',
  components: {
    Spinner,
    DownloadButton
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteConstants,
    iQuoteFunctions,
    externalQuotesModelMixin,
    iQuoteExportsAPIMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackExportMixin
  ],
  props: {
    autotaskCustAccNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      autotaskComponent: {
        autotaskCustAccNo: '',
        isDownloadingAutotaskSpreadsheet: false,
        isLoadingAutotaskEntries: false,
        autoTaskEntries: []
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteStore_GetDesignPack.isReadOnly
    },
    autotaskData () {
      var returnData = null

      if (!this.iQuoteStore_GetDesignPack.useDetailDataForSummaries) {
        returnData = this.autotaskComponent.autoTaskEntries
      } else {
        returnData = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.showOnExportATCodesList === true && x.paused === false).map(x => ({
          categoryName: x.categoryName,
          code: x.code,
          description: x.description,
          quantity: x.quantity,
          recurringCost: x.recurringCost,
          unitOOCost: x.unitOOExternalCost + x.unitOOInternalCost,
          unitARCost: x.unitARExternalCost + x.unitARInternalCost,
          unitOOPrice: x.unitOOPriceOverride !== null ? x.unitOOPriceOverride : x.unitOOExternalPrice + x.unitOOInternalPrice,
          unitARPrice: x.unitARPriceOverride !== null ? x.unitARPriceOverride : x.unitARExternalPrice + x.unitARInternalPrice,
          unitMRCost: x.unitMRExternalCost + x.unitMRInternalCost,
          unitMRPrice: x.unitMRExternalPrice + x.unitMRInternalPrice,
          supplier: x.supplier,
          quoteRef: x.quoteRef
        }))
      }

      return returnData
    }
  },
  async mounted () {
    this.autotaskComponent.isLoadingAutotaskEntries = true
    await this.iQuoteFunctions_delay(1000) /* Need to wait in case designpack is saving from previous tab */
    this.autotaskComponent.autotaskCustAccNo = this.autotaskCustAccNo /* The property */

    if (this.iQuoteStore_GetDesignPack.editedAutotaskEntries.length === 0) {
      var entries = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackAutotaskEntriesFromDb(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
      this.autotaskComponent.autoTaskEntries = entries
    } else {
      this.autotaskComponent.autoTaskEntries = this.iQuoteStore_GetDesignPack.editedAutotaskEntries
    }

    this.autotaskComponent.isLoadingAutotaskEntries = false
  },
  updated () {

  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    onCustomerAccNoChanged (event) {
      this.$emit('customerAccNoChanged', event.target.value)
    },
    async downloadAutoTask () {
      var data = await this.iQuoteExportsAPIMixin_getQuoteDesignPackAutoTaskExportExcel(this.iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport())

      if (data !== 'error') {
        return data
      } else {
        return null
      }
    },
    downloadAutoTaskSpreadsheet: async function () {
      this.autotaskComponent.isDownloadingAutotaskSpreadsheet = true
      var data = await this.iQuoteExportsAPIMixin_getQuoteDesignPackAutoTaskExportExcel(this.iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport())

      if (data !== 'error') {
        var a = document.createElement('a')
        a.style = 'display: none'
        a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
        a.download = 'autotask_export_quote_' + this.iQuoteStore_GetDesignPack.quoteDesignPackId + '.xlsx'
        document.body.appendChild(a)
        a.click()
      } else {
        this.iQuoteFunctions_ShowError('Error Downloading.  Please retry. Contact your system administrator if the problem persists')
      }

      this.autotaskComponent.isDownloadingAutotaskSpreadsheet = false
    }
  }
}
</script>
<style scoped>
#AutotaskTable {
  width: 100%
}

</style>
