import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsRingCentralMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
    calcRingCentralMixin_ringCentralNonPSUpfrontCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if ((unitCost.serviceId === this.iQuoteConstants.services.ringCentral) &&
               unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_PS) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },
    calcRingCentralMixin_UpfrontCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.ringCentral) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    },
    calcRingCentralMixin_ManagedServicesCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.RINGCENTRAL_MANAGEDSERVICES) {
          total += unitCost.price * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcRingCentralMixin_ManagedServicesCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.RINGCENTRAL_MANAGEDSERVICES) {
          total += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcRingCentralMixin_CSMCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.RINGCENTRAL_CSM) {
          total += unitCost.price * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcRingCentralMixin_CSMCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.RINGCENTRAL_CSM) {
          total += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    /* OTHER VALUES */
    calcRingCentralMixin_totalRingCentralCCUserCount () {
      var total = 0
      if (this.iQuoteStore_GetQuoteRingCentral.ccUserProfiles?.length) {
        this.iQuoteStore_GetQuoteRingCentral.ccUserProfiles.forEach(item => {
          if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.DIGITAL_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIALS_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.UNLIMITED_AGENT
          ) {
            total += item.minCommitment
          }
        })
      }
      return total
    },
    calcRingCentralMixin_totalRingCentralUCUserCount () {
      var total = 0
      if (this.iQuoteStore_GetQuoteRingCentral.ucUserProfiles?.length) {
        this.iQuoteStore_GetQuoteRingCentral.ucUserProfiles.forEach(item => {
          if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER
          ) {
            total += item.minCommitment
          }
        })
      }
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcRingCentralMixin_getLicenseIdForUserProfile (technologyUserProfileId) {
      var licenseId = null
      var subscriptionBillingPeriodId = this.iQuoteStore_GetQuoteRingCentral.options.subscriptionBillingPeriodId

      switch (technologyUserProfileId) {
        case this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_BASIC_USER
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ESSENTIAL_USER
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER:
          if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER3
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER3
          }
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER:
          if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER3
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER3
          }
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER:
          if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 20 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER1
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount < 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER2
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER3
          } else if (this.calcRingCentralMixin_totalRingCentralUCUserCount >= 100 && subscriptionBillingPeriodId === this.iQuoteConstants.subscriptionBillingIntervals.MONTHLY) {
            licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER3
          }
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.CX_NOW_INTEGRATION:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_CXNOW_INT
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.LIVE_REPORTS:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_LIVE_REPORTS
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.INVIEW_PERFORMANCE_MANAGEMENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_INVIEW_MGT
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.ADDITIONAL_LOCAL_NUMBER:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_ADD_NUMBER
          break
        case this.iQuoteConstants.ringCentralUCUserProfiles.MICROSOFT_DYNAMICS_UNIFIED_SERVICE_DESK:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_UC_MSFT_DYNAMICS
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.DIGITAL_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_DIGITAL_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.ESSENTIALS_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_ESSENTIALS_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.STANDARD_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_STANDARD_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.PREMIUM_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_PREMIUM_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.ULTIMATE_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_ULTIMATE_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.UNLIMITED_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_UNLIMITED_AGENT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.FEEDBACK_SURVEY_MANAGER:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_SURVEY_MNGR
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.WORKFORCE_MANAGEMENT_PRO:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_WORKFORCE_MGT_PRO
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.QUALITY_MANAGEMENT_PRO:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_QUALITY_MGT_PRO
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.SCREEN_RECORDING_PRO:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_SCREEN_REC_PRO
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.QUALITY_MANAGEMENT_ANALYTICS_PRO:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_QTY_MGT_ANLY_PRO
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.OMNICHANNEL_ANALYTICS_PRO:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_OMNI_ANLY_PRO
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.SALESFORCE_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_SF_AGT
          break
        case this.iQuoteConstants.ringCentralCCUserProfiles.ZENDESK_AGENT:
          licenseId = this.iQuoteConstants.licenses.RINGCENTRAL_CC_ZEN_AGENT
          break
      }
      return licenseId
    },
    calcRingCentralMixin_calculate: function () {
      // for Ring Central, cost and price come from the licenses table in the DB (but the cost can be overridden)
      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId === undefined) {
        return
      }

      var quote = this.iQuoteStore_GetQuote

      var ucUserProfiles = this.iQuoteStore_GetQuoteRingCentral.ucUserProfiles
      var ccUserProfiles = this.iQuoteStore_GetQuoteRingCentral.ccUserProfiles
      var ucUserProfilesApprovedRCPrice = this.iQuoteStore_GetQuoteRingCentral.ucUserProfilesApprovedRCPrice
      var ccUserProfilesApprovedRCPrice = this.iQuoteStore_GetQuoteRingCentral.ccUserProfilesApprovedRCPrice

      var options = this.iQuoteStore_GetQuoteRingCentral.options
      var licenseId = null
      var cost
      var price
      var costPerYear
      var percentage

      for (let index = 0; index < ucUserProfiles.length; index++) {
        const userProfile = ucUserProfiles[index]
        licenseId = this.calcRingCentralMixin_getLicenseIdForUserProfile(userProfile.technologyUserProfileId)

        if (licenseId !== null) {
          let buyPrice = null
          let sellPrice = null
          if (options.ucPricingApprovedByRC && ucUserProfilesApprovedRCPrice?.length) {
            const approvedRCPrice = ucUserProfilesApprovedRCPrice.find(w => w.quoteTechnologyId === ucUserProfiles[index].quoteTechnologyId &&
              w.technologyUserProfileId === ucUserProfiles[index].technologyUserProfileId)
            if (approvedRCPrice !== undefined) {
              buyPrice = approvedRCPrice.buyPrice
            }
          }
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, userProfile.minCommitment, sellPrice, null, null, buyPrice)
        }
      }

      for (let index = 0; index < ccUserProfiles.length; index++) {
        const userProfile = ccUserProfiles[index]
        licenseId = this.calcRingCentralMixin_getLicenseIdForUserProfile(userProfile.technologyUserProfileId)

        if (licenseId !== null) {
          let buyPrice = null
          let sellPrice = null
          if (options.ccPricingApprovedByRC && ccUserProfilesApprovedRCPrice?.length) {
            const approvedRCPrice = ccUserProfilesApprovedRCPrice.find(w => w.quoteTechnologyId === ccUserProfiles[index].quoteTechnologyId &&
              w.technologyUserProfileId === ccUserProfiles[index].technologyUserProfileId)
            if (approvedRCPrice !== undefined) {
              buyPrice = approvedRCPrice.buyPrice
            }
          }
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, userProfile.minCommitment, sellPrice, null, null, buyPrice)
        }
      }

      switch (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId) {
        case this.iQuoteConstants.quoteServiceLevelTypes.STANDARD:
          percentage = 3
          break
        case this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM:
          percentage = 5
          break
      }

      var marginPs = 33
      cost = (percentage / 100) * (this.ringCentralLicensesAnnualCost)
      costPerYear = cost
      price = cost / ((100 - marginPs) / 100)

      // KP 3543 - Remove managed services from Ring Central
      // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.RINGCENTRAL_MANAGEDSERVICES, 1, costPerYear, cost, price)

      if (options.ucProfessionalServicesRequired) {
        cost = this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.RINGCENTRAL_PS)
        cost *= (this.calcRingCentralMixin_totalRingCentralUCUserCount + this.calcRingCentralMixin_totalRingCentralCCUserCount)
        costPerYear = cost * 12
        price = this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.RINGCENTRAL_PS)
        price *= (this.calcRingCentralMixin_totalRingCentralUCUserCount + this.calcRingCentralMixin_totalRingCentralCCUserCount)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.RINGCENTRAL_PS, 1, costPerYear, cost, price)
      }

      if (options.customerServiceManagerRequired) {
        cost = 0.16 * this.ringCentralLicensesAnnualCost / 12
        costPerYear = cost
        price = cost / ((100 - marginPs) / 100)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.RINGCENTRAL_CSM, 1, costPerYear, cost, price)
      }

      if (this.iQuoteStore_GetQuoteRingCentral.options.byoc) {
        var numSipChannels = this.iQuoteStore_GetQuoteRingCentral.options.sipChannelsCalculated
        var additionalSbcLicencesForInternalCalls = this.iQuoteStore_GetQuoteRingCentral.options.additionalSbcLicencesForInternalCalls
        var totalNumTeamsUsers = this.iQuoteStore_GetQuoteRingCentral.options.numRingCentralUsers + Math.ceil(this.iQuoteStore_GetQuoteRingCentral.options.numPBXUsers)

        if (this.iQuoteStore_GetQuoteRingCentral.options.numAdditionalSIPChannels > 0) {
          if (quote.termLengthMonths >= 36) {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_36, this.iQuoteStore_GetQuoteRingCentral.options.numAdditionalSIPChannels)
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_36, this.iQuoteStore_GetQuoteRingCentral.options.numAdditionalSIPChannels)
          } else {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_12, this.iQuoteStore_GetQuoteRingCentral.options.numAdditionalSIPChannels)
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_12, this.iQuoteStore_GetQuoteRingCentral.options.numAdditionalSIPChannels)
          }
        }

        if (numSipChannels > 0) {
          if (!this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP)) {
            // Might have already been added by Teams Connector if ordered together with Ring Central
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP, 2)
          }

          if (quote.termLengthMonths >= 36) {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_36, numSipChannels)
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_36, numSipChannels)
          } else {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_12, numSipChannels)
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_12, numSipChannels)
          }

          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_MAINTEL_SBC_SUPPORT, Math.ceil((numSipChannels + additionalSbcLicencesForInternalCalls) / 25))

          if (numSipChannels <= 100 || this.iQuoteStore_GetQuoteRingCentral.options.numPBXUsers > 0) {
            switch (this.iQuoteStore_GetQuoteRingCentral.options.usersPerSipChannel) {
              case 1:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
              case 3:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
              case 5:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
              case 7:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
              case 10:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
              case 120:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
                break
            }
          } else {
            switch (this.iQuoteStore_GetQuoteRingCentral.options.usersPerSipChannel) {
              case 1:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
              case 3:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
              case 5:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
              case 7:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
              case 10:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
              case 120:
                this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
                break
            }
          }

          var iconBandwidth = 2 * (5 + ((numSipChannels + additionalSbcLicencesForInternalCalls) / 5))
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ICON_BANDWIDTH_CONTRIBUTION, iconBandwidth)

          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SBC_PORT_CHARGE, numSipChannels + additionalSbcLicencesForInternalCalls)

          if (this.iQuoteStore_GetQuoteRingCentral.options.teamsConnectorNumberPortingOptionId === 2 /* 2 = NEW DDI */) {
            var numDDIs = this.iQuoteStore_GetQuoteRingCentral.options.newDDIs
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_NEW_DDI, numDDIs)
          }

          let upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR, this.iQuoteConstants.designPackPsSchedules.DAY)
          let upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATOR, 1, null, upfrontCost, upfrontPrice)

          upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.ENGINEER_LEVEL_B, this.iQuoteConstants.designPackPsSchedules.DAY)
          upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ENGINEER_LEVEL_B, 1, null, upfrontCost, upfrontPrice)
        }
      }
    }
  }
}
