<template>
  <div class="locContainer">
    <div
      v-if="!showCode"
      class="locUsername locTextBox locMinRow"
    >
      <PortalTextBox
        :item-error="usernameError"
        :item-value="username"
        item-place-holder="Username"
        item-type="ema"
        text-id="txtUsername"
        @EditValue="updateUsername($event)"
        @EnterClicked="selectPassword()" />
    </div>
    <div
      v-if="!showCode"
      class="locPassword locTextBox locMinRow"
    >
      <PortalTextBox
        :item-error="passwordError"
        :item-value="password"
        text-id="pwdPassword"
        item-place-holder="Password"
        item-type="pwd"
        @EditValue="updatePassword($event)"
        @EnterClicked="attemptLogon()" />
    </div>
    <div
      v-if="showCode"
      class="locPassword locTextBox locMinRow"
      style="padding: 5px;"
    >
      <label>Please enter the verification code for:</label>
      <label>{{ username }}</label>
      <br>
      <br>
    </div>
    <div
      v-if="showCode"
      class="locPassword locTextBox locMinRow"
    >
      <PortalTextBox
        :item-error="codeError"
        :item-value="code"
        text-id="txtCode"
        item-place-holder="Verification Code"
        item-type="cod"
        @EditValue="updateCode($event)"
        @EnterClicked="attemptLogon()" />
    </div>
    <div
      class="locButtons locItem locMinRow"
    >
      <!-- <button
        v-show="showCode"
        :style="loginBackgroundColour"
        title="Cancel verification code"
        class="verifyButton"
        @click="cancelLogon()" >
        <p>CANCEL</p>
      </button> -->
      <button
        :style="loginBackgroundColour"
        :title="verifyTitle"
        :class="verifyClass"
        @click="attemptLogon()" >
        <p>{{ verifyText }}</p>
      </button>
    </div>
  </div>
</template>
<script>
import PortalTextBox from '../common/portalTextBox'
// import axios from '../../communications/interceptors/authInterceptors';
import AuthRepository from '../../communications/repositories/authRepository'
import { AuthEventBus } from '../../events/authEvents'
import { DataEventBus } from '../../events/dataEvents'
import { usersMixin } from '../../mixins/users'
import { verifyMixin } from '../../mixins/verifyMixin'
import paths from '../../router/paths'
import { setTimeout, clearTimeout } from 'timers'
import { mapActions } from 'vuex'
export default {
  components: {
    PortalTextBox
  },
  mixins: [
    AuthEventBus,
    DataEventBus,
    AuthRepository,
    usersMixin,
    verifyMixin
  ],
  data () {
    return {
      username: '',
      password: '',
      code: '',
      usernameError: false,
      passwordError: false,
      codeError: false,
      timeoutSet: null,
      attemptingLogon: false,
      showCode: false,
      verifyComplete: false,
      useMFA: process.env.VUE_APP_USE_MFA,
      attemptCount: 1
    }
  },
  computed: {
    loginBackgroundColour () {
      return `background-color: ${this.$store.getters.loginButtonColour}`
    },
    isLoggedOn () {
      return this.$store.getters.username !== null && this.$store.getters.username !== '' && this.verifyComplete
    },
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    verifyText () {
      if (this.showCode) {
        return 'VERIFY'
      } else {
        return 'LOGIN'
      }
    },
    verifyTitle () {
      if (this.showCode) {
        return 'Verify the multi-factor authentication code'
      } else {
        return 'Log into iQuote'
      }
    },
    verifyClass () {
      if (this.showCode) {
        return 'verifyButton'
      } else {
        return 'loginButton'
      }
    }
  },
  mounted () {
    DataEventBus.$on('channel-resolved', () => {
      // console.log('channel-resolved')
    })
  },
  methods: {
    ...mapActions([
      'setUserid',
      'setLoggedOn'
    ]),
    resetForm () {
      this.username = ''
      this.password = ''
      this.clearErrorsAndTimeout()
      document.getElementById('txtUsername').focus()
    },
    clearErrorsAndTimeout () {
      clearTimeout(this.timeoutSet)
      this.usernameError = false
      this.passwordError = false
    },
    async attemptLogon () {
      if (!this.showCode) {
        this.usernameError = this.username.length < 5
        this.passwordError = this.password.length < 8 || this.password.length > 255
        if (this.usernameError) {
          this.$store.commit('showMessage', { content: 'Please enter a username of at least 5 characters but less than 255.', color: 'red', timeout: 5000 })
          this.timeoutSet = setTimeout(() => this.resetForm(), 1000)
        } else if (this.passwordError) {
          this.$store.commit('showMessage', { content: 'Please enter a password of at least 8 characters but less than 255.', color: 'red', timeout: 5000 })
          this.timeoutSet = setTimeout(() => this.resetForm(), 1000)
        } else {
          if (await this.sendLogon()) {
            if (this.useMFA === 'true') {
              this.showCode = true
              this.$store.commit('showMessage', { content: 'A verification code has been sent to your email address. Please copy the code into the code box provided.', color: 'blue', timeout: 8000 })
              this.$emit('VerifyCode', true)
              // document.getElementById('txtCode').focus()
            } else {
              this.setLoggedOn(true)
              this.pageRedirect(this.username)
            }
          }
        }
      } else {
        this.verifyToken()
      }
    },
    cancelLogon () {
      this.showCode = false
      this.code = ''
      this.$emit('VerifyCode', false)
      this.username = ''
      this.password = ''
      this.clearErrorsAndTimeout()
    },
    updateUsername (value) {
      this.username = value
      this.clearErrorsAndTimeout()
    },
    updatePassword (value) {
      this.password = value
      this.clearErrorsAndTimeout()
    },
    updateCode (value) {
      this.code = value
      this.clearErrorsAndTimeout()
    },
    selectPassword () {
      document.getElementById('pwdPassword').focus()
    },
    async sendLogon () {
      if (this.attemptingLogon) {
        return false
      }
      if (this.username.length === 0 || this.password.length === 0) {
        AuthEventBus.$emit('show-loader', false)
        this.usernameError = true
        this.passwordError = true
        this.$store.commit('showMessage', { content: 'Please enter a username and password in order to log in to the Portal.', color: 'red', timeout: 3000 })
        this.attemptingLogon = false
        return false
      }
      this.attemptingLogon = true
      AuthEventBus.$emit('show-loader', true, 'Attempting to login...')
      if (await this.authRequestSendLogon(this.username, this.password)) {
        AuthEventBus.$emit('show-loader', false)
        this.attemptingLogon = false
        return true
      } else {
        AuthEventBus.$emit('show-loader', false)
        this.usernameError = true
        this.passwordError = true
        this.$store.commit('showMessage', { content: 'Access Denied - Invalid Username and/or Password. If the problem persists please email portalquery@maintel.co.uk.', color: 'red', timeout: 6000 })
        this.attemptingLogon = false
        return false
      }
    },
    async verifyToken () {
      // this.attemptCount++
      // if (this.attemptCount > 5) {
      //   this.$store.commit('showMessage', { content: 'You have reached the maximum attempts; please wait 10 minutes and then try logging in again.', color: 'red', timeout: 5000 })
      //   return
      // }
      AuthEventBus.$emit('show-loader', true, 'Verifying token...')
      var req = '{"To": "' + this.$store.getters.tokens.email + '", "Code":"' + this.code.trim() + '", "Username":"' + this.username + '"}'
      try {
        var rtn = await this.verifyTwilioToken(req)
        AuthEventBus.$emit('show-loader', false)
        if (rtn) {
          AuthEventBus.$emit('logged-on', true)
          this.setLoggedOn(true)
          this.pageRedirect(this.username)
          this.showCode = false
        } else {
          // if (this.attemptCount === 5) {
          //   this.$store.commit('showMessage', { content: 'Verification failed. You have reached the maximum attempts; please wait 10 minutes and then try logging in again.', color: 'red', timeout: 5000 })
          //   this.startLoginTimer()
          // } else {
          this.$store.commit('showMessage', { content: 'The code entered is not valid. Please try again.', color: 'red', timeout: 5000 })
          // }
        }
      } catch (error) {
        console.log(error)
      }
      return rtn
    },
    pageRedirect (username) {
      DataEventBus.$emit('logged-on', username)
      var page = 'home'
      var returnUrl = localStorage.getItem('returnUrl')
      if (returnUrl && returnUrl !== '' && returnUrl !== 'undefined') {
        if (paths.find(o => o.path === returnUrl)) {
          page = paths.find(o => o.path === returnUrl).name
        }
        localStorage.setItem('returnUrl', undefined)
      }
      AuthEventBus.$emit('load-page', page)
    },
    startLoginTimer () {
      this.attemptCount++
      setTimeout(() => {
        this.attemptCount = 0
      }, 10 * 60 * 1000)
    }
  }
}
</script>

<style>
    .locContainer{
      width:100%;
      height: 100%;
      margin-top: 48px;
    }
    .locItem{
      width:100%;
    }
    .locMinRow{
      min-height: 30px;
    }
    .locButtons{
      align-items:flex-end;
      display:flex;
      margin-top:60px;
    }
    .locTextBox{
      align-self: center;
    }
    .mttContainer {
      margin-bottom: 10px;
    }
    .mttIcon {
      margin-top: 2px;
    }
    .loginButton {
      margin-left: 140px;
      transform: skewX(-30deg);
    }
    .loginButton p {
      transform: skewX(30deg);
      padding: 8px;
      margin: 0 12px;
      font-size: 1.2em;
      font-weight: bold;
      color: #fff;
      width: 150px;
    }
    .loginButton p:hover {
      color:#666;
    }
    .verifyButton {
      transform: skewX(-30deg);
      margin: 0 10px 0 0;
    }
    .verifyButton p {
      transform: skewX(30deg);
      padding: 8px;
      margin: 0 12px;
      font-size: 1.2em;
      font-weight: bold;
      color: #fff;
      width: 140px;
    }
    .verifyButton p:hover {
      color:#666;
    }
</style>
