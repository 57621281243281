<template>
  <div class="designPackSummaryContainer">
    <!-- Only load the table if the salesforce opportunity is loaded, as there are fields that will
    raise console errors in it's absence if we don't -->
    <table
      v-if="salesforceOpportunity.owner !== undefined"
      id="DesignOverviewTable"
      class="iquoteDesignpackTable">
      <tr>
        <th>DP Number</th>
        <td class="ReadonlyTableCell">{{ currentDesignPack.quoteDesignPackId + ' Revision ' + currentDesignPack.revisionNumber }}</td>
      </tr>
      <tr>
        <th>Created By</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.createdBy"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Customer Name</th>
        <td class="ReadonlyTableCell">{{ salesforceOpportunity.accountName }}</td>
      </tr>
      <tr>
        <th>Date</th>
        <td class="ReadonlyTableCell">{{ iQuoteStore_GetQuote.dateCreated !== undefined ? new Date(iQuoteStore_GetQuote.dateCreated).toDateString() : new Date(currentDesignPack.createDate).toDateString() }}</td>
      </tr>
      <tr>
        <th>HLD Reference</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.hldReference"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Lead Salesperson</th>
        <td class="ReadonlyTableCell"><span v-if="salesforceOpportunity.owner !== null">{{ salesforceOpportunity.owner.name }}</span></td>
      </tr>
      <tr>
        <th>Overall Solution Owner</th>
        <td class="ReadonlyTableCell"><span v-if="salesforceOpportunity.owner !== null">{{ salesforceOpportunity.solutionOwner }}</span></td>
      </tr>
      <tr>
        <th>Solution Specialist 1</th>
        <td class="ReadonlyTableCell"><span v-if="salesforceOpportunity.owner !== null">{{ salesforceOpportunity.solutionSpecialist1 }}</span></td>
      </tr>
      <tr>
        <th>Solution Specialist 2</th>
        <td class="ReadonlyTableCell"><span v-if="salesforceOpportunity.owner !== null">{{ salesforceOpportunity.solutionSpecialist2 }}</span></td>
      </tr>
      <tr>
        <th>Project Name</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.projectName"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Number of Sites</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model.number="currentDesignPack.numberOfSites"
            :disabled ="isReadOnly"
            type="number"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Practice lead</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.pmoReview"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th v-if="false">PMO Reviewer - Optional</th>
        <td
          v-if="false"
          :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.pmoReviewer"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Maintenance Take On</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.maintenanceTakeOn"
            :disabled ="isReadOnly"
            type="checkbox"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Professional Services Review</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.professionalServicesReview"
            :disabled ="isReadOnly"
            type="checkbox"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>New / Existing Customer</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <select
            id="cboSalesforceCoeCodes"
            v-model="currentDesignPack.newOrExistingCustomer"
            :disabled ="isReadOnly"
          >
            <option
              v-if="currentDesignPack.newOrExistingCustomer === ''"
              key=""
              value=""
              title="< Please Select >"
            >&lt; Please Select &gt;
            </option>
            <option
              key="New"
              value="New"
              title="New"
            >New
            </option>
            <option
              key="Existing"
              value="Existing"
              title="Existing"
            >Existing
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <th>Upgrade / Migration</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.upgradeMigration"
            :disabled="isReadOnly"
            type="checkbox"
            name=""
            @change="onUpgradeMigrationChanged()">
        </td>
      </tr>
      <tr
        v-if="currentDesignPack.upgradeMigration && designOverviewComponent.showUpgradeMigrationBanner"
        class="banner">
        <td colspan="2">
          <div>Ceases tab must be completed to highlight any<br> Circuit/Kit removal or decommissioned</div>
        </td>
      </tr>
      <tr>
        <th>Customer Contract Term (Months)</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model.number="currentDesignPack.termLengthMonths"
            :disabled ="isReadOnly"
            type="number"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Cisco VIP Rebate</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          £
          <input
            v-model.number="currentDesignPack.ciscoVIPRebate"
            :disabled ="isReadOnly"
            type="number"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>UC Provider</th>
        <td class="ReadonlyTableCell">
          <span>
            {{ iQuoteStore_GetDesignPack.ucaasProvider }}</span>
        </td>
      </tr>
      <tr>
        <th>UC Users (Calculated)</th>
        <td class="ReadonlyTableCell">
          <span
            :class="{overriddenValue: iQuoteStore_GetDesignPack.ucUsersOverride !== null &&
            iQuoteStore_GetDesignPack.ucUsersOverride !== iQuoteStore_GetDesignPack.ucUsers}" >
            {{ iQuoteStore_GetDesignPack.ucUsers }}</span>
        </td>
      </tr>
      <tr>
        <th>UC Users (Overridden Value)</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model.number="iQuoteStore_GetDesignPack.ucUsersOverride"
            :disabled ="isReadOnly"
            type="number"
            min="0"
            name=""
            @blur="validateAndSaveDesignPack(iQuoteStore_GetDesignPack)">
          <div
            v-if="iQuoteStore_GetDesignPack.ucUsersOverride !== null && iQuoteStore_GetDesignPack.ucUsersOverride !== iQuoteStore_GetDesignPack.ucUsers"
            class="InputError"
          >(Warning: Calculated value is {{ iQuoteStore_GetDesignPack.ucUsers }})
          </div>
        </td>
      </tr>
      <tr>
        <th>CC Provider</th>
        <td class="ReadonlyTableCell">
          <span>
            {{ iQuoteStore_GetDesignPack.ccaasProvider }}</span>
        </td>
      </tr>
      <tr>
        <th>CC Users (Calculated)</th>
        <td class="ReadonlyTableCell">
          <span
            :class="{overriddenValue: iQuoteStore_GetDesignPack.ccUsersOverride !== null &&
            iQuoteStore_GetDesignPack.ccUsersOverride !== iQuoteStore_GetDesignPack.ccUsers}" >
            {{ iQuoteStore_GetDesignPack.ccUsers }}</span>
        </td>
      </tr>
      <tr>
        <th>CC Users (Overridden Value)</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model.number="iQuoteStore_GetDesignPack.ccUsersOverride"
            :disabled ="isReadOnly"
            type="number"
            min="0"
            name=""
            @blur="validateAndSaveDesignPack(iQuoteStore_GetDesignPack)">
          <div
            v-if="iQuoteStore_GetDesignPack.ccUsersOverride !== null && iQuoteStore_GetDesignPack.ccUsersOverride !== iQuoteStore_GetDesignPack.ccUsers"
            class="InputError"
          >(Warning: Calculated value is {{ iQuoteStore_GetDesignPack.ccUsers }})
          </div>
        </td>
      </tr>
      <tr>
        <th>
          Contract Framework
          <span v-if="currentDesignPack.contractFrameworkId >0">
            ({{ iQuoteCalculationsDesignPackMixin.contractFrameworks.find(x => x.id === currentDesignPack.contractFrameworkId).managementCharge }}%)
          </span>
        </th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <select
            id="cboContractFrameworks"
            v-model="currentDesignPack.contractFrameworkId"
            :disabled ="isReadOnly"
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)"
          >
            <option
              v-for="t in iQuoteCalculationsDesignPackMixin.contractFrameworks"
              :key="t.id"
              :value="t.id"
              :title="t.name"
            >{{ t.name }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <th>Manufacturer Code (if applicable)</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.manufacturerCode"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Is NEW manufacturer Code required?</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.newManufacturerCodeRequired"
            :disabled ="isReadOnly"
            type="checkbox"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr>
        <th>Contract ID co-terminated with</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <input
            v-model="currentDesignPack.contractIdCoterminatedWith"
            :disabled ="isReadOnly"
            type="text"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
        </td>
      </tr>
      <tr v-if="currentDesignPack.quoteId !== null">
        <th>Subscription Type</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <select
            id="cboSubscriptionType"
            v-model="designOverviewComponent.quoteLicenseTypeId"
            :disabled ="isReadOnly"
            @blur="UpdateQuoteLicenseType()">
            <option
              v-if="designOverviewComponent.quoteLicenseTypeId === 0"
              key="0"
              value="0"
              title="< Please Select >"
            >&lt; Please Select &gt;
            </option>
            <option
              v-for="t in designOverviewComponent.quoteLicenseTypes"
              :key="t.id"
              :value="t.id"
              :title="t.name"
            >{{ t.name }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <th>Service Level</th>
        <td :class="{ReadonlyTableCell: isReadOnly}">
          <select
            id="cboServiceLevel"
            v-model="currentDesignPack.serviceLevelTypeId"
            :disabled ="isReadOnly"
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
            <option
              v-if="currentDesignPack.serviceLevelTypeId === 0"
              key="0"
              value="0"
              title="< Please Select >"
            >&lt; Please Select &gt;
            </option>
            <option
              v-for="t in designOverviewComponent.serviceLevels"
              :key="t.id"
              :value="t.id"
              :title="t.name"
            >{{ t.name }}
            </option>
          </select>
        </td>
      </tr>
    </table>
    <table
      id="DesignPackContentsTable"
      class="iquoteDesignpackTable"
      style="width: 625px">
      <tr>
        <th style="width: 190px">USD To GBP Exchange Rate</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
        >
          <input
            v-model.number="currentDesignPack.exchangeRateUSDToGBP"
            :disabled ="isReadOnly"
            type="number"
            name=""
            style="width: 90px"
            @change="currentDesignPack.exchangeRateUSDToGBPDate = new Date()"
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
          <label>USD per GBP</label>
          <div v-if="currentDesignPack.exchangeRateUSDToGBPDate !== undefined && currentDesignPack.exchangeRateUSDToGBPDate !== null">
            <label>(on {{ new Date(currentDesignPack.exchangeRateUSDToGBPDate).toDateString() }})</label>
          </div>
        </td>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          rowspan="2"
        >
          <input
            v-show="!isReadOnly"
            type="button"
            value="Open Exchange Rate Website"
            @click="openExchangeRateSite">
        </td>
      </tr>
      <tr>
        <th>GBP To EUR Exchange Rate</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
        >
          <input
            v-model.number="currentDesignPack.exchangeRateGBPToEUR"
            :disabled ="isReadOnly"
            type="number"
            name=""
            style="width: 90px"
            @change="currentDesignPack.exchangeRateGBPToEURDate = new Date()"
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)">
          <label>GBP per EUR</label>
          <div v-if="currentDesignPack.exchangeRateGBPToEURDate !== undefined && currentDesignPack.exchangeRateGBPToEURDate !== null">
            <label>(on {{ new Date(currentDesignPack.exchangeRateGBPToEURDate).toDateString() }})</label>
          </div>
        </td>
      </tr>
      <tr><td colspan="3">&nbsp;</td></tr>
      <tr>
        <th>Description</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          colspan="2">
          <textarea
            id=""
            v-model="currentDesignPack.description"
            :readonly="isReadOnly"
            name="txtDescription"
            style="width: 100%"
            rows="10"
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(currentDesignPack)"/></td>
      </tr>
      <tr/>
      <tr>
        <th>Tabs</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          colspan="2">
          <div>
            <input
              v-model="currentDesignPack.showPSTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowPSTabClicked($event)">
            <label>Professional Services</label>
          </div>
          <div>
            <input
              v-model="currentDesignPack.showAutotaskTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowAutotaskTabClicked($event)">
            <label>Autotask</label>
          </div>
          <div>
            <input
              v-model="currentDesignPack.showHardwareTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowHardwareTabClicked($event)">
            <label>Hardware</label>
          </div>
          <div>
            <input
              v-model="currentDesignPack.showCircuitsTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowCircuitsTabClicked($event)">
            <label>Circuits</label>
          </div>
          <div>
            <input
              v-model="currentDesignPack.showStandardCaveatsTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowStandardCaveatsTabClicked($event)">
            <label>Quotation Instructions</label>
          </div>
          <div>
            <input
              v-model="currentDesignPack.showCeasesTab"
              :disabled ="isReadOnly"
              type="checkbox"
              name=""
              @change="chkShowCeasesTabClicked($event)">
            <label>Ceases</label>
          </div>
        </td>
      </tr>
      <tr>
        <th>Add Order Co-ordination Charge</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          colspan="2">
          <input
            v-model="iQuoteStore_GetDesignPack.addPCTime"
            :disabled ="isReadOnly"
            style="margin-top: 2px"
            type="checkbox"
            name=""
            @change="changePCTime($event)">
        </td>
      </tr>
      <tr>
        <th>Are your Support Dates aligned between Customer and Vendor?</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          colspan="2">
          <input
            v-model="iQuoteStore_GetDesignPack.disableSupportContingency"
            :disabled ="isReadOnly"
            style="margin-top: 2px"
            type="checkbox"
            name=""
            @change="changeSupportContingency($event)">
        </td>
      </tr>
      <tr>
        <th>All charges should be paid upfront</th>
        <td
          :class="{ReadonlyTableCell: isReadOnly}"
          colspan="2">
          <input
            v-model="iQuoteStore_GetDesignPack.chargeUpfront"
            :disabled ="isReadOnly"
            style="margin-top: 2px"
            type="checkbox"
            name=""
            @blur="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(iQuoteStore_GetDesignPack)">
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsDesignPackPSMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackPS'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteStaticDataAPIMixin } from '../../../mixins/iQuote/iQuoteStaticDataAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'

import { mapActions } from 'vuex'

export default {
  name: 'DesignOverview',
  components: {
  },
  mixins: [
    iQuoteStoreMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackPSMixin,
    iQuoteCalculationsDesignPackMixin,
    iQuoteStaticDataAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteCalculationsCommonMixin
  ],
  props: {
    isReadOnly: {
      type: Boolean,
      default: function () { return {} }
    },
    salesforceOpportunity: {
      type: Object,
      default: function () { return {} }
    }
  },
  data () {
    return {
      designOverviewComponent: {
        quoteLicenseTypes: [],
        quoteLicenseTypeId: 0,
        serviceLevels: [],
        showUpgradeMigrationBanner: false
      }
    }
  },
  computed: {
    currentDesignPack () {
      return this.$store.getters.designPack
    },
    totalPSCost () {
      var total = 0.0
      this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(psCoreEntry => {
        total += this.iQuoteStore_getPsProfileScheduleCost(psCoreEntry.designPackPsRoleProfileId, psCoreEntry.designPackPsScheduleId) * psCoreEntry.units
      })

      this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(psOptionsEntry => {
        total += this.iQuoteStore_getPsProfileScheduleCost(psOptionsEntry.designPackPsRoleProfileId, psOptionsEntry.designPackPsScheduleId) * psOptionsEntry.units
      })

      this.iQuoteStore_GetDesignPack.psExpenseEntries.forEach(psExpenseEntry => {
        total += psExpenseEntry.unitCost * psExpenseEntry.units
      })

      this.iQuoteStore_GetDesignPack.psThirdPartyInstallationEntries.forEach(psThirdPartyInstallationEntry => {
        total += psThirdPartyInstallationEntry.unitCost * psThirdPartyInstallationEntry.units
      })

      this.iQuoteStore_GetDesignPack.psThirdPartyOtherEntries.forEach(psThirdPartyOtherEntry => {
        total += psThirdPartyOtherEntry.unitCost * psThirdPartyOtherEntry.units
      })

      return total
    }
  },
  watch: {
    async currentDesignPack () {
      if (this.currentDesignPack.quoteId !== null) {
        await this.iQuoteFunctions_delay(3000) // Wait a little while to get the quote from store
        this.designOverviewComponent.quoteLicenseTypeId = this.iQuoteStore_GetQuote.quoteLicenseTypeId
      }
    }
  },
  async mounted () {
    this.designOverviewComponent.quoteLicenseTypes = this.iQuoteCalculationsDesignPackMixin_filterQuoteLicenseTypes(this.$store.getters.applicationLicenseTypes)
    this.designOverviewComponent.serviceLevels = this.iQuoteCalculationsDesignPackMixin_filterQuoteServiceLevels(this.$store.getters.serviceLevels)
    await this.iQuoteFunctions_delay(3000) // Wait a little while to get the quote from store
    if (this.currentDesignPack.quoteId !== null) {
      this.designOverviewComponent.quoteLicenseTypeId = this.iQuoteStore_GetQuote.quoteLicenseTypeId
    }
  },
  methods: {
    ...mapActions([
      'setDesignPack',
      'setQuote'
    ]),
    async chkShowPSTabClicked (event) {
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showPSTabChanged', this.currentDesignPack.showPSTab)
    },
    async chkShowAutotaskTabClicked (event) {
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showAutotaskTabChanged', this.currentDesignPack.showAutotaskTab)
    },
    async chkShowHardwareTabClicked (event) {
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showHardwareTabChanged', this.currentDesignPack.showHardwareTab)
    },
    async chkShowCircuitsTabClicked (event) {
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showCircuitsTabChanged', this.currentDesignPack.showCircuitsTab)
    },
    async chkShowStandardCaveatsTabClicked (event) {
      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showStandardCaveatsTabChanged', this.currentDesignPack.showStandardCaveatsTab)
    },
    async chkShowCeasesTabClicked (event) {
      if (!this.currentDesignPack.showCeasesTab && this.currentDesignPack.upgradeMigration) {
        this.currentDesignPack.showCeasesTab = true
        this.iQuoteFunctions_ShowError('As Upgrade/Migration is selected, the Ceases tab must be visible')
        return
      }

      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      this.$emit('showCeasesTabChanged', this.currentDesignPack.showCeasesTab)
    },
    openExchangeRateSite () {
      window.open('https://www.xe.com/currencytables/?from=GBP', '_blank')
    },
    async UpdateQuoteLicenseType () {
      if (this.designOverviewComponent.quoteLicenseTypeId !== 0) {
        let quote = { ...this.iQuoteStore_GetQuote, quoteLicenseTypeId: this.designOverviewComponent.quoteLicenseTypeId }
        this.setQuote(quote)
        const updatedQuote = await this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(quote)
        await this.iQuoteFunctions_delay(1500) // Wait a little while for data to finish writing to DB
        if (updatedQuote.id === undefined) {
          this.iQuoteFunctions_ShowError('Failed to save quote')
        } else {
          this.calculationsCommon_Calculate()
          await this.calculationsCommon_saveQuoteCalculationsToDB()
        }
      }
    },
    onUpgradeMigrationChanged () {
      if (this.currentDesignPack.upgradeMigration) {
        this.currentDesignPack.showCeasesTab = true
        this.designOverviewComponent.showUpgradeMigrationBanner = true
        setTimeout(() => {
          this.designOverviewComponent.showUpgradeMigrationBanner = false
        }, 10000)
        this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
      }
    },
    async validateAndSaveDesignPack () {
      if (this.currentDesignPack.ucUsersOverride === '') {
        this.currentDesignPack.ucUsersOverride = null
      } else if (this.currentDesignPack.ucUsersOverride > 100000) {
        this.currentDesignPack.ucUsersOverride = 100000
      }

      if (this.currentDesignPack.ccUsersOverride === '') {
        this.currentDesignPack.ccUsersOverride = null
      } else if (this.currentDesignPack.ccUsersOverride > 100000) {
        this.currentDesignPack.ccUsersOverride = 100000
      }

      await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.currentDesignPack)
    },
    changePCTime (event) {
      if (!event.target.checked && !this.iQuoteStore_GetDesignPack.removedPCTime) {
        this.iQuoteStore_GetDesignPack.removedPCTime = true
      }
      this.iQuoteStore_GetDesignPack.priceOverrideOrderCoordination = null
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.iQuoteStore_GetDesignPack)
    },
    changeSupportContingency (event) {
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.iQuoteStore_GetDesignPack)
    }
  }
}
</script>
<style scoped>

span.overriddenValue {
  text-decoration: line-through
}
.iquoteDesignpackTable label {
  font-weight: bold;
}

#DesignOverviewTable {
  width: 510px;
  float: left;
  margin-bottom: 50px;
}

#DesignOverviewTable th{
  width: 200px;
}

#DesignOverviewTable td{
  width: 300px;
}

#DesignPackContentsTable {
  width: 530px;
  float: left;
  margin-left: 20px;
}

#DesignPackContentsTable th{
  width: 150px;
}

#DesignOverviewTable input[type=text]  {
  width: 300px;
  border: none;
}

#DesignOverviewTable input[type=number]  {
  width: 75px;
}

#DesignPackContentsTable input[type=checkbox]{
  margin-right: 15px;
}

#DesignPackContentsTable input[type=button] {
    background-color: navy;
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
}

.ReadonlyTableCell {
  background-color: silver;
}

.banner {
    margin: 2px;
    padding: 2px;
    background: #009579;
    color: #F5F5F5;
    font-size: large;
    text-align: center;
  }

</style>
