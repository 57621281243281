<template>
  <table
    id="mainTable"
    class="iquoteDesignpackTable noTableShading">
    <tr>
      <th
        width="250px">Task</th>
      <th width="80px">Profile</th>
      <th width="80px">Days / Units</th>
      <th width="80px">Schedule</th>
      <th width="80px">UnitCost</th>
      <th width="80px">Cost</th>
      <th width="115px">&nbsp;</th>
    </tr>
    <tbody
      v-for="psOptionsEntry in psOptionsTableEditorComponent.psOptionsEntries"
      :key="psOptionsEntry.id">
      <tr
        :class="{disabled: psOptionsEntry.disabled, deletedPSRoleProfileRow: hasDeletedPSRoleProfile(psOptionsEntry)}"
      >
        <td>
          <input
            :id="'taskInput' + psOptionsEntry.id"
            v-model="psOptionsEntry.task"
            :disabled="isReadOnly"
            class="descriptionInput"
            type="text"
            @blur="onInputTaskFocusLost(psOptionsEntry, $event)"
            @change="sendChangesToParent()">
        </td>
        <td>
          <select
            v-if="!isBlankEntry(psOptionsEntry)"
            :id="'profileInput' + psOptionsEntry.id"
            :class="{invalidInput: psOptionsEntry.designPackPsRoleProfileId === 0}"
            :disabled="isReadOnly"
            v-model="psOptionsEntry.designPackPsRoleProfileId"
            @change="sendChangesToParent()"
          >
            <option
              v-if="psOptionsEntry.designPackPsRoleProfileId === 0"
              key="0"
              value="0"
              title="< Please Select >"
            >&lt; Please Select &gt;
            </option>
            <option
              v-for="t in populatePSRoleProfile(psOptionsEntry)"
              :key="t.id"
              :value="t.id"
              :class="{activeOption: !t.deleted, deletedOption: t.deleted}"
              :title="t.description"
            >{{ t.description }}
            </option>
          </select>
        </td>
        <td>
          <input
            v-if="!isBlankEntry(psOptionsEntry)"
            :id="'unitsInput' + psOptionsEntry.id"
            v-model.number="psOptionsEntry.units"
            :class="{invalidInput: psOptionsEntry.units === 0}"
            :disabled="isReadOnly"
            type="number"
            min="1"
            oninput="this.value|=0"
            @change="sendChangesToParent()"
            @keyup="validateDayEntry(psOptionsEntry)">
        </td>
        <td>
          <select
            v-if="!isBlankEntry(psOptionsEntry)"
            :id="'cboSchedules' + psOptionsEntry.id"
            :class="{invalidInput: psOptionsEntry.designPackPsScheduleId === 0}"
            v-model="psOptionsEntry.designPackPsScheduleId"
            :disabled="isReadOnly"
            @change="sendChangesToParent()"
          >
            <option
              v-if="psOptionsEntry.designPackPsScheduleId === 0"
              key="0"
              value="0"
              title="< Please Select >"
            >&lt; Please Select &gt;
            </option>
            <option
              v-for="t in sortedPsSchedules"
              :key="t.id"
              :value="t.id"
              :class="{activeOption: !hasDeletedPSRoleProfile(psOptionsEntry), deletedOption: hasDeletedPSRoleProfile(psOptionsEntry)}"
              :title="t.description"
            >{{ t.description }}
            </option>
          </select>
        </td>
        <td class="readOnlyCell"><span v-if="!isBlankEntry(psOptionsEntry)">£{{ calcUnitCost(psOptionsEntry).toFixed(2) }}</span></td>
        <td class="readOnlyCell"><span v-if="!isBlankEntry(psOptionsEntry)">£{{ calcTotalUnitCost(psOptionsEntry).toFixed(2) }}</span></td>
        <td>
          <button
            v-if="!isBlankEntry(psOptionsEntry) && !isReadOnly && !psOptionsEntry.disabled"
            :id="'btnDisable' + psOptionsEntry.id.toString()"
            type="button"
            class="iQuoteIconButton"
            title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
            @click="disableEntry(psOptionsEntry)"
          >
            <img
              :src="iQuoteImagesMixin_iconPause"
              alt="remove">
          </button>
          <button
            v-if="!isBlankEntry(psOptionsEntry) && !isReadOnly && psOptionsEntry.disabled"
            :id="'btnDisable' + psOptionsEntry.id.toString()"
            type="button"
            class="iQuoteIconButton"
            title="Enable this entry so that the costs and prices are included in the design pack and quote"
            @click="enableEntry(psOptionsEntry)"
          >
            <img
              :src="iQuoteImagesMixin_iconResume"
              alt="insert">
          </button>
          <button
            v-if="!isBlankEntry(psOptionsEntry) && !isReadOnly && psOptionsEntry.units !== 0"
            :id="'btnNegate' + psOptionsEntry.id.toString()"
            type="button"
            class="iQuoteIconButton"
            title="Invert the quantity of this entry.  If it's positive make it negative and vice-versa"
            @click="negateEntry(psOptionsEntry)"
          >
            <img
              :src="iQuoteImagesMixin_iconPlusMinus"
              alt="negate">
          </button>
          <button
            v-if="!isBlankEntry(psOptionsEntry) && !isReadOnly"
            :id="'btnDelete' + psOptionsEntry.id.toString()"
            type="button"
            class="iQuoteIconButton"
            title="Delete this entry"
            style="margin-left: 15px"
            @click="deleteEntry(psOptionsEntry)"
          >
            <img
              :src="iQuoteImagesMixin_iconDelete"
              alt="delete">
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackPSMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackPS'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'

import { mapActions } from 'vuex'

export default {
  name: 'PsOptionsTableEditor',
  components: {
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackPSMixin, iQuoteImagesMixin ],
  props: {
  },
  data () {
    return {
      psOptionsTableEditorComponent: {
        psOptionsEntries: [],
        quoteDesignPackRevisionId: 0
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteStore_GetDesignPack.isReadOnly
    },
    sortedPsSchedules () {
      let psSchedules = []
      if (this.iQuoteStore_GetProfessionalServicesLookups.designPackPSSchedules) {
        this.iQuoteStore_GetProfessionalServicesLookups.designPackPSSchedules.forEach(item => {
          if (item.id !== this.iQuoteConstants.designPackPsRoleProfiles.NONE) {
            psSchedules.push(item)
          }
        })
        return psSchedules.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )
    this.initialise(this.iQuoteStore_GetDesignPack)
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    populatePSRoleProfile (psOptionsEntry) {
      let designPackPSRoleProfiles = []

      // populate the Profile dropdown on each entry
      this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.forEach(psRoleProfile => {
        const deletedRoleProfileFoundInEntry = psRoleProfile.deleted && psOptionsEntry.designPackPsRoleProfileId === psRoleProfile.id // a deleted role profile has been found in the entry
        if ((!psRoleProfile.deleted || deletedRoleProfileFoundInEntry) && psRoleProfile.id !== this.iQuoteConstants.designPackPsRoleProfiles.NONE) {
          designPackPSRoleProfiles.push(psRoleProfile)
        }
      })
      return designPackPSRoleProfiles
    },
    initialise (designPack) {
      if (designPack.quoteDesignPackRevisionId === undefined || this.psOptionsTableEditorComponent.quoteDesignPackRevisionId === designPack.quoteDesignPackRevisionId) {
        // There is no design pack, or the designpack that just saved, is the one we are currently looking at, so no need to reload
        return
      }

      this.psOptionsTableEditorComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId
      this.psOptionsTableEditorComponent.psOptionsEntries = []

      designPack.psOptionsEntries.forEach(entry => {
        this.psOptionsTableEditorComponent.psOptionsEntries.push(entry)
      })
      this.addBlankEntry()
    },
    onInputTaskFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    isBlankEntry (entry) {
      return entry.task === '' &&
          entry.designPackPsRoleProfileId === 0 &&
          entry.units === 0 &&
          entry.designPackPsScheduleId === 0
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.psOptionsTableEditorComponent.psOptionsEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    addBlankEntry () {
      this.psOptionsTableEditorComponent.psOptionsEntries.push(this.getBlankEntry())
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        task: '',
        designPackPsRoleProfileId: 0,
        units: 0,
        designPackPsScheduleId: 0,
        designPackItemLookupId: this.iQuoteCalculationsDesignPackMixin_getDesignPackItemLookupId()
      }
    },
    sendChangesToParent () {
      var newEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.psOptionsTableEditorComponent.psOptionsEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]

        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property]) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          newEntries.push(entry)
        }
      }

      this.$emit('tableUpdated', newEntries)
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.psOptionsTableEditorComponent.psOptionsEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    validateDayEntry (psOptionsEntry) {
      // validates if the value is a whole day or multiples of half a day (for example: 1.3 days must be invalid while 1.5 days is valid)
      const offset = psOptionsEntry.units * 2 % 2
      if (offset !== 0 && offset !== 1) {
        psOptionsEntry.units = Math.floor(psOptionsEntry.units) + 0.5
      }
    },
    deleteEntry (entry) {
      var arr = this.psOptionsTableEditorComponent.psOptionsEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(i, 1)
          this.sendChangesToParent()
          return
        }
      }
    },
    disableEntry (entry, sendChangesEvent = true) {
      entry.disabled = true
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    enableEntry (entry, sendChangesEvent = true) {
      entry.disabled = false
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    negateEntry (entry, sendChangesEvent = true) {
      entry.units = -entry.units
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    calcUnitCost (psOptionsEntry) {
      return this.iQuoteStore_getPsProfileScheduleCost(psOptionsEntry.designPackPsRoleProfileId, psOptionsEntry.designPackPsScheduleId)
    },
    calcTotalUnitCost (psOptionsEntry) {
      return this.iQuoteStore_getPsProfileScheduleCost(psOptionsEntry.designPackPsRoleProfileId, psOptionsEntry.designPackPsScheduleId) * psOptionsEntry.units
    },
    hasDeletedPSRoleProfile (psOptionsEntry) {
      return this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.some(x => x.deleted &&
      x.id !== this.iQuoteConstants.designPackPsRoleProfiles.NONE &&
      x.id === psOptionsEntry.designPackPsRoleProfileId)
    }
  }
}
</script>
<style scoped>
  #mainTable {
    margin-top: 15px;
    width: auto;
  }

  table input[type=text], table input[type=number], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
  }

  #mainTable input.descriptionInput {
    width: 250px;
  }

  .readOnlyCell {
    background-color: silver;
  }

  #mainTable tr.disabled td {
    background-color: silver;
  }

  .activeOption {
    color: black !important;
    background-color: white !important;
  }

  .deletedOption {
    color: red !important;
    background-color: white !important;
  }

  tr.deletedPSRoleProfileRow td {
    color: white !important;
    background-color: #DA291C;
  }

  tr.deletedPSRoleProfileRow td input {
    color: white !important;
  }

  tr.deletedPSRoleProfileRow td select {
    color: white !important;
    background-color: #DA291C;
  }

</style>
