import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsNetCallMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin
  ],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcNetCall_calculate: function () {
      if (this.iQuoteStore_GetQuoteAvayaAXP.id === undefined &&
        this.iQuoteStore_GetTeamsConnector.id === undefined &&
        this.iQuoteStore_GetQuoteGenesys.id === undefined &&
        this.iQuoteStore_GetGammaSip.id === undefined
      ) {
        return
      }

      this.calcNetCall_calculateLicensesAndUnitCosts()
    },
    calcNetCall_calculateLicensesAndUnitCosts () {
      var avayaAXP = this.iQuoteStore_GetQuoteAvayaAXP
      var teams = this.iQuoteStore_GetTeamsConnector
      var genesys = this.iQuoteStore_GetQuoteGenesys
      var gammaSIP = this.iQuoteStore_GetGammaSip

      var netCall = false
      var numSipChannels = 0

      if (gammaSIP.id !== undefined && gammaSIP.netCall === true) {
        netCall = true
        numSipChannels += gammaSIP.overridePrimarySIPChannels !== 0 ? gammaSIP.overridePrimarySIPChannels : gammaSIP.sipConfigured
      }

      if (teams.id !== undefined && teams.netCall === true) {
        netCall = true
        numSipChannels += teams.sipChannelsCalculated
      }

      if (avayaAXP.id !== undefined && avayaAXP.options.netCall === true) {
        netCall = true
        numSipChannels += avayaAXP.options.sipChannelsCalculated
      }

      if (genesys.id !== undefined && genesys.options.netCall === true) {
        netCall = true
        numSipChannels += genesys.options.sipChannelsCalculated
      }

      if (netCall) {
        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.NETCALL_12MONTH, 1)
            break
          case 36:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.NETCALL_36MONTH, 1)
            break
          case 60:
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.NETCALL_60MONTH, 1)
            break
        }

        var costPerYear = null
        var upfrontCost
        var upfrontPrice

        if (numSipChannels > 0) {
          // Half day of each type of PS for adding netcall to a new SIP solution
          upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR, this.iQuoteConstants.designPackPsSchedules.HALF_DAY)
          upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.NETCALL_PROJECT_COORDINATION_HALF_DAY, 1, costPerYear, upfrontCost, upfrontPrice)

          upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.ENGINEER_LEVEL_B, this.iQuoteConstants.designPackPsSchedules.HALF_DAY)
          upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.NETCALL_LEAD_ENGINEER_HALF_DAY, 1, costPerYear, upfrontCost, upfrontPrice)
        } else {
          // Adding netCall to an existing SIP solution rather than along with new SIP channels, so we need more PS
          upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR, this.iQuoteConstants.designPackPsSchedules.DAY)
          upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.NETCALL_PROJECT_COORDINATION_DAY, 1, costPerYear, upfrontCost, upfrontPrice)

          upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.ENGINEER_LEVEL_B, this.iQuoteConstants.designPackPsSchedules.DAY)
          upfrontPrice = upfrontCost * 2
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.NETCALL_LEAD_ENGINEER_DAY, 1, costPerYear, upfrontCost, upfrontPrice)
        }
      }
    }
  }
}
