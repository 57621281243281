import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { iQuoteCalculationsDesignPackHardwareMixin } from '../Calculations/iQuoteCalculationsDesignPackHardware'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteCalculationsDesignPackPSMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackPS'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'

export const iQuoteCalculationsDesignPackSalesforceUploadMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackHardwareMixin,
    iQuoteCalculationsDesignPackCircuitsMixin,
    iQuoteCalculationsDesignPackPSMixin,
    externalQuotesModelMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackSalesforceUploadMixin: {
      }
    }
  },
  async mounted () {
  },
  computed: {
  },
  methods: {
    iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairs () {
      var nameValuePairs = []
      var termLengthNameValuePair = {}
      var priceNameValuePair = {}
      var costNameValuePair = {}
      var sfCoeCodes = []

      /* Technology */
      sfCoeCodes = ['Technology', 'Tech (Callmedia) - Lic only', 'Technology subscriptions']
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Technology Selling Price', 'Product_Revenue__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Technology Cost', 'Tech_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Professional Services */
      sfCoeCodes = ['Professional Services']
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Professional Services Selling Price', 'Services_Revenue__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Professional Services Cost', 'Pro_Services_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Third Party Pro Services */
      sfCoeCodes = ['Third Party Pro Services']
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Third Party Pro Services Selling Price', 'Third_Party_Pro_Services_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Third Party Pro Services Cost', 'Third_Party_Pro_Services_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* NS Upfront */
      sfCoeCodes = ['NS Upfront', 'Data Connectivity', 'Voice Line Rental', 'SIP Trunking', 'CaaS', 'Call Traffic', 'Inbound', 'ICON Secure', 'Conferencing']
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('NS Upfront Selling Price', 'NS_Upfront_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('NS Upfront Cost', 'NS_Upfront_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* On Prem Tech */
      sfCoeCodes = ['Tech (Callmedia) - Lic only', 'Technology subscriptions']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('On Prem Tech Subscriptions Term Months', 'On_Prem_Tech_Subscriptions_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('On Prem Tech Subscriptions Selling Price', 'On_Prem_Tech_Subscriptions_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('On Prem Tech Subscriptions Cost', 'On_Prem_Tech_Subs_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Managed Services */
      sfCoeCodes = ['New Managed Services']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Managed Services Term Months', 'New_Managed_Service_Term__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Managed Services Selling Price', 'New_Managed_Services_Value__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Managed Services Cost', 'New_MS_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* New Third Party Support */
      sfCoeCodes = ['New Third Party Support']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Third Party Support Term Months', 'New_Third_Party_Term__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Third Party Support Selling Price', 'New_Third_Party_Support_Value__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Third Party Support Cost', 'New_Third_Party_Support_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* New Vendor Support */
      sfCoeCodes = ['New Vendor Support (SA)', 'New Vendor Support']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Vendor Support (SA) Term Months', 'New_Vendor_Support_Term__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Vendor Support (SA) Selling Price', 'New_Vendor_Support_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('New Vendor Support (SA) Cost', 'New_Vendor_Support_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Voice Line Rental */
      sfCoeCodes = ['Voice Line Rental']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Voice Line Rental Term Months', 'NS_Line_Rental_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Voice Line Rental Selling Price', 'NS_Line_Rental_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Voice Line Rental Cost', 'NS_Line_Rental_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Data Connectivity */
      sfCoeCodes = ['Data Connectivity']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Data Connectivity Term Months', 'NS_Data_Connectivity_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Data Connectivity Selling Price', 'NS_Data_Connectivity_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Data Connectivity Cost', 'NS_Data_Connectivity_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Call Traffic */
      sfCoeCodes = ['Call Traffic']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Call Traffic Term Months', 'NS_Call_Traffic_Term__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Call Traffic Selling Price', 'NS_Call_Traffic_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Call Traffic Cost', 'NS_Call_Traffic_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Inbound */
      sfCoeCodes = ['Inbound']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Inbound Term Months', 'NS_Inbound_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Inbound Selling Price', 'NS_Inbound_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Inbound Cost', 'NS_Inbound_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* SIP Trunking */
      sfCoeCodes = ['SIP Trunking']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('SIP Trunking Term Months', 'NS_SIP_Trunking_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('SIP Trunking Selling Price', 'NS_SIP_Trunking_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('SIP Trunking Cost', 'NS_SIP_Trunking_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* Conferencing */
      sfCoeCodes = ['Conferencing']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Conferencing Term Months', 'Conferencing_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Conferencing Selling Price', 'Conferencing_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Conferencing Cost', 'Conferencing_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* ICON Secure */
      sfCoeCodes = ['ICON Secure']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('ICON Secure Term Months', 'ICON_Secure_Term__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('ICON Secure Selling Price', 'ICON_Secure_Selling_Price__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('ICON Secure Cost', 'ICON_Secure_Cost_Price__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      /* CaaS */
      sfCoeCodes = ['Caas']
      termLengthNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('CaaS Term Months', 'NS_CaaS_Term_Months__c', this.iQuoteStore_GetDesignPack.termLengthMonths)
      priceNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('CaaS Selling Price', 'NS_CaaS_Sell__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice(sfCoeCodes))
      costNameValuePair = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('CaaS Cost', 'NS_CaaS_Cost__c', this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost(sfCoeCodes))
      if (costNameValuePair.value !== 'fred0.00' || priceNameValuePair.value !== 'fred0.00') {
        nameValuePairs.push(termLengthNameValuePair)
        nameValuePairs.push(priceNameValuePair)
        nameValuePairs.push(costNameValuePair)
      }

      var ucUsers = this.iQuoteStore_GetDesignPack.ucUsers

      if (this.iQuoteStore_GetDesignPack.ucUsersOverride !== null) {
        ucUsers = this.iQuoteStore_GetDesignPack.ucUsersOverride
      }

      var ccUsers = this.iQuoteStore_GetDesignPack.ccUsers

      if (this.iQuoteStore_GetDesignPack.ccUsersOverride !== null) {
        ccUsers = this.iQuoteStore_GetDesignPack.ccUsersOverride
      }

      var UCaaS = ''
      var provider = ''

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        UCaaS = 'ICON Communicate (Avaya Aura)'
        provider = 'Avaya'
      } else if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined || this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined) {
        UCaaS = 'ICON Communicate (Mitel)'
        provider = 'Mitel (Maintel Cloud, powered by Mitel)'
      } else if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        provider = 'Gamma (Maintel Cloud, powered by Gamma)'
      } else if (this.iQuoteStore_GetQuoteRingCentral.ucTechId !== undefined) {
        UCaaS = 'Ringcentral Cloud Office (RCO)'
        provider = 'Ring Central'
      } else if (this.iQuoteStore_GetQuoteGenesys.id !== undefined) {
        provider = 'Genesys'
      } else if (this.iQuoteStore_GetTeamsConnector.id !== undefined) {
        UCaaS = 'ICON Teams Connector'
        provider = ''
      } else if (this.externalQuotesModelMixin_State.quoteIconNow.id !== undefined) {
        UCaaS = 'Icon Now'
        provider = 'Maintel (ICON or Private Cloud Co-Location)'
      }

      if (UCaaS !== '') {
        // If the setting is blank in salesforce, then override, otherwise leave it to what it was
        nameValuePairs.push(
          this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('UCaaS Solution', 'CaaS_Provider__c', UCaaS)
        )
      }

      if (provider !== '') {
        nameValuePairs.push(
          this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('CaaS Provider', 'NS_CaaS_provider__c', provider)
        )
      }
      var CCaaS = ''

      if (ccUsers > 0) {
        if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined ||
          this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
          CCaaS = 'ICON Contact (Avaya)'
        } else if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
          CCaaS = 'ICON Contact (Mitel)'
        } else if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id !== undefined) {
          CCaaS = 'Callmedia CX Now'
        } else if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
          CCaaS = 'ICON Contact (Callmedia)'
        } else if (this.$_totalRingCentralCCAgentCount() > 0) {
          CCaaS = 'Ringcentral Cloud Office (RCO)'
        } else if (this.iQuoteStore_GetQuoteGenesys.id !== undefined) {
          CCaaS = 'Genesys Cloud'
        }
      }

      if (CCaaS !== '') {
        // If the setting is blank in salesforce, then override, otherwise leave it to what it was
        nameValuePairs.push(
          this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('CCaaS Solution', 'CaaS_Provider2__c', CCaaS)
        )
      }

      nameValuePairs.push(
        this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('No of New UC Users', 'No_of_new_UC_seats_excl_ICON_Now__c', ucUsers)
      )

      nameValuePairs.push(
        this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('No of New CC Users', 'No_of_new_CC_seats_excl_ICON_Now__c', ccUsers)
      )

      nameValuePairs.push(
        this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject('Cisco VIP Rebate', 'Cisco_VIP_Rebate__c', this.iQuoteStore_GetDesignPack.ciscoVIPRebate)
      )

      console.log('Salesforce Upload:', nameValuePairs)

      return {
        nameValuePairs: nameValuePairs
      }
    },

    /********************************
    * PRIVATE METHODS
    **********************************/

    $_totalRingCentralCCAgentCount () {
      if (this.iQuoteStore_GetQuoteRingCentral.ccTechId === undefined) {
        return 0
      }

      var total = 0
      this.iQuoteStore_GetQuoteRingCentral.ccUserProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.DIGITAL_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.ESSENTIALS_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.STANDARD_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.PREMIUM_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.ULTIMATE_AGENT ||
            item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.UNLIMITED_AGENT
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairObject (description, fieldname, value) {
      return {
        description: description,
        name: fieldname,
        value: value.toString()
      }
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes (CodesToInclude) {
      var salesforceCodesInLowerCase = CodesToInclude.map(code => code.toLowerCase())
      return this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => salesforceCodesInLowerCase.includes(x.SF_COE.toLowerCase()) && this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(x) === false)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringSellingPrice (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.upfrontSellPrice + entry.recurringSellPrice, 0).toFixed(2)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontAndRecurringCost (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.upfrontInternalCost + entry.upfrontExternalCost + entry.recurringInternalCost + entry.recurringExternalCost, 0).toFixed(2)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringSellingPrice (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.recurringSellPrice, 0).toFixed(2)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesRecurringCost (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.recurringInternalCost + entry.recurringExternalCost, 0).toFixed(2)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontSellingPrice (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.upfrontSellPrice, 0).toFixed(2)
    },
    $_iQuoteCalculationsDesignPackSalesforceUploadMixin_getCodesUpfrontCost (sfCoeToInclude) {
      var entries = this.$_iQuoteCalculationsDesignPackSalesforceUploadMixin_getDesignPackEntriesBySalesforceCodes(sfCoeToInclude)
      return entries.reduce((previousValue, entry) => previousValue + entry.upfrontInternalCost + entry.upfrontExternalCost, 0).toFixed(2)
    }
  }
}
