<template>
  <v-content>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <core-footer v-if="$route.name !== 'Maps'" />
    <Chat />
  </v-content>
</template>

<script>
import Chat from '../../components/common/chat'
export default {
  metaInfo () {
    return {
      title: 'iQuote'
    }
  },
  components: {
    Chat
  }
}
</script>

<style>
#core-view {
  background: white;
  padding-right: 4vw;
}
</style>
