import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsiQuoteModuleStatsMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteStoreMixin
  ],
  data () {
    return {}
  },
  computed: {
    iQuoteCalculationsiQuoteModuleStatsMixin_ucAnalyticsFoundationsUserCount () {
      var returnVal = 0

      returnVal += this.iQuoteCalculationsiQuoteModuleStatsMixin_mitelUCUserCount
      returnVal += this.iQuoteCalculationsiQuoteModuleStatsMixin_mitelUCE2UserCount
      returnVal += this.iQuoteCalculationsiQuoteModuleStatsMixin_auraUserCount

      return returnVal
    },
    iQuoteCalculationsiQuoteModuleStatsMixin_auraUserCount () {
      var auraCount = 0
      var aura = this.iQuoteStore_GetQuoteTechnologyAvayaAura

      if (aura.userProfiles !== undefined) {
        aura.userProfiles.forEach(userProfile => {
          auraCount += userProfile.minCommitment
        })
      }
      return auraCount
    },
    iQuoteCalculationsiQuoteModuleStatsMixin_mitelUCUserCount () {
      var mitelUCCount = 0
      var mitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC

      if (mitelUC.userProfiles !== undefined) {
        mitelUC.userProfiles.forEach(userProfile => {
          mitelUCCount += userProfile.minCommitment
        })
      }
      return mitelUCCount
    },
    iQuoteCalculationsiQuoteModuleStatsMixin_mitelUCE2UserCount () {
      var mitelUCE2 = this.iQuoteStore_GetQuoteTechnologyMitelUCE2
      var users = 0

      if (mitelUCE2.userProfiles !== undefined) {
        var ucUserProfiles = mitelUCE2.userProfiles.filter(x => x.userProfileCategoryId === this.iQuoteConstants.MitelUCE2UserProfileCategories.MITEL_UC)

        ucUserProfiles.forEach(userProfile => {
          users += userProfile.minCommitment
        })
      }

      return users
    },
    iQuoteCalculationsiQuoteModuleStatsMixin_teamsUserCount () {
      var teamsCount = 0
      var teams = this.iQuoteStore_GetTeamsConnector

      if (teams.numTeamsUsers !== undefined) {
        teamsCount = teams.numTeamsUsers
      }

      return teamsCount
    },
    iQuoteCalculationsiQuoteModuleStatsMixin_ringCentralUserCount () {
      var ringCentralCount = 0
      var ringCentral = this.iQuoteStore_GetQuoteRingCentral

      if (ringCentral.ucUserProfiles !== undefined) {
        ringCentral.ucUserProfiles.forEach(userProfile => {
          if (userProfile.userProfileCategoryId === this.iQuoteConstants.RingCentralUCUserProfileCategories.PROFILES) {
            ringCentralCount += userProfile.minCommitment
          }
        })
      }
      return ringCentralCount
    }
  },
  created () {
  },
  methods: {
  }
}
