import { render, staticRenderFns } from "./adjunctsAACC.vue?vue&type=template&id=624c6606&scoped=true"
import script from "./adjunctsAACC.vue?vue&type=script&lang=js"
export * from "./adjunctsAACC.vue?vue&type=script&lang=js"
import style0 from "./adjunctsAACC.vue?vue&type=style&index=0&id=624c6606&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624c6606",
  null
  
)

export default component.exports