<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote</label>
      <img
        :src="iconCommunicate"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select required Technology vendor</p>
      <TooltipIcon text="Select Required Technology Vendor - Further vendors to be added" />
      <br>
      <TechnologyTile
        v-for="t in serviceTechnologies"
        :technology-id="t.id"
        :technology-name="t.technologyName"
        :key="t.id"
      />
      <ServiceCategoryTile
        v-for="t in serviceCategories"
        :service-category-id="t.id"
        :service-category-name="t.serviceCategoryName"
        :key="t.id"
        @removed="setService()"
      />
      <br><br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteTechnologyAPIMixin } from '../../../../mixins/iQuote/iQuoteTechnologyAPIMixin'
import TooltipIcon from '../../../common/tooltipIcon'
import TechnologyTile from '../../tiles/iQuoteTechnologyTile'
import ServiceCategoryTile from '../../tiles/iQuoteServiceCategoryTile'
export default {
  name: 'IconCommunicate',
  components: {
    TechnologyTile,
    TooltipIcon,
    ServiceCategoryTile
  },
  mixins: [iQuoteTechnologyAPIMixin],
  data () {
    return {
      serviceTechnologies: [],
      quote: {},
      serviceCategories: []
    }
  },
  computed: {
    question () {
      return require(`../../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../../assets/icons/MaintelUCPrivatePlus.png`)
    },
    toiQuotePrevious () {
      return require(`../../../../assets/icons/toiQuotePrev.png`)
    }
  },
  mounted () {
    this.setService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    setService: async function () {
      this.quote = this.$store.getters.quote
      var technologies = await this.getTechnologiesByServiceId(this.$store.getters.currentService)

      var categories = await this.getServiceCategoriesByServiceId(this.$store.getters.currentService)
      this.serviceCategories = []

      for (let index = 0; index < categories.length; index++) {
        const cat = categories[index]

        if (cat.id > 0) {
          this.serviceCategories.push(cat)

          var technologiesInCategory = await this.getTechnologiesByServiceCategoryId(cat.id)

          technologiesInCategory.forEach(techInCategory => {
          // Remove technology tile if it is under this service category
            technologies = technologies.filter(tech => tech.id !== techInCategory.id)
          })
        }
      }

      this.serviceTechnologies = technologies
    }
  }
}
</script>
<style scoped>
</style>
