<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconTeamsConnector"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="teamsConnectorRecordingsComponent.quote.quoteOpportunityId + ' (revision ' + teamsConnectorRecordingsComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Teams Connector - Call Recording</h3>
      <br>
      <label
        :class="{InputError:!isValidnumRecordingChannels}"
        for="numRecordingChannels"
      >Number of Recording Channels<TooltipIcon
        text="Number of Recording Channels" />
      </label>
      <input
        id="numRecordingChannels"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingChannels"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        class=""
        for="cboSubscriptionBillingPeriod"
      >Recording Type</label>
      <select
        id="cboSubscriptionBillingPeriod"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.teamsConnectorRecordingTypeId"
      >
        <option
          key="1"
          value="1"
          title="Voice"
        >Voice</option>
        <option
          key="2"
          value="2"
          title="UC"
        >UC</option>
      </select>
      <label
        class=""
      >Compliance Required</label>
      <TriStateToggleSwitch
        ref="sql-toggle"
        :enabled="true"
        :current-state="teamsConnectorRecordingsComponent.teamsConnector.recordingComplianceRequired ? 1 : 2"
        :tri-state="false"
        class="toggleSwitch"
        tabindex="1"
        @statechanged="recordingComplianceRequiredChanged()"
      />
      <label
        class=""
      >Add-on Analytics<TooltipIcon
        width="550"
        text="<div>This add-on enables the usage of analytics packages for the respective users.  The add-on enables (based on the subscribed analytics packages):<br/><br/>
        <ul>
          <li>Visualised Transcript</li>
          <li>Highlight Keywords</li>
          <li>Categorisation on basis of keywords</li>
          <li>Displaying sentiment</li>
          <li>Usage of additional analystics users rights * access management for specified named users. Analysis rules can be determined</li>
          <li>Usage of aditional reporting</li>
        </ul>
        </div>" /></label>
      <TriStateToggleSwitch
        ref="sql-toggle"
        :enabled="true"
        :current-state="teamsConnectorRecordingsComponent.teamsConnector.recordingAnalyticsRequired ? 1 : 2"
        :tri-state="false"
        class="toggleSwitch"
        tabindex="1"
        @statechanged="teamsConnectorRecordingsComponent.teamsConnector.recordingAnalyticsRequired = !teamsConnectorRecordingsComponent.teamsConnector.recordingAnalyticsRequired"
      />
      <label
        :class="{InputError:!isValidNumRecordingTranscriptionPackages}"
        for="numRecordingTranscriptionPackages"
      >Transcription Packages (100hrs)<TooltipIcon
        text="Package contains the ability to convert 100h of speech to text based on the Microsoft Cognitive Services" />
      </label>
      <input
        id="numRecordingTranscriptionPackages"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingTranscriptionPackages"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidNumRecordingTranslationPackages}"
        for="numRecordingTranslationPackages"
      >Translation Packages (100hrs)
        <TooltipIcon text="Package offers the ability to do a translation of the transcribed text based on the Microsoft Cognitive Services" />
      </label>
      <input
        id="numRecordingTranslationPackages"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingTranslationPackages"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidNumRecordingStorageUnits}"
        for="numnumRecordingStorageUnits"
      >Storage Units (500gb)<TooltipIcon
        text="This storage option includes the storage of up to 500Gb in a Microsoft Azure block object Storage Options for Complance" />
      </label>
      <input
        id="numnumRecordingStorageUnits"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingStorageUnits"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        class=""
        for="cboRecordingRedundencyOptionId"
      >Redundancy Options
      </label>
      <select
        id="cboRecordingRedundencyOptionId"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.teamsConnectorRecordingRedundencyOptionId"
      >
        <option
          key="1"
          value="1"
          title="Local"
        >Local</option>
        <option
          v-if="teamsConnectorRecordingsComponent.teamsConnector.recordingComplianceRequired"
          key="2"
          value="2"
          title="Geo-redundancy"
        >Geo-redundancy</option>
      </select>
      <label
        :class="{InputError:!isValidNumRecordingArchiveStorageUnits}"
        for="numRecordingArchiveStorageUnits"
      >Archive Storage Units (500gb)<TooltipIcon
        text="Includes storage of 500gb in a Microsoft Azure block object Storage with access tier archive and local redundancy" />
      </label>
      <input
        id="numRecordingArchiveStorageUnits"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingArchiveStorageUnits"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        :class="{InputError:!isValidNumRecordingExportPackages}"
        for="numRecordingExportPackages"
      >Export Packages (500gb)<TooltipIcon
        text="Applies if the recorded data is to be exported from the Azure storage location to another location outside of Azure" />
      </label>
      <input
        id="numRecordingExportPackages"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.numRecordingExportPackages"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        class=""
        for="cboTeamsConnectorRecordingExportTypeId"
      >Export Type
      </label>
      <select
        id="cboTeamsConnectorRecordingExportTypeId"
        v-model.number="teamsConnectorRecordingsComponent.teamsConnector.teamsConnectorRecordingExportTypeId"
      >
        <option
          key="1"
          value="1"
          title="Public"
        >Public</option>
        <option
          key="2"
          value="2"
          title="Azure"
        >Azure</option>
      </select>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('TeamsConnector')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('TeamsConnectorAttendantConsole')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'TeamsConnectorRecordings',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin],
  data () {
    return {
      teamsConnectorRecordingsComponent: {
        quote: {},
        teamsConnector: {
          id: 0,
          numRecordingChannels: 0,
          numRecordingTranscriptionPackages: 0,
          numRecordingTranslationPackages: 0,
          numRecordingStorageUnits: 0,
          teamsConnectorRecordingTypeId: 1, // 1 = Voice, 2 = UC
          teamsConnectorRecordingRedundencyOptionId: 1, // 1 = Local, 2 = Geo-redundancy
          numRecordingArchiveStorageUnits: 0,
          recordingComplianceRequired: false,
          recordingAnalyticsRequired: false,
          numRecordingExportPackages: 0,
          teamsConnectorRecordingExportTypeId: 1 // 1 = Public, 2 = Azure
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconTeamsConnector () {
      return require(`../../../assets/icons/MaintelTeamsConnector.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumRecordingTranslationPackages () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingTranslationPackages
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumRecordingTranscriptionPackages () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingTranscriptionPackages
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidnumRecordingChannels () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingChannels
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumRecordingStorageUnits () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingStorageUnits
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumRecordingArchiveStorageUnits () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingArchiveStorageUnits
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumRecordingExportPackages () {
      var value = this.teamsConnectorRecordingsComponent.teamsConnector.numRecordingExportPackages
      return (this.iQuoteFunctions_IsInteger(value))
    }
  },
  async mounted () {
    this.teamsConnectorRecordingsComponent.quote = this.$store.getters.quote
    this.teamsConnectorRecordingsComponent.teamsConnector = this.iQuoteStore_GetTeamsConnector
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTeamsConnector'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      if (!this.isValidNumRecordingTranslationPackages ||
          !this.isValidNumRecordingTranscriptionPackages ||
          !this.isValidnumRecordingChannels ||
          !this.isValidNumRecordingStorageUnits ||
          !this.isValidNumRecordingArchiveStorageUnits ||
          !this.isValidNumRecordingExportPackages
      ) {
        ValidationFailed = true
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      }

      this.setQuoteTeamsConnector(this.teamsConnectorRecordingsComponent.teamsConnector)
      await this.saveQuoteTeamsConnectorProperties(this.teamsConnectorRecordingsComponent.teamsConnector)
      this.setCurrentQuoteState(page)
    },
    recordingComplianceRequiredChanged () {
      this.teamsConnectorRecordingsComponent.teamsConnector.recordingComplianceRequired = !this.teamsConnectorRecordingsComponent.teamsConnector.recordingComplianceRequired

      if (!this.teamsConnectorRecordingsComponent.teamsConnector.recordingComplianceRequired && this.teamsConnectorRecordingsComponent.teamsConnector.teamsConnectorRecordingRedundencyOptionId === 2) {
        this.iQuoteFunctions_ShowError('Redundancy will be changed to "Local", as "Geo-Redundancy" is not supported without compliance')
        this.teamsConnectorRecordingsComponent.teamsConnector.teamsConnectorRecordingRedundencyOptionId = 1 // Local, as Geo-Redundancy not support when no compliance
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width:320px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}
</style>
