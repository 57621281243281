/*******************************************************
 * Maintel Table Handler
 *
 * This mixin has been created to add client paging and
 * sorting to bound view tables. It only includes the
 * paging footer if more than one page of results is
 * returned.
 *
 * To use this mixin, reference it in your page, create a
 * table row (tr) bound to the data export 'currentFilter'.
 * The associated cells (td) should be bound to the fields
 * included in the column object (see below). The
 * 'initialiseTable' method should be called after object
 * population with the following parameters:
 *
 * Parameters:
 *    tblId               The id of the table to attach to
 *    objs                The resolved object array to populate
 *                        the table with.
 *    cols                An array of object column names to
 *                        display in the header
 *    labels              An array of labels for the columns
 *    types               (optional) an array of column types
 *    height              (optional) the height of the table.
 *                        Defaults to '650px'
 *    widths              (optional) an array of widths to apply
 *                        to the columns. If not provided, all
 *                        columns will have the same width
 *    rowsperpage         (optional) The number of rows per pare
 *                        to display in the table grid
 *    showsearchalways    (optional) If the search controls will
 *                        be shown regardless of the number of
 *                        records returned.
 *    downloadFile        (optional) If the name of a download
 *                        file is provided, the download links is
 *                        displayed. This is the full name including
 *                        the extension.
 *
 * The style of the table is defined (in this project) in
 * the App.vue file for global application.
 *
 * For use in the iQuote, tab indexes have been put
 * on the header and footer controls. They have events
 * attached enabling the space bar to activate them. The
 * tab orders assigned are:
 *
 *  Header Columns        15
 *  Footer Controls       25
 *
 * These values are used as the row tab order is set to
 * 20 in this project.
 *******************************************************/

export default {
  data () {
    return {
      cols: [],
      labels: [],
      widths: [],
      types: [],
      originalObject: [],
      currentObject: [],
      currentFilter: [],
      currentTableId: '',
      objPerPage: 25,
      currentPageNo: 1,
      totRecords: 1,
      totPages: 1,
      tableHeight: '650px',
      showSearchAlways: false,
      showDownload: false,
      downloadFile: '',
      showPaginationAtTop: false
    }
  },
  methods: {
    initialiseTable: async function (tblId, objs, cols, labels, types, height, widths, rowsperpage, showsearchalways, downloadFile, showPaginationAtTop) {
      this.cols = cols
      this.labels = labels
      this.widths = widths
      this.types = types
      this.currentTableId = tblId
      this.originalObject = objs
      this.currentObject = objs
      this.totRecords = this.currentObject.length
      if (downloadFile) {
        this.showDownload = true
        this.downloadFile = downloadFile
      } else {
        this.showDownload = false
      }
      if (showsearchalways) {
        this.showSearchAlways = showsearchalways
      } else {
        this.showSearchAlways = false
      }
      if (showPaginationAtTop) {
        this.showPaginationAtTop = showPaginationAtTop
      } else {
        this.showPaginationAtTop = false
      }
      if (rowsperpage !== null && Number(rowsperpage) > 5) { this.objPerPage = Number(rowsperpage) }
      this.totPages = Math.ceil(this.currentObject.length / this.objPerPage)
      if (height !== null) { this.tableHeight = height }
      var tbl = document.getElementById(this.currentTableId)
      if (tbl) {
        var tfoot = tbl.getElementsByTagName('tfoot')[0]
        if (tfoot) { tbl.removeChild(tfoot) }

        if (this.currentObject.length > 0) {
          if (showPaginationAtTop) {
            this.getPagingHeader(cols.length)
          } else {
            tbl.appendChild(this.getPagingFooter(cols.length))
          }
          this.getSortingHeader()
          if (widths != null) { this.setColumnWidths(tbl) }
          this.currentFilter = this.getPage()
          this.setPageType()
          this.gotoFirstPage()
        } else {
          this.currentFilter = []
          tbl.appendChild(this.getNoResultsFooter(cols.length))
        }
      }
    },
    setColumnWidths (tbl) {
      var ths = tbl.childNodes[0].childNodes[0].childNodes
      for (let i = 0; i < ths.length; i++) {
        ths[i].style.width = this.widths[i]
      }
    },
    upArrow () {
      return require(`../assets/icons/up_arrow.png`)
    },
    downArrow () {
      return require(`../assets/icons/down_arrow.png`)
    },
    toFirst () {
      return require(`../assets/icons/toFirst.png`)
    },
    toPrev () {
      return require(`../assets/icons/toPrev.png`)
    },
    toNext () {
      return require(`../assets/icons/toNext.png`)
    },
    toLast () {
      return require(`../assets/icons/toLast.png`)
    },
    download () {
      return require(`../assets/icons/download.png`)
    },
    selectPageSize () {
      var tbl = document.getElementById(this.currentTableId)
      var pageSizeCtl = tbl.getElementsByClassName('pageSize')[0]
      this.currentPageNo = 1
      this.objPerPage = pageSizeCtl.value
      this.currentFilter = this.getPage()
    },
    changePage () {
      var tbl = document.getElementById(this.currentTableId)
      var pageSortNo = tbl.getElementsByClassName('pageSortNo')[0].value
      if (isNaN(pageSortNo)) { pageSortNo = 1 }
      if (pageSortNo > this.totPages) { pageSortNo = this.totPages }
      tbl.getElementsByClassName('pageSortNo')[0].value = pageSortNo
      this.currentPageNo = pageSortNo
      this.currentFilter = this.getPage()
    },
    gotoFirstPage () {
      this.currentPageNo = 1
      var tbl = document.getElementById(this.currentTableId)
      tbl.getElementsByClassName('pageSortNo')[0].value = this.currentPageNo
      this.currentFilter = this.getPage()
    },
    gotoPreviousPage () {
      if (this.currentPageNo > 1) {
        this.currentPageNo = Number(this.currentPageNo) - 1
      }
      var tbl = document.getElementById(this.currentTableId)
      tbl.getElementsByClassName('pageSortNo')[0].value = this.currentPageNo
      this.currentFilter = this.getPage()
    },
    gotoNextPage () {
      if (this.currentPageNo >= this.totPages) {
        this.currentPageNo = this.totPages
      } else {
        this.currentPageNo = Number(this.currentPageNo) + 1
      }
      var tbl = document.getElementById(this.currentTableId)
      tbl.getElementsByClassName('pageSortNo')[0].value = this.currentPageNo
      this.currentFilter = this.getPage()
    },
    gotoLastPage () {
      this.currentPageNo = this.totPages
      var tbl = document.getElementById(this.currentTableId)
      tbl.getElementsByClassName('pageSortNo')[0].value = this.currentPageNo
      this.currentFilter = this.getPage()
    },
    getNoResultsFooter (span) {
      var tfoot = document.createElement('tfoot')
      var tr = document.createElement('tr')
      var td = document.createElement('td')
      td.setAttribute('colspan', span)
      td.setAttribute('class', 'tableNoResultsLabel')
      td.setAttribute('style', 'text-align: center')
      td.innerText = 'No Results'
      tr.appendChild(td)
      tfoot.appendChild(tr)
      return tfoot
    },
    getPagingFooter (span) {
      var tfoot = document.createElement('tfoot')
      var tr = document.createElement('tr')
      var tdDownload = document.createElement('td')
      tdDownload.setAttribute('style', 'text-align: left')
      var buttonDownload = document.createElement('img')
      buttonDownload.setAttribute('tabindex', '25')
      buttonDownload.id = `buttonDownload${this.currentTableId}`
      buttonDownload.title = `Download table as ${this.downloadFile}`
      buttonDownload.src = this.download()
      buttonDownload.style = 'width: 32px;'
      buttonDownload.onclick = this.exportCSVFile
      if (this.showDownload) {
        tdDownload.appendChild(buttonDownload)
        tr.appendChild(tdDownload)
      }
      var td = document.createElement('td')
      if (this.showDownload) {
        td.setAttribute('colspan', span - 1)
      } else {
        td.setAttribute('colspan', span)
      }
      td.setAttribute('style', 'text-align: center')
      var buttonFirst = document.createElement('img')
      buttonFirst.setAttribute('tabindex', '25')
      buttonFirst.id = `firstPage${this.currentTableId}`
      buttonFirst.onclick = this.gotoFirstPage
      buttonFirst.onkeyup = this.fireCommand
      buttonFirst.title = 'Go to the first page of results'
      buttonFirst.src = this.toFirst()
      td.appendChild(buttonFirst)
      var buttonPrevious = document.createElement('img')
      buttonPrevious.setAttribute('tabindex', '25')
      buttonPrevious.id = `previousPage${this.currentTableId}`
      buttonPrevious.onclick = this.gotoPreviousPage
      buttonPrevious.onkeyup = this.fireCommand
      buttonPrevious.title = 'Go to the previous page of results'
      buttonPrevious.src = this.toPrev()
      td.appendChild(buttonPrevious)
      var spanPageSize = document.createElement('span')
      spanPageSize.className = 'tableSortLabel'
      spanPageSize.innerHTML = 'Per Page'
      // td.appendChild(spanPageSize)
      var selectPageSize = document.createElement('select')
      selectPageSize.setAttribute('v-model', 'selectedValue')
      selectPageSize.onchange = this.selectPageSize
      selectPageSize.className = 'tableSortLabel pageSize'
      var opt1 = document.createElement('option')
      opt1.value = 25
      if (this.objPerPage === opt1.value) { opt1.setAttribute('selected', 'true') }
      opt1.appendChild(document.createTextNode('10'))
      selectPageSize.appendChild(opt1)
      var opt2 = document.createElement('option')
      opt2.value = 25
      if (this.objPerPage === opt2.value) { opt2.setAttribute('selected', 'true') }
      opt2.appendChild(document.createTextNode('25'))
      selectPageSize.appendChild(opt2)
      var opt3 = document.createElement('option')
      opt3.value = 50
      if (this.objPerPage === opt3.value) { opt3.setAttribute('selected', 'true') }
      opt3.appendChild(document.createTextNode('50'))
      selectPageSize.appendChild(opt3)
      // td.appendChild(selectPageSize)
      var spanPages = document.createElement('span')
      spanPages.className = 'tableSortLabel'
      spanPages.innerHTML = 'Page'
      td.appendChild(spanPages)
      var inputPageNo = document.createElement('input')
      inputPageNo.type = 'text'
      inputPageNo.setAttribute('tabindex', '25')
      inputPageNo.id = `pageSortNo${this.currentTableId}`
      inputPageNo.value = this.currentPageNo + ''
      inputPageNo.className = 'tableSortField pageSortNo'
      inputPageNo.onchange = this.changePage
      td.appendChild(inputPageNo)
      var spanOf = document.createElement('span')
      spanOf.className = 'tableSortLabel'
      spanOf.innerHTML = 'Of'
      td.appendChild(spanOf)
      var inputPageTot = document.createElement('input')
      inputPageTot.type = 'text'
      inputPageTot.setAttribute('tabindex', '25')
      inputPageTot.id = `pageSortTot${this.currentTableId}`
      inputPageTot.value = this.totPages + ''
      inputPageTot.setAttribute('readonly', true)
      inputPageTot.className = 'tableSortField pageSortTot'
      td.appendChild(inputPageTot)
      var spanRecords = document.createElement('span')
      spanRecords.className = 'tableSortLabel'
      spanRecords.id = `tableSortLabel${this.currentTableId}`
      spanRecords.innerHTML = '(Total: ' + this.totRecords + ')'
      td.appendChild(spanRecords)
      var buttonNext = document.createElement('img')
      buttonNext.setAttribute('tabindex', '25')
      buttonNext.id = `nextPage${this.currentTableId}`
      buttonNext.onclick = this.gotoNextPage
      buttonNext.onkeyup = this.fireCommand
      buttonNext.title = 'Go to the next page of results'
      buttonNext.src = this.toNext()
      td.appendChild(buttonNext)
      var buttonLast = document.createElement('img')
      buttonLast.setAttribute('tabindex', '25')
      buttonLast.id = `lastPage${this.currentTableId}`
      buttonLast.onclick = this.gotoLastPage
      buttonLast.onkeyup = this.fireCommand
      buttonLast.title = 'Go to the last page of results'
      buttonLast.src = this.toLast()
      td.appendChild(buttonLast)
      var searchBox = document.createElement('input')
      searchBox.id = `tableSearchBox${this.currentTableId}`
      searchBox.type = 'text'
      searchBox.placeholder = 'Search...'
      searchBox.setAttribute('name', `tableSearchBox${this.currentTableId}`)
      searchBox.setAttribute('tabindex', '25')
      searchBox.setAttribute('autocomplete', 'off')
      searchBox.className = 'tableSearchBox'
      searchBox.onkeyup = this.searchFor
      // searchBox.onkeyup = this.setPageType
      td.appendChild(searchBox)
      tr.appendChild(td)
      tfoot.appendChild(tr)

      return tfoot
    },
    getPagingHeader (span) {
      var tbl = document.getElementById(this.currentTableId)
      var thead = tbl.getElementsByTagName('thead')[0]
      thead.innerHTML = ''
      var tr = document.createElement('tr')
      var tdDownload = document.createElement('td')
      tdDownload.setAttribute('style', 'text-align: left')
      var buttonDownload = document.createElement('img')
      buttonDownload.setAttribute('tabindex', '25')
      buttonDownload.id = `buttonDownload${this.currentTableId}`
      buttonDownload.title = `Download table as ${this.downloadFile}`
      buttonDownload.src = this.download()
      buttonDownload.style = 'width: 32px;'
      buttonDownload.onclick = this.exportCSVFile
      if (this.showDownload) {
        tdDownload.appendChild(buttonDownload)
        tr.appendChild(tdDownload)
      }
      var td = document.createElement('td')
      if (this.showDownload) {
        td.setAttribute('colspan', span - 1)
      } else {
        td.setAttribute('colspan', span)
      }
      td.setAttribute('style', 'text-align: center')
      var buttonFirst = document.createElement('img')
      buttonFirst.setAttribute('tabindex', '25')
      buttonFirst.id = `firstPage${this.currentTableId}`
      buttonFirst.onclick = this.gotoFirstPage
      buttonFirst.onkeyup = this.fireCommand
      buttonFirst.title = 'Go to the first page of results'
      buttonFirst.src = this.toFirst()
      td.appendChild(buttonFirst)
      var buttonPrevious = document.createElement('img')
      buttonPrevious.setAttribute('tabindex', '25')
      buttonPrevious.id = `previousPage${this.currentTableId}`
      buttonPrevious.onclick = this.gotoPreviousPage
      buttonPrevious.onkeyup = this.fireCommand
      buttonPrevious.title = 'Go to the previous page of results'
      buttonPrevious.src = this.toPrev()
      td.appendChild(buttonPrevious)
      var spanPageSize = document.createElement('span')
      spanPageSize.className = 'tableSortLabel'
      spanPageSize.innerHTML = 'Per Page'
      // td.appendChild(spanPageSize)
      var selectPageSize = document.createElement('select')
      selectPageSize.setAttribute('v-model', 'selectedValue')
      selectPageSize.onchange = this.selectPageSize
      selectPageSize.className = 'tableSortLabel pageSize'
      var opt1 = document.createElement('option')
      opt1.value = 25
      if (this.objPerPage === opt1.value) { opt1.setAttribute('selected', 'true') }
      opt1.appendChild(document.createTextNode('10'))
      selectPageSize.appendChild(opt1)
      var opt2 = document.createElement('option')
      opt2.value = 25
      if (this.objPerPage === opt2.value) { opt2.setAttribute('selected', 'true') }
      opt2.appendChild(document.createTextNode('25'))
      selectPageSize.appendChild(opt2)
      var opt3 = document.createElement('option')
      opt3.value = 50
      if (this.objPerPage === opt3.value) { opt3.setAttribute('selected', 'true') }
      opt3.appendChild(document.createTextNode('50'))
      selectPageSize.appendChild(opt3)
      // td.appendChild(selectPageSize)
      var spanPages = document.createElement('span')
      spanPages.className = 'tableSortLabel'
      spanPages.innerHTML = 'Page'
      td.appendChild(spanPages)
      var inputPageNo = document.createElement('input')
      inputPageNo.type = 'text'
      inputPageNo.setAttribute('tabindex', '25')
      inputPageNo.id = `pageSortNo${this.currentTableId}`
      inputPageNo.value = this.currentPageNo + ''
      inputPageNo.className = 'tableSortField pageSortNo'
      inputPageNo.onchange = this.changePage
      td.appendChild(inputPageNo)
      var spanOf = document.createElement('span')
      spanOf.className = 'tableSortLabel'
      spanOf.innerHTML = 'Of'
      td.appendChild(spanOf)
      var inputPageTot = document.createElement('input')
      inputPageTot.type = 'text'
      inputPageTot.setAttribute('tabindex', '25')
      inputPageTot.id = `pageSortTot${this.currentTableId}`
      inputPageTot.value = this.totPages + ''
      inputPageTot.setAttribute('readonly', true)
      inputPageTot.className = 'tableSortField pageSortTot'
      td.appendChild(inputPageTot)
      var spanRecords = document.createElement('span')
      spanRecords.className = 'tableSortLabel'
      spanRecords.id = `tableSortLabel${this.currentTableId}`
      spanRecords.innerHTML = '(Total: ' + this.totRecords + ')'
      td.appendChild(spanRecords)
      var buttonNext = document.createElement('img')
      buttonNext.setAttribute('tabindex', '25')
      buttonNext.id = `nextPage${this.currentTableId}`
      buttonNext.onclick = this.gotoNextPage
      buttonNext.onkeyup = this.fireCommand
      buttonNext.title = 'Go to the next page of results'
      buttonNext.src = this.toNext()
      td.appendChild(buttonNext)
      var buttonLast = document.createElement('img')
      buttonLast.setAttribute('tabindex', '25')
      buttonLast.id = `lastPage${this.currentTableId}`
      buttonLast.onclick = this.gotoLastPage
      buttonLast.onkeyup = this.fireCommand
      buttonLast.title = 'Go to the last page of results'
      buttonLast.src = this.toLast()
      td.appendChild(buttonLast)
      var searchBox = document.createElement('input')
      searchBox.id = `tableSearchBox${this.currentTableId}`
      searchBox.type = 'text'
      searchBox.placeholder = 'Search...'
      searchBox.setAttribute('name', `tableSearchBox${this.currentTableId}`)
      searchBox.setAttribute('tabindex', '25')
      searchBox.setAttribute('autocomplete', 'off')
      searchBox.className = 'tableSearchBox'
      searchBox.onkeyup = this.searchFor
      // searchBox.onkeyup = this.setPageType
      td.appendChild(searchBox)
      tr.appendChild(td)
      thead.appendChild(tr)
    },

    getPage () {
      var start = (this.currentPageNo - 1) * this.objPerPage
      var end = this.currentPageNo * this.objPerPage
      var rtnObj = this.currentObject.slice(start, end)

      this.setPageType()
      return rtnObj
    },
    setPageType () {
      this.removeFillerRow()
      var start = (this.currentPageNo - 1) * this.objPerPage
      var end = this.currentPageNo * this.objPerPage
      if (end > this.totRecords) {
        end = this.totRecords
      }
      if (this.totRecords <= this.objPerPage && this.currentObject.length === this.originalObject.length) {
        this.setSinglePage()
      } else if (end - start < this.objPerPage || this.currentObject.length < this.objPerPage) {
        this.setMultiplePage()
      }
    },
    setSinglePage () {
      var tbl = document.getElementById(this.currentTableId)
      if (tbl) {
        tbl.style.height = 'auto'
        var tfoot = tbl.getElementsByTagName('tfoot')[0]
        if (tfoot && !this.showSearchAlways) {
          tfoot.style.display = 'none'
        }
      }
    },
    setMultiplePage () {
      // Check if we have rows to fill. If we do we need to create an extra row to add to the table body
      var tbl = document.getElementById(this.currentTableId)
      if (tbl) {
        tbl.style.height = this.tableHeight
        var tbody = tbl.getElementsByTagName('tbody')[0]
        if (tbody) {
          var trFill = document.createElement('tr')
          trFill.id = `fillerRow${this.currentTableId}`
          trFill.className = `fillerRow`
          var tdFill = document.createElement('td')
          tdFill.setAttribute('colspan', this.cols.length)
          tdFill.style.height = '100%'
          trFill.appendChild(tdFill)
          tbody.appendChild(trFill)
        }
      }
    },
    removeFillerRow () {
      var filler = document.getElementById(`fillerRow${this.currentTableId}`)
      if (filler != null) {
        filler.remove()
      }
    },
    getSortingHeader () {
      var tbl = document.getElementById(this.currentTableId)
      var thead = tbl.getElementsByTagName('thead')[0]
      if (!this.showPaginationAtTop) {
        thead.innerHTML = ''
      }
      var tr = document.createElement('tr')
      for (let i = 0; i < this.labels.length; i++) {
        const label = this.labels[i]
        var th = document.createElement('th')
        var arrow = document.createElement('img')
        arrow.src = this.upArrow()
        arrow.style.opacity = 0
        arrow.style.marginLeft = '5px'
        th.innerHTML = label
        th.title = label
        th.name = this.cols[i]
        th.className = 'columnHeader'
        if (this.types) {
          if (this.types.length > 0) {
            th.abbr = this.types[i].toLowerCase()
          }
        }
        th.onclick = this.doSort
        th.onkeyup = this.fireCommand
        th.setAttribute('tabindex', '15')
        th.onmouseenter = this.showArrow
        th.onmouseleave = this.hideArrow
        if (label.length > 0) {
          th.appendChild(arrow)
        }
        tr.appendChild(th)
      }
      thead.appendChild(tr)
    },
    doSort (e) {
      var dataType = ''
      var el = e.target
      var col = e.target.name
      var arrow = e.target.getElementsByTagName('img')
      if (col === '') {
        // We have clicked on the image icon, so resolve the target via the parent
        el = e.target.parentElement
        col = e.target.parentElement.name
        arrow = e.target.parentElement.getElementsByTagName('img')
      }
      if (arrow.length > 0) {
        if (this.currentSort === 'asc' && this.currentSortColumn === col) {
          this.currentSort = 'desc'
          arrow[0].src = this.downArrow()
        } else if (this.currentSort === 'desc' && this.currentSortColumn === col) {
          this.currentSort = 'asc'
          arrow[0].src = this.upArrow()
        } else {
          this.currentSort = 'asc'
          arrow[0].src = this.upArrow()
        }
      }
      arrow.onclick = this.doSort
      this.currentSortColumn = col
      if (el.abbr) { dataType = el.abbr }
      this.reorderDataSet(dataType)
      this.hideArrow(e)

      this.currentPageNo = 1
      this.gotoFirstPage()
    },
    fireCommand (e) {
      if (e.keyCode === 32) {
        var id = e.srcElement.id
        if (id.indexOf('Page') > -1) {
          if (id.indexOf('firstPage') > -1) { this.gotoFirstPage() }
          if (id.indexOf('nextPage') > -1) { this.gotoNextPage() }
          if (id.indexOf('previousPage') > -1) { this.gotoPreviousPage() }
          if (id.indexOf('lastPage') > -1) { this.gotoLastPage() }
        } else {
          this.doSort(e)
        }
      }
    },
    showArrow (e) {
      var arrow = e.target.getElementsByTagName('img')
      if (arrow.length > 0) {
        arrow[0].style.opacity = 0.9
      }
    },
    hideArrow (e) {
      if (e.target.parentNode.childNodes) {
        for (let i = 0; i < e.target.parentNode.childNodes.length; i++) {
          const element = e.target.parentNode.childNodes[i]
          if (element.nodeName !== '#text') {
            var arr = element.getElementsByTagName('img')
            if (arr.length > 0) {
              if (this.currentSortColumn !== element.name) {
                arr[0].style.opacity = 0
              } else {
                arr[0].style.opacity = 0.9
              }
            }
          }
        }
      }
    },
    sortBy (property) {
      var sortOrder = 1
      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        var propertyANotANumber = isNaN(a[property])
        var propertyBNotANumber = isNaN(b[property])
        var result = ((propertyANotANumber ? a[property].toLowerCase() : a[property]) < (propertyBNotANumber ? b[property].toLowerCase() : b[property])) ? -1 : ((propertyANotANumber ? a[property].toLowerCase() : a[property]) > (propertyBNotANumber ? b[property].toLowerCase() : b[property])) ? 1 : 0
        return result * sortOrder
      }
    },
    sortByDate (property) {
      var sortOrder = 1
      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        var ad = a[property]
        var bd = b[property]
        // if we always have dd-mm-yyyy hh:mm but need yyyy-mm-ddThh:mm
        a = new Date(ad.substr(6, 4) + '-' + ad.substr(3, 2) + '-' + ad.substr(0, 2) + 'T' + ad.substr(11, 5))
        b = new Date(bd.substr(6, 4) + '-' + bd.substr(3, 2) + '-' + bd.substr(0, 2) + 'T' + bd.substr(11, 5))
        var result = a - b
        return result * sortOrder
      }
    },
    reorderDataSet (dataType) {
      var lcolName = this.currentSortColumn
      if (dataType.toLowerCase() === 'date') {
        if (this.currentSort === 'desc') {
          this.currentObject.sort(this.sortByDate('-' + lcolName))
        } else {
          this.currentObject.sort(this.sortByDate(lcolName))
        }
      } else {
        if (this.currentSort === 'desc') {
          this.currentObject.sort(this.sortBy('-' + lcolName))
        } else {
          this.currentObject.sort(this.sortBy(lcolName))
        }
      }
    },
    searchFor () {
      if (document.getElementById(`tableSearchBox${this.currentTableId}`)) {
        var str = document.getElementById(`tableSearchBox${this.currentTableId}`).value
        var pageSortTot = document.getElementById(`pageSortTot${this.currentTableId}`)
        var pageSortNo = document.getElementById(`pageSortNo${this.currentTableId}`)
        var spanRecords = document.getElementById(`tableSortLabel${this.currentTableId}`)
        if (str.length > 0) {
          this.currentObject = []
          this.originalObject.forEach(element => {
            var foundOne = false
            for (var key in element) {
              if (element[key] && element[key].toString().toLowerCase().indexOf(str.toLowerCase()) > -1) {
                foundOne = true
              }
            }
            if (foundOne) { this.currentObject.push(element) }
          })
          spanRecords.innerHTML = '(Filtered: ' + this.currentObject.length + ')'
        } else {
          this.currentObject = this.originalObject
          spanRecords.innerHTML = '(Total: ' + this.originalObject.length + ')'
        }
      }
      // set the variable state and send the current filter to the parent page
      this.totRecords = this.currentObject.length
      if (this.currentObject.length === 0) {
        this.totPages = 1
      } else {
        this.totPages = Math.ceil(this.currentObject.length / this.objPerPage)
      }
      pageSortNo.value = 1
      pageSortTot.value = this.totPages
      this.currentFilter = this.getPage()
      // we need to be on the first page of results (also stops the spacer being upside down!)
      this.$nextTick(() => this.gotoFirstPage())
    },
    convertToCSV (objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (const index in array[0]) {
        if (this.cols.includes(index)) {
          if (str !== '') str += ','
          str += index.replace(/_/g, ' ')
        }
      }
      str += '\r\n'
      for (let i = 0; i < array.length; i++) {
        let line = ''
        for (const index in array[i]) {
          if (this.cols.includes(index)) {
            if (line !== '') line += ','
            line += '"' + array[i][index] + '"'
          }
        }
        str += line + '\r\n'
      }
      return str
    },
    exportCSVFile () {
      const jsonObject = JSON.stringify(this.originalObject)
      const csv = this.convertToCSV(jsonObject)
      const exportedFilename = this.downloadFile || 'export.csv'
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.$store.commit('showMessage', { content: 'The download of ' + exportedFilename + ' completed successfully.', color: 'green', timeout: 3000 })
        }
      }
    }
  }
}
