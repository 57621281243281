// This module is used for state mamangement for iQuote related variables
const state = {
  quote: {},
  quotes: [],
  quoteTechnologyAvayaAura: {},
  quoteTechnologyCallmedia: {},
  quoteTechnologyAvayaElite: {},
  quoteTechnologyAvayaAACC: {},
  quoteTechnologyMitelCC: {},
  quoteTechnologyMitelUC: {},
  quoteTechnologyCallmediaCXNow: {},
  quoteTechnologyIconGateway: {},
  quoteGammaSIP: {},
  quoteSIPMigrationProperties: {},
  quoteLicensesRequired: [],
  quoteVMsRequired: [],
  quoteBladeServers: [],
  quoteUnitCosts: [],
  quoteDesignPackEntries: [],
  quoteCustomerSummaryEntries: [],
  quoteUsers: [],
  quoteExpansions: {},
  quoteCallmediaCXNowDdiDetails: {},
  teamsConnector: {},
  quotePhoneLinePlus: {},
  quoteGenesys: {},
  designPack: {},
  designPackList: [],
  quoteRingCentral: {},
  quoteSycurio: {},
  quoteUCAnalytics: {},
  quoteAvayaAXP: {},
  designPackDetailedDataEntries: [],
  quoteTechnologyMitelUCE2: {}
}

const mutations = {
  SET_DESIGNPACK (state, designPack) {
    state.designPack = designPack
  },
  SET_DESIGNPACK_DETAILED_DATA_ENTRIES (state, designPackDetailedDataEntries) {
    state.designPackDetailedDataEntries = designPackDetailedDataEntries
  },
  SET_DESIGNPACK_LIST (state, designPackList) {
    state.designPackList = designPackList
  },
  SET_QUOTE (state, quote) {
    state.quote = quote
  },
  SET_QUOTE_USERS (state, quoteUsers) {
    state.quoteUsers = quoteUsers
  },
  SET_QUOTES (state, quotes) {
    state.quotes = quotes
  },
  SET_QUOTES_TECHNOLOGY_AVAYA_AURA (state, quoteTechnologyAvayaAura) {
    state.quoteTechnologyAvayaAura = quoteTechnologyAvayaAura
  },
  SET_QUOTES_TECHNOLOGY_CALLMEDIA (state, quoteTechnologyCallmedia) {
    state.quoteTechnologyCallmedia = quoteTechnologyCallmedia
  },
  SET_QUOTES_TECHNOLOGY_CALLMEDIACXNOW (state, quoteTechnologyCallmediaCXNow) {
    state.quoteTechnologyCallmediaCXNow = quoteTechnologyCallmediaCXNow
  },
  SET_QUOTES_TECHNOLOGY_ICONGATEWAY (state, quoteTechnologyIconGateway) {
    state.quoteTechnologyIconGateway = quoteTechnologyIconGateway
  },
  SET_QUOTES_TECHNOLOGY_AVAYA_ELITE (state, quoteTechnologyAvayaElite) {
    state.quoteTechnologyAvayaElite = quoteTechnologyAvayaElite
  },
  SET_QUOTES_TECHNOLOGY_AVAYA_AACC (state, quoteTechnologyAvayaAACC) {
    state.quoteTechnologyAvayaAACC = quoteTechnologyAvayaAACC
  },
  SET_QUOTES_TECHNOLOGY_MITEL_CC (state, quoteTechnologyMitelCC) {
    state.quoteTechnologyMitelCC = quoteTechnologyMitelCC
  },
  SET_QUOTES_TECHNOLOGY_MITEL_UC (state, quoteTechnologyMitelUC) {
    state.quoteTechnologyMitelUC = quoteTechnologyMitelUC
  },
  SET_QUOTES_TECHNOLOGY_MITEL_UC_E2 (state, quoteTechnologyMitelUCE2) {
    state.quoteTechnologyMitelUCE2 = quoteTechnologyMitelUCE2
  },
  SET_QUOTE_CALLMEDIA_CXNOW_DDI_DETAILS (state, quoteCallmediaCXNowDdiDetails) {
    state.quoteCallmediaCXNowDdiDetails = quoteCallmediaCXNowDdiDetails
  },
  SET_QUOTES_GAMMA_SIP (state, quoteGammaSIP) {
    state.quoteGammaSIP = quoteGammaSIP
  },
  SET_QUOTES_PHONELINEPLUS (state, quotePhoneLinePlus) {
    state.quotePhoneLinePlus = quotePhoneLinePlus
  },
  SET_QUOTES_TEAMS_CONNECTOR (state, teamsConnector) {
    state.teamsConnector = teamsConnector
  },
  SET_QUOTES_GENESYS (state, quoteGenesys) {
    state.quoteGenesys = quoteGenesys
  },
  SET_QUOTES_RINGCENTRAL (state, quoteRingCentral) {
    state.quoteRingCentral = quoteRingCentral
  },
  SET_QUOTES_SYCURIO (state, quoteSycurio) {
    state.quoteSycurio = quoteSycurio
  },
  SET_QUOTES_UC_ANALYTICS (state, quoteUCAnalytics) {
    state.quoteUCAnalytics = quoteUCAnalytics
  },
  SET_QUOTES_AVAYA_AXP (state, quoteAvayaAXP) {
    state.quoteAvayaAXP = quoteAvayaAXP
  },
  SET_QUOTES_EXPANSIONS (state, quoteExpansions) {
    state.quoteExpansions = quoteExpansions
  },
  SET_QUOTES_SIP_MIGRATION_PROPERTIES (state, quoteSIPMigrationProperties) {
    state.quoteSIPMigrationProperties = quoteSIPMigrationProperties
  },
  SET_QUOTES_LICENSES_REQUIRED (state, quoteLicensesRequired) {
    state.quoteLicensesRequired = quoteLicensesRequired
  },
  SET_QUOTES_VMS_REQUIRED (state, quoteVMsRequired) {
    state.quoteVMsRequired = quoteVMsRequired
  },
  SET_QUOTES_BLADE_SERVERS_REQUIRED (state, quoteBladeServers) {
    state.quoteBladeServers = quoteBladeServers
  },
  SET_QUOTES_UNIT_COSTS (state, quoteUnitCosts) {
    state.quoteUnitCosts = quoteUnitCosts
  },
  SET_QUOTES_DESIGN_PACK_ENTRIES (state, quoteDesignPackEntries) {
    state.quoteDesignPackEntries = quoteDesignPackEntries
  },
  SET_QUOTES_CUSTOMER_SUMMARY_ENTRIES (state, quoteCustomerSummaryEntries) {
    state.quoteCustomerSummaryEntries = quoteCustomerSummaryEntries
  }
}

const actions = {
  setDesignPack: ({ commit }, designPack) => {
    commit('SET_DESIGNPACK', designPack)
  },
  setDesignPackDetailedDataEntries: ({ commit }, designPackDetailedDataEntries) => {
    commit('SET_DESIGNPACK_DETAILED_DATA_ENTRIES', designPackDetailedDataEntries)
  },
  setDesignPackList: ({ commit }, designPackList) => {
    commit('SET_DESIGNPACK_LIST', designPackList)
  },
  setQuoteUsers: ({ commit }, quoteUsers) => {
    commit('SET_QUOTE_USERS', quoteUsers)
  },
  setQuote: ({ commit }, quote) => {
    commit('SET_QUOTE', quote)
  },
  setQuotes: ({ commit }, quotes) => {
    commit('SET_QUOTES', quotes)
  },
  setQuoteTechnologyAvayaAura: ({ commit }, quoteTechnologyAvayaAura) => {
    commit('SET_QUOTES_TECHNOLOGY_AVAYA_AURA', quoteTechnologyAvayaAura)
  },
  setQuoteTechnologyCallmedia: ({ commit }, quoteTechnologyCallmedia) => {
    commit('SET_QUOTES_TECHNOLOGY_CALLMEDIA', quoteTechnologyCallmedia)
  },
  setQuoteTechnologyCallmediaCXNow: ({ commit }, quoteTechnologyCallmediaCXNow) => {
    commit('SET_QUOTES_TECHNOLOGY_CALLMEDIACXNOW', quoteTechnologyCallmediaCXNow)
  },
  setQuoteTechnologyIconGateway: ({ commit }, quoteTechnologyIconGateway) => {
    commit('SET_QUOTES_TECHNOLOGY_ICONGATEWAY', quoteTechnologyIconGateway)
  },
  setQuoteTechnologyAvayaElite: ({ commit }, quoteTechnologyAvayaElite) => {
    commit('SET_QUOTES_TECHNOLOGY_AVAYA_ELITE', quoteTechnologyAvayaElite)
  },
  setQuoteTechnologyAvayaAACC: ({ commit }, quoteTechnologyAvayaAACC) => {
    commit('SET_QUOTES_TECHNOLOGY_AVAYA_AACC', quoteTechnologyAvayaAACC)
  },
  setQuoteTechnologyMitelCC: ({ commit }, quoteTechnologyMitelCC) => {
    commit('SET_QUOTES_TECHNOLOGY_MITEL_CC', quoteTechnologyMitelCC)
  },
  setQuoteTechnologyMitelUC: ({ commit }, quoteTechnologyMitelUC) => {
    commit('SET_QUOTES_TECHNOLOGY_MITEL_UC', quoteTechnologyMitelUC)
  },
  setQuoteTechnologyMitelUCE2: ({ commit }, quoteTechnologyMitelUCE2) => {
    commit('SET_QUOTES_TECHNOLOGY_MITEL_UC_E2', quoteTechnologyMitelUCE2)
  },
  setQuoteGammaSIP: ({ commit }, quoteGammaSIP) => {
    commit('SET_QUOTES_GAMMA_SIP', quoteGammaSIP)
  },
  setQuotePhoneLinePlus: ({ commit }, quotePhoneLinePlus) => {
    commit('SET_QUOTES_PHONELINEPLUS', quotePhoneLinePlus)
  },
  setQuoteGenesys: ({ commit }, quoteGenesys) => {
    commit('SET_QUOTES_GENESYS', quoteGenesys)
  },
  setQuoteRingCentral: ({ commit }, quoteRingCentral) => {
    commit('SET_QUOTES_RINGCENTRAL', quoteRingCentral)
  },
  setQuoteSycurio: ({ commit }, quoteSycurio) => {
    commit('SET_QUOTES_SYCURIO', quoteSycurio)
  },
  setQuoteUCAnalytics: ({ commit }, quoteUCAnalytics) => {
    commit('SET_QUOTES_UC_ANALYTICS', quoteUCAnalytics)
  },
  setQuoteAvayaAXP: ({ commit }, quoteAvayaAXP) => {
    commit('SET_QUOTES_AVAYA_AXP', quoteAvayaAXP)
  },
  setQuoteTeamsConnector: ({ commit }, teamsConnector) => {
    commit('SET_QUOTES_TEAMS_CONNECTOR', teamsConnector)
  },
  setQuoteSIPMigrationProperties: ({ commit }, quoteSIPMigrationProperties) => {
    commit('SET_QUOTES_SIP_MIGRATION_PROPERTIES', quoteSIPMigrationProperties)
  },
  setQuoteExpansions: ({ commit }, quoteExpansions) => {
    commit('SET_QUOTES_EXPANSIONS', quoteExpansions)
  },
  setCallmediaCXNowDdiDetails: ({ commit }, quoteCallmediaCXNowDdiDetails) => {
    commit('SET_QUOTE_CALLMEDIA_CXNOW_DDI_DETAILS', quoteCallmediaCXNowDdiDetails)
  },
  setQuoteLicensesRequired: ({ commit }, quoteLicensesRequired) => {
    commit('SET_QUOTES_LICENSES_REQUIRED', quoteLicensesRequired)
  },
  setQuoteVMsRequired: ({ commit }, quoteVMsRequired) => {
    commit('SET_QUOTES_VMS_REQUIRED', quoteVMsRequired)
  },
  setQuoteBladeServers: ({ commit }, quoteBladeServers) => {
    commit('SET_QUOTES_BLADE_SERVERS_REQUIRED', quoteBladeServers)
  },
  setQuoteUnitCosts: ({ commit }, quoteUnitCosts) => {
    commit('SET_QUOTES_UNIT_COSTS', quoteUnitCosts)
  },
  setQuoteDesignPackEntries: ({ commit }, quoteDesignPackEntries) => {
    commit('SET_QUOTES_DESIGN_PACK_ENTRIES', quoteDesignPackEntries)
  },
  setQuoteCustomerSummaryEntries: ({ commit }, quoteCustomerSummaryEntries) => {
    commit('SET_QUOTES_CUSTOMER_SUMMARY_ENTRIES', quoteCustomerSummaryEntries)
  }

}

const getters = {
  designPack: state => {
    return state.designPack
  },
  designPackDetailedDataEntries: state => {
    return state.designPackDetailedDataEntries
  },
  designPackList: state => {
    return state.designPackList
  },
  quoteUsers: state => {
    return state.quoteUsers
  },
  quote: state => {
    return state.quote
  },
  quotes: state => {
    return state.quotes
  },
  quoteTechnologyAvayaAura: state => {
    return state.quoteTechnologyAvayaAura
  },
  quoteTechnologyCallmedia: state => {
    return state.quoteTechnologyCallmedia
  },
  quoteTechnologyCallmediaCXNow: state => {
    return state.quoteTechnologyCallmediaCXNow
  },
  quoteTechnologyIconGateway: state => {
    return state.quoteTechnologyIconGateway
  },
  quoteTechnologyAvayaElite: state => {
    return state.quoteTechnologyAvayaElite
  },
  quoteTechnologyAvayaAACC: state => {
    return state.quoteTechnologyAvayaAACC
  },
  quoteTechnologyMitelCC: state => {
    return state.quoteTechnologyMitelCC
  },
  quoteTechnologyMitelUC: state => {
    return state.quoteTechnologyMitelUC
  },
  quoteTechnologyMitelUCE2: state => {
    return state.quoteTechnologyMitelUCE2
  },
  quoteGammaSIP: state => {
    return state.quoteGammaSIP
  },
  quotePhoneLinePlus: state => {
    return state.quotePhoneLinePlus
  },
  teamsConnector: state => {
    return state.teamsConnector
  },
  quoteGenesys: state => {
    return state.quoteGenesys
  },
  quoteRingCentral: state => {
    return state.quoteRingCentral
  },
  quoteSycurio: state => {
    return state.quoteSycurio
  },
  quoteUCAnalytics: state => {
    return state.quoteUCAnalytics
  },
  quoteAvayaAXP: state => {
    return state.quoteAvayaAXP
  },
  quoteSIPMigrationProperties: state => {
    return state.quoteSIPMigrationProperties
  },
  quoteExpansions: state => {
    return state.quoteExpansions
  },
  quoteCallmediaCXNowDdiDetails: state => {
    return state.quoteCallmediaCXNowDdiDetails
  },
  quoteLicensesRequired: state => {
    return state.quoteLicensesRequired
  },
  quoteVMsRequired: state => {
    return state.quoteVMsRequired
  },
  quoteBladeServers: state => {
    return state.quoteBladeServers
  },
  quoteUnitCosts: state => {
    return state.quoteUnitCosts
  },
  quoteDesignPackEntries: state => {
    return state.quoteDesignPackEntries
  },
  quoteCustomerSummaryEntries: state => {
    return state.quoteCustomerSummaryEntries
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
