import { render, staticRenderFns } from "./createUser.vue?vue&type=template&id=c2a780a8&scoped=true"
import script from "./createUser.vue?vue&type=script&lang=js"
export * from "./createUser.vue?vue&type=script&lang=js"
import style0 from "./createUser.vue?vue&type=style&index=0&id=c2a780a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2a780a8",
  null
  
)

export default component.exports