import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteCalculationsDesignPackMixin } from './iQuoteCalculationsDesignPack'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsDesignPackPSMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsDesignPackMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackPSMixin: {
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackPSMixin_psProfilesWithScheduleCosts () {
      return this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.filter(x => this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfileSchedules.some(item => item.designPackPsRoleProfileId === x.id))
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async iQuoteCalculationsDesignPackPSMixin_updatePsCoreEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psCoreEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsCoreEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsOptionsEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psOptionsEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsOptionsEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psExpenseEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyInstallationEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psThirdPartyInstallationEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries')
    },
    async iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyOtherEntries (newEntries) {
      var designPack = this.iQuoteStore_GetDesignPack
      designPack.psThirdPartyOtherEntries = newEntries
      this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack, 'iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyOtherEntries')
    },
    iQuoteCalculationsDesignPackPSMixin_getPsProfileDescription (id) {
      var match = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.find(x => x.id === id)

      if (match === undefined) {
        return 'None'
      }

      return match.description
    },
    iQuoteCalculationsDesignPackPSMixin_getPsScheduleDescription (id) {
      var match = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSSchedules.find(x => x.id === id)

      if (match === undefined) {
        return 'None'
      }

      return match.description
    },
    iQuoteCalculationsDesignPackPSMixin_validatePSEntries (entries) {
      let validDeliveryManager3Days = true, validDeliveryManager5Days = true, validProjectManager3Days = true
      if (entries !== undefined) {
        entries.forEach(entry => {
          if (entry.units > 0) {
            if (entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.DELIVERY_MANAGER) {
              if ((entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY && entry.units < 6)) {
                  validDeliveryManager3Days = false
              }
              
              if ((entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY && entry.units > 5) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY && entry.units > 5) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL && entry.units > 5) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY && entry.units > 10) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS && entry.units > 10) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY && entry.units > 10) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY && entry.units > 10)) {  
                  validDeliveryManager5Days = false
              }
            } else if (entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_MANAGER) {
              if ((entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL && entry.units < 3) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY && entry.units < 6) ||
              (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY && entry.units < 6)) {
                  validProjectManager3Days = false
              }
            }
          }
        })
      }
      return {validDeliveryManager3Days: validDeliveryManager3Days, validDeliveryManager5Days: validDeliveryManager5Days, validProjectManager3Days: validProjectManager3Days}
    }
  }
}
