<template>
  <v-navigation-drawer
    id="app-drawer"
    :mini-variant.sync="mini"
    :mini-variant-width="48"
    :permanent= "true"
    :style="sidebarBackgroundColour"
    v-model="inputValue"
    app
    fixed
    persistent
    mobile-break-point="991"
    width="260"
    height="100%"
  >
    <v-img
      :src="menuLogo"
      height="100%"
    >
      <v-layout
        class="fill-height"
        tag="v-list"
        column
      >
        <v-list
          :class="{collapsed: mini }"
          dense
          expand>
          <v-list-tile avatar>
            <v-list-tile-avatar
              :class="{hidden: mini }"
              color="none"
            >
              <v-img
                :src="logoImage"
                height="64"
                contain
              />
            </v-list-tile-avatar>
            <v-list-tile-title
              :class="{hidden: mini }"
              :style="sidebarTextColour"
              class="title">
              iQuote
            </v-list-tile-title>
            <v-btn
              id="btnNavMenu"
              :class="{collapsed: mini }"
              icon
              @click.stop="mini = !mini">
              <v-icon
                :style="sidebarTextColour"
              >mdi-menu</v-icon>
            </v-btn>
          </v-list-tile>
          <v-divider/>
          <v-list-tile
            v-for="(link, i) in navLinks"
            :key="i"
            :to="link.to"
            :style="sidebarTextColour"
            :active-class="sidebarSelectedTextStyleStyle"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon
                :style="`${sidebarTextColour}`"
                :active-class="sidebarSelectedTextStyleStyle"
              >{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title
              v-text="link.text"
            />
          </v-list-tile>
          <v-list-tile
            v-if="showAccount"
            :style="sidebarTextColour"
            :active-class="sidebarSelectedTextStyleStyle"
            class="v-list-item"
            style="bottom:10px;position:absolute;width:230px;"
            to="/my-account"
          >
            <v-list-tile-action style="width:30px">
              <v-icon
                :style="sidebarTextColour"
                :active-class="sidebarSelectedTextStyleStyle"
              >mdi-account</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>
              Account Settings
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="showHelp"
            :style="sidebarTextColour"
            :active-class="sidebarSelectedTextStyleStyle"
            class="v-list-item"
            style="bottom:48px;position:absolute;width:230px;"
            to="/help"
          >
            <v-list-tile-action style="width:30px">
              <v-icon
                :style="sidebarTextColour"
                :active-class="sidebarSelectedTextStyleStyle"
              >mdi-help-circle</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>
              Help
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { permissionsMixin } from '../../mixins/permissions'
export default {
  mixins: [
    permissionsMixin
  ],
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    mini: false
  }),
  computed: {
    ...mapState(['menuLogo', 'colour']),
    logoImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.menuLogo}`
    },
    sidebarBackgroundColour () {
      return `background-color:${this.$store.getters.sidebarBackgroundColour}`
    },
    sidebarTextColour () {
      return `color:${this.$store.getters.sidebarTextColour}`
    },
    sidebarSelectedTextStyleStyle () {
      return this.$store.getters.sidebarSelectedTextStyle
    },
    navLinks: {
      get () {
        return this.$store.getters.navLinks
      }
    },
    showAccount: {
      get () {
        return this.$store.getters.showAccount
      }
    },
    showHelp: {
      get () {
        return (this.$store.getters.viewEntityPermission ||
                this.$store.getters.createEntityPermission ||
                this.$store.getters.viewCustomerPermission ||
                this.$store.getters.assignToCustomerPermission ||
                this.$store.getters.customers.length > 1) &&
                this.$store.getters.channelCompany.showHelp
      }
    },
    inputValue: {
      get () {
        return this.$store.getters.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    },
    isLoggedOn () {
      return this.$store.getters.loggedOn
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.viewiQuotePermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters.viewAreaTicketsPermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters.viewAreasPermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters.viewEntityPermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters.viewSecurityPermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
    this.$store.watch(
      (state, getters) => getters.viewQuotesAndProjectsPermission,
      (newValue) => {
        if (newValue !== undefined) {
          this.setNavigation()
        }
      }
    )
  },
  methods: {
    ...mapActions([
      'setNavigation',
      'setAreas'
    ]),
    ...mapMutations([
      'setDrawer',
      'toggleDrawer'
    ])
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-list {
      height:100vh;
    }
    .v-list__tile {
      border-radius: 4px;
      padding: 0px 10px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }
    }

    .collapsed {
      margin-left: -16px;
    }
    .expanded {
      margin-right: -16px
    }
    .collapsed button {
      margin-left: 10px;
    }
    .hidden {
      display: none;
    }
    .bottomLink {
      position: absolute;
      bottom: 10px;
      display: block;
      width: 240px;}
  }
  .menuItemSelected {
    color: #4caf50!important;
    font-weight: bold!important;
    box-shadow:0 12px 20px -10px rgba(18, 76, 0, 0.2), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(18, 76, 0, 0.2) !important
  }
  .menuItemSelected_testco {
    color: #ff6a5f!important;
    font-weight: bold!important;
    box-shadow:0 12px 20px -10px rgba(244, 25, 20, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 125, 20, 0.2) !important
  }
</style>
