<template>
  <div
    :class="selected"
    class="serviceTileContainer">
    <div class="tile">
      <img
        v-if="showServiceIcons"
        :id="id"
        :src="imageSource"
        class="serviceImage"
        @click="gotoPage(pageSource)"
      >
      <div
        v-if="!showServiceIcons"
        class="servicesWithoutImages"
        @click="gotoPage(pageSource)">
        <span> {{ getServiceAbbreviation }}</span>
      </div>
      <div class="tileButtons">
        <button
          class="removeService"
          @click="remove()">X</button>
      </div>
      <div class="serviceName">
        {{ getServiceName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

export default {
  name: 'ServiceTile',
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteConstants, iQuoteFunctions],
  props: {
    serviceId: {
      type: Number,
      default: -1
    },
    serviceName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      page: 'SelectService'
    }
  },
  computed: {
    selected () {
      var isSelected = ''

      if (this.serviceId === this.iQuoteConstants.services.communicate) {
        if (this.$store.getters.quoteTechnologyAvayaAura.id !== undefined ||
            this.$store.getters.quoteTechnologyMitelUC.id !== undefined ||
            this.$store.getters.quoteTechnologyMitelUCE2.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.contact) {
        if (this.$store.getters.quoteTechnologyAvayaElite.id !== undefined ||
            this.$store.getters.quoteTechnologyAvayaAACC.id !== undefined ||
            this.$store.getters.quoteTechnologyCallmedia.id !== undefined ||
            this.$store.getters.quoteTechnologyMitelCC.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.sip) {
        if (this.$store.getters.quoteSIPMigrationProperties.id !== undefined ||
            this.$store.getters.quoteGammaSIP.id !== undefined ||
            this.$store.getters.quotePhoneLinePlus.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
        if (this.$store.getters.quoteTechnologyCallmediaCXNow.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.gateway) {
        if (this.$store.getters.quoteTechnologyIconGateway.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.expansions) {
        if (this.$store.getters.quoteExpansions.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.teamsConnector) {
        if (this.$store.getters.teamsConnector.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.genesys) {
        if (this.$store.getters.quoteGenesys.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.ringCentral) {
        if (this.$store.getters.quoteRingCentral.ucTechId !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.sycurio) {
        if (this.$store.getters.quoteSycurio.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.ucAnalytics) {
        if (this.$store.getters.quoteUCAnalytics.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceId === this.iQuoteConstants.services.avayaAXP) {
        if (this.$store.getters.quoteAvayaAXP.id !== undefined) {
          isSelected = 'selected'
        }
      } else {
      }
      return isSelected
    },
    imageSource () {
      if (this.serviceId === this.iQuoteConstants.services.communicate) {
        return require(`../../../assets/icons/MaintelUCPrivatePlus.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.contact) {
        return require(`../../../assets/icons/MaintelCXPrivate.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.sip) {
        return require(`../../../assets/icons/MaintelSIPTrunking.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
        return require(`../../../assets/icons/callmediaCXNow.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.gateway) {
        return require(`../../../assets/icons/MaintelGatewayInterconnect.png`)      
      } else if (this.serviceId === this.iQuoteConstants.services.teamsConnector) {
        return require(`../../../assets/icons/MaintelTeamsConnector.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.genesys) {
        return require(`../../../assets/icons/MaintelGenesys.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.ringCentral) {
        return require(`../../../assets/icons/MaintelRingCentral.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.sycurio) {
        return require(`../../../assets/icons/Maintel PCI Payment.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.ucAnalytics) {
        return require(`../../../assets/icons/MaintelUCAnalytics.png`)
      } else if (this.serviceId === this.iQuoteConstants.services.avayaAXP) {
        return require(`../../../assets/icons/MaintelAvayaAXP.png`)
      } else {
        return require(`../../../assets/icons/MaintelUCPrivatePlus.png`)
      }
    },
    pageSource () {
      if (this.serviceId === this.iQuoteConstants.services.communicate) {
        return `IconCommunicate`
      } else if (this.serviceId === this.iQuoteConstants.services.contact) {
        return `IconContact`
      } else if (this.serviceId === this.iQuoteConstants.services.sip) {
        return `SIPServices`
      } else if (this.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
        return `UserProfilesCallmediaCXNow`
      } else if (this.serviceId === this.iQuoteConstants.services.gateway) {
        return `IconGateway`
      } else if (this.serviceId === this.iQuoteConstants.services.expansions) {
        return `Expansions`
      } else if (this.serviceId === this.iQuoteConstants.services.teamsConnector) {
        return `TeamsConnector`
      } else if (this.serviceId === this.iQuoteConstants.services.genesys) {
        return `UserProfilesGenesys`
      } else if (this.serviceId === this.iQuoteConstants.services.avayaAXP) {
        return `UserProfilesAvayaAXP`
      } else if (this.serviceId === this.iQuoteConstants.services.ringCentral) {
        return `UserProfilesRingCentralUC`
      } else if (this.serviceId === this.iQuoteConstants.services.sycurio) {
        return `Sycurio`
      } else if (this.serviceId === this.iQuoteConstants.services.ucAnalytics) {
        return `UCAnalytics`
      } else {
        return `IconCommunicate`
      }
    },
    getServiceAbbreviation () {
      if (this.serviceId === this.iQuoteConstants.services.expansions) {
        return 'ME'
      }
    },
    getServiceName () {
      if (this.serviceId === this.iQuoteConstants.services.communicate) {
        return 'Maintel UC Private +'
      } else if (this.serviceId === this.iQuoteConstants.services.contact) {
        return 'Maintel CX Private'
      } else if (this.serviceId === this.iQuoteConstants.services.sip) {
        return 'Maintel SIP Trunking'
      } else if (this.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
        return 'Callmedia CXNow'
      } else if (this.serviceId === this.iQuoteConstants.services.gateway) {
        return 'Maintel Gateway Interconnect'
      } else if (this.serviceId === this.iQuoteConstants.services.expansions) {
        return 'Maintel Expansions'
      } else if (this.serviceId === this.iQuoteConstants.services.teamsConnector) {
        return 'Maintel Teams Connector'
      } else if (this.serviceId === this.iQuoteConstants.services.genesys) {
        return 'Maintel Genesys Cloud'
      } else if (this.serviceId === this.iQuoteConstants.services.ringCentral) {
        return 'Maintel RingCentral'
      } else if (this.serviceId === this.iQuoteConstants.services.sycurio) {
        return 'Maintel PCI Payment'
      } else if (this.serviceId === this.iQuoteConstants.services.ucAnalytics) {
        return 'Maintel UC Analytics'
      } else if (this.serviceId === this.iQuoteConstants.services.avayaAXP) {
        return 'Maintel Avaya Experience Platform'
      }
    },
    showServiceIcons () {
      return this.serviceId !== this.iQuoteConstants.services.expansions
    },
    id () {
      return 'service' + this.serviceId
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setCurrentService',
      'setCurrentTechnology',
      'setQuoteTechnologyAvayaAura',
      'setQuoteTechnologyCallmedia',
      'setQuoteTechnologyMitelCC',
      'setQuoteTechnologyMitelUC',
      'setQuoteTechnologyMitelUCE2',
      'setQuoteTechnologyAvayaElite',
      'setQuoteTechnologyAvayaAACC',
      'setQuoteTechnologyCallmediaCXNow',
      'setQuoteTechnologyIconGateway',
      'setQuoteTeamsConnector',
      'setQuoteGenesys',
      'setQuoteAvayaAXP',
      'setQuoteGammaSIP',
      'setQuoteSIPMigrationProperties',
      'setQuoteExpansions',
      'setQuotePhoneLinePlus',
      'setQuoteRingCentral',
      'setQuoteSycurio',
      'setQuoteUCAnalytics'
    ]),
    gotoPage (page) {
      this.setCurrentService(this.serviceId)

      // For SIP the Service there is only one technology, so we don't show technologytiles.  We are automatically editing the SIP technology
      if (page === 'SIPService') {
        this.setCurrentTechnology(this.iQuoteConstants.technologies.gammaSIP)
      }

      this.setCurrentQuoteState(page)
    },
    async remove () {
      if (window.confirm('Remove configuration for this service?')) {
        var technologyId
        var quote = this.$store.getters.quote
        var serviceName = 'unknown'

        // Find all technologies under the selected service and delete
        switch (this.serviceId) {
          case this.iQuoteConstants.services.communicate:
            serviceName = 'Maintel UC Private +'
            var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
            if (quoteTechnologyAvayaAura.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAura)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
            if (quoteTechnologyMitelUC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyMitelUCE2 = this.$store.getters.quoteTechnologyMitelUCE2
            if (quoteTechnologyMitelUCE2.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_E2)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTechnologyAvayaAura({})
            this.setQuoteTechnologyMitelUC({})
            this.setQuoteTechnologyMitelUCE2({})

            break
          case this.iQuoteConstants.services.contact:
            serviceName = 'Maintel CX Private'
            var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
            if (quoteTechnologyAvayaElite.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaElite)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
            if (quoteTechnologyAvayaAACC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAACC)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
            if (quoteTechnologyCallmedia.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.callmedia)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
            if (quoteTechnologyMitelCC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelCC)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTechnologyAvayaElite({})
            this.setQuoteTechnologyAvayaAACC({})
            this.setQuoteTechnologyCallmedia({})
            this.setQuoteTechnologyMitelCC({})
            break
          case this.iQuoteConstants.services.sip:
            serviceName = 'SIP'

            var quoteGammaSIP = this.$store.getters.quoteGammaSIP
            if (quoteGammaSIP.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.gammaSIP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteGammaSIP({})

            var quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
            if (quoteSIPMigrationProperties.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.sipMigrationOffer)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteSIPMigrationProperties({})

            var quotePhoneLinePlus = this.$store.getters.quotePhoneLinePlus
            if (quotePhoneLinePlus.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.phoneLinePlus)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuotePhoneLinePlus({})
            break
          case this.iQuoteConstants.services.callmediaCXNow:
            serviceName = 'Callmedia CX Now'
            var quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
            if (quoteTechnologyCallmediaCXNow.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.callmediaCXNow)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTechnologyCallmediaCXNow({})
            break
          case this.iQuoteConstants.services.gateway:
            serviceName = 'Gateway'
            var quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway
            if (quoteTechnologyIconGateway.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.iconGateway)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTechnologyIconGateway({})
            break
          case this.iQuoteConstants.services.expansions:
            serviceName = 'Expansions'
            var quoteExpansions = this.$store.getters.quoteExpansions
            if (quoteExpansions.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.quoteExpansions)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteExpansions({})
            break
          case this.iQuoteConstants.services.teamsConnector:
            serviceName = 'Teams Connector'
            var teamsConnector = this.$store.getters.teamsConnector
            if (teamsConnector.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.teamsConnector)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTeamsConnector({})
            break
          case this.iQuoteConstants.services.genesys:
            serviceName = 'Genesys'
            var quoteGenesys = this.$store.getters.quoteGenesys
            if (quoteGenesys.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.genesys)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteGenesys({})
            break
          case this.iQuoteConstants.services.ringCentral:
            serviceName = 'RingCentral'
            var quoteRingCentral = this.$store.getters.quoteRingCentral
            if (quoteRingCentral.ucTechId !== undefined) {
              // Delete from DB
              this.deleteQuoteTechnology(quoteRingCentral.ucTechId)
              this.deleteQuoteTechnology(quoteRingCentral.ccTechId)
            }

            // Clear form details from store
            this.setQuoteRingCentral({})
            break
          case this.iQuoteConstants.services.sycurio:
            serviceName = 'Maintel PCI Payment'
            var quoteSycurio = this.$store.getters.quoteSycurio
            if (quoteSycurio.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.sycurio)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteSycurio({})
            break
          case this.iQuoteConstants.services.ucAnalytics:
            serviceName = 'UC Analytics'
            var quoteUCAnalytics = this.$store.getters.quoteUCAnalytics
            if (quoteUCAnalytics.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.ucAnalytics)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteUCAnalytics({})
            break
          case this.iQuoteConstants.services.avayaAXP:
            serviceName = 'Avaya AXP'
            var quoteAvayaAXP = this.$store.getters.quoteAvayaAXP
            if (quoteAvayaAXP.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAXP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteAvayaAXP({})
            break
        }

        this.$emit('removed')
        this.iQuoteFunctions_ShowMessage('Service "' + serviceName + '" Removed')
      }
    }
  }
}
</script>

<style scoped>
.serviceTileContainer {
  display: inline-block;
  width: 122px;
  margin-right: 45px;
  background-color: #fff;
  padding: 7px;
  text-align: center;
}

.serviceTileContainer:hover {
  background-color: rgb(199, 209, 199);    
  box-shadow: inset 0px 0px 0px 1px #252525;
}

.serviceTileContainer.selected .serviceImage, .serviceTileContainer.selected .serviceName, .serviceTileContainer.selected .servicesWithoutImages {
  font-weight: bold;
}

.serviceTileContainer {
  height: 188px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.serviceTileContainer.selected {
  border: 1px solid black;
}

.serviceTileContainer.selected:hover {
  background-color: rgb(122, 136, 122);
  box-shadow: inset 0px 0px 0px 1px #252525;
}

.serviceTileContainer img {
  width: 102px;
  cursor: pointer;
}

.serviceName {
  margin-top: 5px;
  font-size: 15.5px;
  color: black;
  font-weight: normal;
  display: block;
}

.quotePanel .tileButtons .removeService {
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  top: -97px;
  left: 71px;
  display: block;
}

.tileButtons {
  width: 100%;
  height: 0px;
  background-color: orange;
}

.serviceTileContainer .tileButtons {
  visibility: hidden;
}

.serviceTileContainer.selected .tileButtons {
  visibility: visible;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesWithoutImages {
  width: 102px;
  height: 102px;
  border: 1px solid gray;
  border-radius: 8%;
  cursor: pointer;
  background-image: url('../../../assets/icons/pillar-blur-gradient-72dpi.png');
  background-size: 200% auto;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
 }

 .servicesWithoutImages span {
    position: absolute;
    left: 5px;
    color: white;
    top: -9px;
    font-size: 39px;
    font-weight: 400;
}
</style>
