import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'

export const iQuoteCalculationsGenesysMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  computed: {
    /*
     * BUSINESS VALUES
     */

    calcGenesysConnector_genesysNonPSUpfrontCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if ((unitCost.serviceId === this.iQuoteConstants.services.teamsConnector || unitCost.serviceId === this.iQuoteConstants.services.genesys) &&
               unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_PS) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })
      return total
    },

    calcGenesysConnector_UpfrontCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector || unitCost.serviceId === this.iQuoteConstants.services.genesys) {
            total += unitCost.price * unitCost.quantity
          }
        }
      })
      return total
    },
    calcGenesysConnector_ManagedServicesCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.GENESYS_MANAGEDSERVICES) {
          total += unitCost.price * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcGenesysConnector_ManagedServicesCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.GENESYS_MANAGEDSERVICES) {
          total += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcGenesysConnector_CSMCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.GENESYS_CSM) {
          total += unitCost.price * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    },
    calcGenesysConnector_CSMCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.GENESYS_CSM) {
          total += unitCost.cost * unitCost.quantity * unitCost.paymentsPerYear
        }
      })
      return total
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcGenesysConnector_getLicenseIdForUserProfile (technologyUserProfileId) {
      var licenseId = null
      var paymentTerm = this.iQuoteStore_GetQuoteGenesys.options.genesysPaymentTermId
      var userType = this.iQuoteStore_GetQuoteGenesys.options.genesysUserTypeId

      switch (technologyUserProfileId) {
        case this.iQuoteConstants.genesysUserProfiles.GC_COMMUNICATE:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALPREPAY_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALPREPAY_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALM2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALM2M_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_M2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_M2M_HOURLY
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALPREPAY_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALPREPAY_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALM2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_ANNUALM2M_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD1_M2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_COMMUNICATE_ANNUALPREPAYGENESYS_PURECLOUD1_M2M_HOURLY_NAMED
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALPREPAY_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALPREPAY_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALM2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_ANNUALM2M_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_M2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD2_M2M_HOURLY
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALPREPAY_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALPREPAY_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALM2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_ANNUALM2M_HOURLY
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_M2M_CONCURRENT
            } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.HOURLY) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD3_M2M_HOURLY
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GENESYS_1_USER_WEM_UPGRADE_II:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMII_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMIIC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMII_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMIIC_ANNUALM2M_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMII_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR1WEMIIC_M2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GENESYS_2_USER_WEM_UPGRADE_I:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR2WEMI_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR2WEMIC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR2WEMI_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_170_NV_USR2WEMIC_ANNUALM2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_1_DIGITAL_UPGRADE_I:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGI_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGI_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIC_ANNUALM2M_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGI_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIC_M2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_1_DIGITAL_UPGRADE_II:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGII_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIIC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGII_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIIC_ANNUALM2M_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGII_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR1DIGIIC_M2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_2_DIGITAL_UPGRADE_I:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGI_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGIC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGI_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGIC_ANNUALM2M_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGI_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_USR2DIGIC_M2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.MICROSOFT_TEAMS_INTEGRATION:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_MSTEAMS_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_MSTEAMS_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_MSTEAMS_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_MSTEAMS_ANNUALM2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_WALLBOARD_DEVICE_CHARGE:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD_WALLBOARD_ANNUALPREPAY
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            licenseId = this.iQuoteConstants.licenses.GENESYS_PURECLOUD_WALLBOARD_ANNUALM2M
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_SALESFORCE_ADD_ON:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFAN_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFANC_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFAN_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFANC_ANNUALM2M_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFAN_M2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFANC_M2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_SALESFORCE_COMMUNICATE_ADD_ON:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFCAN_ANNUALPREPAY_NAMED
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFCAN_ANNUALM2M_NAMED
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.M2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_PC_170_NV_PCSFCAN_M2M_NAMED
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_2_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_2_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_2_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_2_ANNUALM2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_3_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_3_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_3_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_DIGITAL_3_ANNUALM2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_CX_AI_EXPERIENCE_FOR_DIGITAL:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_CONCURRENT
            }
          }
          break
        case this.iQuoteConstants.genesysUserProfiles.GC_CX_AI_EXPERIENCE:
          if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALPREPAY) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_CONCURRENT
            }
          } else if (paymentTerm === this.iQuoteConstants.genesysPaymentTerms.ANNUALM2M) {
            if (userType === this.iQuoteConstants.genesysUserTypes.NAMED) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_NAMED
            } else if (userType === this.iQuoteConstants.genesysUserTypes.CONCURRENT) {
              licenseId = this.iQuoteConstants.licenses.GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_CONCURRENT
            }
          }
          break
      }
      return licenseId
    },
    calcGenesysConnector_calculate: function () {
      if (this.iQuoteStore_GetQuoteGenesys.id === undefined) {
        return
      }

      var quote = this.$store.getters.quote
      var numSipChannels = 0
      var hasGcDigital2Or3 = false
      var additionalSbcLicencesForInternalCalls = this.iQuoteStore_GetQuoteGenesys.options.additionalSbcLicencesForInternalCalls
      var userProfiles = this.iQuoteStore_GetQuoteGenesys.userProfiles
      var licenseId = null
      var licensePrice
      var cost
      var price
      var costPerYear
      var percentage
      var margin = 33
      var totalNumTeamsUsers = this.iQuoteStore_GetQuoteGenesys.options.numGenesysUsers + Math.ceil(this.iQuoteStore_GetQuoteGenesys.options.numPBXUsers)

      userProfiles.forEach(userProfile => {
        if ((userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2 ||
          userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3) &&
          userProfile.minCommitment > 0) {
          hasGcDigital2Or3 = true
        }
        licenseId = this.calcGenesysConnector_getLicenseIdForUserProfile(userProfile.technologyUserProfileId)

        if (licenseId !== null) {
          licensePrice = this.utilFunctions_GetLicensePrice(licenseId)
          this.iQuoteStore_AddRequiredLicenseToQuote(licenseId, userProfile.minCommitment, licensePrice)
        }
      })

      switch (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId) {
        case this.iQuoteConstants.quoteServiceLevelTypes.STANDARD:
          percentage = 2.3
          break
        case this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM:
          percentage = 3.84
          break
      }

      cost = (percentage / 100) * (this.genesysLicensesAnnualCost)
      costPerYear = cost
      price = cost / ((100 - margin) / 100)
      // KP 3542 - Remove genesys managed services
      // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.GENESYS_MANAGEDSERVICES, 1, costPerYear, cost, price)

      cost = this.utilFunctions_GetUnitCostCost(this.iQuoteConstants.unitCosts.GENESYS_BYOC_CHARGE)
      cost *= this.iQuoteStore_GetQuoteGenesys.options.numCallsPerYear
      cost *= this.iQuoteStore_GetQuoteGenesys.options.avgCallDurationMins / 60
      costPerYear = cost * 12
      price = this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.GENESYS_BYOC_CHARGE)
      price *= this.iQuoteStore_GetQuoteGenesys.options.numCallsPerYear
      price *= this.iQuoteStore_GetQuoteGenesys.options.avgCallDurationMins / 60
      // KP - Removed due to ticket 3160
      // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.GENESYS_BYOC_CHARGE, 1, costPerYear, cost, price)

      if (this.iQuoteStore_GetQuoteGenesys.options.customerServiceManagerRequired) {
        cost = 6000 + (this.genesysLicensesAnnualCost * 0.06)
        costPerYear = cost
        price = cost / ((100 - margin) / 100)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.GENESYS_CSM, 1, costPerYear, cost, price)
      }

      numSipChannels = this.iQuoteStore_GetQuoteGenesys.options.sipChannelsCalculated

      if (this.iQuoteStore_GetQuoteGenesys.options.numAdditionalSIPChannels > 0) {
        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_36, this.iQuoteStore_GetQuoteGenesys.options.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_36, this.iQuoteStore_GetQuoteGenesys.options.numAdditionalSIPChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_ACTIVE_12, this.iQuoteStore_GetQuoteGenesys.options.numAdditionalSIPChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_ADDITIONAL_SIP_STANDBY_12, this.iQuoteStore_GetQuoteGenesys.options.numAdditionalSIPChannels)
        }
      }

      if (numSipChannels > 0 && !hasGcDigital2Or3) {
        if (!this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP) &&
            !this.utilFunctions_UnitCostExistsOnQuote(this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP)) {
          // Might have already been added by Teams Connector or CXNow if ordered together with Genesys
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SIP_SETUP, 2)
        }

        if (quote.termLengthMonths >= 36) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_36, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_36, numSipChannels)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_ACTIVE_12, numSipChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TEAMS_SIP_STANDBY_12, numSipChannels)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_MAINTEL_SBC_SUPPORT, Math.ceil((numSipChannels + additionalSbcLicencesForInternalCalls) / 25))

        if (numSipChannels <= 100 || this.iQuoteStore_GetQuoteGenesys.options.numPBXUsers > 0) {
          switch (this.iQuoteStore_GetQuoteGenesys.options.usersPerSipChannel) {
            case 1:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
            case 120:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1, totalNumTeamsUsers)
              break
          }
        } else {
          switch (this.iQuoteStore_GetQuoteGenesys.options.usersPerSipChannel) {
            case 1:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 3:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 5:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 7:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 10:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
            case 120:
              this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.GENESYS_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2, totalNumTeamsUsers)
              break
          }
        }

        var iconBandwidth = 2 * (5 + ((numSipChannels + additionalSbcLicencesForInternalCalls) / 5))
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ICON_BANDWIDTH_CONTRIBUTION, iconBandwidth)

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_SBC_PORT_CHARGE, numSipChannels + additionalSbcLicencesForInternalCalls)

        if (this.iQuoteStore_GetQuoteGenesys.options.teamsConnectorNumberPortingOptionId === 2 /* 2 = NEW DDI */) {
          var numDDIs = this.iQuoteStore_GetQuoteGenesys.options.newDDIs
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_NEW_DDI, numDDIs)
        }

        let upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR, this.iQuoteConstants.designPackPsSchedules.DAY)
        let upfrontPrice = upfrontCost * 2
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATOR, 1, null, upfrontCost, upfrontPrice)

        upfrontCost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.ENGINEER_LEVEL_B, this.iQuoteConstants.designPackPsSchedules.DAY)
        upfrontPrice = upfrontCost * 2
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.TEAMS_ENGINEER_LEVEL_B, 1, null, upfrontCost, upfrontPrice)
      }
    }
  }
}
