export const environmentMixin = {
    computed: {
        getEnvironmentName () {
            const name = this.$store.getters.channelCompany.name
            if (name.includes('Local')) return 'Local'
            else if (name.includes('Staging')) return 'Staging'
            else if (name.includes('External Test')) return 'Ext Test'
            else if (name.includes('Internal Test')) return 'Test'
            else if (name.includes('Development')) return 'Dev'
            else return ''
          }
    }
  }