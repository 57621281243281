<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="avayaAXPIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="avayaAXPPage2Component.quote.quoteOpportunityId + ' (revision ' + avayaAXPPage2Component.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>Avaya Social Connections</h3>
      <label
      >Select Projected Social Message Count Amount
      </label>
      <br><br>
      <div
        v-for="entry in getAvayaAXPSocialMediaPackages"
        :key="entry.id">
        <input
          :id="entry.id"
          :name="entry.id"
          :value="entry.id"
          v-model.number="avayaAXPPage2Component.avayaAXP.options.avayaAXPSocialMediaPackageId"
          type="radio"
        >
        {{ entry.name }}
      </div>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesAvayaAXP')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AvayaAXPPage3')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

import { mapActions } from 'vuex'
export default {
  name: 'AvayaAXPPage2',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin, iQuoteStoreMixin],
  data () {
    return {
      avayaAXPPage2Component: {
        quote: {},
        avayaAXP: {
          options: {
            id: 0,
            avayaAXPSocialMediaPackageId: 0,
            numAvayaAXP: 0,
            numPBXUsers: 0,
            sipChannelsCalculated: 0,
            additionalSbcLicencesForInternalCalls: 0,
            subscriptionBillingPeriodId: 1, // 1 = Annual, 2 = Monthly
            teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
            usersPerSipChannel: 5,
            numAdditionalSIPChannels: 0
          }
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    avayaAXPIcon () {
      return require(`../../../assets/icons/MaintelAvayaAXP.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    getAvayaAXPSocialMediaPackages () {
      return this.iQuoteStore_GetAvayaAXPSocialMediaPackages
    }
  },
  async mounted () {
    this.avayaAXPPage2Component.quote = this.$store.getters.quote
    this.avayaAXPPage2Component.avayaAXP = this.iQuoteStore_GetQuoteAvayaAXP
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteAvayaAXP'
    ]),
    async gotoPage (page) {
      await this.saveQuoteOptionsAvayaAXP(this.avayaAXPPage2Component.avayaAXP.options)
      this.setQuoteAvayaAXP(this.avayaAXPPage2Component.avayaAXP)
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false
      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied

        await this.saveQuoteOptionsAvayaAXP(this.avayaAXPPage2Component.avayaAXP.options)
        this.setQuoteAvayaAXP(this.avayaAXPPage2Component.avayaAXP)
        this.setCurrentQuoteState('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width: 380px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}
</style>
