<template>
  <div
    v-if="$store.getters.openMainForm"
    class="stormNewBackground"
  >
    <div class="stormNewHolder">
      <div class="stormTableholder">
        <div class="stormNewTitle">New UC Analytics Company</div>
        <label
          class="stormCompanyDetailLabel"
          for="cboAddCompany"
        ><span class="compulsoryField">*</span>Company:</label>
        <PortalDropdown
          id="cboAddCompany"
          v-model="newCompany.companyId"
          :options="companies"
          :disabled="false"
          :max-item="999999"
          :class="{ errorHighlight: stormCompanyError }"
          :attach-blur-event="false"
          parent-class="createCustomerRight"
          placeholder="Please select a Company to Comission in UC Analytics"
          tabindex="2"
          @selected="selectCustomerCompany"
          @blur="checkForm"
        />
        <label
          class="stormCompanyDetailLabel"
          for="txtAddCompanyBaseUrl"
        ><span class="compulsoryField">*</span>Base URL:</label>
        <input
          id="txtAddCompanyBaseUrl"
          v-model="newCompany.baseUrl"
          :class="{ errorHighlight: stormBaseUrlError }"
          class="stormCompanyDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          class="stormCompanyDetailLabel"
          for="txtAddCompanyAPICode"
        ><span class="compulsoryField">*</span>API Code:</label>
        <input
          id="txtAddCompanyAPICode"
          v-model="newCompany.apiCode"
          :class="{ errorHighlight: stormAPICodeError }"
          class="stormCompanyDetailInput"
          type="text"
          @blur="checkForm"
        >
        <label
          class="stormCompanyDetailSubscriptionTierLabel"
          for="cboAddCompanySubscriptionTier"
        ><span class="compulsoryField">*</span>Subscription Tier:</label>
        <select
          id="cboAddCompanySubscriptionTier"
          ref="subscriptionTierDropDown"
          class="subscriptionTierDropDown"
          @change="setSubscriptionTier()"
        >
          <option
            v-for="s in subscriptionTiers"
            :key="s.id"
            :value="s.id"
            :selected="newCompany.subscriptionTierId===s.id"
          >{{ s.name }}
          </option>
        </select>
        <p style="margin: 20px 0 10px 10px;">(<span class="compulsoryField">*</span> marks a compulsory field )</p>
        <Spinner v-if="isSaving" />
        <input
          id="stormCancelNewButton"
          type="button"
          class="stormCancelNewButton"
          value="Cancel"
          title="Cancel the add operation"
          @click="closeForm()"
        >
        <input
          v-if="!isSaving"
          id="btnAddCompany"
          :disabled="!formOK"
          value="Add Company"
          type="button"
          class="stormCompanyAddButton"
          title="Adds a new UC Analytics user"
          @click="addNewCompany()"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { stormMixin } from '../../../mixins/stormMixin'
import Spinner from '../../common/spinner'
import PortalDropdown from '../../common/portalDropdown'
import { mapActions } from 'vuex'
export default {
  name: 'StormNewCompany',
  components: {
    Spinner,
    PortalDropdown
  },
  mixins: [
    stormMixin
  ],
  data () {
    return {
      isSaving: false,
      formOK: false,
      newCompany: {},
      stormCompanyError: false,
      stormBaseUrlError: false,
      stormAPICodeError: false
    }
  },
  computed: {
    companies () {
      return this.$store.getters.companies
    },
    subscriptionTiers () {
      return this.$store.getters.stormSubscriptionTiers
    },
    stormDefaultUser () {
      return this.$store.getters.stormDefaultUser
    }
  },
  mounted () {
    this.passwordConfirm = ''
    this.newCompany = this.getNewCompany()
  },
  methods: {
    ...mapActions([
      'setMainForm'
    ]),
    getNewCompany () {
      return { companyId: 0, baseUrl: '', apiCode: '', subscriptionTierId: 1 }
    },
    checkExistingCompany (email) {
      return this.$store.getters.stormCompanys.filter(u => u.email === this.newCompany.email.trim()).length > 0
    },
    addNewCompany: async function () {
      if (this.newCompany.companyId === 0 || this.newCompany.baseUrl.length === 0 || this.newCompany.apiCode.length === 0) {
        this.$store.commit('showMessage', { content: 'You must fill in all compulsory fields in order to add a new company', color: 'red', timeout: 3000 })
      } else {
        this.isSaving = true
        if (this.formOK) {
          if (this.newCompany.baseUrl.slice(-1) !== '/') {
            this.newCompany.baseUrl = this.newCompany.baseUrl + '/'
          }
          var payload = { companyId: this.newCompany.companyId, baseUrl: this.newCompany.baseUrl, apiCode: this.newCompany.apiCode, subscriptionTierId: this.newCompany.subscriptionTierId }
          var rtn = await this.addStormCompany(payload)
          if (rtn) {
            // Now we create our generic viewer account to use if the user is not set up
            rtn = await this.addGenericUser(this.newCompany.companyId)
            if (rtn) {
              this.closeForm()
              this.$emit('reload-companies', 0)
              this.$store.commit('showMessage', { content: 'Successfully Saved!', color: 'blue', timeout: 3000 })
            } else {
              this.$store.commit('showMessage', { content: 'Something went wrong adding the generic user, please try again..', color: 'red', timeout: 3000 })
            }
          } else {
            this.$store.commit('showMessage', { content: 'Something went wrong adding the new company, please try again..', color: 'red', timeout: 3000 })
          }
        } else {
          this.$store.commit('showMessage', { content: 'Something went wrong, please try again..', color: 'red', timeout: 3000 })
        }
        this.isSaving = false
      }
    },
    /******************************************************
    * This method adds the generic user to the new company.
    * It performs the following operations:
    * 1.  Resolves the admin auth token for the new company
    * 2.  Adds the new user to the new company
    * 3.  Retrieves the users and resolves the new user id
    * 4.  Retrieves the security groups for the new company
    * 5.  Sets the maintel user group security group for the new user
    * 6.  Retrieves the user groups for the new company
    * 7.  Sets the user group for the new user
    ******************************************************/
    addGenericUser: async function (companyId) {
      // 1.  Resolve the admin auth token for the new company
      var adminAuthToken = await this.getupAdminStormAuthTokenForCompany(companyId)
      if (adminAuthToken.length > 0) {
        var nUser = { id: 0,
          name: 'Maintel Default Viewer',
          description: 'Account used to view the dashboard in iQuote if the user is not set up',
          accountName: this.stormDefaultUser,
          password: 'jw4@2p9n$GP12rr#',
          passwordConfirm: 'jw4@2p9n$GP12rr#',
          hasSystemAccount: false,
          systemOnly: false,
          timeZone: 'GMT Standard Time',
          forename: 'Maintel',
          surname: 'Default Viewer',
          email: this.stormDefaultUser,
          phoneNumber: null,
          uri: null,
          accountDisabled: false }
        // 2.  Add the new user to the new company
        var rtn = await this.addStormUserToCompany(nUser, companyId, adminAuthToken)
        if (rtn) {
          // 3.  Retrieve the users and resolves the new user id
          var users = await this.getStormUsers(companyId, adminAuthToken)
          var userId = 0
          if (users.length > 0) {
            for (let i = 0; i < users.length; i++) {
              if (users[i].email === this.stormDefaultUser) {
                userId = users[i].id
                break
              }
            }
            if (userId > 0) {
              // 4.  Retrieve the security groups for the new company
              var securityGroups = await this.getStormSecurityGroups(companyId, adminAuthToken)
              if (securityGroups.length > 0) {
                var sgId = 0
                for (let i = 0; i < securityGroups.length; i++) {
                  if (securityGroups[i].name === this.$store.getters.stormUserSecurityGroup) {
                    sgId = securityGroups[i].id
                    break
                  }
                }
                if (sgId > 0) {
                  // 5.  Set the maintel user group security group for the new user
                  rtn = await this.addUserToStormSecurityGroupForCompany(userId, sgId, companyId, adminAuthToken)
                  if (rtn) {
                    // 6.  Retrieve the user groups for the new company
                    var userGroups = await this.getStormUserGroups(companyId, adminAuthToken)
                    if (userGroups.length > 0) {
                      var ugId = 0
                      for (let i = 0; i < userGroups.length; i++) {
                        if (userGroups[i].name === this.$store.getters.stormStandardUserGroup) {
                          ugId = userGroups[i].id
                        }
                      }
                      if (ugId > 0) {
                        // 7.  Set the user group for the new user
                        rtn = await this.addUserToStormUserGroupForCompany(userId, ugId, companyId, adminAuthToken)
                        if (rtn) {
                          return true
                        } else {
                          console.log('The default viewr failed to be added to the standard user group for the new company.')
                          return false
                        }
                      } else {
                        console.log('The standard user group could not be resolved for the new company.')
                      }
                    } else {
                      console.log('The user groups could not be resolved for the new company.')
                    }
                  } else {
                    console.log('The default viewer failed to be added to the user security groupu for the new com0pany.')
                  }
                } else {
                  console.log('The standard security group could not be resolved for the new company.')
                }
              } else {
                console.log('The security groups for the new company could nto be resolved.')
              }
            } else {
              console.log('The default view account was not resolve in the newly created company.')
            }
          } else {
            console.log('The default view account was not found to be created in the new company instance.')
          }
        } else {
          console.log('Failed to add the default view account to the new company instance.')
        }
      } else {
        console.log('The admin account in the new company could not be resolved.')
      }
      return rtn
    },
    closeForm () {
      this.setMainForm(false)
      this.clearErrors()
      this.newCompany = this.getNewCompany()
    },
    clearErrors () {
      this.stormForenameError = false
      this.stormSurameError = false
      this.stormEmailError = false
      this.stormPasswordError = false
      this.stormBaseUrlError = false
      this.stormAPICodeError = false
    },
    checkForm: function () {
      this.clearErrors()
      this.stormCompanyError = this.newCompany.companyId === 0
      if (!this.stormCompanyError) {
        this.stormBaseUrlError = this.newCompany.baseUrl.length === 0
      }
      if (!this.stormCompanyError) {
        this.stormAPICodeError = this.newCompany.apiCode.length === 0 || this.newCompany.apiCode.length > 36
      }

      var errorMessage = ''
      if (this.stormCompanyError) {
        errorMessage = ' Please select a company to add a UC Analytics entry to.'
      }
      if (this.stormBaseUrlError) {
        errorMessage = ' Please enter the base url for the UC Analytics instance.'
      }
      if (this.stormAPICodeError) {
        errorMessage = ' The API code must be greater than 0 and less than 36 characters long.'
      }
      if (errorMessage !== '') {
        this.$store.commit('showMessage', { content: errorMessage, color: 'red', timeout: 5000 })
      }
      this.formOK = !this.stormCompanyError && !this.stormBaseUrlError && !this.stormAPICodeError
    },
    setSubscriptionTier () {
      this.newCompany.subscriptionTierId = Number(this.$refs.subscriptionTierDropDown.selectedOptions[0].value)
    },
    selectCustomerCompany (option) {
      if (option) {
        this.newCompany.companyId = option.id
      }
    }
  }
}

</script>
<style scoped>
  .stormCompanyHolder {
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    display:none;
  }
  .stormCompanyDetailLabel {
    width: 100px;
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
  .stormCompanyDetailSubscriptionTierLabel {
    width: 160px;
    display: inline-block;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
  .stormCompanyDetailData {
    width: 270px;
    display: inline-block;
  }
  .stormCompanyDetailInput {
    width: 265px;
    display: inline-block;
    margin:0 0 10px 10px;
    padding: 0 0 0 5px;
  }
  .stormCompanyAddButton {
    float:right;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
    margin: 10px;
  }
  .stormCancelNewButton {
    margin: 10px;
    float: left;
    cursor: pointer;
    border: 2px solid rgba(44,62,80,1);
  }
  .stormCompanyAddButton:disabled {
    opacity: 0.7;
    cursor: default;
  }
  .errorHighlight {
    border: 1px solid red;
  }
  .stormNewBackground {
    position: absolute;
    z-index: 888;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,0.6)
  }
  .stormNewHolder {
    display: inline-block;
    background-color: rgba(240,240,240,1);
    position: absolute;
    border: solid 4px rgba(44,62,80,1);
    left: calc(50% - 220px);
    top: calc(50% - 200px);
    height: 320px;
    width: 420px;
    z-index: 999;
  }
  .stormNewTitle {
    padding:10px;
    background-color: rgba(44,62,80,0.7);
    color: #fff;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .stormTableholder {
    overflow: auto;
    height: 330px;
    max-height: 340px;
  }
  .compulsoryField {
    color:red;
    padding:0 8px;
  }
  .subscriptionTierDropDown {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 0 0 5px;
    width: 214px;
    -moz-appearance: auto;
    -webkit-appearance: auto;
    appearance: auto;
  }
  #cboAddCompany {
    width:280px;
    margin: 0 0 10px 0;
  }
</style>
