import communicationsAPI from '../communications/interceptors/apiInterceptor'
import { autotaskAPIMixin } from '../mixins/autotaskAPIMixin'
import { autotaskServiceMixin } from '../mixins/autotaskServiceMixin'
import { usersMixin } from '../mixins/users'
import { mapActions } from 'vuex'
export const ticketMixin = {
  data () {
    return {
      ticketTimer: null
    }
  },
  mixins: [
    autotaskAPIMixin,
    autotaskServiceMixin,
    usersMixin
  ],
  computed: {
    currentSites () {
      return this.$store.getters.sites
    },
    ticketList () {
      var _tickets = this.$store.getters.tickets
      if (!_tickets || _tickets.length === 0) {
        if (this.ticketTimer !== null) {
          return []
        }
        return []
      }
      return _tickets
    },
    ticketUDFList () {
      var _tickets = this.$store.getters.ticketUDFs
      if (!_tickets || _tickets.length === 0) {
        if (this.ticketTimer !== null) {
          return []
        }
        return []
      }
      return _tickets
    },
    pmnTicketList () {
      var _tickets = this.$store.getters.pmnTickets
      if (!_tickets || _tickets.length === 0) {
        if (this.ticketTimer !== null) {
          return []
        }
        return []
      }
      return _tickets
    },
    pmnTicketUDFList () {
      var _tickets = this.$store.getters.pmnTicketUDFs
      if (!_tickets || _tickets.length === 0) {
        if (this.ticketTimer !== null) {
          return []
        }
        return []
      }
      return _tickets
    },
    username () {
      return this.$store.getters.username
    }
  },
  methods: {
    ...mapActions([
      'setAutotaskContact'
    ]),
    getSiteContact (site) {
      var rtn = 0
      var contacts = this.$store.getters.autotaskContact
      if (contacts && contacts.length > 0) {
        contacts.forEach(contact => {
          if (contact.accountId === site.id || contact.accountId === site.parent) {
            rtn = contact.id
          }
        })
      }
      return rtn
    },
    setNewContact: async function (siteId) {
      var rtn = 0
      var newContact = {}
      newContact.id = 0
      // Resolve the site as the root
      var site = this.currentSites.filter(s => s.id === siteId)[0]

      // A user should not have access to a child site ID without its parent site, but we
      // have had at least one instance where a user's access was set up incorrectly. If
      // the parent site cannot be resolved, handle it gracefully or the screen will hang
      if (site !== undefined) {
        // If we have resolved a site which has a parent, then set the site to the parent. We will only ever have one tier deep (otherwise will have to use while)
        if (site.parent > 0) {
          var parentSite = this.currentSites.filter(s => s.id === site.parent)[0]
          // If the user only has access to the child site, we must log the ticket and
          // try to create/resolve the contact against that
          if (parentSite != null) {
            siteId = parentSite.id
          }
        }

        // Attempt to retrieve the email address of the logged-in user in order to retrieve the contact ID
        var loggedInUserContactId = -1
        var loggedInUserEmailAddress = ''
        var loggedInUser = await this.getUserList(String(this.$store.getters.userid))
        if (loggedInUser !== null && loggedInUser !== undefined && loggedInUser.length > 0) {
          if (loggedInUser[0].emailAddress !== null && loggedInUser[0].emailAddress !== undefined && loggedInUser[0].emailAddress !== '') {
            loggedInUserEmailAddress = loggedInUser[0].emailAddress
          }
        }
        // Attempt to obtain the contact ID from the email address
        if (loggedInUserEmailAddress !== '') {
          if (this.$store.getters.autotaskContact.length > 0) {
            var loggedInUserContactDetails = this.$store.getters.autotaskContact.filter(c => c.emailAddress === loggedInUserEmailAddress)

            // Having retrieved all contacts for the present user's email address, we must now
            // loop through them to ensure that the contact is valid for the present site
            loggedInUserContactDetails.forEach(contact => {
              if (contact.accountId === site.id || contact.accountId === site.parentId) {
                loggedInUserContactId = contact.id
              }
            })
          }
        }

        // If the logged-in user does not have a contact for the site or its parent, we will
        // try to add one, since a user must have a contact for either the site or parent
        // site to log a ticket against it
        if (loggedInUserContactId === -1) {
          newContact.emailAddress = this.$store.getters.currentUser.emailAddress
          newContact.firstName = this.$store.getters.currentUser.firstName
          newContact.lastName = this.$store.getters.currentUser.lastName
          newContact.accountId = siteId
          newContact.note = 'Contact added via the iQuote'
          var data = await this.addAutotaskAPIContact(newContact)
          if (data !== 'error') {
            rtn = data.itemId

            // Now that we have confirmed the new contact in the Auotask API, we'll add it to the contacts table in the MaintelAutotask database.
            newContact.id = rtn
            newContact.address1 = ''
            newContact.address2 = ''
            newContact.city = ''
            newContact.state = ''
            newContact.zipcode = ''
            newContact.country = ''
            newContact.lastActivityDateTime = this.getTodayString()
            newContact.dateCreated = this.getTodayString()
            newContact.phone = ''
            newContact.mobilePhone = ''

            data = await this.addAutotaskContact(newContact)

            // Finally we reload the contacts that are resolved against the email address to the store.
            var aContacts = await this.getAutotaskContact(newContact.emailAddress)
            this.setAutotaskContact(aContacts)
          }
        } else {
          rtn = loggedInUserContactId
        }
      }
      return rtn
    },
    sendResourceNotification: async function (to, title, body, ticket) {
      var result = false
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': this.$store.getters.userid
        }
      }
      var issueType
      if (this.ticket.issueTypeId) {
        issueType = this.$store.getters.issueTypeList.find(i => i.value === this.ticket.issueTypeId.toString()).name
      } else {
        issueType = 'Not Set'
      }
      var ticketUpdateObject = {
        TicketId: ticket.id,
        TicketTitle: ticket.title,
        TicketNumber: ticket.ticketNumber,
        TicketStatus: this.$store.getters.statusList.find(c => c.value === ticket.statusId.toString()).name,
        TicketPriority: this.$store.getters.priorityList.find(c => c.value === ticket.priorityId.toString()).name,
        TicketIssue: issueType,
        TicketNoteTitle: title,
        TicketNote: body,
        ToAddress: to,
        ChannelCompanyId: this.$store.getters.channelCompany.id
      }
      try {
        await communicationsAPI.post('ticket/email/update', ticketUpdateObject, axiosConfig).then(() => {
          result = true
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
      return result
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getTodayString () {
      var date = new Date()
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - (offset * 60 * 1000))
      return date.toISOString().replace('Z', '')
    }
  }
}
