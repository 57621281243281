<template>
  <div
    :class="selected"
    class="technologyTileContainer"
  >
    <div>
      <img
        :id="id"
        :src="imageSource"
        :alt="technologyName"
        @click="gotoPage()"
      >
    </div>
    <div class="tileButtons" >
      <button @click="remove()">Remove</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'

export default {
  name: 'TechnologyTile',
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteConstants],
  props: {
    technologyId: {
      type: Number,
      default: -1
    },
    technologyName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    selected () {
      var isSelected = ''

      if (this.technologyId === this.iQuoteConstants.technologies.avayaAura) {
        if (this.$store.getters.quoteTechnologyAvayaAura.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaElite) {
        if (this.$store.getters.quoteTechnologyAvayaElite.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaAACC) {
        if (this.$store.getters.quoteTechnologyAvayaAACC.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.callmedia) {
        if (this.$store.getters.quoteTechnologyCallmedia.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.oceana) {

      } else if (this.technologyId === this.iQuoteConstants.technologies.gammaSIP) {
        if (this.$store.getters.quoteGammaSIP.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_MSP) {
        if (this.$store.getters.quoteTechnologyMitelUC.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_E2) {
        if (this.$store.getters.quoteTechnologyMitelUCE2.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelCC) {
        if (this.$store.getters.quoteTechnologyMitelCC.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.sipMigrationOffer) {
        if (this.$store.getters.quoteSIPMigrationProperties.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.technologyId === this.iQuoteConstants.technologies.coltSip) {

      } else {
      }
      return isSelected
    },
    imageSource () {
      if (this.technologyId === this.iQuoteConstants.technologies.avayaAura) {
        return require(`../../../assets/icons/avayaAura.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaElite) {
        return require(`../../../assets/icons/avayaElite.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaAACC) {
        return require(`../../../assets/icons/avayaAACC.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.callmedia) {
        return require(`../../../assets/icons/callmedia.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.oceana) {
        return require(`../../../assets/icons/avayaOceana.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.gammaSIP) {
        return require(`../../../assets/icons/gammaSIP.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_MSP) {
        return require(`../../../assets/icons/mitelUC-MSP.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_E2) {
        return require(`../../../assets/icons/mitelUC-E2.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelCC) {
        return require(`../../../assets/icons/mitelCC.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.sipMigrationOffer) {
        return require(`../../../assets/icons/sipMigrationOfferComingSoon.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.talkTalkBusinessSip) {
        return require(`../../../assets/icons/TalkTalkBusinessSipDisabled.png`)
      } else if (this.technologyId === this.iQuoteConstants.technologies.coltSip) {
        return require(`../../../assets/icons/coltSipDisabled.png`)
      } else {
        return require(`../../../assets/icons/avayaAura.png`)
      }
    },
    pageSource () {
      if (this.technologyId === this.iQuoteConstants.technologies.avayaAura) {
        return `UserProfilesAura`
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaElite) {
        return `UserProfilesElite`
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaAACC) {
        return `UserProfilesAACC`
      } else if (this.technologyId === this.iQuoteConstants.technologies.callmedia) {
        return `UserProfilesCallmedia`
      } else if (this.technologyId === this.iQuoteConstants.technologies.oceana) {
        return `UserProfilesOceana`
      } else if (this.technologyId === this.iQuoteConstants.technologies.gammaSIP) {
        return `SipGammaService`
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_MSP) {
        return `UserProfilesMitelUC`
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelUC_E2) {
        return `mitelUCE2Page1`
      } else if (this.technologyId === this.iQuoteConstants.technologies.mitelCC) {
        return `UserProfilesMitelCC`
      } else if (this.technologyId === this.iQuoteConstants.technologies.sipMigrationOffer) {
        return `SipMigrationOffer`
      } else if (this.technologyId === this.iQuoteConstants.technologies.talkTalkBusinessSip) {
        return `UkSip`
      } else if (this.technologyId === this.iQuoteConstants.technologies.coltSip) {
        return `InternationalSip`
      } else {
        return `AvayaAura`
      }
    },
    id () {
      if (this.technologyId === this.iQuoteConstants.technologies.avayaAura) {
        return `btnAura`
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaElite) {
        return `btnElite`
      } else if (this.technologyId === this.iQuoteConstants.technologies.avayaAACC) {
        return `btnAACC`
      } else if (this.technologyId === this.iQuoteConstants.technologies.callmedia) {
        return `btnCallmedia`
      } else if (this.technologyId === this.iQuoteConstants.technologies.oceana) {
        return `btnOceana`
      } else if (this.technologyId === this.iQuoteConstants.technologies.gammaSIP) {
        return `btnICONSIP`
      } else if (this.technologyId === this.iQuoteConstants.technologies.talkTalkBusinessSip) {
        return `btnTalkTalkBusinessSip`
      } else if (this.technologyId === this.iQuoteConstants.technologies.coltSip) {
        return `btnColtSip`
      } else if (this.technologyId === this.iQuoteConstants.technologies.sipMigrationOffer) {
        return `btnSipMigrationOffer`
      } else {
        return `btnAura`
      }
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setCurrentTechnology',
      'setQuoteTechnologyAvayaAura',
      'setQuoteTechnologyCallmedia',
      'setQuoteTechnologyAvayaElite',
      'setQuoteTechnologyAvayaAACC',
      'setQuoteTechnologyMitelCC',
      'setQuoteTechnologyMitelUC',
      'setQuoteGammaSIP',
      'setQuoteSIPMigrationProperties',
      'setQuoteTechnologyMitelUCE2'
    ]),
    gotoPage () {
      this.setCurrentTechnology(this.technologyId)
      this.setCurrentQuoteState(this.pageSource)
    },
    async remove () {
      if (window.confirm('Remove configuration for this solution?')) {
        var quote = this.$store.getters.quote
        var technologyId

        // Depending on technology chosen, clear associated form data from local store
        switch (this.technologyId) {
          case this.iQuoteConstants.technologies.avayaAura:
            var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
            if (quoteTechnologyAvayaAura.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAura)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // delete from local store
            this.setQuoteTechnologyAvayaAura({})
            break
          case this.iQuoteConstants.technologies.avayaElite:
            var quoteTechnologyAvayaElite = this.$store.getters.quoteTechnologyAvayaElite
            if (quoteTechnologyAvayaElite.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaElite)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyAvayaElite({})
            break
          case this.iQuoteConstants.technologies.avayaAACC:
            var quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
            if (quoteTechnologyAvayaAACC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAACC)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyAvayaAACC({})
            break
          case this.iQuoteConstants.technologies.callmedia:
            var quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
            if (quoteTechnologyCallmedia.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.callmedia)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyCallmedia({})
            break
          case this.iQuoteConstants.technologies.mitelCC:
            var quoteTechnologyMitelCC = this.$store.getters.quoteTechnologyMitelCC
            if (quoteTechnologyMitelCC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelCC)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyMitelCC({})
            break
            case this.iQuoteConstants.technologies.mitelUC_MSP:
            var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
            if (quoteTechnologyMitelUC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyMitelUC({})
            break
            case this.iQuoteConstants.technologies.mitelUC_E2:
            var quoteTechnologyMitelUCE2 = this.$store.getters.quoteTechnologyMitelUCE2
            if (quoteTechnologyMitelUCE2.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_E2)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }
            // delete from local store
            this.setQuoteTechnologyMitelUCE2({})
            break
          case this.iQuoteConstants.technologies.oceana:
            // TODO: Not implemented yet
            // this.setQuoteUserProfileOceana({})
            break
        }
      }
    }
  }
}
</script>
<style scoped>
  .technologyTileContainer {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: #fff;
    border: solid 2px #235622;
    border-radius: 8px;
  }
  .technologyTileContainer:hover {
    background-color: rgb(199, 209, 199);
    border: solid 2px #000;
  }
  .technologyTileContainer.selected {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: rgb(199, 209, 199);
    border: solid 2px #235622;
  }
  .technologyTileContainer.selected:hover {
    background-color: rgb(122, 136, 122);
    border: solid 2px #000;
  }
  .technologyTileContainer img {
    width:140px;
    cursor: pointer;
  }
  .technologyTileContainer .tileButtons{
    visibility:hidden;
  }
  .technologyTileContainer.selected .tileButtons{
    visibility:visible;
  }
</style>
