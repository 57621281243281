<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <h2>Quote Summary</h2>
      #<label for="txtiQuoteReference">iQuote</label>
      <input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      ><br>
      <table id="quoteSummaryTable">
        <tr>
          <td id="quoteSummaryTableColumn1">
            <label for="txtAccount">Account</label>
            <input
              id="txtAccount"
              :value="quote.salesforceAccountName"
              readonly="true"
              type="text"
            ><br>
            <label for="txtFirstName">Contact Name</label>
            <input
              id="txtFirstName"
              :value="quote.salesforceContactFirstName"
              type="text"
              readonly="true"
            >
            <input
              id="txtLastName"
              :value="quote.salesforceContactLastName"
              readonly="true"
              type="text"
            ><br>
            <label for="txtQuoteDescription">Quote Description</label>
            <input
              id="txtQuoteDescription"
              :value="quote.quoteDescription"
              readonly="true"
              type="text"
            ><br>
            <label for="txtApprovalStatus">Salesforce Approval Status</label>
            <input
              id="txtApprovalStatus"
              :value="approvalStatus"
              readonly="true"
              type="text"
            >
            <img
              :src="refresh"
              class="refresh"
              @click="getSalesforceOpportunity()"
            >
          </td>
          <td>
            <label
              v-if="!calculationsCommonMixin_isExpansionOnlyQuote"
              :class="{InputError: quote.termLengthMonths === null}"
              for="cboTermLength"
            >Term Length</label>
            <label
              v-if="calculationsCommonMixin_isExpansionOnlyQuote"
              :class="{InputError: quote.termLengthMonths === null}"
              for="txtTermLength"
            >Term Length (Months)</label>
            <input
              v-if="calculationsCommonMixin_isExpansionOnlyQuote"
              id="txtTermLength"
              v-model.number="quote.termLengthMonths"
              :disabled="reviewComponent.quoteIsBeingSaved"
              type="text"
              @keyup="quoteModified()"
            >
            <select
              v-if="!calculationsCommonMixin_isExpansionOnlyQuote"
              id="cboTermLength"
              ref="cboTermLength"
              v-model.number="quote.termLengthMonths"
              :disabled="reviewComponent.quoteIsBeingSaved"
              @change="quoteModified()"
            >
              <option
                v-for="t in calculationsCommonMixin_allowedTermLengths"
                :key="t"
                :value="t"
                :title="t"
              >{{ t }} Months
              </option>
            </select>
            <div style="display:none">
              <label
                :class="{InputError: quote.quoteResilienceTypeId === null}"
                for="cboResilience"
              >Application SLA</label>
              <select
                id="cboResilience"
                v-model.number="quote.quoteResilienceTypeId"
                @change="quoteModified()"
              >
                <option
                  v-if="quoteTechnologyAvayaAura.id !== undefined"
                  key="1"
                  value="1"
                  title="Disaster Recovery">Disaster Recovery</option>
                <option
                  v-if="quoteTechnologyAvayaAura.id !== undefined"
                  key="2"
                  value="2"
                  title="Business Continuity">Business Continuity</option>
                <option
                  v-if="quoteTechnologyAvayaAura.id === undefined"
                  key="3"
                  value="3"
                  title="Not Applicable">Not Applicable</option>
              </select>
            </div>
            <label
              v-if="quoteTechnologyAvayaAura.id !== undefined"
              class=""
              for="chkCriticalAvailability"
            >Critical Availability
              <TooltipIcon text="Enhanced SLA based on additional redundancy built into each ICON Data Centre for core voice services" />
            </label>
            <input
              v-if="quoteTechnologyAvayaAura.id !== undefined"
              id="chkCriticalAvailability"
              v-model.number="quote.criticalAvailability"
              :disabled="reviewComponent.quoteIsBeingSaved"
              type="checkbox"
              @change="quoteModified()"
            >
            <label
              :class="{InputError: quote.quoteLicenseTypeId === null}"
              for="cboApplicationLicenseType"
            >Application License Type
              <TooltipIcon
                width="500"
                text="This selects the customers required license model. <br><br>
                    <ul>
                      <li><strong>Subscription</strong> - Greenfield customers looking for an opex pricing model covering Licenses, Managed Services and ICON Infrastructure</li>
                      <li><strong>Surrender</strong> - (For Existing Avaya customers only) who wish to receive a discount on their opex pricing model by surrendering existing Avaya perpetual licensing and covering Licenses, Managed Servics and ICON infrastructure</li>
                      <li><strong>Perpetual</strong> - Existing customers looking for opex pricing for infrastructure and Managed Services only</li>
                    </ul>" />
            </label>
            <select
              id="cboApplicationLicenseType"
              v-model.number="quote.quoteLicenseTypeId"
              :disabled="reviewComponent.quoteIsBeingSaved"
              @change="quoteModified()"
            >
              <option
                key="1"
                value="1"
                title="Subscription">Subscription</option>
              <option
                v-if="iQuoteCalculationsReviewScreenMixin_ShowPerpetualLicenseType"
                key="2"
                value="2"
                title="Perpetual">Perpetual</option>
              <option
                v-if="iQuoteCalculationsReviewScreenMixin_ShowSurrenderLicenseType"
                key="3"
                value="3"
                title="Surrender">Surrender</option>
            </select>
            <label
              for="cboCallTarrif"
            >Call Tarrif
              <TooltipIcon text="Maintel has 3 different rate cards for SIP Calling plans.  Choose the one the customer wants.  You can download the rates with the 'Call Tariff' button at the bottom of this page" />
            </label>
            <select
              id="cboCallTarrif"
              v-model.number="quote.quoteCallTarrifId"
              :disabled="reviewComponent.quoteIsBeingSaved"
              @change="quoteModified()"
            >
              <option
                key="1"
                value="1"
                title="None">None</option>
              <option
                v-if="quoteTechnologyCallmediaCXNow.id !== undefined ||
                  quoteTechnologyAvayaAura.id !== undefined ||
                  quoteTechnologyMitelUC.id !== undefined ||
                  teamsConnector.id !== undefined ||
                  phoneLinePlus.id !== undefined ||
                quoteGammaSIP.id !== undefined"
                key="2"
                value="2"
                title="Enterprise">Enterprise</option>
              <option
                v-if="quoteTechnologyCallmediaCXNow.id !== undefined ||
                  quoteTechnologyAvayaAura.id !== undefined ||
                  quoteTechnologyMitelUC.id !== undefined ||
                  teamsConnector.id !== undefined ||
                  phoneLinePlus.id !== undefined ||
                quoteGammaSIP.id !== undefined"
                key="3"
                value="3"
                title="Corporate">Corporate</option>
              <option
                v-if="quoteTechnologyCallmediaCXNow.id !== undefined ||
                  quoteTechnologyAvayaAura.id !== undefined ||
                  quoteTechnologyMitelUC.id !== undefined ||
                  teamsConnector.id !== undefined ||
                  phoneLinePlus.id !== undefined ||
                quoteGammaSIP.id !== undefined"
                key="4"
                value="4"
                title="Public">Public</option>
            </select>
            <br>
            <label
              v-if="callmediaCXNowCalcs_totalUsersCount > 50"
              class=""
              for="chkAccelerateCXNow"
            >Accelerate CX Now
              <TooltipIcon text="Tick the Accelerate Now box to provide a discount for any legacy licenses transferred from existing Callmedia customers" />
            </label>
            <input
              v-if="callmediaCXNowCalcs_totalUsersCount > 50"
              id="chkAccelerateCXNow"
              v-model="quoteTechnologyCallmediaCXNow.options.accelerateCXNow"
              type="checkbox"
              class="small"
              @change="quoteModified()"
            >
            <div
              v-if="quoteTechnologyCallmediaCXNow.id !== undefined && quoteTechnologyCallmediaCXNow.options.accelerateCXNow"
              class="InputError">Applicable to existing customers only
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="!isValidTermLength">
      <span class="InputError">Please correct the term length.  Supported range: 6-84 months</span>
    </div>
    <div v-if="quote.quoteLicenseTypeId === iQuoteConstants.quoteLicenceTypes.PERPETUAL">
      <span class="InputError">License upgrades and vendor support must be quoted separately</span>
    </div>
    <div v-if="calcGenesysConnector_CSMCost > 50000">
      <span class="InputError">CSM costs require to be quoted separately as the value exceeds thresholds. Please contact Chris Dolan for a quote</span>
    </div>
    <hr>
    <div v-if="reviewComponent.quoteIsBeingCalculated">
      <h3 style="text-align: center">Calculating Quote</h3>
      <br>
      <spinner />
    </div>
    <div v-if="reviewComponent.quoteIsBeingSaved">
      <h3 style="text-align: center">Saving Quote</h3>
      <br>
      <spinner />
    </div>
    <div
      v-if="reviewComponent.quoteSaveError && !reviewComponent.quoteIsBeingCalculated && !reviewComponent.quoteIsBeingSaved"
      style="text-align: center"
    >
      <h3>Error saving quote - Please try again and Contact System Administrator if error persists</h3>
      <input
        v-if="quoteIsValid"
        id="btnTrySavingQuoteAgain"
        value="Try Again"
        type="button"
        @click="quoteModified"
      >
    </div>
    <div v-if="isValidTermLength && !reviewComponent.quoteIsBeingCalculated && !reviewComponent.quoteSaveError && !reviewComponent.quoteIsBeingSaved">
      <div
        v-if="(!calculationsCommonMixin_isExpansionOnlyQuote || quote.termLengthMonths >= 12) && quoteSIPMigrationProperties.id === undefined"
        class="quotePanel"
      >
        <h2>Pricing Override</h2>
        <input
          id="chkChargeUpfront"
          v-model="quote.chargeUpfront"
          type="checkbox"
          class="small"
          @change="chargeUpfrontUpdated"
        >
        <label
          class="chargeLabel"
          for="chkChargeUpFront"
        >Charge Up Front<tooltipIcon text="Displays upfront charges for ICON implementation." /></label>
        <span
          v-if="totalBladeServers > 1 && quote.chargeUpfront && !quote.removedChargeUpfront"
          class="inputWarning">Since blade servers in DCs are >1 ({{ iQuoteFunctions_RoundToTwoDecimalPlaces(totalBladeServers) }}), charge upfront has been selected</span>
        <div
          v-if="!quote.chargeUpfront && iQuoteStore_GetQuoteSycurio.id !== undefined"
          class="InputError"><br>Sycurio upfront costs cannot be amortised and will remain as upfront charges
        </div>
      </div>
      <div class="quotePanel">
        <hr>
        <quoteSummary
          id="iQuoteDetails"
          :loading="false"
          :buttons="[]"
          :hide-header="true"
          style="width:100%"
        />
        <div class="buttons">
          <div class="leftButtons">
            <input
              v-if="!quote.locked"
              id="btnBack"
              value="< Back"
              type="button"
              @click="setCurrentQuoteState('SelectService')"
            >
          </div>
          <div class="rightButtons">
            <input
              v-if="opportunity.approvalStatus === 'Approved' && quoteIsValid"
              v-show="false"
              id="btnPublish"
              value="Publish"
              type="button"
              @click="publishQuote"
            >
          </div>
          <div class="middleButtons">
            <input
              v-if="false"
              id="btnViewBOM"
              value="Customer Quote Summary"
              type="button"
              @click="viewCustomerSummary"
            >
            <input
              v-if="quoteIsValid"
              id="btnViewExternalQuotes"
              value="External Quotes"
              type="button"
              @click="viewExternalQuotes"
            >
            <input
              v-if="quoteIsValid && quoteTechnologyCallmediaCXNow.id !== undefined"
              id="btnViewCxNowDDI"
              style="display:none"
              value="DDI Detail"
              type="button"
              @click="viewCxNowDdiDetail"
            >
            <input
              v-if="quoteIsValid && quote.quoteCallTarrifId !== 1 /* None */ &&
                (quoteTechnologyCallmediaCXNow.id !== undefined ||
                quoteTechnologyAvayaAura.id !== undefined ||
                quoteTechnologyMitelUC.id !== undefined ||
                teamsConnector.id !== undefined ||
                phoneLinePlus.id !== undefined ||
              quoteGammaSIP.id !== undefined)"
              id="downloadCallTarrifDoc"
              class="downloadCallTarrifDocButton"
              value="Call Tariff"
              type="button"
              @click="downloadCallTarrifDoc"
            >

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteCalculationsReviewScreenMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsReviewScreenMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import Spinner from '../../common/spinner'
import quoteSummary from '../panels/quoteSummary'

export default {
  name: 'Review',
  components: {
    TooltipIcon, Spinner, quoteSummary
  },
  mixins: [
    iQuoteCalculationsCommonMixin,
    iQuoteQuoteAPIMixin,
    iQuoteExportsAPIMixin,
    iQuoteSalesforceAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteCalculationsReviewScreenMixin,
    externalQuotesModelMixin],
  data () {
    return {
      reviewComponent: {
        quoteIsBeingCalculated: false,
        quoteIsBeingSaved: false,
        quoteSaveError: false
      },
      termLengths: [],
      resiliences: [],
      applicationLicenseTypes: [],
      serviceLevels: [],
      quote: {
        chargeUpfront: false,
        removedChargeUpfront: false
      },
      opportunity: {
        approved: ''
      },
      quoteTechnologyAvayaAura: {
        userProfiles: [],
        adjuncts: {
          rightfax: false
        },
        hardware: []
      },
      quoteExpansions: {},
      quoteTechnologyCallmedia: {
        userProfiles: [],
        adjuncts: {}
      },
      quoteTechnologyCallmediaCXNow: {
        userProfiles: [],
        adjuncts: {},
        options: {
          accelerateCXNow: false
        }
      },
      quoteTechnologyAvayaElite: {
        userProfiles: [],
        adjuncts: {}
      },
      quoteSIPMigrationProperties: {},
      teamsConnector: {},
      phoneLinePlus: {},
      quoteGammaSIP: {},
      quoteSycurio: {},
      quoteUCAnalytics: {},
      quoteAvayaAXP: {}
    }
  },
  computed: {
    isValidTermLength () {
      if (!this.calculationsCommonMixin_isExpansionOnlyQuote) {
        return true
      } else {
        return (this.quote.termLengthMonths !== '' && this.quote.termLengthMonths >= 6 && this.quote.termLengthMonths <= 84)
      }
    },
    refresh () {
      return require(`../../../assets/icons/refresh.png`)
    },
    approvalStatus () {
      if (this.opportunity.approvalStatus === undefined) {
        return 'Failed to retrieve status'
      } else if (this.opportunity.approvalStatus === null || this.opportunity.approvalStatus === '') {
        return 'Not Approved'
      } else {
        return this.opportunity.approvalStatus
      }
    },
    downloadDesignPackImageUrl () {
      return require(`../../../assets/icons/downloadDesignPack.png`)
    },
    quoteIsValid () {
      return this.quote.termLengthMonths !== null &&
      this.quote.quoteServiceLevelTypeId !== null &&
        this.quote.quoteResilienceTypeId !== null &&
        this.quote.criticalAvailability !== null &&
        this.quote.quoteLicenseTypeId !== null &&
        this.isValidTermLength
    },
    totalBladeServers () {
      let totalQuantity = 0
      if (this.iQuoteStore_GetQuoteBladeServers.length > 0) {
        this.iQuoteStore_GetQuoteBladeServers.forEach(item => {
          totalQuantity += item.quantityRequired
        })
      }
      return totalQuantity
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.quoteExpansions = this.$store.getters.quoteExpansions
    this.quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
    this.quoteTechnologyCallmedia = this.$store.getters.quoteTechnologyCallmedia
    this.quoteTechnologyCallmediaCXNow = this.$store.getters.quoteTechnologyCallmediaCXNow
    this.quoteSycurio = this.$store.getters.quoteSycurio
    this.quoteUCAnalytics = this.$store.getters.quoteUCAnalytics
    this.quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
    this.quoteTechnologyIconGateway = this.$store.getters.quoteTechnologyIconGateway
    this.teamsConnector = this.$store.getters.teamsConnector
    this.phoneLinePlus = this.$store.getters.quotePhoneLinePlus
    this.quoteAvayaAXP = this.$store.getters.quoteAvayaAXP
    this.termLengths = this.$store.getters.termLengths
    this.resiliences = this.$store.getters.resiliences
    this.applicationLicenseTypes = this.$store.getters.applicationLicenseTypes
    this.serviceLevels = this.$store.getters.serviceLevels
    this.quoteGammaSIP = this.$store.getters.quoteGammaSIP

    // Note that totalBladeServers will only be > 0 if recalculateQuote() has been run at least once before
    // this.quote.chargeUpfront = this.quote.chargeUpfront || (this.totalBladeServers > 1 && !this.quote.removedChargeUpfront)
    this.setQuote(this.quote)

    this.getSalesforceOpportunity()

    // We need to run this once to calculate the number of blade servers
    this.recalculateQuote()

    this.externalQuotesModelMixin_reset()

    if (!this.quote.chargeUpfront && (this.totalBladeServers > 1 && !this.quote.removedChargeUpfront)) {
      // totalBladeServers count should now be correct after running recalculateQuote() function.  If we aren't currently
      // charging upfront and the number of bladeservers says we should, then set to charge upfront and recalculate quote
      this.quote.chargeUpfront = true
      this.setQuote(this.quote)

      this.recalculateQuote()
    }

    await this.saveQuote()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuote',
      'setQuoteVMsRequired',
      'setQuoteLicensesRequired',
      'setQuoteBladeServers',
      'setQuoteUnitCosts',
      'setQuoteDesignPackEntries',
      'setQuoteTechnologyCallmediaCXNow'
    ]),
    recalculateQuote () {
      this.reviewComponent.quoteIsBeingCalculated = true
      this.calculationsCommon_Calculate()
      this.reviewComponent.quoteIsBeingCalculated = false
    },
    async saveQuote () {
      this.setQuote(this.quote)
      this.reviewComponent.quoteIsBeingSaved = true
      var updatedQuote = await this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(this.quote)
      if (updatedQuote.id === undefined) {
        this.iQuoteFunctions_ShowError('Failed to save quote')
        this.reviewComponent.quoteSaveError = true
      } else {
        this.reviewComponent.quoteSaveError = false
        await this.calculationsCommon_saveQuoteCalculationsToDB()
      }
      await this.iQuoteFunctions_delay(1500) // Wait a little while for data to finish writing to DB
      this.reviewComponent.quoteIsBeingSaved = false
      return updatedQuote
    },
    viewBillOfMaterials () {
      this.setCurrentQuoteState('BillOfMaterials')
    },
    viewCustomerSummary () {
      this.setCurrentQuoteState('CustomerSummary')
    },
    viewExternalQuotes () {
      this.setCurrentQuoteState('ExternalQuotes')
    },
    viewCxNowDdiDetail () {
      this.setCurrentQuoteState('CxNowDdiDetail')
    },
    async quoteModified () {
      if (this.quote.termLengthMonths < 12 || this.quoteSIPMigrationProperties.id !== undefined) {
        // Have to charge upfront for contracts under a year and for SIP Migration
        this.quote.chargeUpfront = true
      }

      if (this.quoteTechnologyCallmediaCXNow.id !== undefined) {
        this.setQuoteTechnologyCallmediaCXNow(this.quoteTechnologyCallmediaCXNow)
      }

      if (this.quoteIsValid) {
        this.setQuote(this.quote)
        this.recalculateQuote()
        await this.saveQuote()
      }
    },
    async publishQuote () {
      this.quote.dateLastEdited = new Date()
      this.quote.datePublished = new Date()
      this.quote.quoteStateId = this.iQuoteConstants.quoteStates.PUBLISHED
      var updatedQuote = await this.saveQuote()

      if (updatedQuote.id !== undefined) {
        this.setQuote(this.quote)
        this.setCurrentQuoteState('Published')
      }
    },
    async chargeUpfrontUpdated (event) {
      if (!event.target.checked && !this.quote.removedChargeUpfront) {
        this.quote.removedChargeUpfront = true
      }

      this.setQuote(this.quote)
      this.recalculateQuote()
      await this.saveQuote()
    },
    downloadCallTarrifDoc: async function () {
      var data = await this.iQuoteExportsAPIMixin_getQuoteCallTarrifDoc(this.quote.id)
      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      a.download = 'quoteCallTariff_' + this.quote.id + '.xls'
      document.body.appendChild(a)
      a.click()
    },
    getSalesforceOpportunity: async function () {
      this.opportunity = {}
      this.opportunity.approvalStatus = 'Updating...'
      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.quote.salesforceQuoteNumber)
      if (SalesforceOpportunity !== null && SalesforceOpportunity !== '') {
        this.opportunity = SalesforceOpportunity
      } else {
        this.opportunity = {}
      }
    }
  }
}
</script>
<style scoped>
  .downloadDesignPackButton {cursor:pointer;}
  .downloadCallTarrifDocButton {cursor:pointer;}

  .quotePanel {
    width: 950px;
    text-align: left;
  }

  .quotePanel label {
    width: 190px;
    display: inline-block;
    font-size: 0.9em;
  }
  .quotePanel input {margin:3px;}
  .quotePanel input[type=text]{
    width: 140px;
    display: inline-block;
    /*border: solid 1px #000;*/
  }
  .quotePanel input[type=button]{
    margin: 5px;
    border: solid 1px #000;
    cursor: pointer;
  }
  .quotePanel input[type=number]{
    width: 90px;
    /*border: solid 1px #000;*/
  }
  .quotePanel .longLabel {
    width: 350px;
  }
  .quotePanel .chargeLabel {
    width: 280px;
  }

  .quotePanel .middleButtons {
    width: 550px;
  }

  table {
    width: auto;
  }

  table td {
    padding: 2px 6px;
  }

  table th {
    padding: 2px 6px;
  }

  h2 {
    margin-top: 0px;
  }

  input[type="checkbox"][readonly] {
  pointer-events: none;
  }

  #quoteSummaryTable label{
    font-weight: bold;
  }

  #quoteSummaryTableColumn2 {
    padding-left:40px;
  }

  #quoteSummaryTableColumn1 label {
    width:170px;
  }

  #quoteSummaryTableColumn2 label {
    width:125px;
  }

  .serviceTitleColumn {
    width: 350px;
  }

  .priceColumn {
    width: 120px;
  }

  .quantityColumn {
    width: 120px;
  }

  .inputWarning {
    color: rgb(255, 142, 4);
    font-weight: 400;
  }

  #btnRequestCommercialSupport {margin-right: 160px; border:solid 1px #000;}
  #txtiQuoteReference {width: 240px;}
  #txtAccount  {width: 240px;}
  #txtFirstName {width: 115px;}
  #txtLastName {width: 115px;}
  #txtQuoteDescription {width: 240px;}
  #txtTermLength {width: 60px; margin-right:180px;}

  #costSummaryTable {
    width: 950px
  }

  #quoteCommercialSummaryTable {
    width:700px;
  }

#quoteCommercialSummaryTable label{
    width:100px;
    font-weight: bold;
  }

</style>
