<template>
  <div class="designPackSummaryContainer quotePanel">
    <div
      class="psSummarySection"
    >
      <h3>Overview</h3>
      <table
        id="PsCostSummaryTable"
        class="iquoteDesignpackTable">
        <tr>
          <th
            width="250px">Professional Services</th>
          <th width="100px">Cost</th>
        </tr>
        <tr>
          <td>Professional Services (Core)</td>
          <td>£{{ iQuoteCalculationsDesignPackMixin_totalPsCoreCost.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>Professional Services (Options)</td>
          <td>£{{ iQuoteCalculationsDesignPackMixin_totalPsOptionsCost.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>Maintel Expenses</td>
          <td>£{{ iQuoteCalculationsDesignPackMixin_totalPsExpensesCost.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>Third Parties</td>
          <td>£{{ iQuoteCalculationsDesignPackMixin_totalPsThirdPartyCost.toFixed(2) }}</td>
        </tr>
        <tr>
          <th>TOTAL:</th>
          <th>£{{ (iQuoteCalculationsDesignPackMixin_totalPsCoreCost + iQuoteCalculationsDesignPackMixin_totalPsOptionsCost + iQuoteCalculationsDesignPackMixin_totalPsExpensesCost + iQuoteCalculationsDesignPackMixin_totalPsThirdPartyCost).toFixed(2) }}</th>
        </tr>
      </table>
      <table
        id="PsDayAllocationTable"
        class="iquoteDesignpackTable">
        <tr>
          <th
            width="250px">Allocation Summary</th>
          <th width="80px">In-Hours Days</th>
          <th width="80px">In-Hours Half Days</th>
          <th width="80px">Mon-Sat OOH Days x 1.5</th>
          <th width="80px">Mon-Sat OOH Half Days x 1.5</th>
          <th width="80px">Sundays and Bank Holidays Days x 2</th>
          <th width="80px">Sundays and Bank Holidays Half Days x 2</th>
          <th width="80px">Total</th>
        </tr>
        <tr
          v-for="profile in activePSProfiles"
          :key="profile.id"
        >
          <td><b>{{ profile.description }}</b></td>
          <td :class="{ cellContainsValue: getInHoursDaysForProfile(profile.id) !== 0}">{{ getInHoursDaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getInHoursHalfDaysForProfile(profile.id) !== 0}">{{ getInHoursHalfDaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getOutOfHoursDaysForProfile(profile.id) !== 0}">{{ getOutOfHoursDaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getOutOfHoursHalfDaysForProfile(profile.id) !== 0}">{{ getOutOfHoursHalfDaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getOutOfHoursSundaysForProfile(profile.id) !== 0}">{{ getOutOfHoursSundaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getOutOfHoursHalfSundaysForProfile(profile.id) !== 0}">{{ getOutOfHoursHalfSundaysForProfile(profile.id) }}</td>
          <td :class="{ cellContainsValue: getTotalDaysForProfile(profile.id) !== 0}"><b>{{ getTotalDaysForProfile(profile.id) }}</b></td>
        </tr>
        <tr>
          <th><b>TOTAL:</b></th>
          <th :class="{ cellContainsValue: getInHoursDaysForProfile(null) !== 0}">{{ getInHoursDaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getInHoursHalfDaysForProfile(null) !== 0}">{{ getInHoursHalfDaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getOutOfHoursDaysForProfile(null) !== 0}">{{ getOutOfHoursDaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getOutOfHoursHalfDaysForProfile(null) !== 0}">{{ getOutOfHoursHalfDaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getOutOfHoursSundaysForProfile(null) !== 0}">{{ getOutOfHoursSundaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getOutOfHoursHalfSundaysForProfile(null) !== 0}">{{ getOutOfHoursHalfSundaysForProfile(null) }}</th>
          <th :class="{ cellContainsValue: getTotalDaysForProfile(null) !== 0}"><b>{{ getTotalDaysForProfile(null) }}</b></th>
        </tr>
      </table>
    </div>
    <div>
      <h3>Professional Services (Core)</h3>
      <div style="display: flex;">
        <PsCoreTableEditor
          @tableUpdated="onPsCoreTableUpdated"
        />
        <div style="margin-top: 1%">
          <div v-if="showCoreDeliveryManager3DaysWarning" class="banner bannerDeliveryManager3Days">Delivery Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
          <div v-if="showCoreDeliveryManager5DaysWarning" class="banner bannerDeliveryManager5Days">Any more than 5 days requires Project Management not Delivery Manager</div>
          <div v-if="showCoreProjectManager3DaysWarning" class="banner bannerProjectManager3Days">Project Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
        </div>
      </div>
    </div>

    <div>
      <h3>Professional Services (Options)</h3>
      <div style="display: flex;">
        <PsOptionsTableEditor
          @tableUpdated="onPsOptionsTableUpdated"
        />
        <div style="margin-top: 1%">
          <div v-if="showOptionsDeliveryManager3DaysWarning" class="banner bannerDeliveryManager3Days">Delivery Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
          <div v-if="showOptionsDeliveryManager5DaysWarning" class="banner bannerDeliveryManager5Days">Any more than 5 days requires Project Management not Delivery Manager</div>
          <div v-if="showOptionsProjectManager3DaysWarning" class="banner bannerProjectManager3Days">Project Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
        </div>
      </div>
    </div>
    <div>
      <h3>Expenses</h3>
      <PsExpensesTableEditor
        @tableUpdated="onPsExpensesTableUpdated"
      />
    </div>
    <div>
      <h3>Third Party (Installation)</h3>
      <PsThirdPartyInstallationTableEditor
        @tableUpdated="onPsThirdPartyInstallationTableUpdated"
      />
    </div>
    <div>
      <h3>Third Party (Other)</h3>
      <PsThirdPartyOtherTableEditor
        @tableUpdated="onPsThirdPartyOtherTableUpdated"
      />
    </div>
    <p />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import PsCoreTableEditor from '../designPackComponents/psCoreTableEditor'
import PsOptionsTableEditor from '../designPackComponents/psOptionsTableEditor'
import PsExpensesTableEditor from '../designPackComponents/psExpensesTableEditor'
import PsThirdPartyInstallationTableEditor from '../designPackComponents/psThirdPartyInstallationTableEditor'
import PsThirdPartyOtherTableEditor from '../designPackComponents/psThirdPartyOtherTableEditor'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackPSMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackPS'

export default {
  name: 'ProfessionalServices',
  components: {
    PsCoreTableEditor, PsOptionsTableEditor, PsExpensesTableEditor, PsThirdPartyInstallationTableEditor, PsThirdPartyOtherTableEditor
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackPSMixin ],
  data () {
    return {
      showCoreDeliveryManager3DaysWarning: false,
      showCoreDeliveryManager5DaysWarning: false,
      showCoreProjectManager3DaysWarning: false,      
      showOptionsDeliveryManager3DaysWarning: false,
      showOptionsDeliveryManager5DaysWarning: false,
      showOptionsProjectManager3DaysWarning: false
    }
  },
  computed: {
    psProfilesInUse () {
      // Get the list of PS profiles used for both PS Core and PS Options
      var pscoreprofiles = this.iQuoteStore_GetDesignPack.psCoreEntries.map(x => x.designPackPsRoleProfileId)
      var psoptionsprofiles = this.iQuoteStore_GetDesignPack.psOptionsEntries.map(x => x.designPackPsRoleProfileId)
      var allPsProfiles = []

      allPsProfiles = pscoreprofiles.concat(psoptionsprofiles)

      // Remove any dupes of the profiles by converting into a set than back into an array
      return Array.from(new Set(allPsProfiles))
    },
    activePSProfiles () {
      let designPackPSRoleProfiles = []

      // populate the Allocation Summary section profiles
      this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfiles.forEach(psRoleProfile => {
        const deletedRoleProfileFoundInEntries = psRoleProfile.deleted &&
        (this.iQuoteStore_GetDesignPack.psCoreEntries.some(x => x.designPackPsRoleProfileId === psRoleProfile.id) ||
        this.iQuoteStore_GetDesignPack.psOptionsEntries.some(x => x.designPackPsRoleProfileId === psRoleProfile.id))
        if ((!psRoleProfile.deleted || deletedRoleProfileFoundInEntries) && psRoleProfile.id !== this.iQuoteConstants.designPackPsRoleProfiles.NONE) {
          designPackPSRoleProfiles.push(psRoleProfile)
        }
      })
      return designPackPSRoleProfiles
    }
  },
  mounted () {
    const designPack = this.iQuoteStore_GetDesignPack

    this.validatePsCoreEntries(designPack.psCoreEntries)
    this.validatePsOptionsEntries(designPack.psOptionsEntries)
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    getInHoursDaysForProfile (designPackPsRoleProfileId) { // takes the integer part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY) && !entry.disabled)) {
              countForProfile += entry.units
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units / 2
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY) && !entry.disabled)) {
              countForProfile += entry.units
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units / 2
            }
          })
        }

        // Work out the total number of units for this profile (rounded down for positive values, and rounded up for negative)
        if (countForProfile > 0) {
          count += Math.floor(countForProfile)
        } else {
          count += Math.ceil(countForProfile)
        }
      })

      return count
    },
    getInHoursHalfDaysForProfile (designPackPsRoleProfileId) { // takes the decimal part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY) && !entry.disabled)) {
              countForProfile += entry.units * 2
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.DAY) && !entry.disabled)) {
              countForProfile += entry.units * 2
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units
            }
          })

          // Work out the total number of half units for this ps profile
          count += countForProfile % 2
        }
      })

      return count
    },
    getOutOfHoursDaysForProfile (designPackPsRoleProfileId) { // takes the integer part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if (entry.designPackPsRoleProfileId === psProfileIdToCount && !entry.disabled) {
              if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS) {
                countForProfile += entry.units / 2
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY) {
                countForProfile += entry.units
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY) {
                countForProfile += entry.units / 2
              }
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if (entry.designPackPsRoleProfileId === psProfileIdToCount && !entry.disabled) {
              if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS) {
                countForProfile += entry.units / 2
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY) {
                countForProfile += entry.units
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY) {
                countForProfile += entry.units / 2
              }
            }
          })

          // Work out the total number of units for this profile (rounded down for positive values, and rounded up for negative)
          if (countForProfile > 0) {
            count += Math.floor(countForProfile)
          } else {
            count += Math.ceil(countForProfile)
          }
        }
      })

      return count
    },
    getOutOfHoursHalfDaysForProfile (designPackPsRoleProfileId) { // takes the decimal part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if (entry.designPackPsRoleProfileId === psProfileIdToCount && !entry.disabled) {
              if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS) {
                countForProfile += entry.units
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY) {
                countForProfile += entry.units * 2
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY) {
                countForProfile += entry.units
              }
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if (entry.designPackPsRoleProfileId === psProfileIdToCount && !entry.disabled) {
              if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS) {
                countForProfile += entry.units
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY) {
                countForProfile += entry.units * 2
              } else if (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY) {
                countForProfile += entry.units
              }
            }
          })

          // Work out the total number of half units for this ps profile
          count += countForProfile % 2
        }
      })
      return count
    },
    getOutOfHoursSundaysForProfile (designPackPsRoleProfileId) { // takes the integer part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if ((entry.designPackPsRoleProfileId === psProfileIdToCount && entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL && !entry.disabled)) {
              countForProfile += entry.units
            } else if ((entry.designPackPsRoleProfileId === psProfileIdToCount && entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY && !entry.disabled)) {
              countForProfile += entry.units / 2
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if ((entry.designPackPsRoleProfileId === psProfileIdToCount && entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL && !entry.disabled)) {
              countForProfile += entry.units
            } else if ((entry.designPackPsRoleProfileId === psProfileIdToCount && entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY && !entry.disabled)) {
              countForProfile += entry.units / 2
            }
          })
        }

        // Work out the total number of units for this profile (rounded down for positive values, and rounded up for negative)
        if (countForProfile > 0) {
          count += Math.floor(countForProfile)
        } else {
          count += Math.ceil(countForProfile)
        }
      })

      return count
    },

    getOutOfHoursHalfSundaysForProfile (designPackPsRoleProfileId) { // takes the decimal part
      var count = 0

      // Go through each user profile in use
      this.psProfilesInUse.forEach(psProfileIdToCount => {
        var countForProfile = 0

        if (psProfileIdToCount === designPackPsRoleProfileId || designPackPsRoleProfileId === null) {
          this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL) && !entry.disabled)) {
              countForProfile += entry.units * 2
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units
            }
          })

          this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
            if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL) && !entry.disabled)) {
              countForProfile += entry.units * 2
            } else if (((entry.designPackPsRoleProfileId === psProfileIdToCount) && (entry.designPackPsScheduleId === this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY) && !entry.disabled)) {
              countForProfile += entry.units
            }
          })

          // Work out the total number of half units for this ps profile
          count += countForProfile % 2
        }
      })

      return count
    },
    getTotalDaysForProfile (designPackPsRoleProfileId) {
      return this.getInHoursDaysForProfile(designPackPsRoleProfileId) +
       this.getOutOfHoursDaysForProfile(designPackPsRoleProfileId) +
       this.getOutOfHoursSundaysForProfile(designPackPsRoleProfileId) +
       ((
         this.getOutOfHoursHalfDaysForProfile(designPackPsRoleProfileId) +
         this.getInHoursHalfDaysForProfile(designPackPsRoleProfileId) +
         this.getOutOfHoursHalfSundaysForProfile(designPackPsRoleProfileId)
       ) / 2)
    },
    resetPSCoreValidations () {
      this.showCoreDeliveryManager3DaysWarning = false
      this.showCoreDeliveryManager5DaysWarning = false
      this.showCoreProjectManager3DaysWarning = false
    },
    resetPSOptionsValidations () {
      this.showOptionsDeliveryManager3DaysWarning = false
      this.showOptionsDeliveryManager5DaysWarning = false
      this.showOptionsProjectManager3DaysWarning = false
    },
    onPsCoreTableUpdated (newEntries) {
      this.resetPSCoreValidations()      
      this.validatePsCoreEntries(newEntries)

      this.iQuoteCalculationsDesignPackPSMixin_updatePsCoreEntries(newEntries)
    },
    onPsOptionsTableUpdated (newEntries) {
      this.resetPSOptionsValidations()
      this.validatePsOptionsEntries(newEntries)

      this.iQuoteCalculationsDesignPackPSMixin_updatePsOptionsEntries(newEntries)
    },
    onPsExpensesTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackPSMixin_updatePsExpenseEntries(newEntries)
    },
    onPsThirdPartyInstallationTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyInstallationEntries(newEntries)
    },
    onPsThirdPartyOtherTableUpdated (newEntries) {
      this.iQuoteCalculationsDesignPackPSMixin_updatePsThirdPartyOtherEntries(newEntries)
    },
    validatePsCoreEntries(entries) {
      const coreValidationEntries = this.iQuoteCalculationsDesignPackPSMixin_validatePSEntries(entries)
      this.showCoreDeliveryManager3DaysWarning = !coreValidationEntries.validDeliveryManager3Days
      this.showCoreDeliveryManager5DaysWarning = !coreValidationEntries.validDeliveryManager5Days
      this.showCoreProjectManager3DaysWarning = !coreValidationEntries.validProjectManager3Days
    },
    validatePsOptionsEntries(entries) {
      const optionsValidationEntries = this.iQuoteCalculationsDesignPackPSMixin_validatePSEntries(entries)
      this.showOptionsDeliveryManager3DaysWarning = !optionsValidationEntries.validDeliveryManager3Days
      this.showOptionsDeliveryManager5DaysWarning = !optionsValidationEntries.validDeliveryManager5Days
      this.showOptionsProjectManager3DaysWarning = !optionsValidationEntries.validProjectManager3Days
    }
  }
}
</script>
<style scoped>
  #PsCostSummaryTable {
    width: auto;
    display: inline-block;
    vertical-align: top;
  }

  #PsDayAllocationTable {
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
  }

  #psCoreTable {
    width: 350px;
    display: inline-block;
    vertical-align: top;
  }

  .psSummarySection {
    margin-bottom: 50px;
    box-shadow: dimgray;
  }

  td.cellContainsValue {
    font-weight: bold;
  }

  .banner {
    margin: 12px 0 0 20px;
    padding: 8px;  
    color: #F5F5F5;
    box-shadow: 3px 3px 5px 1px #585858;
    border-radius: 10px;    
    min-width: 650px;
    height: 35px;
    font-size: 0.8em;
  }

  .bannerDeliveryManager3Days {
    background: #005e95;
    
  }   

  .bannerDeliveryManager5Days {
    background: #890095;
  }   

  .bannerProjectManager3Days {
    background: #2c095a;
  }

</style>
