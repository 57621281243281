import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { iQuoteCalculationsDesignPackPSMixin } from './iQuoteCalculationsDesignPackPS'
import { iQuoteCalculationsDesignPackHardwareMixin } from './iQuoteCalculationsDesignPackHardware'
import { iQuoteCalculationsDesignPackMixin } from './iQuoteCalculationsDesignPack'
import capitalize from 'lodash/capitalize'

export const iQuoteCalculationsDesignPackExportMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    iQuoteCalculationsDesignPackPSMixin,
    iQuoteCalculationsDesignPackMixin,
    iQuoteCalculationsDesignPackHardwareMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackExportMixin: {
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackExportMixin_selectedDesignPack () {
      return this.iQuoteStore_GetDesignPack
    },
    subscriptionType () {
      if (this.iQuoteStore_GetQuote.quoteLicenseTypeId === undefined) {
        return ''
      }

      return Object.keys(this.iQuoteConstants.quoteLicenceTypes)[this.iQuoteStore_GetQuote.quoteLicenseTypeId - 1]
    }
  },
  mounted () {
  },
  methods: {
    iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport () {
      var designPack = this.iQuoteCalculationsDesignPackExportMixin_selectedDesignPack
      var designPackForExport = {
        salesforceOpportunityId: designPack.salesforceOpportunityId,
        termLengthMonths: designPack.termLengthMonths,
        ciscoVIPRebate: designPack.ciscoVIPRebate,
        createdBy: designPack.createdBy,
        createDate: designPack.createDate,
        hldReference: designPack.hldReference,
        description: designPack.description,
        name: designPack.name,
        projectName: designPack.projectName,
        numberOfSites: designPack.numberOfSites,
        pmoReview: designPack.pmoReview,
        pmoReviewer: designPack.pmoReviewer,
        maintenanceTakeOn: designPack.maintenanceTakeOn ? 'Yes' : '',
        professionalServicesReview: designPack.professionalServicesReview ? 'Yes' : '',
        newOrExistingCustomer: designPack.newOrExistingCustomer,
        upgradeMigration: designPack.upgradeMigration ? 'Yes' : '',
        ucUsers: designPack.ucUsers,
        ccUsers: designPack.ccUsers,
        ucUsersOverride: designPack.ucUsersOverride,
        ccUsersOverride: designPack.ccUsersOverride,
        manufacturerCode: designPack.manufacturerCode,
        newManufacturerCodeRequired: designPack.newManufacturerCodeRequired ? 'Yes' : '',
        contractIdCoterminatedWith: designPack.contractIdCoterminatedWith,
        contractFramework: this.iQuoteCalculationsDesignPackMixin.contractFrameworks.find(x => x.id === designPack.contractFrameworkId).name,
        quoteDesignPackId: designPack.quoteDesignPackId,
        quoteDesignPackRevisionId: designPack.quoteDesignPackRevisionId,
        revisionNumber: designPack.revisionNumber,
        quoteId: designPack.quoteId,
        margins: designPack.margins,
        discounts: designPack.discounts,
        designPackContractDetailedSummaryTable: this.iQuoteCalculationsDesignPackExportMixin_getDesignPackContractDetailedSummaryTable(),
        designPackBreakdownDetailedSummaryTable: this.iQuoteCalculationsDesignPackExportMixin_getDesignPackBreakdownDetailedSummaryTable(),
        designPackBusinessCoeDetailedSummaryTable: this.iQuoteCalculationsDesignPackExportMixin_getDesignPackBusinessCoeDetailedSummaryTable(),
        designPackSalesforceViewSummaryTable: this.iQuoteCalculationsDesignPackExportMixin_getDesignPackSalesforceViewSummaryTable(),
        designPackContractSummaryTable: this.iQuoteCalculationsDesignPackExportMixin_getDesignPackContractSummaryTable(),
        psCoreEntries: [], /* Added in code further down */
        psOptionsEntries: [], /* Added in code further down */
        psExpenseEntries: [], /* Added in code further down */
        psThirdPartyInstallationEntries: [], /* Added in code further down */
        psThirdPartyOtherEntries: [], /* Added in code further down */
        hardwareMainEntries: [], /* Added in code further down */
        hardwareMiscEntries: [], /* Added in code further down */
        circuitsEquinixCloudConnectEntries: designPack.circuitsEquinixCloudConnectEntries.filter(x => x.disabled === false),
        circuitsHostingAndCablingEntries: designPack.circuitsHostingAndCablingEntries.filter(x => x.disabled === false),
        circuitsWanConnectivityEntries: designPack.circuitsWanConnectivityEntries.filter(x => x.disabled === false),
        circuitsBroadbandEntries: designPack.circuitsBroadbandEntries.filter(x => x.disabled === false),
        circuitsFixedLineVoiceEntries: designPack.circuitsFixedLineVoiceEntries.filter(x => x.disabled === false),
        standardCaveats: designPack.standardCaveats,
        ceasesEntries: designPack.ceasesEntries,
        subscriptionType: capitalize(this.subscriptionType),
        serviceLevel: designPack.serviceLevelTypeId !== 0 ? capitalize(Object.keys(this.iQuoteConstants.quoteServiceLevelTypes)[designPack.serviceLevelTypeId - 1]) : '',
        useDetailDataForSummaries: designPack.useDetailDataForSummaries
      }

      designPack.psCoreEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.psCoreEntries.push(
          {
            task: entry.task,
            designPackPsRoleProfile: this.iQuoteCalculationsDesignPackPSMixin_getPsProfileDescription(entry.designPackPsRoleProfileId),
            designPackPsSchedule: this.iQuoteCalculationsDesignPackPSMixin_getPsScheduleDescription(entry.designPackPsScheduleId),
            units: entry.units,
            unitCost: this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId),
            cost: entry.units * this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId)
          }
        )
      })

      designPack.psOptionsEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.psOptionsEntries.push(
          {
            task: entry.task,
            designPackPsRoleProfile: this.iQuoteCalculationsDesignPackPSMixin_getPsProfileDescription(entry.designPackPsRoleProfileId),
            designPackPsSchedule: this.iQuoteCalculationsDesignPackPSMixin_getPsScheduleDescription(entry.designPackPsScheduleId),
            units: entry.units,
            unitCost: this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId),
            cost: entry.units * this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId)
          }
        )
      })

      designPack.psExpenseEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.psExpenseEntries.push(
          {
            item: entry.item,
            designPackPsRoleProfile: this.iQuoteCalculationsDesignPackPSMixin_getPsProfileDescription(entry.designPackPsRoleProfileId),
            units: entry.units,
            unitCost: entry.unitCost,
            cost: entry.units * entry.unitCost
          }
        )
      })

      designPack.psThirdPartyInstallationEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.psThirdPartyInstallationEntries.push(
          {
            item: entry.item,
            provider: entry.provider,
            units: entry.units,
            unitCost: entry.unitCost,
            cost: entry.units * entry.unitCost
          }
        )
      })

      designPack.psThirdPartyOtherEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.psThirdPartyOtherEntries.push(
          {
            item: entry.item,
            provider: entry.provider,
            units: entry.units,
            unitCost: entry.unitCost,
            cost: entry.units * entry.unitCost
          }
        )
      })

      designPack.hardwareMainEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.hardwareMainEntries.push(
          {
            siteNo: entry.siteNo,
            location: entry.location,
            postcode: entry.postcode,
            description: entry.description,
            partNumber: entry.partNumber,
            vendor: this.iQuoteCalculationsDesignPackExportMixin_getHardwareVendorDescription(entry),
            quantity: entry.quantity,
            unitCost: entry.unitCost,
            currency: this.iQuoteCalculationsDesignPackExportMixin_getHardwareCurrencyDescription(entry),
            marginPercent: entry.marginPercent,
            unitCostGBP: this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(entry),
            totalCostGBP: this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP(entry),
            sellPrice: this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(entry)
          }
        )
      })

      designPack.hardwareMiscEntries.filter(x => x.disabled === false).forEach(entry => {
        designPackForExport.hardwareMiscEntries.push(
          {
            type: this.iQuoteCalculationsDesignPackExportMixin_getHardwareTypeDescription(entry),
            subType: this.iQuoteCalculationsDesignPackExportMixin_getHardwareSubTypeDescription(entry),
            code: this.iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryCode(entry),
            quantity: entry.quantity,
            unitCost: this.iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryUnitCost(entry),
            totalCost: this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalCost(entry),
            marginPercent: entry.marginPercent,
            sellPrice: this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalPrice(entry)
          }
        )
      })

      designPackForExport.margins.forEach(entry => {
        entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
      })

      designPackForExport.discounts.forEach(entry => {
        entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
      })

      return designPackForExport
    },
    iQuoteCalculationsDesignPackExportMixin_getHardwareTypeDescription (entry) {
      var foundEntry = this.iQuoteStore_GetHardwareTableLookups.miscEquipmentTypes.find(x => x.id === entry.designPackHardwareMiscEquipmentTypeId)

      if (foundEntry !== undefined) {
        return foundEntry.description
      } else {
        return 'None'
      }
    },
    iQuoteCalculationsDesignPackExportMixin_getHardwareSubTypeDescription (entry) {
      var foundEntry = this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.find(x => x.id === entry.designPackHardwareMiscEquipmentSubTypeId)

      if (foundEntry !== undefined) {
        return foundEntry.description
      } else {
        return 'None'
      }
    },
    iQuoteCalculationsDesignPackExportMixin_getHardwareVendorDescription (entry) {
      var foundEntry = this.iQuoteStore_GetHardwareTableLookups.vendors.find(x => x.id === entry.designPackHardwareVendorId)

      if (foundEntry !== undefined) {
        return foundEntry.description
      } else {
        return 'None'
      }
    },
    iQuoteCalculationsDesignPackExportMixin_getHardwareCurrencyDescription (entry) {
      var foundEntry = this.iQuoteCalculationsDesignPackMixin.hardwareCurrencies.find(x => x.id === entry.designPackHardwareCurrencyId)

      if (foundEntry !== undefined) {
        return foundEntry.description
      } else {
        return 'None'
      }
    },
    iQuoteCalculationsDesignPackExportMixin_getDesignPackBreakdownDetailedSummaryTable () {
      var rows = []
      var summaryEntries = []

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntrySources.forEach(designPackEntrySource => {
        summaryEntries = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.source === designPackEntrySource && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(x))

        if (summaryEntries.length > 0) {
          rows.push({
            type: 'heading',
            headingText: designPackEntrySource
          })

          summaryEntries.forEach(designPackEntry => {
            rows.push({
              type: 'entry',
              description: designPackEntry.description,
              SF_COE: designPackEntry.SF_COE,
              upfrontSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontSellPrice),
              upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontExternalCost),
              upfrontRlecValue: this.iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost),
              upfrontRlecPercent: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost),
              upfrontInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontInternalCost),
              upfrontGmValue: this.iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost),
              upfrontGmPercent: this.iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost),
              recurringSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringSellPrice),
              recurringExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringExternalCost),
              recurringRlecValue: this.iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost),
              recurringRlecPercent: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost),
              recurringInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringInternalCost),
              recurringGmValue: this.iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost),
              recurringGmPercent: this.iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost)
            })
          })
        }
      })

      rows.push({
        type: 'total',
        upfrontSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice),
        upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        upfrontRlecValue: this.iQuoteCalculationsDesignPackMixin_totalUpfrontRlecValue,
        upfrontRlecPercent: this.iQuoteCalculationsDesignPackMixin_totalUpfrontRlecPercent,
        upfrontInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost),
        upfrontGmValue: this.iQuoteCalculationsDesignPackMixin_totalUpfrontGmValue,
        upfrontGmPercent: this.iQuoteCalculationsDesignPackMixin_totalUpfrontGmPercent,
        recurringSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice),
        recurringExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        recurringRlecValue: this.iQuoteCalculationsDesignPackMixin_totalRecurringRlecValue,
        recurringRlecPercent: this.iQuoteCalculationsDesignPackMixin_totalRecurringRlecPercent,
        recurringInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost),
        recurringGmValue: this.iQuoteCalculationsDesignPackMixin_totalRecurringGmValue,
        recurringGmPercent: this.iQuoteCalculationsDesignPackMixin_totalRecurringGmPercent
      })

      return rows
    },
    iQuoteCalculationsDesignPackExportMixin_getDesignPackBusinessCoeDetailedSummaryTable () {
      var rows = []

      this.iQuoteCalculationsDesignPackMixin_sfAndBusinessValues.forEach(designPackEntry => {
        rows.push({
          type: 'entry',
          sfCoe: designPackEntry.sfCoe,
          businessCoe: designPackEntry.businessCoe,
          upfrontSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontSellPrice),
          upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontExternalCost),
          upfrontRlecValue: this.iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost),
          upfrontRlecPercent: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontExternalCost),
          upfrontInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontInternalCost),
          upfrontGmValue: this.iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost),
          upfrontGmPercent: this.iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.upfrontSellPrice, designPackEntry.upfrontInternalCost + designPackEntry.upfrontExternalCost),
          recurringSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringSellPrice),
          recurringExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringExternalCost),
          recurringRlecValue: this.iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost),
          recurringRlecPercent: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringExternalCost),
          recurringInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.recurringInternalCost),
          recurringGmValue: this.iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost),
          recurringGmPercent: this.iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.recurringSellPrice, designPackEntry.recurringInternalCost + designPackEntry.recurringExternalCost)
        })
      })

      rows.push({
        type: 'total',
        upfrontSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice),
        upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        upfrontRlecValue: this.iQuoteCalculationsDesignPackMixin_totalUpfrontRlecValue,
        upfrontRlecPercent: this.iQuoteCalculationsDesignPackMixin_totalUpfrontRlecPercent,
        upfrontInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost),
        upfrontGmValue: this.iQuoteCalculationsDesignPackMixin_totalUpfrontGmValue,
        upfrontGmPercent: this.iQuoteCalculationsDesignPackMixin_totalUpfrontGmPercent,
        recurringSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice),
        recurringExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        recurringRlecValue: this.iQuoteCalculationsDesignPackMixin_totalRecurringRlecValue,
        recurringRlecPercent: this.iQuoteCalculationsDesignPackMixin_totalRecurringRlecPercent,
        recurringInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView),
        recurringGmValue: this.iQuoteCalculationsDesignPackMixin_totalRecurringGmValueSalesforceView,
        recurringGmPercent: this.iQuoteCalculationsDesignPackMixin_totalRecurringGmPercentSalesforceView
      })

      return rows
    },
    iQuoteCalculationsDesignPackExportMixin_getDesignPackContractDetailedSummaryTable () {
      var rows = []
      var summaryEntries = []

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntrySources.forEach(designPackEntrySource => {
        summaryEntries = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.source === designPackEntrySource && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(x))

        if (summaryEntries.length > 0) {
          rows.push({
            type: 'heading',
            headingText: designPackEntrySource
          })

          summaryEntries.forEach(designPackEntry => {
            rows.push({
              type: 'entry',
              description: designPackEntry.description,
              SF_COE: designPackEntry.SF_COE,
              totalSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontSellPrice + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringSellPrice)),
              totalExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontExternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringExternalCost)),
              totalRlecValue: this.iQuoteCalculationsDesignPackMixin_getRlecValue(designPackEntry.upfrontSellPrice + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringSellPrice), designPackEntry.upfrontExternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringExternalCost)),
              totalRlecPercent: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(designPackEntry.upfrontSellPrice + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringSellPrice), designPackEntry.upfrontExternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringExternalCost)),
              totalInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(designPackEntry.upfrontInternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringInternalCost)),
              totalGmValue: this.iQuoteCalculationsDesignPackMixin_getGmValue(designPackEntry.upfrontSellPrice + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringSellPrice), designPackEntry.upfrontExternalCost + designPackEntry.upfrontInternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringExternalCost) + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringInternalCost)),
              totalGmPercent: this.iQuoteCalculationsDesignPackMixin_getGmPercent(designPackEntry.upfrontSellPrice + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringSellPrice), designPackEntry.upfrontExternalCost + designPackEntry.upfrontInternalCost + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringExternalCost) + ((this.iQuoteStore_GetDesignPack.termLengthMonths / 12) * designPackEntry.recurringInternalCost))
            })
          })
        }
      })

      rows.push({
        type: 'total',
        totalSellPrice: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice),
        totalExternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost),
        totalRlecValue: this.iQuoteCalculationsDesignPackMixin_totalContractRlecValue,
        totalRlecPercent: this.iQuoteCalculationsDesignPackMixin_totalContractRlecPercent,
        totalInternalCost: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost),
        totalGmValue: this.iQuoteCalculationsDesignPackMixin_totalContractGmValue,
        totalGmPercent: this.iQuoteCalculationsDesignPackMixin_totalContractGmPercent
      })

      return rows
    },
    iQuoteCalculationsDesignPackExportMixin_getDesignPackSalesforceViewSummaryTable () {
      return []
    },
    iQuoteCalculationsDesignPackExportMixin_getDesignPackContractSummaryTable () {
      var rows = []

      rows.push({
        description: 'Revenue',
        install: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice),
        annual: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice),
        year1: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice + this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice),
        contract: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice)
      })
      rows.push({
        description: 'Third Party Costs',
        install: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
      })
      rows.push({
        description: 'RLEC £',
        install: this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice + this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
      })
      rows.push({
        description: 'RLEC %',
        install: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice + this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
      })
      rows.push({
        description: 'Internal Costs',
        install: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getCostDisplay(this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost)
      })
      rows.push({
        description: 'Gross Margin',
        install: this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice + this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost + this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
      })
      rows.push({
        description: 'GM%',
        install: this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        annual: this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost),
        year1: this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice + this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost),
        contract: this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost + this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
      })

      return rows
    }

  }
}
