<template>
  <v-footer
    id="core-footer"
    absolute
  >
    <div class="footer-items">
      <span class="font-weight-light">
        <span>Version: {{ ver }}</span>
        <span style="margin-left: -30%;" v-if="getEnvironmentName !== ''">Environment: {{ getEnvironmentName }}</span>
      </span>
      <span class="font-weight-light copyright">
        &copy;
        {{ (new Date()).getFullYear() }}
        Maintel Software
      </span>
    </div>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
import { environmentMixin } from '../../mixins/environmentMixin'

export default {
  mixins: [
    environmentMixin
  ],
  data: () => ({
  }),
  computed: {
    ...mapState(['version']),
    ver () {
      return this.$store.getters.version
    }
  }
}
</script>

<style>
#core-footer {
  z-index: 0;
  background: #eee
}
.footer-items {
  width:100%;
}
.footer-items span {
  width: calc(50% - 2px);
  display: inline-block;
}
</style>
