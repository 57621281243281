import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { mapActions } from 'vuex'

export const iQuoteCalculationsMitelUCE2Mixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
      quoteTechnologyMitelUCE2: {},
      quote: {},
      licenses: {}
    }
  },
  computed: {
    mitelUCE2Calcs_MiCollabUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ENTRY_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.PREMIER_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    mitelUCE2Calcs_CCUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    mitelUCE2Calcs_UCUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.TELEPHONY ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ENTRY_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.PREMIER_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    mitelUCE2Calcs_IVRUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return 0
      }

      var users = 0

      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
        if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.IVR_PORT) {
          users += userProfile.minCommitment
        }
      })
      return users
    },
    mitelUCE2Calcs_RecordingUsersToHandle () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return 0
      }

      var users = 0

      var recordingUserProfiles = this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.filter(
        x => x.userProfileCategoryId === this.iQuoteConstants.MitelUCE2UserProfileCategories.CALL_RECORDING
      )

      recordingUserProfiles.forEach(userProfile => {
        users += userProfile.minCommitment
      })
      return users
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelUCE2'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcMitelUCE2_calculate: function () {
      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return
      }

      this.calcMitelUCE2UserLicenses()
      this.calcMitelUCE2VMs()
      this.calcMitelUCE2_VMs_MCD()
      this.calcMitelUCRecording_VMs()
      this.calcMitelUCE2UnitCosts()
    },
    calcMitelUCRecording_VMs: function () {
      if (this.mitelUCE2Calcs_RecordingUsersToHandle > 0) {
        const hoursPerGb = 18.2
        const minutesPerGb = hoursPerGb * 60
        const daysPerYear = 260
        const recordingSpacePerMinute = 1 / minutesPerGb

        var storage = this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.maxConcurrentCalls *
                      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.numCallsPerAgentPerDay *
                      this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.avgCallDuration *
                      recordingSpacePerMinute *
                      ((this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.retentionPeriod / 12) * daysPerYear)

        if (storage > 2000) {
          storage = storage / 5
        }

        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.virtualMachineId, this.iQuoteConstants.dataCentres.fareham, true, 1, 0, storage)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.virtualMachineId, this.iQuoteConstants.dataCentres.goswellRoad, true, 1, 0, storage, false, true)

        if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.maxConcurrentCalls <= 200) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_SML, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else {
          var quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.concurrentCallsToRemoteWorkers / 400)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_MED, this.iQuoteConstants.dataCentres.fareham, true, quantity)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_SRC_MED, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
        }
      }
    },
    calcMitelUCE2UnitCosts: function () {
      var iconSecCertCostPerYear = 0
      var servers = this.iQuoteStore_GetQuoteVMsRequired.filter(x => (x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2 ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_SML ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_MED ||
                                                                         x.virtualMachineId === this.iQuoteConstants.virtualMachines.VM_MICONTACT_LRG) &&
                                                                         x.dataCentreId === this.iQuoteConstants.dataCentres.fareham
      )

      var serverCount = 0

      servers.forEach(server => {
        serverCount += server.quantity
      })

      if (serverCount > 0) {
        if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.publicNameSpaceOptionId === this.iQuoteConstants.PublicNameSpaceOptions.MAINTEL_PROVIDES) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_UPFRONT, 1, null, this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_UPFRONT_COST)

          if (serverCount > 3) {
            iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_MAX
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
          } else {
            iconSecCertCostPerYear = this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_BASE_COST + (this.iQuoteConstants.pricing.ICON_SEC_CERTIFICATE_COST_PER_SERVER * serverCount)
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING, 1, iconSecCertCostPerYear, iconSecCertCostPerYear)
          }
        }
      }
    },
    calcMitelUCE2VMs: function () {
      this.quoteTechnologyMitelUCE2 = this.$store.getters.quoteTechnologyMitelUCE2
      var quantity = 0

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id === undefined) {
        return
      }

      if (this.mitelUCE2Calcs_UCUsersToHandle > 0) {
        // These VMs are required for every UC MitelUCE2 Deployment
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_FOREST_DC, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_FOREST_DC, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)

        if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.concurrentCallsToRemoteWorkers > 0) {
          if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.concurrentCallsToRemoteWorkers <= 200) {
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_SML, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
          } else {
            quantity = Math.ceil(this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.concurrentCallsToRemoteWorkers / 500)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG, this.iQuoteConstants.dataCentres.fareham, true, quantity)
            this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_DMZ_MBG_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
          }
        }

        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.MITEL_VM_UVR, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.MITEL_VM_UVR, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      if (this.mitelUCE2Calcs_MiCollabUsersToHandle > 0) {
        if (this.mitelUCE2Calcs_MiCollabUsersToHandle <= 250) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_SML, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else if (this.mitelUCE2Calcs_MiCollabUsersToHandle <= 1500) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_MED, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else if (this.mitelUCE2Calcs_MiCollabUsersToHandle <= 2500) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        } else {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICOLLAB_LRG2, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.options.oigServersAreRequired) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MVB_OIG, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MVB_OIG, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)
      }

      var ivrPorts = this.mitelUCE2Calcs_IVRUsersToHandle

      if (ivrPorts >= 40) {
        quantity = Math.ceil(ivrPorts / 120)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.fareham, true, quantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MICONTACT_IVR, this.iQuoteConstants.dataCentres.goswellRoad, true, quantity)
      }
    },
    calcMitelUCE2_VMs_MCD: function () {
      var trunkGateways = 0
      var pathGateways = 0
      var ucUsersToAllocate = this.mitelUCE2Calcs_UCUsersToHandle
      var ccUsersToAllocate = this.mitelUCE2Calcs_CCUsersToHandle

      if (this.mitelUCE2Calcs_UCUsersToHandle === 0) {
        return
      }

      if (this.mitelUCE2Calcs_UCUsersToHandle <= 250 && ((this.numPrimarySipChannels <= 60 && ccUsersToAllocate === 0) || (this.numPrimarySipChannels <= 45 && this.mitelUCE2Calcs_CCUsersToHandle <= 30))) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_SML, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_SML, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.mitelUCE2Calcs_UCUsersToHandle <= 1500 && ((this.numPrimarySipChannels <= 300 && ccUsersToAllocate === 0) || (this.numPrimarySipChannels <= 150 && this.mitelUCE2Calcs_CCUsersToHandle <= 100))) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_MED, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_MED, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)
      } else if (this.mitelUCE2Calcs_UCUsersToHandle <= 10000) {
        if (ccUsersToAllocate > 175 || this.numPrimarySipChannels > 250) {
          pathGateways = Math.ceil(this.numPrimarySipChannels / 350)
        }

        while (ucUsersToAllocate > 0 || ccUsersToAllocate > 0) {
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.fareham, true, 1)
          this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, 1)

          ucUsersToAllocate -= 2500

          if (pathGateways === 0) {
            ccUsersToAllocate -= 175
          } else {
            ccUsersToAllocate -= 1200
          }
        }

        if ((ccUsersToAllocate && this.numPrimarySipChannels > 500) || (ccUsersToAllocate > 0 && this.numPrimarySipChannels > 250)) {
          trunkGateways++
        }

        if (pathGateways === 1 && trunkGateways === 1) {
          trunkGateways = 0
        }

        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.fareham, true, trunkGateways + pathGateways)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.VM_MCD_LRG, this.iQuoteConstants.dataCentres.goswellRoad, true, trunkGateways + pathGateways)
      } else {
        // TODO: put a warning here if exceeding capacity
      }
    },
    calcMitelUCE2UserLicenses: function () {
      this.licenses = this.$store.getters.licenses
      var requiredLicenseId = 0
      var quoteTechnologyMitelUCE2 = this.iQuoteStore_GetQuoteTechnologyMitelUCE2

      console.log('foo', 'calcMitelUCE2UserLicenses')

      if (quoteTechnologyMitelUCE2.id === undefined) {
        return
      }

      quoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
        userProfile.annualPrice = 0
        userProfile.rate = 0
        userProfile.annualTotalCost = 0
        requiredLicenseId = this.getLicenceForuserProfile(userProfile)

        if (requiredLicenseId !== 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(requiredLicenseId, userProfile.minCommitment)
          userProfile.annualTotalCost += userProfile.minCommitment * this.utilFunctions_GetLicenseCost(requiredLicenseId) * 12
          userProfile.annualPrice += userProfile.minCommitment * this.utilFunctions_GetLicensePrice(requiredLicenseId) * 12
          userProfile.rate += this.utilFunctions_GetLicensePrice(requiredLicenseId)
        }
      })

      if (quoteTechnologyMitelUCE2.options.webConferencingIsRequired && this.iQuoteStore_GetQuote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_AWC, quoteTechnologyMitelUCE2.options.additionalWebConferencingPorts)
      }

      if (quoteTechnologyMitelUCE2.options.additionalVoicemailBoxes > 0 && this.iQuoteStore_GetQuote.quoteLicenseTypeId !== this.iQuoteConstants.quoteLicenceTypes.PERPETUAL) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.MITEL_MAILBOX, quoteTechnologyMitelUCE2.options.additionalVoicemailBoxes)
      }

      this.setQuoteTechnologyMitelUCE2(quoteTechnologyMitelUCE2)
    },
    getLicenceForuserProfile (userProfile) {
      var OneYearLicense = null
      var licenseChosen = 0

      switch (userProfile.technologyUserProfileId) {
        case this.iQuoteConstants.mitelUCE2userProfiles.TELEPHONY:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_TELEPHONY_USER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.ENTRY_UC:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_ENTRY_USER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.PREMIER_UC:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_PREMIER_USER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_ELITE_CX_USER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.HOSPITALITY_GUESTROOM:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_HOSP_GUESTROOM_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.HOSPITALITY_STAFF_MEMBER:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_UC_HOSP_STAFFMEMBER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.BUSINESS_REPORTER_CLIENT:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_BUSINESS_REPORTER_CLIENT_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.ADDITIONAL_SYSTEM_ADMIN:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_SYSTEM_ADMIN_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.SOFTPHONE_CLIENT:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_SOFTPHONE_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.MULTIMEDIA_AGENT:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_MULTIMEDIA_AGENT_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.SCHEDULING_AGENT:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_SCHEDULING_AGENT_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.IVR_PORT:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_IVR_PORT_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.REMOTE_REDUNDANT_IVR_ROUTING_ADD_ON:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_CC_REMOTE_REDUN_IVR_STARTER_PACK_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.ESSENTIALS:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_RECORDING_ESSENTIALS_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.PREMIER:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_RECORDING_PREMIER_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.ELITE:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_RECORDING_ELITE_12M
          break
        case this.iQuoteConstants.mitelUCE2userProfiles.PARALLEL:
          OneYearLicense = this.iQuoteConstants.licenses.MITELUC_E2_RECORDING_PARALLEL_12M
          break
      }

      if (OneYearLicense !== null) {
        // Note that the above logic assumes that the ids in the database are always in the sequence
        // 12 month, 36 month then 60 month.
        var threeYearLicense = OneYearLicense + 1
        var fiveYearLicense = OneYearLicense + 2

        switch (this.iQuoteStore_GetQuote.termLengthMonths) {
          case 12:
            licenseChosen = OneYearLicense
            break
          case 36:
            licenseChosen = threeYearLicense
            break
          case 60:
            licenseChosen = fiveYearLicense
            break
        }
      }

      return licenseChosen
    }
  }
}
