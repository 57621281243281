<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select the number of user profiles required:</p>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
          <th>Maximum<br>
            <TooltipIcon text="The maximum number of agents configures the size of the ICON infrastructure and maximum size the customer may grow to.  This value requires to be 20% above the Minimum Commit Users. Adjust to required level" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyAvayaAACC.userProfiles"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            style="width:150px; text-align:left"
          >{{ userProfile.description }}
          </td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
            >
          </td>
          <td>
            <input
              v-model.number="userProfile.maxAllowed"
              type="number"
              min="0"
              max="1000000"
            >
          </td>
        </tr>
        <tr
          v-if="totalUserMaxAllowedCount > 1000"
          style="width:620px; text-align:left">
          <td
            colspan="3"
            class="InputError">Above 1000 AACC agents, pre-sales support is mandatory (Voice and Multimedia channels combined)</td>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr>
          <td
            v-if="quoteTechnologyAvayaAACC.options !== undefined"
            colspan="3"
            style="text-align:left">
            <input
              id="1"
              v-model.number="quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId"
              type="radio"
              name="1"
              value="1">Avaya Aura Agent Desktop
            <label
              v-if="quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === 1"
              class="InputError"
              style="width:620px; text-align:left">Workspaces is the preferred desktop option for customers.&nbsp;&nbsp;AAAD is for legacy installs only
            </label>
          </td>
        </tr>
        <tr>
          <td
            v-if="quoteTechnologyAvayaAACC.options !== undefined"
            colspan="3"
            style="text-align:left">
            <input
              id="2"
              v-model.number="quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId"
              type="radio"
              name="2"
              value="2">Avaya Workspaces
          </td>
        </tr>
      </table>
      <br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('IconContact')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AdjunctsAACC')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfileAACC',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      quote: {},
      quoteTechnologyAvayaAACC: {
        options: {
          avayaAACCDesktopOptionId: 0
        },
        userProfiles: []
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconContact () {
      return require(`../../../assets/icons/MaintelCXPrivate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    totalUserLicenseCount () {
      var totalUserCount = 0

      if (this.quoteTechnologyAvayaAACC.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyAvayaAACC.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyAvayaAACC.userProfiles[index]
          if (this.isValidUserProfile(userProfile)) {
            totalUserCount += userProfile.minCommitment
          }
        }
      }
      return totalUserCount
    },
    totalUserMaxAllowedCount () {
      var totalUserCount = 0

      if (this.quoteTechnologyAvayaAACC.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyAvayaAACC.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyAvayaAACC.userProfiles[index]
          if (this.isValidUserProfile(userProfile)) {
            totalUserCount += userProfile.maxAllowed
          }
        }
      }
      return totalUserCount
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC

    if (this.quoteTechnologyAvayaAACC.id === undefined) {
      var userProfiles
      var adjuncts
      var options

      this.quoteTechnologyAvayaAACC.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAACC)

      if (this.quoteTechnologyAvayaAACC.id === 0) {
        this.quoteTechnologyAvayaAACC.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAACC)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.avayaAACC, this.quoteTechnologyAvayaAACC.id)
        adjuncts = {
          id: 0,
          quoteId: this.quote.id,
          callRecording: false,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          compliance: false,
          compliancePciDss: false,
          wfo: false,
          wfoQuantity: 0,
          wfm: false,
          wfmQuantity: 0
        }
        options = {
          id: 0,
          quoteId: this.quote.id,
          avayaAACCDesktopOptionId: 0
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAACC.id)
        adjuncts = await this.getQuoteAvayaAACCAdjuncts(this.quote.id)
        options = await this.getQuoteOptionsAvayaAACC(this.quote.id)
      }

      // Using $set, as directly assigning to the arrays was not updating the DOM
      this.$set(this.quoteTechnologyAvayaAACC, 'userProfiles', userProfiles)
      this.$set(this.quoteTechnologyAvayaAACC, 'adjuncts', adjuncts)
      this.$set(this.quoteTechnologyAvayaAACC, 'options', options)

      // this.quoteTechnologyAvayaAACC.options = options
      this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAACC'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.quoteTechnologyAvayaAACC.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyAvayaAACC.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        }
      }

      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (this.totalUserLicenseCount === 0 && page === 'AdjunctsAACC') {
        this.iQuoteFunctions_ShowError('Please enter how many users')
        return
      } else if (this.totalUserMaxAllowedCount > 1000) {
        // Can't go next if invalid inputs supplied (but can go back)
        if (page === 'IconContact' && this.totalUserLicenseCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter less than 1000 users in max allowed, or enter 0 (this will remove AACC from the quote)')
        } else if (page === 'AdjunctsAACC') {
          this.iQuoteFunctions_ShowError('AACC does not support this number of agents. Max allowed total must not exceed 1000')
        }
        return
      } else if (this.quoteTechnologyAvayaAACC.options.avayaAACCDesktopOptionId === 0) {
        this.iQuoteFunctions_ShowError('Please choose a desktop option')
        return
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied (even if pressing back rather than next)
        this.quoteTechnologyAvayaAACC.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAACC.userProfiles)
        this.quoteTechnologyAvayaAACC.options = await this.saveQuoteOptionsAvayaAACC(this.quoteTechnologyAvayaAACC.options)
        this.quoteTechnologyAvayaAACC.adjuncts = await this.saveQuoteAACCAdjuncts(this.quoteTechnologyAvayaAACC.adjuncts)
      }

      if (this.totalUserLicenseCount > 0) {
        // update local store
        this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
      } else {
        this.deleteQuoteTechnology(this.quoteTechnologyAvayaAACC.id)
        // delete from local store
        this.setQuoteTechnologyAvayaAACC({})
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2)
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }
</style>
