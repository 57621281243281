export const iQuoteConstants = {
  data () {
    return {
      iQuoteConstants: {
        technologies: Object.freeze({
          avayaAura: 1,
          avayaElite: 2,
          avayaAACC: 3,
          callmedia: 4,
          oceana: 5,
          gammaSIP: 6,
          mitelUC_MSP: 7,
          mitelCC: 8,
          callmediaCXNow: 9,
          iconGateway: 10,
          sipMigrationOffer: 11,
          talkTalkBusinessSip: 12,
          coltSip: 13,
          quoteExpansions: 14,
          teamsConnector: 15,
          phoneLinePlus: 16,
          genesys: 17,
          ringCentralUC: 18,
          ringCentralCC: 19,
          sycurio: 20,
          ucAnalytics: 21,
          avayaAXP: 22,
          mitelUC_E2: 23
        }),
        services: Object.freeze({
          communicate: 1,
          contact: 2,
          sip: 3,
          callmediaCXNow: 4,
          gateway: 5,
          expansions: 6,
          teamsConnector: 7,
          genesys: 8,
          externalQuotes: 9,
          ringCentral: 10,
          sycurio: 11,
          ucAnalytics: 12,
          avayaAXP: 13
        }),
        sipProviders: Object.freeze({
          gamma: 1,
          ttb: 2,
          none: 3
        }),
        serviceCategories: Object.freeze({
          ukSip: 1,
          internationalSip: 2,
          sipMigration: 3,
          phoneLinePlus: 4,
          mitelUC: 5
        }),
        dataCentres: Object.freeze({
          fareham: 1,
          goswellRoad: 2
        }),
        bladeServers: Object.freeze({
          STD_UC: 1,
          STD_CC: 2,
          HPR_UC: 3,
          HPR_CC: 4
        }),
        bladeServerTypes: Object.freeze({
          Standard: 1,
          HighPower: 2
        }),
        virtualMachineCategories: Object.freeze({
          UC: 1,
          CC: 2
        }),
        costTypes: Object.freeze({
          internal: 1,
          external: 2
        }),
        recurrence: Object.freeze({
          oneOff: 1,
          recurring: 2
        }),
        unitCostCategories: Object.freeze({
          ICON_INFRASTRUCTURE: 1,
          THIRD_PARTY: 2,
          ICON_PS: 3,
          AWS_INFRASTRUCTURE: 4,
          CALLMEDIA_CX_PS: 5,
          INTERNAL: 6,
          ICON_GATEWAY_INFRASTRUCTURE: 7,
          INTERNAL_PS: 8,
          SYCURIO: 9,
          UC_ANALYTICS: 10
        }),
        cxNowApps: Object.freeze({
          CHATBOT_STANDALONE: 1,
          CHATBOT_INTEGRATION: 2,
          WHATSAPP: 3
        }),
        cxNowConnectors: Object.freeze({
          CRM_SF_STD: 1,
          CRM_MS_365_STD: 2
        }),
        virtualMachines: Object.freeze({
          SIP_SBC8_0_ASBCE: 1,
          SIP_SBC8_0_EMS: 2,
          AURA_CM_SIMPLEX: 3,
          AURA_CM_DUPLEX: 4,
          AURA_SMGR8_1_PROFILE_2: 5,
          AURA_SM_PROFILE_1: 6,
          AURA_SM_PROFILE_2: 7,
          AURA_SM_PROFILE_3: 8,
          AURA_SM_PROFILE_4: 9,
          AURA_AADS_PROFILE_1: 10,
          AURA_AADS_PROFILE_2: 11,
          AURA_AADS_PROFILE_3: 12,
          AURA_AADS_PROFILE_4: 13,
          AURA_AAMS_PROFILE_1: 14,
          AURA_AAMS_PROFILE_2: 15,
          AURA_AAMS_PROFILE_3: 16,
          AURA_AAMS_PROFILE_4: 17,
          AURA_IXMESSAGING_PROFILE_1: 18,
          AURA_IXMESSAGING_PROFILE_2: 19,
          AURA_IXMESSAGING_PROFILE_3: 20,
          AURA_IXMESSAGING_PROFILE_4: 21,
          AURA_AES_8_0_PROFILE_1: 23,
          AURA_AES_8_0_PROFILE_2: 24,
          AURA_AES_8_0_PROFILE_3: 25,
          ELITE_CMS_PROFILE_1: 26,
          ELITE_CMS_PROFILE_2: 27,
          ELITE_CMS_PROFILE_3: 28,
          AURA_WEBLM_8_1: 29,
          AURA_ADS: 30,
          AURA_MCSM: 31,
          AURA_RFDC: 32,
          REDBOX: 33,
          REDBOX_COMPRESSION: 34,
          RIGHTFAX: 35,
          CALLMEDIA_APP_COMBINED_SQLEXPRESS_CMSRV1: 36,
          CALLMEDIA_SQL_SMALL_VOICE_CMSRV7: 37,
          AURA_IXMESSAGING_10_8_PROFILE_1: 71,
          AURA_IXMESSAGING_10_8_PROFILE_2: 72,
          AURA_APS_8_1_PROFILE_3: 73,
          AURA_APS_8_1_PROFILE_4: 74,
          AURA_APS_8_1_PROFILE_5: 75,
          AURA_VM_UVR: 76,
          CALLMEDIA_APP_COMBINED_STANDARD_CMSRV2: 77,
          CALLMEDIA_APP_COMBINED_ENHANCED_CMSRV3: 78,
          CALLMEDIA_APP_STANDARD_CMSRV4: 79,
          CALLMEDIA_APP_SMALL_S4B_CMSRV5: 80,
          CALLMEDIA_APP_LARGE_S4B_CMSRV6: 81,
          CALLMEDIA_SQL_SMALL_MULTI_CMSRV8: 82,
          CALLMEDIA_SQL_MEDIUM_VOICE_CMSRV9: 83,
          CALLMEDIA_SQL_MEDIUM_MULTI_CMSRV10: 84,
          CALLMEDIA_SQL_LARGE_VOICE_CMSRV11: 85,
          CALLMEDIA_SQL_LARGE_MULTI_CMSRV12: 86,
          CALLMEDIA_VOXEO: 87,
          VM_MCD_SML: 88,
          VM_MCD_MED: 89,
          VM_MCD_LRG: 90,
          VM_MBG_SML: 91,
          VM_MBG_LRG: 92,
          VM_DMZ_MBG_SML: 93,
          VM_DMZ_MBG_LRG: 94,
          VM_SRC_SML: 95,
          VM_SRC_MED: 96,
          MITEL_VM_UVR: 97,
          VM_MICOLLAB_SML: 98,
          VM_MICOLLAB_MED: 99,
          VM_MICOLLAB_LRG: 100,
          VM_MICOLLAB_LRG2: 101,
          VM_MICONTACT_SML: 102,
          VM_MICONTACT_MED: 103,
          VM_MICONTACT_LRG: 104,
          VM_MICC_SQL: 105,
          VM_FOREST_DC: 106,
          VM_MICONTACT_IVR: 107,
          VM_MVB_OIG: 108,
          AURA_AADS_8_0_PROFILE_1: 109,
          AURA_AADS_8_0_PROFILE_2: 110,
          AURA_AADS_8_0_PROFILE_3: 111,
          AURA_AADS_8_0_PROFILE_4: 112,
          AVAYA_WFO_CONSOLIDATED: 113,
          AVAYA_WFO_APPLICATION: 114,
          AVAYA_WFO_DATABASE: 115,
          AVAYA_WFO_LOAD_BALANCER: 116,
          AVAYA_WFO_WFO_RECORDER: 117,
          AVAYA_WFO_THALES_KMS: 118,
          AVAYA_USAGE_MONITORING: 119,
          AVAYA_AACC_WIDGET_WEB_SERVER: 120,
          REDBOX_NO_ZERTO: 121,
          AVAYA_AACC_AMS_SMALL: 122,
          AVAYA_AACC_AMS_LARGE: 123,
          ASC: 124,
          ASC_COMPRESSION: 125,
          ASC_SMALL: 126,
          ASC_MEDIUM: 127,
          ASC_LARGE: 128
          /*,
          INSERT [iQuote].[VirtualMachines] ([Id], [Name], [VirtualMachineSpecId], [VirtualMachineCategoryId]) VALUES (69, N'APS R8.1 - Profile 3 - Max Users 1000', 69, 1)
          INSERT [iQuote].[VirtualMachines] ([Id], [Name], [VirtualMachineSpecId], [VirtualMachineCategoryId]) VALUES (70, N'APS R8.1 - Profile 4 - Max Users 5000', 70, 1)
          INSERT [iQuote].[VirtualMachines] ([Id], [Name], [VirtualMachineSpecId], [VirtualMachineCategoryId]) VALUES (71, N'APS R8.1 - Profile 5 - Max Users 11000 (non HA)', 71, 1)
          INSERT [iQuote].[VirtualMachines] ([Id], [Name], [VirtualMachineSpecId], [VirtualMachineCategoryId]) VALUES (72, N'VM-UVR', 72, 1)
*/
        }),
        licenses: Object.freeze({
          AURA_ENTRY_TIER1_STANDARD_SUBSCRIPTION: 1,
          AURA_STANDARD_TIER1_STANDARD_SUBSCRIPTION: 2,
          AURA_PREMIUM_TIER1_STANDARD_SUBSCRIPTION: 3,
          AURA_ATTENDANT_TIER1_STANDARD_SUBSCRIPTION: 4,
          AURA_ENTRY_TIER2_STANDARD_SUBSCRIPTION: 5,
          AURA_STANDARD_TIER2_STANDARD_SUBSCRIPTION: 6,
          AURA_PREMIUM_TIER2_STANDARD_SUBSCRIPTION: 7,
          AURA_ATTENDANT_TIER2_STANDARD_SUBSCRIPTION: 8,
          AURA_ENTRY_TIER3_STANDARD_SUBSCRIPTION: 9,
          AURA_STANDARD_TIER3_STANDARD_SUBSCRIPTION: 10,
          AURA_PREMIUM_TIER3_STANDARD_SUBSCRIPTION: 11,
          AURA_ATTENDANT_TIER3_STANDARD_SUBSCRIPTION: 12,
          AURA_ENTRY_TIER1_PREMIUM_SUBSCRIPTION: 13,
          AURA_STANDARD_TIER1_PREMIUM_SUBSCRIPTION: 14,
          AURA_PREMIUM_TIER1_PREMIUM_SUBSCRIPTION: 15,
          AURA_ATTENDANT_TIER1_PREMIUM_SUBSCRIPTION: 16,
          AURA_ENTRY_TIER2_PREMIUM_SUBSCRIPTION: 17,
          AURA_STANDARD_TIER2_PREMIUM_SUBSCRIPTION: 18,
          AURA_PREMIUM_TIER2_PREMIUM_SUBSCRIPTION: 19,
          AURA_ATTENDANT_TIER2_PREMIUM_SUBSCRIPTION: 20,
          AURA_ENTRY_TIER3_PREMIUM_SUBSCRIPTION: 21,
          AURA_STANDARD_TIER3_PREMIUM_SUBSCRIPTION: 22,
          AURA_PREMIUM_TIER3_PREMIUM_SUBSCRIPTION: 23,
          AURA_ATTENDANT_TIER3_PREMIUM_SUBSCRIPTION: 24,
          AURA_ENTRY_TIER1_STANDARD_PERPETUAL: 25,
          AURA_STANDARD_TIER1_STANDARD_PERPETUAL: 26,
          AURA_PREMIUM_TIER1_STANDARD_PERPETUAL: 27,
          AURA_ATTENDANT_TIER1_STANDARD_PERPETUAL: 28,
          AURA_ENTRY_TIER2_STANDARD_PERPETUAL: 29,
          AURA_STANDARD_TIER2_STANDARD_PERPETUAL: 30,
          AURA_PREMIUM_TIER2_STANDARD_PERPETUAL: 31,
          AURA_ATTENDANT_TIER2_STANDARD_PERPETUAL: 32,
          AURA_ENTRY_TIER3_STANDARD_PERPETUAL: 33,
          AURA_STANDARD_TIER3_STANDARD_PERPETUAL: 34,
          AURA_PREMIUM_TIER3_STANDARD_PERPETUAL: 35,
          AURA_ATTENDANT_TIER3_STANDARD_PERPETUAL: 36,
          AURA_ENTRY_TIER1_PREMIUM_PERPETUAL: 37,
          AURA_STANDARD_TIER1_PREMIUM_PERPETUAL: 38,
          AURA_PREMIUM_TIER1_PREMIUM_PERPETUAL: 39,
          AURA_ATTENDANT_TIER1_PREMIUM_PERPETUAL: 40,
          AURA_ENTRY_TIER2_PREMIUM_PERPETUAL: 41,
          AURA_STANDARD_TIER2_PREMIUM_PERPETUAL: 42,
          AURA_PREMIUM_TIER2_PREMIUM_PERPETUAL: 43,
          AURA_ATTENDANT_TIER2_PREMIUM_PERPETUAL: 44,
          AURA_ENTRY_TIER3_PREMIUM_PERPETUAL: 45,
          AURA_STANDARD_TIER3_PREMIUM_PERPETUAL: 46,
          AURA_PREMIUM_TIER3_PREMIUM_PERPETUAL: 47,
          AURA_ATTENDANT_TIER3_PREMIUM_PERPETUAL: 48,
          ELITE_VOICE_TIER1_STANDARD_SUBSCRIPTION: 49,
          ELITE_VOICE_TIER2_STANDARD_SUBSCRIPTION: 50,
          ELITE_VOICE_TIER3_STANDARD_SUBSCRIPTION: 51,
          ELITE_VOICE_TIER1_PREMIUM_SUBSCRIPTION: 52,
          ELITE_VOICE_TIER2_PREMIUM_SUBSCRIPTION: 53,
          ELITE_VOICE_TIER3_PREMIUM_SUBSCRIPTION: 54,
          AVAYA_HANDSET_J129: 55,
          AVAYA_HANDSET_J139: 56,
          AVAYA_HANDSET_J169: 57,
          AVAYA_HANDSET_J179: 58,
          SIP_ACTIVE_12_DO_NOT_USE: 59,
          SIP_STANDBY_12_DO_NOT_USE: 60,
          REDBOX_CHANNEL: 62,
          REDBOX_SUPPORT_3RD_LINE: 63,
          REDBOX_SUPPORT_FULL: 64,
          RIGHTFAX: 65,
          DATATRACK_CDR: 66,
          CALLMEDIA_VOICE: 67,
          CALLMEDIA_MULTI_CHANNEL: 68,
          CALLMEDIA_OUTBOUND: 69,
          AURA_ENTRY_TIER1_STANDARD_SURRENDER: 70,
          AURA_STANDARD_TIER1_STANDARD_SURRENDER: 71,
          AURA_PREMIUM_TIER1_STANDARD_SURRENDER: 72,
          AURA_ATTENDANT_TIER1_STANDARD_SURRENDER: 73,
          AURA_ENTRY_TIER2_STANDARD_SURRENDER: 74,
          AURA_STANDARD_TIER2_STANDARD_SURRENDER: 75,
          AURA_PREMIUM_TIER2_STANDARD_SURRENDER: 76,
          AURA_ATTENDANT_TIER2_STANDARD_SURRENDER: 77,
          AURA_ENTRY_TIER3_STANDARD_SURRENDER: 78,
          AURA_STANDARD_TIER3_STANDARD_SURRENDER: 79,
          AURA_PREMIUM_TIER3_STANDARD_SURRENDER: 80,
          AURA_ATTENDANT_TIER3_STANDARD_SURRENDER: 81,
          AURA_ENTRY_TIER1_PREMIUM_SURRENDER: 82,
          AURA_STANDARD_TIER1_PREMIUM_SURRENDER: 83,
          AURA_PREMIUM_TIER1_PREMIUM_SURRENDER: 84,
          AURA_ATTENDANT_TIER1_PREMIUM_SURRENDER: 85,
          AURA_ENTRY_TIER2_PREMIUM_SURRENDER: 86,
          AURA_STANDARD_TIER2_PREMIUM_SURRENDER: 87,
          AURA_PREMIUM_TIER2_PREMIUM_SURRENDER: 88,
          AURA_ATTENDANT_TIER2_PREMIUM_SURRENDER: 89,
          AURA_ENTRY_TIER3_PREMIUM_SURRENDER: 90,
          AURA_STANDARD_TIER3_PREMIUM_SURRENDER: 91,
          AURA_PREMIUM_TIER3_PREMIUM_SURRENDER: 92,
          AURA_ATTENDANT_TIER3_PREMIUM_SURRENDER: 93,
          DATATRACK_TIER1: 94,
          DATATRACK_TIER2: 95,
          DATATRACK_TIER3: 96,
          ELITE_VOICE_TIER1_STANDARD_PERPETUAL: 97,
          ELITE_VOICE_TIER2_STANDARD_PERPETUAL: 98,
          ELITE_VOICE_TIER3_STANDARD_PERPETUAL: 99,
          ELITE_VOICE_TIER1_PREMIUM_PERPETUAL: 100,
          ELITE_VOICE_TIER2_PREMIUM_PERPETUAL: 101,
          ELITE_VOICE_TIER3_PREMIUM_PERPETUAL: 102,
          ELITE_VOICE_TIER1_PREMIUM_SURRENDER: 103,
          ELITE_VOICE_TIER2_PREMIUM_SURRENDER: 104,
          ELITE_VOICE_TIER3_PREMIUM_SURRENDER: 105,
          ELITE_VOICE_TIER1_STANDARD_SURRENDER: 106,
          ELITE_VOICE_TIER2_STANDARD_SURRENDER: 107,
          ELITE_VOICE_TIER3_STANDARD_SURRENDER: 108,
          MITEL_UC_BASIC_TIER1_STANDARD_SUBSCRIPTION: 109,
          MITEL_UC_ENTRY_TIER1_STANDARD_SUBSCRIPTION: 110,
          MITEL_UC_STANDARD_TIER1_STANDARD_SUBSCRIPTION: 111,
          MITEL_UC_PREMIUM_TIER1_STANDARD_SUBSCRIPTION: 112,
          MITEL_UC_ULTIMATE_TIER1_STANDARD_SUBSCRIPTION: 113,
          MITEL_UC_ATTENDANT_TIER1_STANDARD_SUBSCRIPTION: 114,
          MITEL_UC_ANALOGUE_TIER1_STANDARD_SUBSCRIPTION: 115,
          MITEL_UC_BASIC_TIER2_STANDARD_SUBSCRIPTION: 116,
          MITEL_UC_ENTRY_TIER2_STANDARD_SUBSCRIPTION: 117,
          MITEL_UC_STANDARD_TIER2_STANDARD_SUBSCRIPTION: 118,
          MITEL_UC_PREMIUM_TIER2_STANDARD_SUBSCRIPTION: 119,
          MITEL_UC_ULTIMATE_TIER2_STANDARD_SUBSCRIPTION: 120,
          MITEL_UC_ATTENDANT_TIER2_STANDARD_SUBSCRIPTION: 121,
          MITEL_UC_ANALOGUE_TIER2_STANDARD_SUBSCRIPTION: 122,
          MITEL_UC_BASIC_TIER3_STANDARD_SUBSCRIPTION: 123,
          MITEL_UC_ENTRY_TIER3_STANDARD_SUBSCRIPTION: 124,
          MITEL_UC_STANDARD_TIER3_STANDARD_SUBSCRIPTION: 125,
          MITEL_UC_PREMIUM_TIER3_STANDARD_SUBSCRIPTION: 126,
          MITEL_UC_ULTIMATE_TIER3_STANDARD_SUBSCRIPTION: 127,
          MITEL_UC_ATTENDANT_TIER3_STANDARD_SUBSCRIPTION: 128,
          MITEL_UC_ANALOGUE_TIER3_STANDARD_SUBSCRIPTION: 129,
          MITEL_UC_BASIC_TIER1_PREMIUM_SUBSCRIPTION: 130,
          MITEL_UC_ENTRY_TIER1_PREMIUM_SUBSCRIPTION: 131,
          MITEL_UC_STANDARD_TIER1_PREMIUM_SUBSCRIPTION: 132,
          MITEL_UC_PREMIUM_TIER1_PREMIUM_SUBSCRIPTION: 133,
          MITEL_UC_ULTIMATE_TIER1_PREMIUM_SUBSCRIPTION: 134,
          MITEL_UC_ATTENDANT_TIER1_PREMIUM_SUBSCRIPTION: 135,
          MITEL_UC_ANALOGUE_TIER1_PREMIUM_SUBSCRIPTION: 136,
          MITEL_UC_BASIC_TIER2_PREMIUM_SUBSCRIPTION: 137,
          MITEL_UC_ENTRY_TIER2_PREMIUM_SUBSCRIPTION: 138,
          MITEL_UC_STANDARD_TIER2_PREMIUM_SUBSCRIPTION: 139,
          MITEL_UC_PREMIUM_TIER2_PREMIUM_SUBSCRIPTION: 140,
          MITEL_UC_ULTIMATE_TIER2_PREMIUM_SUBSCRIPTION: 141,
          MITEL_UC_ATTENDANT_TIER2_PREMIUM_SUBSCRIPTION: 142,
          MITEL_UC_ANALOGUE_TIER2_PREMIUM_SUBSCRIPTION: 143,
          MITEL_UC_BASIC_TIER3_PREMIUM_SUBSCRIPTION: 144,
          MITEL_UC_ENTRY_TIER3_PREMIUM_SUBSCRIPTION: 145,
          MITEL_UC_STANDARD_TIER3_PREMIUM_SUBSCRIPTION: 146,
          MITEL_UC_PREMIUM_TIER3_PREMIUM_SUBSCRIPTION: 147,
          MITEL_UC_ULTIMATE_TIER3_PREMIUM_SUBSCRIPTION: 148,
          MITEL_UC_ATTENDANT_TIER3_PREMIUM_SUBSCRIPTION: 149,
          MITEL_UC_ANALOGUE_TIER3_PREMIUM_SUBSCRIPTION: 150,
          MITEL_UC_BASIC_TIER1_STANDARD_PERPETUAL: 151,
          MITEL_UC_ENTRY_TIER1_STANDARD_PERPETUAL: 152,
          MITEL_UC_STANDARD_TIER1_STANDARD_PERPETUAL: 153,
          MITEL_UC_PREMIUM_TIER1_STANDARD_PERPETUAL: 154,
          MITEL_UC_ULTIMATE_TIER1_STANDARD_PERPETUAL: 155,
          MITEL_UC_ATTENDANT_TIER1_STANDARD_PERPETUAL: 156,
          MITEL_UC_ANALOGUE_TIER1_STANDARD_PERPETUAL: 157,
          MITEL_UC_BASIC_TIER2_STANDARD_PERPETUAL: 158,
          MITEL_UC_ENTRY_TIER2_STANDARD_PERPETUAL: 159,
          MITEL_UC_STANDARD_TIER2_STANDARD_PERPETUAL: 160,
          MITEL_UC_PREMIUM_TIER2_STANDARD_PERPETUAL: 161,
          MITEL_UC_ULTIMATE_TIER2_STANDARD_PERPETUAL: 162,
          MITEL_UC_ATTENDANT_TIER2_STANDARD_PERPETUAL: 163,
          MITEL_UC_ANALOGUE_TIER2_STANDARD_PERPETUAL: 164,
          MITEL_UC_BASIC_TIER3_STANDARD_PERPETUAL: 165,
          MITEL_UC_ENTRY_TIER3_STANDARD_PERPETUAL: 166,
          MITEL_UC_STANDARD_TIER3_STANDARD_PERPETUAL: 167,
          MITEL_UC_PREMIUM_TIER3_STANDARD_PERPETUAL: 168,
          MITEL_UC_ULTIMATE_TIER3_STANDARD_PERPETUAL: 169,
          MITEL_UC_ATTENDANT_TIER3_STANDARD_PERPETUAL: 170,
          MITEL_UC_ANALOGUE_TIER3_STANDARD_PERPETUAL: 171,
          MITEL_UC_BASIC_TIER1_PREMIUM_PERPETUAL: 172,
          MITEL_UC_ENTRY_TIER1_PREMIUM_PERPETUAL: 173,
          MITEL_UC_STANDARD_TIER1_PREMIUM_PERPETUAL: 174,
          MITEL_UC_PREMIUM_TIER1_PREMIUM_PERPETUAL: 175,
          MITEL_UC_ULTIMATE_TIER1_PREMIUM_PERPETUAL: 176,
          MITEL_UC_ATTENDANT_TIER1_PREMIUM_PERPETUAL: 177,
          MITEL_UC_ANALOGUE_TIER1_PREMIUM_PERPETUAL: 178,
          MITEL_UC_BASIC_TIER2_PREMIUM_PERPETUAL: 179,
          MITEL_UC_ENTRY_TIER2_PREMIUM_PERPETUAL: 180,
          MITEL_UC_STANDARD_TIER2_PREMIUM_PERPETUAL: 181,
          MITEL_UC_PREMIUM_TIER2_PREMIUM_PERPETUAL: 182,
          MITEL_UC_ULTIMATE_TIER2_PREMIUM_PERPETUAL: 183,
          MITEL_UC_ATTENDANT_TIER2_PREMIUM_PERPETUAL: 184,
          MITEL_UC_ANALOGUE_TIER2_PREMIUM_PERPETUAL: 185,
          MITEL_UC_BASIC_TIER3_PREMIUM_PERPETUAL: 186,
          MITEL_UC_ENTRY_TIER3_PREMIUM_PERPETUAL: 187,
          MITEL_UC_STANDARD_TIER3_PREMIUM_PERPETUAL: 188,
          MITEL_UC_PREMIUM_TIER3_PREMIUM_PERPETUAL: 189,
          MITEL_UC_ULTIMATE_TIER3_PREMIUM_PERPETUAL: 190,
          MITEL_UC_ATTENDANT_TIER3_PREMIUM_PERPETUAL: 191,
          MITEL_UC_ANALOGUE_TIER3_PREMIUM_PERPETUAL: 192,
          MITEL_CC_VOICE_AGENT_TIER1_STANDARD_SUBSCRIPTION: 193,
          MITEL_CC_MULTIMEDIA_AGENT_TIER1_STANDARD_SUBSCRIPTION: 194,
          MITEL_CC_VOICE_AGENT_TIER2_STANDARD_SUBSCRIPTION: 195,
          MITEL_CC_MULTIMEDIA_AGENT_TIER2_STANDARD_SUBSCRIPTION: 196,
          MITEL_CC_VOICE_AGENT_TIER3_STANDARD_SUBSCRIPTION: 197,
          MITEL_CC_MULTIMEDIA_AGENT_TIER3_STANDARD_SUBSCRIPTION: 198,
          MITEL_CC_VOICE_AGENT_TIER1_PREMIUM_SUBSCRIPTION: 199,
          MITEL_CC_MULTIMEDIA_AGENT_TIER1_PREMIUM_SUBSCRIPTION: 200,
          MITEL_CC_VOICE_AGENT_TIER2_PREMIUM_SUBSCRIPTION: 201,
          MITEL_CC_MULTIMEDIA_AGENT_TIER2_PREMIUM_SUBSCRIPTION: 202,
          MITEL_CC_VOICE_AGENT_TIER3_PREMIUM_SUBSCRIPTION: 203,
          MITEL_CC_MULTIMEDIA_AGENT_TIER3_PREMIUM_SUBSCRIPTION: 204,
          MITEL_CC_VOICE_AGENT_TIER1_STANDARD_PERPETUAL: 205,
          MITEL_CC_MULTIMEDIA_AGENT_TIER1_STANDARD_PERPETUAL: 206,
          MITEL_CC_VOICE_AGENT_TIER2_STANDARD_PERPETUAL: 207,
          MITEL_CC_MULTIMEDIA_AGENT_TIER2_STANDARD_PERPETUAL: 208,
          MITEL_CC_VOICE_AGENT_TIER3_STANDARD_PERPETUAL: 209,
          MITEL_CC_MULTIMEDIA_AGENT_TIER3_STANDARD_PERPETUAL: 210,
          MITEL_CC_VOICE_AGENT_TIER1_PREMIUM_PERPETUAL: 211,
          MITEL_CC_MULTIMEDIA_AGENT_TIER1_PREMIUM_PERPETUAL: 212,
          MITEL_CC_VOICE_AGENT_TIER2_PREMIUM_PERPETUAL: 213,
          MITEL_CC_MULTIMEDIA_AGENT_TIER2_PREMIUM_PERPETUAL: 214,
          MITEL_CC_VOICE_AGENT_TIER3_PREMIUM_PERPETUAL: 215,
          MITEL_CC_MULTIMEDIA_AGENT_TIER3_PREMIUM_PERPETUAL: 216,
          MITEL_MBG: 217,
          MITEL_MAILBOX: 218,
          MITEL_AWC: 219,
          MITEL_SRCPORTS: 220,
          MITEL_IVRPORTS: 221,
          AVAYA_CALL_RECORDING_TIER1: 222,
          AVAYA_CALL_RECORDING_TIER2: 223,
          AVAYA_CALL_RECORDING_TIER3: 224,
          CXNOW_VOICE_ONLY_USER: 225,
          CXNOW_DIGITAL_ONLY_USER: 226,
          CXNOW_AGENT_SIP: 227,
          CXNOW_NAILEDUP_SIP: 228,
          SIP_ACTIVE_36_DO_NOT_USE: 229,
          SIP_STANDBY_36_DO_NOT_USE: 230,
          CXNOW_SBC_PORT_CHARGE: 231,
          CXNOW_AT_ASSET: 269,
          CXNOW_START_AUTOTASK: 270,
          CXNOW_DIGITAL_AUTOTASK: 271,
          MITEL_TELEWORKER: 272,
          SIP_GAMMA_MINS_LAND_MOB: 273,
          SIP_GAMMA_STCM_DDI_INCE_1_1K: 275,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K: 276,
          TTB_SIP_ACTIVE_12: 277,
          TTB_SIP_ACTIVE_36: 278,
          CALLMEDIA_VOICE_PERPETUAL: 279,
          CALLMEDIA_MULTI_CHANNEL_PERPETUAL: 280,
          CALLMEDIA_OUTBOUND_PERPETUAL: 281,
          TEAMS_SUBSCRIPTION_TIER1: 282,
          TEAMS_SUBSCRIPTION_TIER2: 283,
          TEAMS_SIP_ACTIVE_12: 284,
          TEAMS_SIP_STANDBY_12: 285,
          TEAMS_SIP_ACTIVE_36: 286,
          TEAMS_SIP_STANDBY_36: 287,
          CXNOW_TALKATIVE_PLATFORM: 288,
          CXNOW_TALKATIVE_COMMITTED: 289,
          PHONEPLUS_MONTH_ROLLING: 290,
          PHONEPLUS_1YEAR_DO_NOT_USE: 291,
          PHONEPLUS_3YEAR_DO_NOT_USE: 292,
          CXNOW_SIP_ACTIVE_36: 293,
          CXNOW_SIP_STANDBY_36: 294,
          SIP_MIGRATION: 295,
          CXNOW_RING_CENTRAL: 296,
          GENESYS_COMMUNICATE_ANNUALPREPAY_NAMED_DO_NOT_USE: 297,
          GENESYS_COMMUNICATE_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 298,
          GENESYS_COMMUNICATE_ANNUALPREPAY_HOURLY_DO_NOT_USE: 299,
          GENESYS_COMMUNICATE_ANNUALM2M_NAMED_DO_NOT_USE: 300,
          GENESYS_COMMUNICATE_ANNUALM2M_CONCURRENT_DO_NOT_USE: 301,
          GENESYS_COMMUNICATE_ANNUALM2M_HOURLY_DO_NOT_USE: 302,
          GENESYS_COMMUNICATE_M2M_NAMED_DO_NOT_USE: 303,
          GENESYS_COMMUNICATE_M2M_CONCURRENT_DO_NOT_USE: 304,
          GENESYS_COMMUNICATE_M2M_HOURLY_DO_NOT_USE: 305,
          GENESYS_PURECLOUD1_ANNUALPREPAY_NAMED_DO_NOT_USE: 306,
          GENESYS_PURECLOUD1_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 307,
          GENESYS_PURECLOUD1_ANNUALPREPAY_HOURLY_DO_NOT_USE: 308,
          GENESYS_PURECLOUD1_ANNUALM2M_NAMED_DO_NOT_USE: 309,
          GENESYS_PURECLOUD1_ANNUALM2M_CONCURRENT_DO_NOT_USE: 310,
          GENESYS_PURECLOUD1_ANNUALM2M_HOURLY_DO_NOT_USE: 311,
          GENESYS_PURECLOUD1_M2M_NAMED_DO_NOT_USE: 312,
          GENESYS_PURECLOUD1_M2M_CONCURRENT_DO_NOT_USE: 313,
          GENESYS_PURECLOUD1_M2M_HOURLY_DO_NOT_USE: 314,
          GENESYS_PURECLOUD2_ANNUALPREPAY_NAMED_DO_NOT_USE: 315,
          GENESYS_PURECLOUD2_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 316,
          GENESYS_PURECLOUD2_ANNUALPREPAY_HOURLY_DO_NOT_USE: 317,
          GENESYS_PURECLOUD2_ANNUALM2M_NAMED_DO_NOT_USE: 318,
          GENESYS_PURECLOUD2_ANNUALM2M_CONCURRENT_DO_NOT_USE: 319,
          GENESYS_PURECLOUD2_ANNUALM2M_HOURLY_DO_NOT_USE: 320,
          GENESYS_PURECLOUD2_M2M_NAMED_DO_NOT_USE: 321,
          GENESYS_PURECLOUD2_M2M_CONCURRENT_DO_NOT_USE: 322,
          GENESYS_PURECLOUD2_M2M_HOURLY_DO_NOT_USE: 323,
          GENESYS_PURECLOUD3_ANNUALPREPAY_NAMED_DO_NOT_USE: 324,
          GENESYS_PURECLOUD3_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 325,
          GENESYS_PURECLOUD3_ANNUALPREPAY_HOURLY_DO_NOT_USE: 326,
          GENESYS_PURECLOUD3_ANNUALM2M_NAMED_DO_NOT_USE: 327,
          GENESYS_PURECLOUD3_ANNUALM2M_CONCURRENT_DO_NOT_USE: 328,
          GENESYS_PURECLOUD3_ANNUALM2M_HOURLY_DO_NOT_USE: 329,
          GENESYS_PURECLOUD3_M2M_NAMED_DO_NOT_USE: 330,
          GENESYS_PURECLOUD3_M2M_CONCURRENT_DO_NOT_USE: 331,
          GENESYS_PURECLOUD3_M2M_HOURLY_DO_NOT_USE: 332,
          GENESYS_MSTEAMS_ANNUALPREPAY_NAMED_DO_NOT_USE: 333,
          GENESYS_MSTEAMS_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 334,
          GENESYS_MSTEAMS_ANNUALM2M_NAMED_DO_NOT_USE: 335,
          GENESYS_MSTEAMS_ANNUALM2M_CONCURRENT_DO_NOT_USE: 336,
          GENESYS_ZOOM_INT_ANNUALPREPAY_NAMED_DO_NOT_USE: 337,
          GENESYS_ZOOM_INT_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 338,
          GENESYS_ZOOM_INT_ANNUALM2M_NAMED_DO_NOT_USE: 339,
          GENESYS_ZOOM_INT_ANNUALM2M_CONCURRENT_DO_NOT_USE: 340,
          GENESYS_PURECLOUD_WALLBOARD_ANNUALPREPAY_DO_NOT_USE: 341,
          GENESYS_PURECLOUD_WALLBOARD_ANNUALM2M_DO_NOT_USE: 342,
          GENESYS_170_NV_USR1WEMII_ANNUALPREPAY_NAMED_DO_NOT_USE: 343,
          GENESYS_170_NV_USR1WEMIIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 344,
          GENESYS_170_NV_USR1WEMII_ANNUALM2M_NAMED_DO_NOT_USE: 345,
          GENESYS_170_NV_USR1WEMIIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 346,
          GENESYS_170_NV_USR1WEMII_M2M_NAMED_DO_NOT_USE: 347,
          GENESYS_170_NV_USR1WEMIIC_M2M_CONCURRENT_DO_NOT_USE: 348,
          GENESYS_170_NV_USR1WEMI_ANNUALPREPAY_NAMED_DO_NOT_USE: 349,
          GENESYS_170_NV_USR1WEMIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 350,
          GENESYS_170_NV_USR1WEMI_ANNUALM2M_NAMED_DO_NOT_USE: 351,
          GENESYS_170_NV_USR1WEMIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 352,
          GENESYS_170_NV_USR1WEMI_M2M_NAMED_DO_NOT_USE: 353,
          GENESYS_170_NV_USR1WEMIC_M2M_CONCURRENT_DO_NOT_USE: 354,
          GENESYS_PC_170_NV_USR1DIGI_ANNUALPREPAY_NAMED_DO_NOT_USE: 355,
          GENESYS_PC_170_NV_USR1DIGIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 356,
          GENESYS_PC_170_NV_USR1DIGI_ANNUALM2M_NAMED_DO_NOT_USE: 357,
          GENESYS_PC_170_NV_USR1DIGIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 358,
          GENESYS_PC_170_NV_USR1DIGI_M2M_NAMED_DO_NOT_USE: 359,
          GENESYS_PC_170_NV_USR1DIGIC_M2M_CONCURRENT_DO_NOT_USE: 360,
          GENESYS_PC_170_NV_USR1DIGII_ANNUALPREPAY_NAMED_DO_NOT_USE: 361,
          GENESYS_PC_170_NV_USR1DIGIIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 362,
          GENESYS_PC_170_NV_USR1DIGII_ANNUALM2M_NAMED_DO_NOT_USE: 363,
          GENESYS_PC_170_NV_USR1DIGIIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 364,
          GENESYS_PC_170_NV_USR1DIGII_M2M_NAMED_DO_NOT_USE: 365,
          GENESYS_PC_170_NV_USR1DIGIIC_M2M_CONCURRENT_DO_NOT_USE: 366,
          GENESYS_PC_170_NV_USR2DIGI_ANNUALPREPAY_NAMED_DO_NOT_USE: 367,
          GENESYS_PC_170_NV_USR2DIGIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 368,
          GENESYS_PC_170_NV_USR2DIGI_ANNUALM2M_NAMED_DO_NOT_USE: 369,
          GENESYS_PC_170_NV_USR2DIGIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 370,
          GENESYS_PC_170_NV_USR2DIGI_M2M_NAMED_DO_NOT_USE: 371,
          GENESYS_PC_170_NV_USR2DIGIC_M2M_CONCURRENT_DO_NOT_USE: 372,
          GENESYS_PC_170_NV_PCSFAN_ANNUALPREPAY_NAMED_DO_NOT_USE: 373,
          GENESYS_PC_170_NV_PCSFANC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 374,
          GENESYS_PC_170_NV_PCSFAN_ANNUALM2M_NAMED_DO_NOT_USE: 375,
          GENESYS_PC_170_NV_PCSFANC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 376,
          GENESYS_PC_170_NV_PCSFAN_M2M_NAMED_DO_NOT_USE: 377,
          GENESYS_PC_170_NV_PCSFANC_M2M_CONCURRENT_DO_NOT_USE: 378,
          GENESYS_PC_170_NV_PCSFCAN_ANNUALPREPAY_NAMED_DO_NOT_USE: 379,
          GENESYS_PC_170_NV_PCSFCAN_ANNUALM2M_NAMED_DO_NOT_USE: 380,
          GENESYS_PC_170_NV_PCSFCAN_M2M_NAMED_DO_NOT_USE: 381,
          TEAMS_REC_SMART: 382,
          TEAMS_REC_SMART_200: 383,
          TEAMS_REC_SMART_1000: 384,
          TEAMS_REC_SMART_2500: 385,
          TEAMS_REC_UC: 386,
          TEAMS_REC_UC_200: 387,
          TEAMS_REC_UC_1000: 388,
          TEAMS_REC_UC_2500: 389,
          TEAMS_REC_COMP: 390,
          TEAMS_REC_COMP_200: 391,
          TEAMS_REC_COMP_1000: 392,
          TEAMS_REC_COMP_2500: 393,
          TEAMS_REC_COMP_UC: 394,
          TEAMS_REC_COMP_UC_200: 395,
          TEAMS_REC_COMP_UC_1000: 396,
          TEAMS_REC_COMP_UC_2500: 397,
          TEAMS_REC_ADDON_ANALYTICS: 398,
          TEAMS_REC_STORAGE_500GB_LRS: 399,
          TEAMS_REC_STORAGE_500GB_GRS: 400,
          TEAMS_REC_ARCH_STORAGE_500GB_LRS: 401,
          TEAMS_REC_ARCH_STORAGE_500GB_GRS: 402,
          TEAMS_REC_TRANSCRIPTION_100H: 403,
          TEAMS_REC_TRANSLATE_100H: 404,
          TEAMS_REC_DATA_EXPORT_PUB: 405,
          TEAMS_REC_DATA_EXPORT_AZURE: 406,
          SIP_ACTIVE_SEMA: 407,
          SIP_STANDBY_SEMA: 408,
          CXNOW_WHATSAPP_BASE: 409,
          CXNOW_WHATSAPP_ADD_500: 410,
          PHONEPLUS_1YEAR_DISCOUNT_DO_NOT_USE: 411,
          PHONEPLUS_3YEAR_DISCOUNT_DO_NOT_USE: 412,
          TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1: 413,
          TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2: 414,
          GENESYS_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1: 415,
          GENESYS_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2: 416,
          RINGCENTRAL_UC_BASIC_USER_DO_NOT_USE: 417,
          RINGCENTRAL_UC_ENTRY_USER_DO_NOT_USE: 418,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER1_DO_NOT_USE: 419,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER1_DO_NOT_USE: 420,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER1_DO_NOT_USE: 421,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER2_DO_NOT_USE: 422,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER2_DO_NOT_USE: 423,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER2_DO_NOT_USE: 424,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER3_DO_NOT_USE: 425,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER3_DO_NOT_USE: 426,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER3_DO_NOT_USE: 427,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER1_DO_NOT_USE: 428,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER1_DO_NOT_USE: 429,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER1_DO_NOT_USE: 430,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER2_DO_NOT_USE: 431,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER2_DO_NOT_USE: 432,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER2_DO_NOT_USE: 433,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER3_DO_NOT_USE: 434,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER3_DO_NOT_USE: 435,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER3_DO_NOT_USE: 436,
          RINGCENTRAL_UC_CXNOW_INT_DO_NOT_USE: 437,
          RINGCENTRAL_UC_LIVE_REPORTS_DO_NOT_USE: 438,
          RINGCENTRAL_UC_INVIEW_MGT_DO_NOT_USE: 439,
          RINGCENTRAL_UC_ADD_NUMBER_DO_NOT_USE: 440,
          RINGCENTRAL_UC_MSFT_DYNAMICS_DO_NOT_USE: 441,
          RINGCENTRAL_CC_DIGITAL_AGENT_DO_NOT_USE: 442,
          RINGCENTRAL_CC_ESSENTIALS_AGENT_DO_NOT_USE: 443,
          RINGCENTRAL_CC_STANDARD_AGENT_DO_NOT_USE: 444,
          RINGCENTRAL_CC_SURVEY_MNGR_DO_NOT_USE: 445,
          RINGCENTRAL_CC_WORKFORCE_MGT_PRO_DO_NOT_USE: 446,
          RINGCENTRAL_CC_QUALITY_MGT_PRO_DO_NOT_USE: 447,
          RINGCENTRAL_CC_SCREEN_REC_PRO_DO_NOT_USE: 448,
          RINGCENTRAL_CC_QTY_MGT_ANLY_PRO_DO_NOT_USE: 449,
          RINGCENTRAL_CC_OMNI_ANLY_PRO_DO_NOT_USE: 450,
          RINGCENTRAL_CC_SF_AGT_DO_NOT_USE: 451,
          RINGCENTRAL_CC_ZEN_AGENT_DO_NOT_USE: 452,
          CXNOW_SIP_ACTIVE_12: 453,
          CXNOW_SIP_STANDBY_12: 454,
          RINGCENTRAL_CC_PREMIUM_AGENT_DO_NOT_USE: 455,
          RINGCENTRAL_CC_ULTIMATE_AGENT_DO_NOT_USE: 456,
          RINGCENTRAL_CC_UNLIMITED_AGENT_DO_NOT_USE: 457,
          TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1: 458,
          TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1: 459,
          TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1: 460,
          TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2: 461,
          TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2: 462,
          TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2: 463,
          TEAMS_ADDITIONAL_SIP_ACTIVE_12: 464,
          TEAMS_ADDITIONAL_SIP_STANDBY_12: 465,
          TEAMS_ADDITIONAL_SIP_ACTIVE_36: 466,
          TEAMS_ADDITIONAL_SIP_STANDBY_36: 467,
          CXNOW_ADVANCED_USER: 468,
          CXNOW_BASE_PACKAGE: 469,
          SP_GAMMA_IP_HC_GAMMA_SIP: 470,
          SP_GAMMA_IP_DC_GAMMA_SIP: 471,
          MITEL_ASC_Essentials: 472,
          MITEL_ASC_Premier: 473,
          MITEL_ASC_Elite: 474,
          GENESYS_DIGITAL_2_ANNUALPREPAY_NAMED_DO_NOT_USE: 476,
          GENESYS_DIGITAL_2_ANNUALM2M_NAMED_DO_NOT_USE: 477,
          GENESYS_DIGITAL_2_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 478,
          GENESYS_DIGITAL_2_ANNUALM2M_CONCURRENT_DO_NOT_USE: 479,
          GENESYS_DIGITAL_3_ANNUALPREPAY_NAMED_DO_NOT_USE: 480,
          GENESYS_DIGITAL_3_ANNUALM2M_NAMED_DO_NOT_USE: 481,
          GENESYS_DIGITAL_3_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 482,
          GENESYS_DIGITAL_3_ANNUALM2M_CONCURRENT_DO_NOT_USE: 483,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_NAMED_DO_NOT_USE: 484,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_NAMED_DO_NOT_USE: 485,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 486,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_CONCURRENT_DO_NOT_USE: 487,
          GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_NAMED_DO_NOT_USE: 488,
          GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_NAMED_DO_NOT_USE: 489,
          GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 490,
          GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_CONCURRENT_DO_NOT_USE: 491,
          GENESYS_170_NV_USR2WEMI_ANNUALPREPAY_NAMED_DO_NOT_USE: 492,
          GENESYS_170_NV_USR2WEMI_ANNUALM2M_NAMED_DO_NOT_USE: 493,
          GENESYS_170_NV_USR2WEMIC_ANNUALPREPAY_CONCURRENT_DO_NOT_USE: 494,
          GENESYS_170_NV_USR2WEMIC_ANNUALM2M_CONCURRENT_DO_NOT_USE: 495,
          AURA_ASC_RECORDING_LICENSES: 496,
          AURA_ASC_QUALITY_MONITORING: 497,
          AURA_ASC_SPEECH_ANALYTICS_KEYWORD_SPOTTING: 498,
          AURA_ASC_SPEECH_ANALYTICS_TRANSCRIPTION: 499,
          AURA_ASC_SPEECH_ANALYTICS_ENGINE_ENABLING: 500,
          AURA_ASC_CONNECT_FOR_SALESFORCE: 501,
          AURA_ASC_CONTACT_CENTRE_INTEGRATION: 502,
          SYCURIO_ESSENTIALS_USER_TIER1_1YR: 503,
          SYCURIO_ESSENTIALS_USER_TIER2_1YR: 504,
          SYCURIO_ESSENTIALS_USER_TIER3_1YR: 505,
          SYCURIO_ESSENTIALS_USER_TIER4_1YR: 506,
          SYCURIO_ESSENTIALS_USER_TIER1_3YR: 507,
          SYCURIO_ESSENTIALS_USER_TIER2_3YR: 508,
          SYCURIO_ESSENTIALS_USER_TIER3_3YR: 509,
          SYCURIO_ESSENTIALS_USER_TIER4_3YR: 510,
          SYCURIO_ESSENTIALS_USER_TIER1_5YR: 511,
          SYCURIO_ESSENTIALS_USER_TIER2_5YR: 512,
          SYCURIO_ESSENTIALS_USER_TIER3_5YR: 513,
          SYCURIO_ESSENTIALS_USER_TIER4_5YR: 514,
          GENESYS_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1: 515,
          GENESYS_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2: 516,
          GENESYS_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1: 517,
          GENESYS_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2: 518,
          GENESYS_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1: 519,
          GENESYS_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2: 520,
          GENESYS_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1: 521,
          GENESYS_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2: 522,
          GENESYS_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1: 523,
          GENESYS_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2: 524,
          UC_ANALYTICS_FOUNDATION_USER: 525,
          UC_ANALYTICS_ESSENTIALS_USER_DO_NOT_USE: 526,
          UC_ANALYTICS_PLUS_USER_DO_NOT_USE: 527,
          UC_ANALYTICS_TAILORED_USER: 528,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_1YR_20_99_USERS: 529, // Avaya Axp Profiles
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_1YR_100_199_USERS: 530,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_1YR_200_399_USERS: 531,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_1YR_400_USERS: 532,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_3YR_20_99_USERS: 533,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_3YR_100_199_USERS: 534,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_3YR_200_399_USERS: 535,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_3YR_400_USERS: 536,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_5YR_20_99_USERS: 537,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_5YR_100_199_USERS: 538,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_5YR_200_399_USERS: 539,
          AVAYA_AXP_DIGITAL_ONLY_BUNDLE_5YR_400_USERS: 540,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_1YR_20_99_USERS: 541,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_1YR_100_199_USERS: 542,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_1YR_200_399_USERS: 543,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_1YR_400_USERS: 544,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_3YR_20_99_USERS: 545,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_3YR_100_199_USERS: 546,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_3YR_200_399_USERS: 547,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_3YR_400_USERS: 548,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_5YR_20_99_USERS: 549,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_5YR_100_199_USERS: 550,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_5YR_200_399_USERS: 551,
          AVAYA_AXP_VOICE_ONLY_BUNDLE_5YR_400_USERS: 552,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_1YR_20_99_USERS: 553,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_1YR_100_199_USERS: 554,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_1YR_200_399_USERS: 555,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_1YR_400_USERS: 556,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_3YR_20_99_USERS: 557,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_3YR_100_199_USERS: 558,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_3YR_200_399_USERS: 559,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_3YR_400_USERS: 560,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_5YR_20_99_USERS: 561,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_5YR_100_199_USERS: 562,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_5YR_200_399_USERS: 563,
          AVAYA_AXP_ALL_MEDIA_BUNDLE_5YR_400_USERS: 564,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_1YR_20_99_USERS: 565,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_1YR_100_199_USERS: 566,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_1YR_200_399_USERS: 567,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_1YR_400_USERS: 568,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_3YR_20_99_USERS: 569,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_3YR_100_199_USERS: 570,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_3YR_200_399_USERS: 571,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_3YR_400_USERS: 572,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_5YR_20_99_USERS: 573,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_5YR_100_199_USERS: 574,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_5YR_200_399_USERS: 575,
          AVAYA_AXP_CONNECT_VOICE_BUNDLE_5YR_400_USERS: 576,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_1YR_20_99_USERS: 577, // Avaya Axp AddOns
          AVAYA_AXP_WORKFORCE_MANAGEMENT_1YR_100_199_USERS: 578,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_1YR_200_399_USERS: 579,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_1YR_400_USERS: 580,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_3YR_20_99_USERS: 581,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_3YR_100_199_USERS: 582,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_3YR_200_399_USERS: 583,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_3YR_400_USERS: 584,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_5YR_20_99_USERS: 585,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_5YR_100_199_USERS: 586,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_5YR_200_399_USERS: 587,
          AVAYA_AXP_WORKFORCE_MANAGEMENT_5YR_400_USERS: 588,
          AVAYA_AXP_SCREEN_RECORDING_1YR_20_99_USERS: 589,
          AVAYA_AXP_SCREEN_RECORDING_1YR_100_199_USERS: 590,
          AVAYA_AXP_SCREEN_RECORDING_1YR_200_399_USERS: 591,
          AVAYA_AXP_SCREEN_RECORDING_1YR_400_USERS: 592,
          AVAYA_AXP_SCREEN_RECORDING_3YR_20_99_USERS: 593,
          AVAYA_AXP_SCREEN_RECORDING_3YR_100_199_USERS: 594,
          AVAYA_AXP_SCREEN_RECORDING_3YR_200_399_USERS: 595,
          AVAYA_AXP_SCREEN_RECORDING_3YR_400_USERS: 596,
          AVAYA_AXP_SCREEN_RECORDING_5YR_20_99_USERS: 597,
          AVAYA_AXP_SCREEN_RECORDING_5YR_100_199_USERS: 598,
          AVAYA_AXP_SCREEN_RECORDING_5YR_200_399_USERS: 599,
          AVAYA_AXP_SCREEN_RECORDING_5YR_400_USERS: 600,
          AVAYA_AXP_QUALITY_MANAGEMENT_1YR_20_99_USERS: 601,
          AVAYA_AXP_QUALITY_MANAGEMENT_1YR_100_199_USERS: 602,
          AVAYA_AXP_QUALITY_MANAGEMENT_1YR_200_399_USERS: 603,
          AVAYA_AXP_QUALITY_MANAGEMENT_1YR_400_USERS: 604,
          AVAYA_AXP_QUALITY_MANAGEMENT_3YR_20_99_USERS: 605,
          AVAYA_AXP_QUALITY_MANAGEMENT_3YR_100_199_USERS: 606,
          AVAYA_AXP_QUALITY_MANAGEMENT_3YR_200_399_USERS: 607,
          AVAYA_AXP_QUALITY_MANAGEMENT_3YR_400_USERS: 608,
          AVAYA_AXP_QUALITY_MANAGEMENT_5YR_20_99_USERS: 609,
          AVAYA_AXP_QUALITY_MANAGEMENT_5YR_100_199_USERS: 610,
          AVAYA_AXP_QUALITY_MANAGEMENT_5YR_200_399_USERS: 611,
          AVAYA_AXP_QUALITY_MANAGEMENT_5YR_400_USERS: 612,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_1YR_20_99_USERS: 613,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_1YR_100_199_USERS: 614,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_1YR_200_399_USERS: 615,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_1YR_400_USERS: 616,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_3YR_20_99_USERS: 617,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_3YR_100_199_USERS: 618,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_3YR_200_399_USERS: 619,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_3YR_400_USERS: 620,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_5YR_20_99_USERS: 621,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_5YR_100_199_USERS: 622,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_5YR_200_399_USERS: 623,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_5YR_400_USERS: 624,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_1YR_20_99_USERS: 625,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_1YR_100_199_USERS: 626,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_1YR_200_399_USERS: 627,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_1YR_400_USERS: 628,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_3YR_20_99_USERS: 629,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_3YR_100_199_USERS: 630,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_3YR_200_399_USERS: 631,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_3YR_400_USERS: 632,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_5YR_20_99_USERS: 633,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_5YR_100_199_USERS: 634,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_5YR_200_399_USERS: 635,
          AVAYA_AXP_AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE_5YR_400_USERS: 636,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_1YR_20_99_USERS: 637,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_1YR_100_199_USERS: 638,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_1YR_200_399_USERS: 639,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_1YR_400_USERS: 640,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_3YR_20_99_USERS: 641,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_3YR_100_199_USERS: 642,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_3YR_200_399_USERS: 643,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_3YR_400_USERS: 644,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_5YR_20_99_USERS: 645,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_5YR_100_199_USERS: 646,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_5YR_200_399_USERS: 647,
          AVAYA_AXP_SPEECH_ANALYTICS_BATCH_5YR_400_USERS: 648,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_1YR_20_99_USERS: 649,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_1YR_100_199_USERS: 650,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_1YR_200_399_USERS: 651,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_1YR_400_USERS: 652,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_3YR_20_99_USERS: 653,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_3YR_100_199_USERS: 654,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_3YR_200_399_USERS: 655,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_3YR_400_USERS: 656,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_5YR_20_99_USERS: 657,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_5YR_100_199_USERS: 658,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_5YR_200_399_USERS: 659,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE_5YR_400_USERS: 660,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_1YR_20_99_USERS: 661,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_1YR_100_199_USERS: 662,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_1YR_200_399_USERS: 663,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_1YR_400_USERS: 664,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_3YR_20_99_USERS: 665,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_3YR_100_199_USERS: 666,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_3YR_200_399_USERS: 667,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_3YR_400_USERS: 668,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_5YR_20_99_USERS: 669,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_5YR_100_199_USERS: 670,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_5YR_200_399_USERS: 671,
          AVAYA_AXP_SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS_5YR_400_USERS: 672,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_1YR_20_99_USERS: 673,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_1YR_100_199_USERS: 674,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_1YR_200_399_USERS: 675,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_1YR_400_USERS: 676,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_3YR_20_99_USERS: 677,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_3YR_100_199_USERS: 678,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_3YR_200_399_USERS: 679,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_3YR_400_USERS: 680,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_5YR_20_99_USERS: 681,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_5YR_100_199_USERS: 682,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_5YR_200_399_USERS: 683,
          AVAYA_AXP_STRATEGIC_DESKTOP_ANALYTICS_5YR_400_USERS: 684,
          AVAYA_AXP_COMPLIANCE_TRIGGER_1YR_20_99_USERS: 685,
          AVAYA_AXP_COMPLIANCE_TRIGGER_1YR_100_199_USERS: 686,
          AVAYA_AXP_COMPLIANCE_TRIGGER_1YR_200_399_USERS: 687,
          AVAYA_AXP_COMPLIANCE_TRIGGER_1YR_400_USERS: 688,
          AVAYA_AXP_COMPLIANCE_TRIGGER_3YR_20_99_USERS: 689,
          AVAYA_AXP_COMPLIANCE_TRIGGER_3YR_100_199_USERS: 690,
          AVAYA_AXP_COMPLIANCE_TRIGGER_3YR_200_399_USERS: 691,
          AVAYA_AXP_COMPLIANCE_TRIGGER_3YR_400_USERS: 692,
          AVAYA_AXP_COMPLIANCE_TRIGGER_5YR_20_99_USERS: 693,
          AVAYA_AXP_COMPLIANCE_TRIGGER_5YR_100_199_USERS: 694,
          AVAYA_AXP_COMPLIANCE_TRIGGER_5YR_200_399_USERS: 695,
          AVAYA_AXP_COMPLIANCE_TRIGGER_5YR_400_USERS: 696,
          AVAYA_AXP_APPLICATION_VISUALIZATION_1YR_20_99_USERS: 697,
          AVAYA_AXP_APPLICATION_VISUALIZATION_1YR_100_199_USERS: 698,
          AVAYA_AXP_APPLICATION_VISUALIZATION_1YR_200_399_USERS: 699,
          AVAYA_AXP_APPLICATION_VISUALIZATION_1YR_400_USERS: 700,
          AVAYA_AXP_APPLICATION_VISUALIZATION_3YR_20_99_USERS: 701,
          AVAYA_AXP_APPLICATION_VISUALIZATION_3YR_100_199_USERS: 702,
          AVAYA_AXP_APPLICATION_VISUALIZATION_3YR_200_399_USERS: 703,
          AVAYA_AXP_APPLICATION_VISUALIZATION_3YR_400_USERS: 704,
          AVAYA_AXP_APPLICATION_VISUALIZATION_5YR_20_99_USERS: 705,
          AVAYA_AXP_APPLICATION_VISUALIZATION_5YR_100_199_USERS: 706,
          AVAYA_AXP_APPLICATION_VISUALIZATION_5YR_200_399_USERS: 707,
          AVAYA_AXP_APPLICATION_VISUALIZATION_5YR_400_USERS: 708,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_1YR_20_99_USERS: 709,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_1YR_100_199_USERS: 710,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_1YR_200_399_USERS: 711,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_1YR_400_USERS: 712,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_3YR_20_99_USERS: 713,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_3YR_100_199_USERS: 714,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_3YR_200_399_USERS: 715,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_3YR_400_USERS: 716,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_5YR_20_99_USERS: 717,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_5YR_100_199_USERS: 718,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_5YR_200_399_USERS: 719,
          AVAYA_AXP_EACH_ADDITIONAL_1TB_STORAGE_5YR_400_USERS: 720,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_1YR_20_99_USERS: 721,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_1YR_100_199_USERS: 722,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_1YR_200_399_USERS: 723,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_1YR_400_USERS: 724,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_3YR_20_99_USERS: 725,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_3YR_100_199_USERS: 726,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_3YR_200_399_USERS: 727,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_3YR_400_USERS: 728,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_5YR_20_99_USERS: 729,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_5YR_100_199_USERS: 730,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_5YR_200_399_USERS: 731,
          AVAYA_AXP_INTERACTION_DATA_EXPORT_MANAGER_5YR_400_USERS: 732,
          AVAYA_AXP_AXP_MESSAGING_10K_MSGS_EA_MONTH_FIXED_SUBS: 733,
          AVAYA_AXP_AXP_MESSAGING_70K_MSGS_EA_MONTH_FIXED_SUBS: 734,
          AVAYA_AXP_AXP_MESSAGING_300K_MSGS_EA_MO_FIXED_SUBS: 735,
          AVAYA_AXP_AXP_MESSAGING_1000K_MSGS_EA_MO_FIXED_SUBS: 736,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1: 737,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2: 738,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1: 739,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2: 740,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1: 741,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2: 742,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1: 743,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2: 744,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1: 745,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2: 746,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1: 747,
          AVAYA_AXP_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2: 748,
          UC_ANALYTICS_FOUNDATION_USER_QUOTE_ONLY: 749,
          UC_ANALYTICS_ESSENTIALS_USER: 750,
          UC_ANALYTICS_PLUS_USER: 751,
          PHONEPLUS_1YEAR_DISCOUNT: 752,
          PHONEPLUS_3YEAR_DISCOUNT: 753,
          PHONEPLUS_1YEAR: 754,
          PHONEPLUS_3YEAR: 755,
          GENESYS_COMMUNICATE_ANNUALPREPAY_NAMED: 756,
          GENESYS_COMMUNICATE_ANNUALPREPAY_CONCURRENT: 757,
          GENESYS_COMMUNICATE_ANNUALPREPAY_HOURLY: 758,
          GENESYS_COMMUNICATE_ANNUALM2M_NAMED: 759,
          GENESYS_COMMUNICATE_ANNUALM2M_CONCURRENT: 760,
          GENESYS_COMMUNICATE_ANNUALM2M_HOURLY: 761,
          GENESYS_COMMUNICATE_M2M_NAMED: 762,
          GENESYS_COMMUNICATE_M2M_CONCURRENT: 763,
          GENESYS_COMMUNICATE_M2M_HOURLY: 764,
          GENESYS_PURECLOUD1_ANNUALPREPAY_NAMED: 765,
          GENESYS_PURECLOUD1_ANNUALPREPAY_CONCURRENT: 766,
          GENESYS_PURECLOUD1_ANNUALPREPAY_HOURLY: 767,
          GENESYS_PURECLOUD1_ANNUALM2M_NAMED: 768,
          GENESYS_PURECLOUD1_ANNUALM2M_CONCURRENT: 769,
          GENESYS_PURECLOUD1_ANNUALM2M_HOURLY: 770,
          GENESYS_PURECLOUD1_M2M_NAMED: 771,
          GENESYS_PURECLOUD1_M2M_CONCURRENT: 772,
          GENESYS_PURECLOUD1_M2M_HOURLY: 773,
          GENESYS_PURECLOUD2_ANNUALPREPAY_NAMED: 774,
          GENESYS_PURECLOUD2_ANNUALPREPAY_CONCURRENT: 775,
          GENESYS_PURECLOUD2_ANNUALPREPAY_HOURLY: 776,
          GENESYS_PURECLOUD2_ANNUALM2M_NAMED: 777,
          GENESYS_PURECLOUD2_ANNUALM2M_CONCURRENT: 778,
          GENESYS_PURECLOUD2_ANNUALM2M_HOURLY: 779,
          GENESYS_PURECLOUD2_M2M_NAMED: 780,
          GENESYS_PURECLOUD2_M2M_CONCURRENT: 781,
          GENESYS_PURECLOUD2_M2M_HOURLY: 782,
          GENESYS_PURECLOUD3_ANNUALPREPAY_NAMED: 783,
          GENESYS_PURECLOUD3_ANNUALPREPAY_CONCURRENT: 784,
          GENESYS_PURECLOUD3_ANNUALPREPAY_HOURLY: 785,
          GENESYS_PURECLOUD3_ANNUALM2M_NAMED: 786,
          GENESYS_PURECLOUD3_ANNUALM2M_CONCURRENT: 787,
          GENESYS_PURECLOUD3_ANNUALM2M_HOURLY: 788,
          GENESYS_PURECLOUD3_M2M_NAMED: 789,
          GENESYS_PURECLOUD3_M2M_CONCURRENT: 790,
          GENESYS_PURECLOUD3_M2M_HOURLY: 791,
          GENESYS_MSTEAMS_ANNUALPREPAY_NAMED: 792,
          GENESYS_MSTEAMS_ANNUALPREPAY_CONCURRENT: 793,
          GENESYS_MSTEAMS_ANNUALM2M_NAMED: 794,
          GENESYS_MSTEAMS_ANNUALM2M_CONCURRENT: 795,
          GENESYS_ZOOM_INT_ANNUALPREPAY_NAMED: 796,
          GENESYS_ZOOM_INT_ANNUALPREPAY_CONCURRENT: 797,
          GENESYS_ZOOM_INT_ANNUALM2M_NAMED: 798,
          GENESYS_ZOOM_INT_ANNUALM2M_CONCURRENT: 799,
          GENESYS_PURECLOUD_WALLBOARD_ANNUALPREPAY: 800,
          GENESYS_PURECLOUD_WALLBOARD_ANNUALM2M: 801,
          GENESYS_170_NV_USR1WEMII_ANNUALPREPAY_NAMED: 802,
          GENESYS_170_NV_USR1WEMIIC_ANNUALPREPAY_CONCURRENT: 803,
          GENESYS_170_NV_USR1WEMII_ANNUALM2M_NAMED: 804,
          GENESYS_170_NV_USR1WEMIIC_ANNUALM2M_CONCURRENT: 805,
          GENESYS_170_NV_USR1WEMII_M2M_NAMED: 806,
          GENESYS_170_NV_USR1WEMIIC_M2M_CONCURRENT: 807,
          GENESYS_170_NV_USR1WEMI_ANNUALPREPAY_NAMED: 808,
          GENESYS_170_NV_USR1WEMIC_ANNUALPREPAY_CONCURRENT: 809,
          GENESYS_170_NV_USR1WEMI_ANNUALM2M_NAMED: 810,
          GENESYS_170_NV_USR1WEMIC_ANNUALM2M_CONCURRENT: 811,
          GENESYS_170_NV_USR1WEMI_M2M_NAMED: 812,
          GENESYS_170_NV_USR1WEMIC_M2M_CONCURRENT: 813,
          GENESYS_PC_170_NV_USR1DIGI_ANNUALPREPAY_NAMED: 814,
          GENESYS_PC_170_NV_USR1DIGIC_ANNUALPREPAY_CONCURRENT: 815,
          GENESYS_PC_170_NV_USR1DIGI_ANNUALM2M_NAMED: 816,
          GENESYS_PC_170_NV_USR1DIGIC_ANNUALM2M_CONCURRENT: 817,
          GENESYS_PC_170_NV_USR1DIGI_M2M_NAMED: 818,
          GENESYS_PC_170_NV_USR1DIGIC_M2M_CONCURRENT: 819,
          GENESYS_PC_170_NV_USR1DIGII_ANNUALPREPAY_NAMED: 820,
          GENESYS_PC_170_NV_USR1DIGIIC_ANNUALPREPAY_CONCURRENT: 821,
          GENESYS_PC_170_NV_USR1DIGII_ANNUALM2M_NAMED: 822,
          GENESYS_PC_170_NV_USR1DIGIIC_ANNUALM2M_CONCURRENT: 823,
          GENESYS_PC_170_NV_USR1DIGII_M2M_NAMED: 824,
          GENESYS_PC_170_NV_USR1DIGIIC_M2M_CONCURRENT: 825,
          GENESYS_PC_170_NV_USR2DIGI_ANNUALPREPAY_NAMED: 826,
          GENESYS_PC_170_NV_USR2DIGIC_ANNUALPREPAY_CONCURRENT: 827,
          GENESYS_PC_170_NV_USR2DIGI_ANNUALM2M_NAMED: 828,
          GENESYS_PC_170_NV_USR2DIGIC_ANNUALM2M_CONCURRENT: 829,
          GENESYS_PC_170_NV_USR2DIGI_M2M_NAMED: 830,
          GENESYS_PC_170_NV_USR2DIGIC_M2M_CONCURRENT: 831,
          GENESYS_PC_170_NV_PCSFAN_ANNUALPREPAY_NAMED: 832,
          GENESYS_PC_170_NV_PCSFANC_ANNUALPREPAY_CONCURRENT: 833,
          GENESYS_PC_170_NV_PCSFAN_ANNUALM2M_NAMED: 834,
          GENESYS_PC_170_NV_PCSFANC_ANNUALM2M_CONCURRENT: 835,
          GENESYS_PC_170_NV_PCSFAN_M2M_NAMED: 836,
          GENESYS_PC_170_NV_PCSFANC_M2M_CONCURRENT: 837,
          GENESYS_PC_170_NV_PCSFCAN_ANNUALPREPAY_NAMED: 838,
          GENESYS_PC_170_NV_PCSFCAN_ANNUALM2M_NAMED: 839,
          GENESYS_PC_170_NV_PCSFCAN_M2M_NAMED: 840,
          GENESYS_DIGITAL_2_ANNUALPREPAY_NAMED: 841,
          GENESYS_DIGITAL_2_ANNUALM2M_NAMED: 842,
          GENESYS_DIGITAL_2_ANNUALPREPAY_CONCURRENT: 843,
          GENESYS_DIGITAL_2_ANNUALM2M_CONCURRENT: 844,
          GENESYS_DIGITAL_3_ANNUALPREPAY_NAMED: 845,
          GENESYS_DIGITAL_3_ANNUALM2M_NAMED: 846,
          GENESYS_DIGITAL_3_ANNUALPREPAY_CONCURRENT: 847,
          GENESYS_DIGITAL_3_ANNUALM2M_CONCURRENT: 848,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_NAMED: 849,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_NAMED: 850,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALPREPAY_CONCURRENT: 851,
          GENESYS_CX_AI_EXPERIENCE_FOR_DIGITAL_ANNUALM2M_CONCURRENT: 852,
          GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_NAMED: 853,
          GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_NAMED: 854,
          GENESYS_CX_AI_EXPERIENCE_ANNUALPREPAY_CONCURRENT: 855,
          GENESYS_CX_AI_EXPERIENCE_ANNUALM2M_CONCURRENT: 856,
          GENESYS_170_NV_USR2WEMI_ANNUALPREPAY_NAMED: 857,
          GENESYS_170_NV_USR2WEMI_ANNUALM2M_NAMED: 858,
          GENESYS_170_NV_USR2WEMIC_ANNUALPREPAY_CONCURRENT: 859,
          GENESYS_170_NV_USR2WEMIC_ANNUALM2M_CONCURRENT: 860,
          RINGCENTRAL_UC_BASIC_USER: 861,
          RINGCENTRAL_UC_ESSENTIAL_USER: 862,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER1: 863,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER1: 864,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER1: 865,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER2: 866,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER2: 867,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER2: 868,
          RINGCENTRAL_UC_STANDARD_USER_ANNUAL_TIER3: 869,
          RINGCENTRAL_UC_PREMIUM_USER_ANNUAL_TIER3: 870,
          RINGCENTRAL_UC_ULTIMATE_USER_ANNUAL_TIER3: 871,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER1: 872,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER1: 873,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER1: 874,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER2: 875,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER2: 876,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER2: 877,
          RINGCENTRAL_UC_STANDARD_USER_MONTHLY_TIER3: 878,
          RINGCENTRAL_UC_PREMIUM_USER_MONTHLY_TIER3: 879,
          RINGCENTRAL_UC_ULTIMATE_USER_MONTHLY_TIER3: 880,
          RINGCENTRAL_UC_CXNOW_INT: 881,
          RINGCENTRAL_UC_LIVE_REPORTS: 882,
          RINGCENTRAL_UC_INVIEW_MGT: 883,
          RINGCENTRAL_UC_ADD_NUMBER: 884,
          RINGCENTRAL_UC_MSFT_DYNAMICS: 885,
          RINGCENTRAL_CC_DIGITAL_AGENT: 886,
          RINGCENTRAL_CC_ESSENTIALS_AGENT: 887,
          RINGCENTRAL_CC_STANDARD_AGENT: 888,
          RINGCENTRAL_CC_SURVEY_MNGR: 889,
          RINGCENTRAL_CC_WORKFORCE_MGT_PRO: 890,
          RINGCENTRAL_CC_QUALITY_MGT_PRO: 891,
          RINGCENTRAL_CC_SCREEN_REC_PRO: 892,
          RINGCENTRAL_CC_QTY_MGT_ANLY_PRO: 893,
          RINGCENTRAL_CC_OMNI_ANLY_PRO: 894,
          RINGCENTRAL_CC_SF_AGT: 895,
          RINGCENTRAL_CC_ZEN_AGENT: 896,
          RINGCENTRAL_CC_PREMIUM_AGENT: 899,
          RINGCENTRAL_CC_ULTIMATE_AGENT: 900,
          RINGCENTRAL_CC_UNLIMITED_AGENT: 901,
          SIP_ACTIVE_36: 902,
          SIP_STANDBY_36: 903,
          SIP_ACTIVE_12: 904,
          SIP_STANDBY_12: 905,
          NETCALL_12MONTH: 906,
          NETCALL_36MONTH: 907,
          NETCALL_60MONTH: 908,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER1: 909,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_5USERSPERCHANNEL_TIER2: 910,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER1: 911,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1USERPERCHANNEL_TIER2: 912,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER1: 913,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_3USERSPERCHANNEL_TIER2: 914,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER1: 915,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_1_2_USERSPERCHANNEL_TIER2: 916,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER1: 917,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_7USERSPERCHANNEL_TIER2: 918,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER1: 919,
          RINGCENTRAL_TEAMS_SUBSCRIPTION_USER_10USERSPERCHANNEL_TIER2: 920,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_1001_PLUS: 921,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_2001_PLUS: 922,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_3001_PLUS: 923,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_4001_PLUS: 924,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_5001_PLUS: 925,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_6001_PLUS: 926,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_7001_PLUS: 927,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_8001_PLUS: 928,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_9001_PLUS: 929,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_11001_PLUS: 930,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_12001_PLUS: 931,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_13001_PLUS: 932,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_14001_PLUS: 933,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_15001_PLUS: 934,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_16001_PLUS: 935,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_17001_PLUS: 936,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_18001_PLUS: 937,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_19001_PLUS: 938,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_20001_PLUS: 939,
          MITELUC_E2_UC_TELEPHONY_USER_1M: 940,
          MITELUC_E2_UC_TELEPHONY_USER_12M: 941,
          MITELUC_E2_UC_TELEPHONY_USER_36M: 942,
          MITELUC_E2_UC_TELEPHONY_USER_60M: 943,
          MITELUC_E2_UC_ENTRY_USER_1M: 944,
          MITELUC_E2_UC_ENTRY_USER_12M: 945,
          MITELUC_E2_UC_ENTRY_USER_36M: 946,
          MITELUC_E2_UC_ENTRY_USER_60M: 947,
          MITELUC_E2_UC_PREMIER_USER_1M: 948,
          MITELUC_E2_UC_PREMIER_USER_12M: 949,
          MITELUC_E2_UC_PREMIER_USER_36M: 950,
          MITELUC_E2_UC_PREMIER_USER_60M: 951,
          MITELUC_E2_UC_ELITE_CX_USER_1M: 952,
          MITELUC_E2_UC_ELITE_CX_USER_12M: 953,
          MITELUC_E2_UC_ELITE_CX_USER_36M: 954,
          MITELUC_E2_UC_ELITE_CX_USER_60M: 955,
          MITELUC_E2_UC_HOSP_GUESTROOM_1M: 956,
          MITELUC_E2_UC_HOSP_GUESTROOM_12M: 957,
          MITELUC_E2_UC_HOSP_GUESTROOM_36M: 958,
          MITELUC_E2_UC_HOSP_GUESTROOM_60M: 959,
          MITELUC_E2_UC_HOSP_STAFFMEMBER_1M: 960,
          MITELUC_E2_UC_HOSP_STAFFMEMBER_12M: 961,
          MITELUC_E2_UC_HOSP_STAFFMEMBER_36M: 962,
          MITELUC_E2_UC_HOSP_STAFFMEMBER_60M: 963,
          MITELUC_E2_UC_SRC_3RD_PARTY_RECORD_1M: 964,
          MITELUC_E2_UC_SRC_3RD_PARTY_RECORD_12M: 965,
          MITELUC_E2_UC_SRC_3RD_PARTY_RECORD_36M: 966,
          MITELUC_E2_UC_SRC_3RD_PARTY_RECORD_60M: 967,
          MITELUC_E2_CC_BUSINESS_REPORTER_CLIENT_1M: 968,
          MITELUC_E2_CC_BUSINESS_REPORTER_CLIENT_12M: 969,
          MITELUC_E2_CC_BUSINESS_REPORTER_CLIENT_36M: 970,
          MITELUC_E2_CC_BUSINESS_REPORTER_CLIENT_60M: 971,
          MITELUC_E2_CC_SYSTEM_ADMIN_1M: 972,
          MITELUC_E2_CC_SYSTEM_ADMIN_12M: 973,
          MITELUC_E2_CC_SYSTEM_ADMIN_36M: 974,
          MITELUC_E2_CC_SYSTEM_ADMIN_60M: 975,
          MITELUC_E2_CC_SOFTPHONE_1M: 976,
          MITELUC_E2_CC_SOFTPHONE_12M: 977,
          MITELUC_E2_CC_SOFTPHONE_36M: 978,
          MITELUC_E2_CC_SOFTPHONE_60M: 979,
          MITELUC_E2_CC_MULTIMEDIA_AGENT_1M: 980,
          MITELUC_E2_CC_MULTIMEDIA_AGENT_12M: 981,
          MITELUC_E2_CC_MULTIMEDIA_AGENT_36M: 982,
          MITELUC_E2_CC_MULTIMEDIA_AGENT_60M: 983,
          MITELUC_E2_CC_SCHEDULING_AGENT_1M: 984,
          MITELUC_E2_CC_SCHEDULING_AGENT_12M: 985,
          MITELUC_E2_CC_SCHEDULING_AGENT_36M: 986,
          MITELUC_E2_CC_SCHEDULING_AGENT_60M: 987,
          MITELUC_E2_CC_IVR_PORT_1M: 988,
          MITELUC_E2_CC_IVR_PORT_12M: 989,
          MITELUC_E2_CC_IVR_PORT_36M: 990,
          MITELUC_E2_CC_IVR_PORT_60M: 991,
          MITELUC_E2_CC_REMOTE_REDUN_IVR_STARTER_PACK_1M: 992,
          MITELUC_E2_CC_REMOTE_REDUN_IVR_STARTER_PACK_12M: 993,
          MITELUC_E2_CC_REMOTE_REDUN_IVR_STARTER_PACK_36M: 994,
          MITELUC_E2_CC_REMOTE_REDUN_IVR_STARTER_PACK_60M: 995,
          MITELUC_E2_RECORDING_ESSENTIALS_1M: 996,
          MITELUC_E2_RECORDING_ESSENTIALS_12M: 997,
          MITELUC_E2_RECORDING_ESSENTIALS_36M: 998,
          MITELUC_E2_RECORDING_ESSENTIALS_60M: 999,
          MITELUC_E2_RECORDING_PREMIER_1M: 1000,
          MITELUC_E2_RECORDING_PREMIER_12M: 1001,
          MITELUC_E2_RECORDING_PREMIER_36M: 1002,
          MITELUC_E2_RECORDING_PREMIER_60M: 1003,
          MITELUC_E2_RECORDING_ELITE_1M: 1004,
          MITELUC_E2_RECORDING_ELITE_12M: 1005,
          MITELUC_E2_RECORDING_ELITE_36M: 1006,
          MITELUC_E2_RECORDING_ELITE_60M: 1007,
          MITELUC_E2_RECORDING_PARALLEL_1M: 1008,
          MITELUC_E2_RECORDING_PARALLEL_12M: 1009,
          MITELUC_E2_RECORDING_PARALLEL_36M: 1010,
          MITELUC_E2_RECORDING_PARALLEL_60M: 1011,
          SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_10001_PLUS: 1012 /* Isn't in sequence, because was missed on original SQL insert */
        }),
        mitelUCE2userProfiles: Object.freeze({
          TELEPHONY: 82,
          ENTRY_UC: 83,
          PREMIER_UC: 84,
          ELITE_CX: 85,
          HOSPITALITY_GUESTROOM: 86,
          HOSPITALITY_STAFF_MEMBER: 87,
          BUSINESS_REPORTER_CLIENT: 88,
          ADDITIONAL_SYSTEM_ADMIN: 89,
          SOFTPHONE_CLIENT: 90,
          MULTIMEDIA_AGENT: 91,
          SCHEDULING_AGENT: 92,
          IVR_PORT: 93,
          REMOTE_REDUNDANT_IVR_ROUTING_ADD_ON: 94,
          ESSENTIALS: 95,
          PREMIER: 96,
          ELITE: 97,
          PARALLEL: 98
        }),
        genesysUserProfiles: Object.freeze({
          GC_COMMUNICATE: 23,
          GC_PURECLOUD_1: 24,
          GC_PURECLOUD_2: 25,
          GC_PURECLOUD_3: 26,
          GENESYS_1_USER_WEM_UPGRADE_II: 27,
          GENESYS_2_USER_WEM_UPGRADE_I: 28,
          PURECLOUD_USER_1_DIGITAL_UPGRADE_I: 29,
          PURECLOUD_USER_1_DIGITAL_UPGRADE_II: 30,
          PURECLOUD_USER_2_DIGITAL_UPGRADE_I: 31,
          MICROSOFT_TEAMS_INTEGRATION: 32,
          PURECLOUD_FOR_WALLBOARD_DEVICE_CHARGE: 33,
          PURECLOUD_FOR_SALESFORCE_ADD_ON: 34,
          PURECLOUD_FOR_SALESFORCE_COMMUNICATE_ADD_ON: 35,
          GC_DIGITAL_2: 61,
          GC_DIGITAL_3: 62,
          GC_CX_AI_EXPERIENCE_FOR_DIGITAL: 63,
          GC_CX_AI_EXPERIENCE: 64
        }),
        genesysPaymentTerms: Object.freeze({
          ANNUALPREPAY: 1,
          ANNUALM2M: 2,
          M2M: 3
        }),
        genesysUserTypes: Object.freeze({
          NAMED: 1,
          CONCURRENT: 2,
          HOURLY: 3
        }),
        genesysManagedServiceLevels: Object.freeze({
          STANDARD: 1,
          PREMIUM: 2
        }),
        hardware: Object.freeze({
          AVAYA_HANDSET_J129: 1,
          AVAYA_HANDSET_J139: 2,
          AVAYA_HANDSET_J169: 3,
          AVAYA_HANDSET_J179: 4
        }),
        unitCosts: Object.freeze({
          ICON_REP_UC_SUP: 1,
          ICON_REP_CC_SUP: 2,
          ICON_VM_01: 3,
          ICON_OS_WIN_UC: 4,
          ICON_OS_WIN_CC: 5,
          ICON_DB_SQL_UC: 6,
          ICON_DB_SQL_CC: 7,
          ICON_MNT_01: 8,
          ICON_DC_01: 9,
          ICON_PS_IOH_01: 10,
          ICON_PS_OOH_01: 11,
          ICON_BAK_01_SUP: 12,
          ICON_BLD_STD_01_SUP: 13,
          ICON_BLD_HPR_01_SUP: 14,
          ICON_CHA_01_SUP: 15,
          ICON_CHA_01: 16,
          ICON_SRG_FST_UC: 17,
          ICON_SRG_FST_CC: 18,
          ICON_SRG_BAK_UC: 19,
          ICON_SRG_BAK_CC: 20,
          ICON_SRG_ARC_UC: 21,
          ICON_SRG_ARC_CC: 22,
          ICON_BAK_UC: 23,
          ICON_BAK_CC: 24,
          ICON_REP_UC: 25,
          ICON_REP_CC: 26,
          SIP_SETUP_RESILIENT: 27,
          ICON_INF_ILC: 28,
          CXNOW_START_SMALL: 29,
          CXNOW_START_MEDIUM: 30,
          CXNOW_START_LARGE: 31,
          CXNOW_DIGITAL_SMALL: 32,
          CXNOW_DIGITAL_MEDIUM: 33,
          CXNOW_DIGITAL_LARGE: 34,
          CXNOW_CRM_SF_STD: 35,
          CXNOW_CRM_SF_CUSTOM: 36,
          CXNOW_CRM_MS_365_STD: 37,
          CXNOW_CRM_MS_365_CUSTOM: 38,
          CXNOW_CRM_MS_PREMISE_STD: 39,
          CXNOW_CRM_MS_PREMISE_CUSTOM: 40,
          CXNOW_CHATBOT_STANDALONE: 41,
          CXNOW_CHATBOT_INTEGRATION: 42,
          CXNOW_GAMMA_SIP_SETUP: 43,
          CXNOW_CCIS_SERVER_SMALL: 44,
          CXNOW_CCIS_SERVER_MEDIUM: 45,
          CXNOW_CCIS_SERVER_LARGE: 46,
          ICON_PORTAL: 47,
          AVAYA_USAGE_MONITORING: 48,
          ICON_COMMUNICATE_SBC_PORT_CHARGE: 49,
          ICON_GATEWAY_FAREHAM_RACK: 50,
          ICON_GATEWAY_GOSWELL_RACK: 51,
          ICON_GATEWAY_FAREHAM_LINK: 52,
          ICON_GATEWAY_GOSWELL_LINK: 53,
          ICON_GATEWAY_FAREHAM_WAN_DUAL_FEED: 54,
          ICON_GATEWAY_GOSWELL_WAN_DUAL_FEED: 55,
          ICON_GATEWAY_FAREHAM_WAN_GOLD: 56,
          ICON_GATEWAY_GOSWELL_WAN_GOLD: 57,
          ICON_GATEWAY_FAREHAM_WAN_SILVER: 58,
          ICON_GATEWAY_GOSWELL_WAN_SILVER: 59,
          ICON_GATEWAY_FAREHAM_WAN_BRONZE: 60,
          ICON_GATEWAY_GOSWELL_WAN_BRONZE: 61,
          CXNOW_PS_PROVISIONING: 62,
          CXNOW_PS_PROJECT_COORDINATOR: 63,
          CXNOW_PS_APPLICATIONS_CONSULTANT: 64,
          CXNOW_PS_PRODUCT_SPECIALIST: 65,
          CXNOW_PS_PRODUCT_SPECIALIST_HALFDAY: 66,
          CXNOW_PS_START_BUNDLE: 67,
          CXNOW_PS_DIGITAL_BUNDLE: 68,
          SIP_GAMMA_ML_GREATER_THAN_10_PORT: 69,
          SIP_GAMMA_OOH_EVE_PORT: 70,
          SIP_GAMMA_OOH_SAT_PORT: 71,
          SIP_CPE_STANDARD: 73,
          SIP_CPE_RESILIENT: 74,
          SIP_CORE_BANDWIDTH_STANDARD_UPFRONT_ICON_COMMUNICATE: 75,
          SIP_CORE_BANDWIDTH_RESILIENT_UPFRONT_ICON_COMMUNICATE: 76,
          SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_ICON_COMMUNICATE: 77,
          SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_ICON_COMMUNICATE: 78,
          SIP_PS_PROJECT_MANAGEMENT: 79,
          SIP_PS_PROFESSIONAL_SERVICES: 80,
          SIP_STCM_INSTALL: 81,
          SIP_HELPDESK_INTERNAL: 82,
          SIP_HELPDESK_EXTERNAL: 83,
          SIP_ACCESS_GOLD: 84,
          SIP_ACCESS_SILVER: 85,
          SIP_ACCESS_GOLD_PLUS_PLUS: 86,
          SIP_ACCESS_GOLD_PLUS: 87,
          SIP_ACCESS_SILVER_PLUS: 88,
          ICON_SIP_SBC_PORT_CHARGE: 89,
          SIP_GAMMA_NUMBER: 90,
          SIP_TRUNK_CALL_MANAGER_CHARGE: 91,
          SIP_CPE_ENABLE_2ND_PRI: 92,
          SIP_SETUP_STANDARD: 93,
          SIP_TTB_SETUP: 94,
          SIP_TTB_NUMBER: 95,
          SIP_TTB_SL_PORT: 96,
          SIP_TTB_ML_PORT: 97,
          SIP_TTB_ML_LESS_THAN_11_PORT: 98,
          SIP_TTB_ML_GREATER_THAN_10_PORT: 99,
          SIP_TTB_OOH_EVE_PORT: 100,
          SIP_TTB_OOH_SAT_PORT: 101,
          SIP_TTB_OOH_EVE_MIG: 102,
          SIP_TTB_OOH_SAT_MIG: 103,
          SIP_TRUNK_CALL_MANAGER_FREE: 104,
          TEAMS_PROJECT_COORDINATOR: 105,
          TEAMS_PROJECT_COORDINATION_OOH: 106,
          TEAMS_ENGINEER_LEVEL_B: 107,
          TEAMS_ENGINEERING_LEVEL_A: 108,
          TEAMS_ICON_BANDWIDTH_CONTRIBUTION: 109,
          TEAMS_MAINTEL_SBC_SUPPORT: 110,
          TEAMS_NEW_DDI: 111,
          TEAMS_SIP_SETUP: 112,
          TEAMS_SBC_PORT_CHARGE: 113,
          CXNOW_TALKATIVE_PRO_SERVICES: 114,
          CXNOW_PRO_SERVICES: 115,
          PHONEPLUS_CISCO_ATA: 116,
          PHONEPLUS_CISCO_PA100: 117,
          PHONEPLUS_PLANTRONICS_BLACKWIRE: 118,
          PHONEPLUS_POLYVOYAGER_5200: 119,
          PHONEPLUS_SENNHEISER_SC165: 120,
          PHONEPLUS_POLYCOM_VVX150: 121,
          PHONEPLUS_POLYCOM_VVX250: 122,
          PHONEPLUS_POLYCOM_VVX450: 123,
          PHONEPLUS_POLYCOM_POWER: 124,
          PHONEPLUS_DELIVERY_BAND1: 125,
          PHONEPLUS_DELIVERY_BAND2: 126,
          PHONEPLUS_DELIVERY_BAND3: 127,
          PHONEPLUS_DELIVERY_BAND4: 128,
          PHONEPLUS_DELIVERY_BAND5: 129,
          PHONEPLUS_DELIVERY_BAND6: 130,
          PHONEPLUS_DELIVERY_BAND7: 131,
          PHONEPLUS_DELIVERY_BAND8: 132,
          PHONEPLUS_NUMBER_PORTING: 133,
          PHONEPLUS_PORTING_WEEKDAY_EVE: 134,
          PHONEPLUS_PORTING_SATURDAY: 135,
          ICON_SEC_CERTIFICATE_RECURRING: 136,
          PHONEPLUS_PS_IOH_01: 137,
          PHONEPLUS_PS_OOH_01: 138,
          GENESYS_CSM: 139,
          GENESYS_MANAGEDSERVICES: 140,
          TEAMS_REC_ONBOARDING: 141,
          TEAMS_REC_TENANT_ACTIVATION: 142,
          SIP_GAMMA_SEMA_SETUP_UNDER_150: 143,
          SIP_GAMMA_SEMA_SETUP_150_PLUS: 144,
          SIPMIGRATION_EDGE_6000_901_C2E: 145,
          SIPMIGRATION_EDGE_PC_UK: 146,
          SIPMIGRATION_SHIPMENT: 147,
          SIPMIGRATION_VRVMS_902_C2E: 148,
          SIPMIGRATION_C2E_USAGE_100_SITE: 149,
          SIPMIGRATION_C2E_USAGE_1_100_SURV: 150,
          SIPMIGRATION_C2E_HELPDESK_SUPPORT: 151,
          SIPMIGRATION_MAINTEL_SUPPORT_SERVICE_MONTHLY: 152,
          SIP_STCM_SERVICE: 153,
          GENESYS_BYOC_CHARGE: 154,
          TEAMS_ENGINEERING_LEVEL_B_HALF_DAY: 155,
          TEAMS_PROJECT_COORDINATION_OOH_PROV_PORTING: 156,
          ICON_SRG_ARC_TB_2: 157,
          SIP_GAMMA_SL_PORT: 158,
          SIP_GAMMA_ML_PORT: 159,
          SIP_GAMMA_ML_LESS_THAN_11_PORT: 160,
          RINGCENTRAL_MANAGEDSERVICES: 161,
          RINGCENTRAL_PS: 162,
          RINGCENTRAL_CSM: 163,
          TEAMS_ATTENDANT_CONSOLE_MONTHLY: 164,
          TEAMS_ATTENDANT_CONSOLE_ANNUAL: 165,
          CXNOW_PS_INSTALLATIONS_ENGINEER: 166,
          CXNOW_PS_CLOUD_INFRASTRUCTURE_SPECIALIST: 167,
          ICON_COMMUNICATE_MANAGED_SERVICES: 168,
          ICON_COMMUNICATE_UPFRONT_INFRASTRUCTURE: 169,
          ICON_COMMUNICATE_RECURRING_INFRASTRUCTURE: 170,
          SIPMIGRATION_HARDWARE: 171,
          ICON_SEC_CERTIFICATE_UPFRONT: 172,
          SYCURIO_PS: 173,
          SYCURIO_PSP: 174,
          SYCURIO_MAINTEL_ANNUAL_ASSESSMENT: 175,
          SYCURIO_MAINTEL_SEGMENTATION_TEST: 176,
          SYCURIO_MAINTEL_VULNERABILITY_SCANNER: 177,
          SYCURIO_MAINTEL_SIEM_LICENSES: 178,
          SYCURIO_MAINTEL_SOC_HEADCOUNT: 179,
          SYCURIO_MAINTEL_CIRCUIT_COST: 180,
          DO_NOT_USE_ICON_COMMUNICATE_UPFRONT_INFRASTRUCTURE_QUOTE: 181,
          DO_NOT_USE_ICON_COMMUNICATE_RECURRING_INFRASTRUCTURE_QUOTE: 182,
          UC_ANALYTICS_PROJECT_DELIVERY_MANAGER: 183,
          UC_ANALYTICS_ENGINEERING_LEVEL_A: 184,
          ICON_SIP_RECURRING_INFRASTRUCTURE: 185,
          SIP_CORE_BANDWIDTH_STANDARD_UPFRONT_STANDALONE_SIP: 186,
          SIP_CORE_BANDWIDTH_RESILIENT_UPFRONT_STANDALONE_SIP: 187,
          SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_STANDALONE_SIP: 188,
          SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_STANDALONE_SIP: 189,
          UC_ANALYTICS_SET_UP_FEE_PLUS_PACKAGE: 190,
          ICON_BLADE_SERVERS: 191,
          ICON_COMMUNICATE_UPFRONT_INFRASTRUCTURE_AMORTISED: 192,
          ICON_VM_01_CPU_BASED: 193,
          PHONEPLUS_YEALINK_DECT_REPEATER: 194,
          PHONEPLUS_YEALINK_DECT_W52H: 195,
          PHONEPLUS_YEALINK_DECT_W52P: 196,
          PHONEPLUS_YEALINK_DECT_W73H: 197,
          PHONEPLUS_YEALINK_DECT_W73P: 198,
          NETCALL_PROJECT_COORDINATION_HALF_DAY: 199,
          NETCALL_LEAD_ENGINEER_HALF_DAY: 200,
          NETCALL_PROJECT_COORDINATION_DAY: 201,
          NETCALL_LEAD_ENGINEER_DAY: 202,          
          SIP_GAMMA_STCM_DDI_CONNECTION: 203
        }),
        quoteLicenceTypes: Object.freeze({
          SUBSCRIPTION: 1,
          PERPETUAL: 2,
          SURRENDER: 3
        }),
        quoteServiceLevelTypes: Object.freeze({
          STANDARD: 1,
          PREMIUM: 2,
          MAINTAIN: 3,
          ASSIST: 4,
          ASSURE: 5,
          AUGMENT: 6
        }),
        operatingSystems: Object.freeze({
          APPLIANCE: 1,
          LINUX: 2,
          WINDOWS: 3
        }),
        designPackEntryTypes: Object.freeze({
          AMORTISED: 1,
          UPFRONT: 2
        }),
        quoteStates: Object.freeze({
          UNPUBLISHED: 1,
          PUBLISHED: 2,
          EXPIRED: 3,
          ORDERED: 4,
          SUPERSEDED: 5
        }),
        avayaWFOOptions: Object.freeze({
          NONE: 1,
          AVAYA: 2,
          VERINT: 3
        }),
        avayaAACCDesktopOptions: Object.freeze({
          AGENT_DESKTOP: 1,
          WORKSPACES: 2
        }),
        hardwareCurrencies: Object.freeze({
          GBP: 1,
          USD: 2,
          EUR: 3
        }),
        packages: Object.freeze({
          AVAYA_AACC_VOICE_NODESKTOP_PACKAGE1: 1,
          AVAYA_AACC_VOICE_NODESKTOP_PACKAGE2: 2,
          AVAYA_AACC_VOICE_NODESKTOP_PACKAGE3: 3,
          AVAYA_AACC_VOICE_NODESKTOP_PACKAGE4: 4,
          AVAYA_AACC_VOICE_NODESKTOP_PACKAGE5: 5,
          AVAYA_AACC_VOICEANDMM_WORKSPACES_PACKAGE1: 6,
          AVAYA_AACC_VOICEANDMM_WORKSPACES_PACKAGE2: 7,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE1: 8,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE2: 9,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE3: 10,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE4: 11,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE5: 12,
          AVAYA_AACC_VOICEANDMM_AADD_PACKAGE6: 13,
          AVAYA_AACC_MM_WORKSPACES_PACKAGE1: 14,
          AVAYA_AACC_MM_WORKSPACES_PACKAGE2: 15,
          AVAYA_AACC_MM_AADD_PACKAGE1: 16,
          AVAYA_AACC_MM_AADD_PACKAGE2: 17,
          AVAYA_AACC_MM_AADD_PACKAGE3: 18,
          AVAYA_AACC_MM_AADD_PACKAGE4: 19,
          AVAYA_AACC_VOICE_WORKSPACES_PACKAGE1: 20,
          AVAYA_AACC_VOICE_WORKSPACES_PACKAGE2: 21
        }),
        customerSummaryCategories: Object.freeze({
          ICON_COMMUNICATE_WITH_AVAYA_AURA: 1,
          ICON_COMMUNICATE_WITH_MITEL: 2,
          ICON_CONTACT_WITH_AVAYA_AACC: 3,
          ICON_CONTACT_WITH_AVAYA_ELITE: 4,
          ICON_CONTACT_WITH_MITEL: 5,
          ICON_CONTACT_WITH_CALLMEDIA: 6,
          GAMMA_SIP: 7,
          CALLMEDIA_CX_NOW: 8,
          ICON_GATEWAY: 9,
          SIP_SERVICES: 10,
          EXPANSION: 11,
          TEAMS_CONNECTOR: 12,
          PHONELINE_PLUS: 13,
          GENESYS: 14,
          RINGCENTRAL_UC: 15,
          RINGCENTRAL_CC: 16
        }),
        outOfHoursPorting: Object.freeze({
          NONE: 1,
          WEEKDAY_EVENINGS: 2,
          SATURDAYS: 3
        }),
        sipAccessTypes: Object.freeze({
          GOLD: 1,
          SILVER: 2,
          GOLD_PLUS_PLUS: 3,
          GOLD_PLUS: 4,
          SILVER_PLUS: 5
        }),
        sipAccess: Object.freeze({
          STANDARD: 1,
          RESILIENT: 2
        }),
        chargeInterval: Object.freeze({
          MONTHLY: 1,
          YEARLY: 2,
          ONE_OFF: 3
        }),
        subscriptionBillingIntervals: Object.freeze({
          ANNUAL: 1,
          MONTHLY: 2
        }),
        expansionVmCategories: Object.freeze({
          CUSTOM: 1,
          COMMUNICATE: 2,
          CONTACT: 3,
          SIP: 4,
          CXNOW: 5
        }),
        sipResilienceTypes: Object.freeze({
          ACTIVE_STANDBY: 1,
          ACTIVE: 2
        }),
        expansionVmResilienceTypes: Object.freeze({
          APPLICATION: 1,
          INFRASTRUCTURE: 2,
          NO_RESILIENCE: 3
        }),
        teamsConnectorRecordingRedundencyOptions: Object.freeze({
          NONE: 0,
          LOCAL: 1,
          GEO_REDUNDANCY: 2
        }),
        teamsConnectorRecordingExportTypes: Object.freeze({
          PUBLIC: 1,
          AZURE: 2
        }),
        teamsConnectorRecordingTypes: Object.freeze({
          VOICE: 1,
          UC: 2
        }),
        ringCentralUCUserProfiles: Object.freeze({
          BASIC_USER: 36,
          ESSENTIAL_USER: 37,
          STANDARD_USER: 38,
          PREMIUM_USER: 39,
          ULTIMATE_USER: 40,
          CX_NOW_INTEGRATION: 41,
          LIVE_REPORTS: 42,
          INVIEW_PERFORMANCE_MANAGEMENT: 43,
          ADDITIONAL_LOCAL_NUMBER: 44,
          MICROSOFT_DYNAMICS_UNIFIED_SERVICE_DESK: 45
        }),
        cxNowUserProfiles: Object.freeze({
          VOICE_ONLY: 21,
          DIGITAL_ONLY: 22,
          ADVANCED: 60
        }),
        ringCentralCCUserProfiles: Object.freeze({
          DIGITAL_AGENT: 46,
          ESSENTIALS_AGENT: 47,
          STANDARD_AGENT: 48,
          FEEDBACK_SURVEY_MANAGER: 49,
          WORKFORCE_MANAGEMENT_PRO: 50,
          QUALITY_MANAGEMENT_PRO: 51,
          SCREEN_RECORDING_PRO: 52,
          QUALITY_MANAGEMENT_ANALYTICS_PRO: 53,
          OMNICHANNEL_ANALYTICS_PRO: 54,
          SALESFORCE_AGENT: 55,
          ZENDESK_AGENT: 56,
          PREMIUM_AGENT: 57,
          ULTIMATE_AGENT: 58,
          UNLIMITED_AGENT: 59
        }),
        avayaAXPUserProfiles: Object.freeze({
          ALL_MEDIA_BUNDLE: 65,
          VOICE_ONLY_BUNDLE: 66,
          DIGITAL_ONLY_BUNDLE: 67,
          CONNECT_VOICE_BUNDLE: 68,
          WORKFORCE_MANAGEMENT: 69,
          SCREEN_RECORDING: 70,
          QUALITY_MANAGEMENT: 71,
          AUTOMATED_QUALITY_MANAGEMENT: 72,
          AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE: 73,
          SPEECH_ANALYTICS_BATCH: 74,
          SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE: 75,
          SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS: 76,
          STRATEGIC_DESKTOP_ANALYTICS: 77,
          COMPLIANCE_TRIGGER: 78,
          APPLICATION_VISUALIZATION: 79,
          EACH_ADDITIONAL_1TB_STORAGE: 80,
          INTERACTION_DATA_EXPORT_MANAGER: 81
        }),
        ucAnalyticsQuoteTypes: Object.freeze({
          NEW_TO_UC_ANALYTICS: 1,
          EXISTING_UC_ANALYTICS_UPGRADING_FROM_FOUNDATION: 2,
          EXISTING_UC_ANALYTICS_ADDING_USERS: 3
        }),
        designPackTableSections: Object.freeze({
          ICON_INFRASTRUCTURE: 1,
          AWS_INFRASTRUCTURE: 2,
          ICON_CONNECT: 3,
          SOFTWARE_LICENSES: 4,
          PROFESSIONAL_SERVICES: 5,
          MANAGED_SERVICES: 6,
          SIP_SOFWARE_LICENSES: 7,
          TECH: 8,
          ICON_INFRASTRUCTURE_TC: 9,
          AWS_INFRASTRUCTURE_TC: 10,
          ICON_CONNECT_TC: 11,
          SOFTWARE_LICENSES_TC: 12,
          PROFESSIONAL_SERVICES_TC: 13,
          MANAGED_SERVICES_TC: 14,
          SIP_SOFWARE_LICENSES_TC: 15,
          TECH_TC: 16,
          ICON_INFRASTRUCTURE_CXN: 17,
          AWS_INFRASTRUCTURE_CXN: 18,
          ICON_CONNECT_CXN: 19,
          SOFTWARE_LICENSES_CXN: 20,
          PROFESSIONAL_SERVICES_CXN: 21,
          MANAGED_SERVICES_CXN: 22,
          SIP_SOFWARE_LICENSES_CXN: 23,
          TECH_CXN: 24,
          ICON_INFRASTRUCTURE_NS_UPFRONT: 25,
          THIRD_PARTY_PRO_SERVICES_INSTALLATION: 26,
          THIRD_PARTY_PRO_SERVICES_OTHER: 27
        }),
        salesforceCoeCodes: Object.freeze({
          CALL_TRAFFIC: 1,
          CONFERENCING: 2,
          INBOUND: 3,
          SIP_TRUNKING: 4,
          VOICE_LINE_RENTAL: 5,
          CAAS: 6,
          DATA_CONNECTIVITY: 7,
          ICON_SECURE: 8,
          NS_UPFRONT: 9,
          MOBILE: 10,
          NEW_MANAGED_SERVICES: 11,
          NEW_THIRD_PARTY_SUPPORT: 12,
          NEW_VENDOR_SUPPORT_SA: 13,
          PROFESSIONAL_SERVICES: 14,
          THIRD_PARTY_PRO_SERVICES: 15,
          TECHNOLOGY: 16,
          TECH_CALLMEDIA_LIC_ONLY: 17,
          TECHNOLOGY_SUBSCRIPTIONS: 18,
          NEW_VENDOR_SUPPORT: 19,
          MANAGED_SERVICE: 20,
          CALLS_AND_LINES: 21,
          DATA: 22
        }),
        dpEntryClassification: Object.freeze({
          UPFRONT_INTERNAL: 1,
          UPFRONT_EXTERNAL: 2,
          RECURRING_INTERNAL: 3,
          RECURRING_EXTERNAL: 4
        }),
        designPackPsRoleProfiles: Object.freeze({
          NONE: 0,
          ENGINEERING_LEVEL_D: 1,
          APPLICATIONS_PRODUCT_SPECIALIST: 2,
          ENGINEER_LEVEL_A: 3,
          ENGINEER_LEVEL_B: 4,
          ENGINEER_LEVEL_C: 5,
          CONSULT_LEVEL_B: 6,
          SENIOR_PROJECT_MANAGER: 7,
          PROJECT_COORDINATOR: 8,
          PROJECT_MANAGER: 9,
          DELIVERY_MANAGER: 10,
          SENIOR_APPLICATIONS_CONSULTANT: 11,
          EXTERNAL_EXPENSES: 12,
          ENGINEER_LEVEL_E: 13,
          DESIGN_LEVEL_A: 14,
          CONSULT_LEVEL_A: 15,
          PORTFOLIO_LEAD: 16
        }),
        designPackPsSchedules: Object.freeze({
          NONE: 0,
          HALF_DAY: 1,
          DAY: 2,
          EVENING_4HRS: 3,
          SATURDAY: 4,
          SUNDAY_OR_BANK_HOL: 5,
          SATURDAY_HALF_DAY: 6,
          SUNDAY_HALF_DAY: 7
        }),
        designPackPsRoleProfileSchedules: Object.freeze({
          LABOUR_PROJECT_COORDINATOR_HALF_DAY: 36,
          LABOUR_PROJECT_COORDINATOR_DAY: 37,
          LABOUR_PROJECT_MANAGER_DAY: 42
        }),
        designPackContractFrameworkIds: Object.freeze({
          NONE: 1,
          RM3808: 2,
          RM3804: 3,
          OJEU: 4,
          JISC_TPS: 5,
          G_CLOUD_11: 6,
          RM3733: 7,
          SCOTTISH_GOVERNMENT_TELEPHONY_PURCHASING_SERVICE: 8,
          NHS_LONDON_PROCUREMENT_PARTNERSHIP: 9,
          OTHER: 10,
          RM6116: 11
        }),
        salesMargins: Object.freeze({
          DEFAULT_MARGIN: 20
        }),
        pricing: Object.freeze({
          ICON_SEC_CERTIFICATE_UPFRONT_COST: 372,
          ICON_SEC_CERTIFICATE_BASE_COST: 231.20,
          ICON_SEC_CERTIFICATE_COST_PER_SERVER: 153.60,
          ICON_SEC_CERTIFICATE_COST_MAX: 732.80
        }),
        limits: Object.freeze({
          DP_PS_MAX_COST_WITHOUT_APPROVAL: 30000
        }),
        QuoteDesignPackTypes: Object.freeze({
          FROM_QUOTE: 1,
          STANDALONE: 2,
          FROM_EXTERNAL_DP: 3
        }),
        GenesysUserProfileCategories: Object.freeze({
          PROFILES: 1,
          ADDONS: 2
        }),
        RingCentralUCUserProfileCategories: Object.freeze({
          PROFILES: 3,
          ADDONS: 4
        }),
        RingCentralCCUserProfileCategories: Object.freeze({
          PROFILES: 5,
          ADDONS: 6
        }),
        AvayaAXPUserProfileCategories: Object.freeze({
          PROFILES: 7,
          AVAYA_ELITE: 8,
          ADDONS: 9
        }),
        MitelUCE2UserProfileCategories: Object.freeze({
          MITEL_UC: 10,
          MITEL_CC: 11,
          CALL_RECORDING: 12,
          MITEL_HOSPITALITY: 13
        }),
        AvayaAXPSocialMediaMessagingOptions: Object.freeze({
          MESSAGING_NONE: 1,
          MESSAGING_10K_MSGS_EA_MONTH_FIXED_SUBS: 2,
          MESSAGING_70K_MSGS_EA_MONTH_FIXED_SUBS: 3,
          MESSAGING_300K_MSGS_EA_MO_FIXED_SUBS: 4,
          MESSAGING_1000K_MSGS_EA_MO_FIXED_SUBS: 5
        }),
        PublicNameSpaceOptions: Object.freeze({
          MAINTEL_PROVIDES: 1,
          CUSTOMER_PROVIDES: 2
        })
      }
    }
  }
}
