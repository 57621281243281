<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="ucAnalyticsIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="ucAnalyticsComponent.quote.quoteOpportunityId + ' (revision ' + ucAnalyticsComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br><br>
      <label
        :class="{InputError:!isValidNumEssentialsUsers}"
        for="numEssentialsUsers"
      >Number of Essentials Users
      </label>
      <input
        id="numEssentialsUsers"
        v-model.number="ucAnalyticsComponent.ucAnalytics.options.numEssentialsUsers"
        class="small"
        type="number"
        min="100"
        max="9999"
        @blur="correctInvalidInputs()"
      >
      <span
        v-if="multipleProfileTypesSelected && ucAnalyticsComponent.ucAnalytics.options.numEssentialsUsers > 0"
        style="color: red"
      >
        *Multiple profile types are not supported
      </span>
      <br>
      <label
        :class="{InputError:!isValidNumPlusUsers}"
        for="numPlusUsers"
      >Number of Plus Users
      </label>
      <input
        id="numPlusUsers"
        v-model.number="ucAnalyticsComponent.ucAnalytics.options.numPlusUsers"
        class="small"
        type="number"
        min="100"
        max="9999"
        @blur="correctInvalidInputs()"
      >
      <span
        v-if="multipleProfileTypesSelected && ucAnalyticsComponent.ucAnalytics.options.numPlusUsers > 0"
        style="color: red"
      >
        *Multiple profile types are not supported
      </span>
      <br>
      <label
        :class="{InputError:!isValidNumTailoredUsers}"
        for="numTailoredUsers"
      >Number of Tailored Users
      </label>
      <input
        id="numTailoredUsers"
        v-model.number="ucAnalyticsComponent.ucAnalytics.options.numTailoredUsers"
        class="small"
        type="number"
        min="100"
        max="9999"
        @blur="correctInvalidInputs()"
      >
      <span
        v-if="multipleProfileTypesSelected && ucAnalyticsComponent.ucAnalytics.options.numTailoredUsers > 0"
        style="color: red"
      >
        *Multiple profile types are not supported
      </span>
      <span
        v-if="ucAnalyticsComponent.ucAnalytics.options.numTailoredUsers > 0"
        class="banner"
      >
        Contact Data Track for Quote then add it to iQuote via a BOM
      </span>
      <div>
        <br><br>
        <div
          v-for="entry in getUCAnalyticsQuoteTypes"
          :key="entry.id">
          <input
            :id="entry.id"
            :name="entry.id"
            :value="entry.id"
            v-model.number="ucAnalyticsComponent.ucAnalytics.options.ucAnalyticsQuoteTypeId"
            type="radio"
          >
          {{ entry.name }}
        </div>
      </div>
      <br>
      <label
        v-if="false"
        :class="{InputError:!isValidProofOfConcept}"
        for="chkIsProofOfConcept"
      >Proof of Concept (POC)?
        <TooltipIcon text="<ul><li>POC will only be done on the Essential and Plus tier</li><li>POC will provide a free familiarisation session<li>POC will last 4 weeks from point at which data is live in the dashboard</li></ul>" />
      </label>
      <input
        v-if="false"
        id="chkIsProofOfConcept"
        v-model="ucAnalyticsComponent.ucAnalytics.options.isProofOfConcept"
        type="checkbox"
        class="small"
      >
      <div
        v-if="!isValidProofOfConcept"
        class="iQuoteWarningMessage"
      >
        POC not supported for Tailored Quotes
      </div>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService', false)"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('SelectService', true)"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteCalculationsiQuoteModuleStatsMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsiQuoteModuleStatsMixin'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'UCAnalytics',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin, iQuoteCalculationsiQuoteModuleStatsMixin],
  data () {
    return {
      ucAnalyticsComponent: {
        quote: {},
        ucAnalytics: {
          options: {
            id: 0,
            quoteId: 0,
            numEssentialsUsers: 0,
            numPlusUsers: 0,
            numTailoredUsers: 0,
            isProofOfConcept: false,
            ucAnalyticsQuoteTypeId: 0
          }
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    ucAnalyticsIcon () {
      return require(`../../../assets/icons/MaintelUCAnalytics.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumEssentialsUsers () {
      var value = this.ucAnalyticsComponent.ucAnalytics.options.numEssentialsUsers
      return (this.iQuoteFunctions_IsInteger(value) &&
      (value === 0 || (value <= 9999 && value >= 100 && this.totalUCAnalyticsUserCount === value)))
    },
    isValidNumPlusUsers () {
      var value = this.ucAnalyticsComponent.ucAnalytics.options.numPlusUsers
      return (this.iQuoteFunctions_IsInteger(value) &&
      (value === 0 || (value <= 9999 && value >= 100 && this.totalUCAnalyticsUserCount === value)))
    },
    isValidNumTailoredUsers () {
      var value = this.ucAnalyticsComponent.ucAnalytics.options.numTailoredUsers
      return (this.iQuoteFunctions_IsInteger(value) &&
      (value === 0 || (value <= 9999 && value >= 100 && this.totalUCAnalyticsUserCount === value)))
    },
    multipleProfileTypesSelected () {
      var options = this.ucAnalyticsComponent.ucAnalytics.options

      return (options.numEssentialsUsers > 0 && options.numEssentialsUsers !== this.totalUCAnalyticsUserCount) ||
             (options.numPlusUsers > 0 && options.numPlusUsers !== this.totalUCAnalyticsUserCount) ||
             (options.numTailoredUsers > 0 && options.numTailoredUsers !== this.totalUCAnalyticsUserCount)
    },
    isValidProofOfConcept () {
      return !this.ucAnalyticsComponent.ucAnalytics.options.isProofOfConcept || this.ucAnalyticsComponent.ucAnalytics.options.numTailoredUsers === 0
    },
    totalUCAnalyticsUserCount () {
      var options = this.ucAnalyticsComponent.ucAnalytics.options
      return options.numEssentialsUsers + options.numPlusUsers + options.numTailoredUsers
    },
    getUCAnalyticsQuoteTypes () {
      return this.iQuoteStore_GetUCAnalyticsQuoteTypes
    }
  },
  async mounted () {
    this.ucAnalyticsComponent.quote = this.$store.getters.quote
    var ucAnalyticsStore = this.$store.getters.quoteUCAnalytics

    if (ucAnalyticsStore.id === undefined || ucAnalyticsStore.id === 0) {
      var options

      this.ucAnalyticsComponent.ucAnalytics.id = await this.getQuoteTechnologyId(this.ucAnalyticsComponent.quote.id, this.iQuoteConstants.technologies.ucAnalytics)

      if (this.ucAnalyticsComponent.ucAnalytics.id === 0) {
        this.ucAnalyticsComponent.ucAnalytics.id = await this.addQuoteTechnologyId(this.ucAnalyticsComponent.quote.id, this.iQuoteConstants.technologies.ucAnalytics)

        options = {
          id: 0,
          quoteId: this.ucAnalyticsComponent.quote.id,
          numEssentialsUsers: this.iQuoteCalculationsiQuoteModuleStatsMixin_ucAnalyticsFoundationsUserCount,
          numPlusUsers: 0,
          numTailoredUsers: 0,
          isProofOfConcept: false,
          ucAnalyticsQuoteTypeId: this.iQuoteConstants.ucAnalyticsQuoteTypes.NEW_TO_UC_ANALYTICS
        }

        options = await this.saveQuoteOptionsUCAnalytics(options)
      } else {
        // Retrieve data from DB
        options = await this.getQuoteOptionsUCAnalytics(this.ucAnalyticsComponent.quote.id)
      }

      // Ticket 5914 - disable POC in UC Analytics
      this.ucAnalyticsComponent.ucAnalytics.options.isProofOfConcept = false

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.ucAnalyticsComponent.ucAnalytics, 'options', options)
      }

      this.setQuoteUCAnalytics(this.ucAnalyticsComponent.ucAnalytics)
    } else {
      this.ucAnalyticsComponent.ucAnalytics = ucAnalyticsStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteUCAnalytics'
    ]),
    correctInvalidInputs () {
      var options = this.ucAnalyticsComponent.ucAnalytics.options

      if (options.numEssentialsUsers === '') {
        options.numEssentialsUsers = 0
      }

      if (options.numPlusUsers === '') {
        options.numPlusUsers = 0
      }

      if (options.numTailoredUsers === '') {
        options.numTailoredUsers = 0
      }
    },
    validatePage (clickedNext) {
      var ValidationFailed = false

      if (!this.isValidNumEssentialsUsers || !this.isValidNumPlusUsers || !this.isValidNumTailoredUsers) {
        ValidationFailed = true
      }

      if (this.multipleProfileTypesSelected) {
        this.iQuoteFunctions_ShowError('Multiple Profile Types are not supported')
        ValidationFailed = true
      } else if (clickedNext && !(this.totalUCAnalyticsUserCount >= 100 && this.totalUCAnalyticsUserCount <= 9999)) {
        this.iQuoteFunctions_ShowError('Please enter between 100 and 9999 users')
        ValidationFailed = true
      } else if (ValidationFailed && this.totalUCAnalyticsUserCount !== 0) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        ValidationFailed = true
      } else if (!this.isValidProofOfConcept) {
        this.iQuoteFunctions_ShowError('POC not supported for Tailored Quotes')
        ValidationFailed = true
      }
      return !ValidationFailed
    },
    async gotoPage (page, clickedNext) {
      this.correctInvalidInputs()

      if (this.validatePage(clickedNext)) {
        if (this.totalUCAnalyticsUserCount > 0) {
          // Save to DB only if valid data is supplied (even if pressing back rather than next)
          this.ucAnalyticsComponent.ucAnalytics.options = await this.saveQuoteOptionsUCAnalytics(this.ucAnalyticsComponent.ucAnalytics.options)
          // update local store
          this.setQuoteUCAnalytics(this.ucAnalyticsComponent.ucAnalytics)
        } else {
          this.deleteQuoteTechnology(this.ucAnalyticsComponent.ucAnalytics.id)
          // delete from local store
          this.setQuoteUCAnalytics({})
        }

        // change displayed screen
        this.setCurrentQuoteState(page)
      }
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }

  .quotePanel {
    width: 1200px;
  }

  table tr td .numericTableColumn {
    width:55px;
  }

  table tr th .numericTableColumn {
    width:55px;
  }

  .banner {
    margin: 0 0 0 30px;
    padding: 5px 15px 5px 15px;
    color: #F5F5F5;
    background: #009579;
    box-shadow: 3px 3px 5px 1px #585858;
    border-radius: 10px;
  }
</style>
