<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconCommunicate"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3 class="appDarkFont">Adjuncts</h3>
      <br>
      <span style="font-weight: 500">ASC Call Recording</span>
      <br>
      <label
        for="numRecordingLicenses"
      >Recording Licenses
        <TooltipIcon text="Recording of voice and SMS - Named user" />
      </label>
      <input
        id="numRecordingLicenses"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.recordingLicenses"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        class=""
        for="chkRightfaxServer"
      >Rightfax Server
        <TooltipIcon text="Enables servers for Right Fax. License for Right Fax needs to be ordered separately" />
      </label>
      <input
        id="chkRightfaxServer"
        v-model="quoteTechnologyAvayaAura.adjuncts.rightfax"
        type="checkbox"
        class="small"
      >
      <div
        v-if="quoteTechnologyAvayaAura.adjuncts.rightfax"
        class="InputError">Please contact supplier for the Rightfax license costs separately
      </div>
      <label
        :class="{InputError:!isValidMaxConcurrentCalls}"
        for="numMaxConcurrentCalls"
      >Max Concurrent calls</label>
      <input
        id="numMaxConcurrentCalls"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        :class="{InputError:!isValidNumCallsPerAgentPerDay}"
        for="numAgentCallsPerDay"
      >Calls per agent per day</label>
      <input
        id="numAgentCallsPerDay"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.numCallsPerAgentPerDay"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        :class="{InputError:!isValidAvgCallDuration}"
        for="numAvgCallDuration"
      >Avg call duration (minutes)
        <TooltipIcon text="Average Call Duration for Call recording, average time for all recorded calls in minutes" />
      </label>
      <input
        id="numAvgCallDuration"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.avgCallDuration"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        :class="{InputError:!isValidRetentionPeriod}"
        for="numRetentionPeriod"
      >Retention period (months)
        <TooltipIcon text="Retention period in months for all recorded calls" />
      </label>
      <input
        id="numRetentionPeriod"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.retentionPeriod"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="cboVirtualMachine"
      >Specify the number of concurrent replays required
        <TooltipIcon text="If greater 5 Million conversations to be saved or greater than 40 concurrent replays then view requirements in ISS" />
      </label>
      <select
        id="cboVirtualMachine"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.virtualMachineId"
      >
        <option
          key="126"
          value="126"
          title="Small server"
        >≤ 10 Concurrent replays</option>
        <option
          key="127"
          :selected="true"
          value="127"
          title="Medium server"
        >≤ 20 Concurrent replays</option>
        <option
          key="128"
          value="128"
          title="Large server"
        >≤ 40 Concurrent replays</option>
      </select>
      <br><br>
      <span style="font-weight: 500">Add-On Licences</span>
      <br>
      <label
        for="numQualityMonitoring"
      >Quality Monitoring
        <TooltipIcon text="Quality Monitoring - Named user" />
      </label>
      <input
        id="numQualityMonitoring"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.qualityMonitoring"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="numKeywordSpotting"
      >Keyword Spotting
        <TooltipIcon text="Speech Analytics, Keyword Spotting - Named user" />
      </label>
      <input
        id="numKeywordSpotting"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.keywordSpotting"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="numTranscription"
      >Transcription
        <TooltipIcon text="Speech Analytics, Transcription - Named user" />
      </label>
      <input
        id="numTranscription"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.transcription"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="numEngineEnabling"
      >Engine Enabling
        <TooltipIcon text="Speech Analytics, Engine Enabling - Named user" />
      </label>
      <input
        id="numEngineEnabling"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.engineEnabling"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="numConnectForSalesforce"
      >Connect For Salesforce
        <TooltipIcon text="Connect for Salesforce, Recording control & call tagging within Salesforce - Named user" />
      </label>
      <input
        id="numConnectForSalesforce"
        v-model.number="quoteTechnologyAvayaAura.adjuncts.connectForSalesforce"
        type="number"
        min="0"
        max="1000000"
      >
      <label
        for="chkContactCentreIntegration"
      >Contact Centre Integration
        <TooltipIcon text="CTI solution for tagging of additional call details - Per system" />
      </label>
      <input
        id="chkContactCentreIntegration"
        v-model="quoteTechnologyAvayaAura.adjuncts.contactCentreIntegration"
        type="checkbox"
        class="small"
      >
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesAura')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

import { mapActions } from 'vuex'
export default {
  name: 'AdjunctsAura',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      quote: {},
      quoteTechnologyAvayaAura: {
        userProfiles: [],
        hardware: [],
        adjuncts: {
          recordingLicenses: 0,
          qualityMonitoring: 0,
          keywordSpotting: 0,
          transcription: 0,
          engineEnabling: 0,
          connectForSalesforce: 0,
          contactCentreIntegration: false,
          virtualMachineId: 126
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../assets/icons/MaintelUCPrivatePlus.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidMaxConcurrentCalls () {
      var value = this.quoteTechnologyAvayaAura.adjuncts.maxConcurrentCalls
      return (this.quoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidNumCallsPerAgentPerDay () {
      var value = this.quoteTechnologyAvayaAura.adjuncts.numCallsPerAgentPerDay
      return (this.quoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidAvgCallDuration () {
      var value = this.quoteTechnologyAvayaAura.adjuncts.avgCallDuration
      return (this.quoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidRetentionPeriod () {
      var value = this.quoteTechnologyAvayaAura.adjuncts.retentionPeriod
      return (this.quoteTechnologyAvayaAura.adjuncts.recordingLicenses > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura
    if (this.quoteTechnologyAvayaAura.id === undefined) {
      this.quoteTechnologyAvayaAura.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAura)
      // Using $set, as directly assigning to the arrays was not updating the DOM
      var userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAura.id)
      var hardware = await this.getQuoteTechnologyHardware(this.quoteTechnologyAvayaAura.id)
      var adjuncts = await this.getQuoteAuraAdjuncts(this.quote.id)

      this.$set(this.quoteTechnologyAvayaAura, 'userProfiles', userProfiles)
      this.$set(this.quoteTechnologyAvayaAura, 'hardware', hardware)
      this.$set(this.quoteTechnologyAvayaAura, 'adjuncts', adjuncts)
      this.setQuoteTechnologyAvayaAura(this.quoteTechnologyAvayaAura)
    }

    if (this.quoteTechnologyAvayaAura.adjuncts.virtualMachineId === undefined) {
      this.quoteTechnologyAvayaAura.adjuncts.virtualMachineId = this.iQuoteConstants.virtualMachines.ASC_SMALL
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAura'
    ]),
    gotoPage (page) {
      var ValidationFailed = false
      if (!this.isValidMaxConcurrentCalls) {
        ValidationFailed = true
      }
      if (!this.isValidNumCallsPerAgentPerDay) {
        ValidationFailed = true
      }
      if (!this.isValidAvgCallDuration) {
        ValidationFailed = true
      }
      if (!this.isValidRetentionPeriod) {
        ValidationFailed = true
      }

      if (ValidationFailed) {
        alert('Please correct the inputs on this page')
        return
      }

      this.setQuoteTechnologyAvayaAura(this.quoteTechnologyAvayaAura)
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidMaxConcurrentCalls) {
        ValidationFailed = true
      }
      if (!this.isValidNumCallsPerAgentPerDay) {
        ValidationFailed = true
      }
      if (!this.isValidAvgCallDuration) {
        ValidationFailed = true
      }
      if (!this.isValidRetentionPeriod) {
        ValidationFailed = true
      }
      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied
        this.quoteTechnologyAvayaAura.adjuncts = await this.saveQuoteAuraAdjuncts(this.quoteTechnologyAvayaAura.adjuncts)
        this.setQuoteTechnologyAvayaAura(this.quoteTechnologyAvayaAura)
        this.gotoPage('SelectService')
      } else {
        alert('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
</style>
