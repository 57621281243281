<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="mitelIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h2>Mitel UC E2 - CC Add Ons</h2>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyMitelUCE2.userProfiles.filter(x => x.userProfileCategoryId === iQuoteConstants.MitelUCE2UserProfileCategories.MITEL_CC)"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            style="width:220px; text-align:left"
          >{{ userProfile.description }}
            <TooltipIcon
              v-if="userProfile.additionalInfo != ''"
              :text="userProfile.additionalInfo" />
          </td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = userProfile.minCommitment"
              @change="userProfile.maxAllowed = userProfile.minCommitment"
            >
          </td>
          <td>
            <label
              v-if="userProfile.minCommitment > 0 && userProfile.warning != ''"
              class="InputError"
              style="width:620px; text-align:left"
            >{{ userProfile.warning }}
            </label>
          </td>
        </tr>
      </table>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('MitelUCE2Page1')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('MitelUCE2Page3')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'
export default {
  name: 'MitelUCE2Page2',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      quote: {},
      quoteTechnologyMitelUCE2: {
        userProfiles: [],
        options: {
          webConferencingIsRequired: false,
          oigServersAreRequired: false,
          additionalWebConferencingPorts: 0,
          additionalVoicemailBoxes: 0,
          concurrentCallsToRemoteWorkers: 0,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          virtualMachineId: 126 /* ASC_SMALL */
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../../assets/icons/question.png`)
    },
    mitelIcon () {
      return require(`../../../../assets/icons/mitelUC.png`)
    },
    toiQuotePrevious () {
      return require(`../../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../../assets/icons/toiQuoteNext.png`)
    },
    isValidAdditionalWebConferencingPorts () {
      var value = this.quoteTechnologyMitelUCE2.options.additionalWebConferencingPorts
      return (this.iQuoteFunctions_IsInteger(value) && value >= 0 && value <= 200)
    },
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    var quoteTechnologyMitelUCE2Store = this.iQuoteStore_GetQuoteTechnologyMitelUCE2

    if (quoteTechnologyMitelUCE2Store.id === undefined || quoteTechnologyMitelUCE2Store.id === 0) {
      var userProfiles
      var options

      this.quoteTechnologyMitelUCE2.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.mitelUC_E2)

      if (this.quoteTechnologyMitelUCE2.id === 0) {
        this.quoteTechnologyMitelUCE2.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.mitelUC_E2)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.mitelUC_E2, this.quoteTechnologyMitelUCE2.id)

        options = {
          id: 0,
          quoteId: this.quote.id,
          webConferencingIsRequired: false,
          oigServersAreRequired: false,
          additionalWebConferencingPorts: 0,
          additionalVoicemailBoxes: 0,
          concurrentCallsToRemoteWorkers: 0,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          virtualMachineId: 126 /* ASC_SMALL */
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyMitelUCE2.id)
        options = await this.getQuoteOptionsMitelUCE2(this.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyMitelUCE2, 'userProfiles', userProfiles)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyMitelUCE2, 'options', options)
      }

      this.setQuoteTechnologyMitelUCE2(this.quoteTechnologyMitelUCE2)
    } else {
      this.quoteTechnologyMitelUCE2 = quoteTechnologyMitelUCE2Store
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelUCE2'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.quoteTechnologyMitelUCE2.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyMitelUCE2.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        }
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      }

      // Save to DB only if valid data is supplied (even if pressing back rather than next)
      this.quoteTechnologyMitelUCE2.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyMitelUCE2.userProfiles)
      this.quoteTechnologyMitelUCE2.options = await this.saveQuoteOptionsMitelUCE2(this.quoteTechnologyMitelUCE2.options)

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return this.iQuoteFunctions_IsInteger(userProfile.minCommitment) && userProfile.minCommitment >= 0
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }
  .quotePanel{
    width: 600px;
  }
  .quotePanel label{
    width:350px;
  }
</style>
