<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="avayaAXPIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="userProfilesAvayaAXPComponent.quote.quoteOpportunityId + ' (revision ' + userProfilesAvayaAXPComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <div class="titleBox">
        <h2>Avaya AXP</h2>
      </div>
      <table class="noTableShading">
        <tbody>
          <tr>
            <td style="text-align:left; width: 30px">&nbsp;</td>
            <td style="text-align:left; width: 0px">&nbsp;</td>
            <td style="text-align:left; width: 30px">&nbsp;</td>
          </tr>
        </tbody>
        <tbody
          v-for="category in userProfileCategories"
          :key="category.id">
          <tr><td>&nbsp;</td></tr>
          <tr class="TableSectionHeader">{{ category.description }}</tr>
          <tr
            v-if="category.id !== iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS"
          >
            <th />
            <th>Quantity
              <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
            </th>
          </tr>
          <tr
            v-if="category.id === iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS"
          >
            <th />
            <th>Included
              <TooltipIcon text="How many of each add-on that are included with the selected bundles" />
            </th>
            <th>Additional
              <TooltipIcon text="How many additional of each add-on are required" />
            </th>
          </tr>
          <tr
            v-for="userProfile in getUserProfilesByCategoryId(category.id)"
            :key="userProfile.technologyUserProfileId"
            :class="{disabledAddOn: !addonAllowed(userProfile)}"
          >
            <td
              :class="{InputError: !isValidUserProfile(userProfile), SubProfile: isSubUserProfile(userProfile)}"
              style="text-align:left"
            >{{ userProfile.description }}
              <TooltipIcon
                v-if="userProfile.additionalInfo != ''"
                :text="userProfile.additionalInfo" />
            </td>
            <td
              v-if="category.id === iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS"
              class="numericTableColumn">
              {{ calcIncludedLicenses(userProfile) }}
            </td>
            <td
              class="numericTableColumn">
              <input
                v-if="userProfile.technologyUserProfileId !== iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER"
                :readonly="!addonAllowed(userProfile)"
                v-model.number="userProfile.minCommitment"
                type="number"
                min="0"
                max="1000000"
                @keyup="userProfile.maxAllowed = userProfile.minCommitment"
                @change="userProfile.maxAllowed = userProfile.minCommitment"
              >
              <select
                v-if="userProfile.technologyUserProfileId === iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER"
                :disabled="!addonAllowed(userProfile)"
                v-model.number="userProfile.minCommitment"
                style="width:85px; border: 1px solid gray;"
              >
                <option
                  key="0"
                  value="0"
                  title="No"
                >No</option>
                <option
                  key="1"
                  value="1"
                  title="Yes"
                >Yes</option>
              </select>
            </td>
            <td
              v-if="userProfile.warning != ''"
            >
              <div
                :class="{hiddenwarning: userProfile.minCommitment === 0}"
                class="warningbox"
              >
                <div
                  class="warningboxcontent"
                >
                  {{ userProfile.warning }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tr><td colspan="3">&nbsp;</td></tr>
      </table>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AvayaAXPPage2')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfilesAvayaAXP',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      userProfilesAvayaAXPComponent: {
        quote: {},
        avayaAXP: {
          userProfiles: [],
          options: {}
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    avayaAXPIcon () {
      return require(`../../../assets/icons/MaintelAvayaAXP.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    userProfileCategories () {
      var categories = []
      this.$store.getters.userProfileCategories.forEach(item => {
        if (item.technologyId === this.iQuoteConstants.technologies.avayaAXP) {
          categories.push(item)
        }
      })

      return categories
    },
    totalAvayaAXPUserCount () {
      var total = 0
      this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles.forEach(item => {
        if (item.userProfileCategoryId === 7) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalAvayaAXPAddOnsCount () {
      var total = 0
      this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles.forEach(item => {
        if (item.userProfileCategoryId === 8) {
          total += item.minCommitment
        }
      })

      return total
    },
    atLeastOneUserProfileWithMinimumAdded () {
      var bundleProfiles = this.getUserProfilesByCategoryId(this.iQuoteConstants.AvayaAXPUserProfileCategories.PROFILES)

      return bundleProfiles.filter(x => x.minCommitment >= this.minOrderQuantity(x)).length > 0
    }
  },
  async mounted () {
    this.userProfilesAvayaAXPComponent.quote = this.$store.getters.quote
    var avayaAXPStore = this.$store.getters.quoteAvayaAXP

    if (avayaAXPStore.id === undefined || avayaAXPStore.id === 0) {
      var userProfiles
      var options

      this.userProfilesAvayaAXPComponent.avayaAXP.id = await this.getQuoteTechnologyId(this.userProfilesAvayaAXPComponent.quote.id, this.iQuoteConstants.technologies.avayaAXP)

      if (this.userProfilesAvayaAXPComponent.avayaAXP.id === 0) {
        this.userProfilesAvayaAXPComponent.avayaAXP.id = await this.addQuoteTechnologyId(this.userProfilesAvayaAXPComponent.quote.id, this.iQuoteConstants.technologies.avayaAXP)

        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.avayaAXP, this.userProfilesAvayaAXPComponent.avayaAXP.id)
        options = {
          id: 0,
          quoteId: this.userProfilesAvayaAXPComponent.quote.id,
          avayaAXPSocialMediaPackageId: 1,
          numAvayaAXPUsers: 0,
          numPBXUsers: 0,
          sipChannelsCalculated: 0,
          additionalSbcLicencesForInternalCalls: 0,
          subscriptionBillingPeriodId: this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL,
          teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
          numCallsPerYear: 0,
          avgCallDurationMins: 0,
          usersPerSipChannel: 5,
          newDDIs: 0,
          numAdditionalSIPChannels: 0,
          netCall: false
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.userProfilesAvayaAXPComponent.avayaAXP.id)
        options = await this.getQuoteOptionsAvayaAXP(this.userProfilesAvayaAXPComponent.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesAvayaAXPComponent.avayaAXP, 'userProfiles', userProfiles)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesAvayaAXPComponent.avayaAXP, 'options', options)
      }

      this.setQuoteAvayaAXP(this.userProfilesAvayaAXPComponent.avayaAXP)
    } else {
      this.userProfilesAvayaAXPComponent.avayaAXP = avayaAXPStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteAvayaAXP'
    ]),
    calcIncludedLicenses (addonUserProfile) {
      if (addonUserProfile.userProfileCategoryId !== this.iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS) {
        return ''
      } else {
        var bundleProfiles = this.getUserProfilesByCategoryId(this.iQuoteConstants.AvayaAXPUserProfileCategories.PROFILES)
        var count = 0

        bundleProfiles.forEach(userProfile => {
          switch (userProfile.technologyUserProfileId) {
            case this.iQuoteConstants.avayaAXPUserProfiles.ALL_MEDIA_BUNDLE:
              if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SCREEN_RECORDING ||
                addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.QUALITY_MANAGEMENT
              ) {
                count += userProfile.minCommitment
              }
          }
        })

        if (count > 0) {
          return count
        } else {
          return '-'
        }
      }
    },
    addonAllowed (addonUserProfile) {
      if (addonUserProfile.userProfileCategoryId !== this.iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS) {
        return true
      } else {
        var bundleProfiles = this.getUserProfilesByCategoryId(this.iQuoteConstants.AvayaAXPUserProfileCategories.PROFILES)
        var addOnProfiles = this.getUserProfilesByCategoryId(this.iQuoteConstants.AvayaAXPUserProfileCategories.ADDONS)
        var isAllowed = false

        if (this.atLeastOneUserProfileWithMinimumAdded === false &&
          addonUserProfile.technologyUserProfileId !== this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER) {
        // If you have no user profiles on the order, then we assume it's an upgrade and we don't know what they already have,
        // so allow everything apart from Interraction Data Export manager, which needs to know the number of users
          isAllowed = true
        }

        if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT) {
          if (addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.QUALITY_MANAGEMENT).minCommitment > 0 ||
          bundleProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.ALL_MEDIA_BUNDLE).minCommitment > 0) {
            // Is allowed if the parent is allowed
            isAllowed = this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.QUALITY_MANAGEMENT))
          }
        } else if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE) {
          if (addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT).minCommitment > 0 ||
          bundleProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.ALL_MEDIA_BUNDLE).minCommitment > 0) {
            // Is allowed if the parent is allowed
            isAllowed = this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT))
          }
        } else if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE) {
          if (addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_BATCH).minCommitment > 0) {
            // Is allowed if the parent is allowed
            isAllowed = this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_BATCH))
          }
        } else if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS) {
          if (addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_BATCH).minCommitment > 0) {
            // Is allowed if the parent is allowed
            isAllowed = this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_BATCH))
          }
        } else if (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.STRATEGIC_DESKTOP_ANALYTICS) {
          if (addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.COMPLIANCE_TRIGGER).minCommitment > 0 &&
              addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.APPLICATION_VISUALIZATION).minCommitment > 0) {
            // Is allowed if the parents are allowed
            isAllowed = this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.COMPLIANCE_TRIGGER)) &&
                        this.addonAllowed(addOnProfiles.find(x => x.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.APPLICATION_VISUALIZATION))
          }
        } else {
          // All other addons allowed are based on the quantities of the user profiles

          bundleProfiles.forEach(userProfile => {
            switch (userProfile.technologyUserProfileId) {
              case this.iQuoteConstants.avayaAXPUserProfiles.ALL_MEDIA_BUNDLE:
                /* All add-ons are enabled by All Media bundle */
                if (userProfile.minCommitment > 0) {
                  isAllowed = true
                }
                break
              case this.iQuoteConstants.avayaAXPUserProfiles.VOICE_ONLY_BUNDLE:
                /* All add-ons are enabled by Voice only */
                if (userProfile.minCommitment > 0) {
                  isAllowed = true
                }
                break
              case this.iQuoteConstants.avayaAXPUserProfiles.DIGITAL_ONLY_BUNDLE:
                /* Only some add-ons are enabled by Digital Only */
                if (userProfile.minCommitment > 0 &&
                  (addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.STRATEGIC_DESKTOP_ANALYTICS ||
                  addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.COMPLIANCE_TRIGGER ||
                  addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.APPLICATION_VISUALIZATION ||
                  addonUserProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.EACH_ADDITIONAL_1TB_STORAGE)
                ) {
                  isAllowed = true
                }
            }
          })
        }

        return isAllowed
      }
    },
    async gotoPage (page) {
      var ValidationFailed = false

      for (let index = 0; index < this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles.length; index++) {
        const userProfile = this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles[index]

        // if the user deleted the contents the textbox, set it to 0 quantity
        if (userProfile.minCommitment === '') {
          userProfile.minCommitment = 0
        }

        if (!this.addonAllowed(userProfile) && userProfile.minCommitment > 0) {
          this.iQuoteFunctions_ShowError('One or more disabled add-ons had a quantity.  These have been set to zero')
          userProfile.minCommitment = 0
        }

        if (userProfile.technologyUserProfileId !== this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER &&
          userProfile.minCommitment > 0) {
          if (userProfile.minCommitment < this.minOrderQuantity(userProfile) || userProfile.minCommitment > this.maxOrderQuantity(userProfile)) {
            this.iQuoteFunctions_ShowError('Please enter between ' + this.minOrderQuantity(userProfile) + ' and ' + this.maxOrderQuantity(userProfile) + ' quantity for ""' + userProfile.description + '""')
            return
          }
        }

        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        }
      }

      if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please correct incorrect inputs on the page')
        return
      }

      this.userProfilesAvayaAXPComponent.avayaAXP.options.numAvayaAXPUsers = this.totalAvayaAXPUserCount

      // Save to DB only if valid data is supplied (even if pressing back rather than next)
      this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles)
      this.userProfilesAvayaAXPComponent.avayaAXP.options = await this.saveQuoteOptionsAvayaAXP(this.userProfilesAvayaAXPComponent.avayaAXP.options)

      // update local store
      this.setQuoteAvayaAXP(this.userProfilesAvayaAXPComponent.avayaAXP)

      // update local store
      this.setCurrentQuoteState(page)
    },
    minOrderQuantity (userProfile) {
      var minOrderQuantity = 20

      if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.EACH_ADDITIONAL_1TB_STORAGE
      ) {
        minOrderQuantity = 1
      }

      return minOrderQuantity
    },
    maxOrderQuantity (userProfile) {
      var maxOrderQuantity = 9999

      if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.INTERACTION_DATA_EXPORT_MANAGER) {
        maxOrderQuantity = 1
      } else if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE ||
                 userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE ||
                 userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS
      ) {
        maxOrderQuantity = 10
      }

      return maxOrderQuantity
    },
    isValidUserProfile (userProfile) {
      if (!this.iQuoteFunctions_IsInteger(userProfile.minCommitment)) {
        return false
      }

      if (userProfile.minCommitment === 0) {
        return true
      }

      return (userProfile.minCommitment >= this.minOrderQuantity(userProfile) && userProfile.minCommitment <= this.maxOrderQuantity(userProfile))
    },
    isSubUserProfile (userProfile) {
      if (userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.AUTOMATED_QUALITY_MANAGEMENT_EACH_ADDITIONAL_LANGUAGE ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LANGUAGE ||
        userProfile.technologyUserProfileId === this.iQuoteConstants.avayaAXPUserProfiles.SPEECH_ANALYTICS_EACH_ADDITIONAL_LINE_OF_BUSINESS) {
        return true
      } else {
        return false
      }
    },
    getUserProfilesByCategoryId (categoryId) {
      var userProfiles = []
      this.userProfilesAvayaAXPComponent.avayaAXP.userProfiles.forEach(item => {
        if (item.userProfileCategoryId === categoryId) {
          userProfiles.push(item)
        }
      })

      return userProfiles
    }
  }
}
</script>
<style scoped>
  table {
    width: 100%;
  }

  table tr th {text-align: center; vertical-align: top}
  table tr td {text-align: center; vertical-align: top}

  table tbody tr.disabledAddOn {
    color: silver;
  }

  table tbody tr.disabledAddOn:hover {
    color: silver;
  }

  .SubProfile {
   padding-left: 30px;
  }

  .quotePanel {
    width: 1050px;
  }

  .UserProfileWarningBox {
    display: static;
    border: 1px solid black;
    color: red;
  }

  .warningbox {
    display: block;
    align-items: center;
    width: 100%;
  }

  .warningboxcontent {
    display: block;
    margin: 0px 0 0 0px;
    padding: 10px;
    background: #009579;
    color: white;
    box-shadow: 3px 3px 5px 1px #585858;
    border-radius: 10px;
    text-wrap: wrap;
  }

  .warningbox.hiddenwarning {
    width: 0px;
  }

  .warningbox.hiddenwarning>.warningboxcontent {
    margin: 0;
    padding: 0;
    box-shadow: none;
    text-wrap: nowrap;
  }

  .banner {
    margin: 12px 0 0 130px;
    padding: 10px;
    background: #009579;
    color: #F5F5F5;
    box-shadow: 3px 3px 5px 1px #585858;
    border-radius: 10px;
  }
</style>
