<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      <input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <br>
      <br>
      <h3 class="appDarkFont">Adjuncts</h3>
      <label
        class=""
        for="chkCallRecording"
      >Call Recording (GDPR Inc)
        <TooltipIcon text="awaiting tooltip info...." />
      </label>
      <br>
      <div
        v-if="quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls > 0"
        class="InputError"
      >Subscription / Surrender licenses only.  Perpetual licenses are not supported
      </div>
      <label
        :class="{InputError:!isValidMaxConcurrentCalls}"
        class="indent"
        for="numMaxConcurrentCalls"
      >Max Concurrent calls</label>
      <input
        id="numMaxConcurrentCalls"
        v-model.number="quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls"
        type="number"
        min="0"
        max="1000000"
      >
      <br>
      <label
        :class="{InputError:!isValidNumCallsPerAgentPerDay}"
        class="indent"
        for="numAgentCallsPerDay"
      >Calls per agent per day</label>
      <input
        id="numAgentCallsPerDay"
        v-model.number="quoteTechnologyAvayaAACC.adjuncts.numCallsPerAgentPerDay"
        :readonly="quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls === 0"
        type="number"
        min="0"
        max="1000000"
      >
      <br>
      <label
        :class="{InputError:!isValidAvgCallDuration}"
        class="indent"
        for="numAvgCallDuration"
      >Avg call duration (minutes)
        <TooltipIcon text="Average Call Duration for Call recording, average time for all recorded calls in minutes" />
      </label>
      <input
        id="numAvgCallDuration"
        v-model.number="quoteTechnologyAvayaAACC.adjuncts.avgCallDuration"
        :readonly="quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls === 0"
        type="number"
        min="0"
        max="1000000"
      >
      <br>
      <label
        :class="{InputError:!isValidRetentionPeriod}"
        class="indent"
        for="numRetentionPeriod"
      >Retention period (months)
        <TooltipIcon text="Retention period in months for all recorded calls" />
      </label>
      <input
        id="numRetentionPeriod"
        v-model.number="quoteTechnologyAvayaAACC.adjuncts.retentionPeriod"
        :readonly="quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls === 0"
        type="number"
        min="0"
        max="1000000"
      >
      <br>
      <br>
      <div style="display: none">

        <br>
        <label
          class=""
          for="chkCompliance"
        >Compliance
          <TooltipIcon text="awaiting tooltip info...." />
        </label>
        <input
          id="chkCompliance"
          v-model="quoteTechnologyAvayaAACC.adjuncts.compliance"
          type="checkbox"
          class="small"
        >
      </div>
      <br>
      <label
        class=""
        for="cbowfm"
      >Compliance PCI/DSS
        <TooltipIcon text="awaiting tooltip info...." />
      </label>
      <input
        id="chkCompliancePciDss"
        v-model="quoteTechnologyAvayaAACC.adjuncts.compliancePciDss"
        type="checkbox"
        class="small"
      >
      <span
        v-if="quoteTechnologyAvayaAACC.adjuncts.compliancePciDss"
        class="InputError"
        for="chkWFO"
      >Eckoh service needs to be quoted seperately
      </span>
      <br>
      <br>
      <div style="display: none">
        <br>
        <label
          :class="{InputError:!isValidWFMQuantity}"
          for="chkWFM"
        >WFM
          <TooltipIcon text="awaiting tooltip info...." />
        </label>
        <input
          id="chkWFM"
          v-model="quoteTechnologyAvayaAACC.adjuncts.wfm"
          type="checkbox"
          class="small"
        >
        <br>
        <label
          for="numWFMQuantity" />
        <input
          id="numWFMQuantity"
          v-model.number="quoteTechnologyAvayaAACC.adjuncts.wfmQuantity"
          :readonly="quoteTechnologyAvayaAACC.adjuncts.wfm === false"
          type="number"
          min="0"
          max="10000"
        >
        <br>
        <br>
        <br>
        <label
          :class="{InputError:!isValidWFOQuantity}"
          for="chkWFO"
        >WFO
          <TooltipIcon text="awaiting tooltip info...." />
        </label>
        <input
          id="chkWFO"
          v-model="quoteTechnologyAvayaAACC.adjuncts.wfo"
          type="checkbox"
          class="small"
        >
        <br>
        <label
          for="numWFOQuantity" />
        <input
          id="numWFOQuantity"
          v-model.number="quoteTechnologyAvayaAACC.adjuncts.wfoQuantity"
          :readonly="quoteTechnologyAvayaAACC.adjuncts.wfo === false"
          type="number"
          min="0"
          max="10000"
        >
        <br>
        <br>
      </div>
      <br>

      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesAACC')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShowHelpMixin } from '../../../mixins/showHelpMixin'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import TooltipIcon from '../../common/tooltipIcon'

import { mapActions } from 'vuex'
export default {
  name: 'AdjunctsAvayaAACC',
  components: {
    TooltipIcon
  },
  mixins: [ShowHelpMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      wfo: [],
      quote: {},
      quoteTechnologyAvayaAACC: {
        userProfiles: [],
        adjuncts: {
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconContact () {
      return require(`../../../assets/icons/MaintelCXPrivate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidWFOQuantity () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.wfo === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.wfoQuantity
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidWFMQuantity () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.wfm === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.wfmQuantity
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidMaxConcurrentCalls () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.callRecording === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.maxConcurrentCalls
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidNumCallsPerAgentPerDay () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.callRecording === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.numCallsPerAgentPerDay
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidAvgCallDuration () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.callRecording === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.avgCallDuration
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    isValidRetentionPeriod () {
      if (this.quoteTechnologyAvayaAACC.adjuncts.callRecording === false) {
        return true
      }

      var value = this.quoteTechnologyAvayaAACC.adjuncts.retentionPeriod
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    this.wfo = this.$store.getters.wfooptions
    this.quoteTechnologyAvayaAACC = this.$store.getters.quoteTechnologyAvayaAACC
    if (this.quoteTechnologyAvayaAACC.id === undefined) {
      this.quoteTechnologyAvayaAACC.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.avayaAACC)
      // Using $set, as directly assigning to the arrays was not updating the DOM
      var userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyAvayaAACC.id)
      var adjuncts = await this.getQuoteAvayaAACCAdjuncts(this.quote.id)
      var options = await this.getQuoteOptionsAvayaAACC(this.quote.id)

      this.$set(this.quoteTechnologyAvayaAACC, 'userProfiles', userProfiles)
      this.$set(this.quoteTechnologyAvayaAACC, 'adjuncts', adjuncts)
      this.$set(this.quoteTechnologyAvayaAACC, 'options', options)

      this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyAvayaAACC'
    ]),
    gotoPage (page) {
      this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
      this.setCurrentQuoteState(page)
    },
    async finishService () {
      // Save to DB only if valid data is supplied
      this.quoteTechnologyAvayaAACC.adjuncts = await this.saveQuoteAACCAdjuncts(this.quoteTechnologyAvayaAACC.adjuncts)
      this.setQuoteTechnologyAvayaAACC(this.quoteTechnologyAvayaAACC)
      this.gotoPage('SelectService')
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
  .quotePanel label{width:280px;}
  .quotePanel{width:800px;}
  .indent{margin-left:50px;margin-right:-50px}
</style>
