import { mapActions } from 'vuex'
import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteQuoteTechnologyAPIMixin } from '../iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../iQuoteQuoteAPIMixin'
import { iQuoteQuoteExpansionsAPIMixin } from '../iQuoteQuoteExpansionsAPIMixin'
import { iQuoteQuoteDesignPackEntriesAPIMixin } from '../iQuoteQuoteDesignPackEntriesAPIMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteCalculationsCommonMixin } from '../iQuoteCalculationsCommonMixin'
import { externalQuotesModelMixin } from '../Models/externalQuotesModelMixin'
import { iQuoteQuoteBomsAPIMixin } from '../API/externalQuotes/iQuoteQuoteBomsAPIMixin'
import { iQuoteQuoteIconConnectAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconConnectAPIMixin'
import { iQuoteQuoteIconNowAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconNowAPIMixin'
import { iQuoteQuoteManagedServiceAPIMixin } from '../API/externalQuotes/iQuoteQuoteManagedServiceAPIMixin'
import { iQuoteQuoteNewManagedServiceAPIMixin } from '../API/externalQuotes/iQuoteQuoteNewManagedServiceAPIMixin'
import { iQuoteQuoteCallsAndLinesAPIMixin } from '../API/externalQuotes/iQuoteQuoteCallsAndLinesAPIMixin'
import { iQuoteQuoteHorizonAPIMixin } from '../API/externalQuotes/iQuoteQuoteHorizonAPIMixin'
import { iQuoteQuoteIconSecureAPIMixin } from '../API/externalQuotes/iQuoteQuoteIconSecureAPIMixin'
import { iQuoteQuoteSecureHomeworkerAPIMixin } from '../API/externalQuotes/iQuoteQuoteSecureHomeworkerAPIMixin'
import { iQuoteQuoteTwilioAPIMixin } from '../API/externalQuotes/iQuoteQuoteTwilioAPIMixin'
import { iQuoteStaticDataAPIMixin } from '../iQuoteStaticDataAPIMixin'

export const iQuoteDuplicateMixin = {
  data () {
    return {
    }
  },
  mixins: [
    iQuoteConstants,
    iQuoteQuoteTechnologyAPIMixin,
    iQuoteQuoteAPIMixin,
    iQuoteQuoteExpansionsAPIMixin,
    iQuoteFunctions,
    iQuoteStoreMixin,
    iQuoteQuoteDesignPackEntriesAPIMixin,
    iQuoteCalculationsCommonMixin,
    iQuoteQuoteTechnologyAPIMixin,
    iQuoteQuoteExpansionsAPIMixin,
    externalQuotesModelMixin,
    iQuoteQuoteBomsAPIMixin,
    iQuoteStoreMixin,
    iQuoteQuoteIconConnectAPIMixin,
    iQuoteQuoteIconNowAPIMixin,
    iQuoteQuoteManagedServiceAPIMixin,
    iQuoteQuoteNewManagedServiceAPIMixin,
    iQuoteQuoteCallsAndLinesAPIMixin,
    iQuoteQuoteHorizonAPIMixin,
    iQuoteQuoteIconSecureAPIMixin,
    iQuoteQuoteSecureHomeworkerAPIMixin,
    iQuoteQuoteTwilioAPIMixin,
    iQuoteStaticDataAPIMixin
  ],
  computed: {
  },
  methods: {
    ...mapActions([
      'setQuote',
      'setQuoteGammaSIP',
      'setQuoteTeamsConnector',
      'setQuoteGenesys',
      'setQuoteSIPMigrationProperties',
      'setQuotePhoneLinePlus',
      'setQuoteTechnologyAvayaAura',
      'setQuoteTechnologyCallmedia',
      'setQuoteTechnologyCallmediaCXNow',
      'setQuoteTechnologyIconGateway',
      'setQuoteTechnologyMitelCC',
      'setQuoteTechnologyMitelUC',
      'setQuoteTechnologyMitelUCE2',
      'setQuoteTechnologyAvayaElite',
      'setQuoteTechnologyAvayaAACC',
      'setQuoteVMsRequired',
      'setQuoteLicensesRequired',
      'setQuoteBladeServers',
      'setQuoteUnitCosts',
      'setQuoteDesignPackEntries',
      'setQuoteExpansions',
      'setCurrentQuoteState',
      'setCurrentQuotePage',
      'setCurrentQuoteSubPage',
      'setQuoteRingCentral',
      'setCurrentQuoteExternalQuotesState',
      'setQuoteSycurio',
      'setQuoteUCAnalytics',
      'setQuoteAvayaAXP'
    ]),
    updateQuoteTechnologyIdAndClearIds (items, quoteTechnologyId) {
      items.forEach(item => {
        item.id = 0
        item.quoteTechnologyId = quoteTechnologyId
      })
    },
    zeroIds (items) {
      items.forEach(item => {
        item.id = 0
      })
    },
    iQuoteDuplicate_clearIdAndLinkToQuote (item, quote) {
      item.id = 0
      item.quoteId = quote.id
    },
    async iQuoteDuplicate_CreateDuplicateQuote (quote, createNewQuoteOpportunity, clearPriceOverrides = false) {
      if (quote.id === undefined) {
        return
      }

      if (createNewQuoteOpportunity) {
        quote.quoteOpportunityId = 0 // This will create a completely new quote opportunity.  Otherwise it's a new revision of existing quote opportunity
      }

      // Setting a new id for the quotes, means that a new quote (revision) record will be created
      quote.id = 0
      quote.lastEditedByUserId = this.$store.getters.userid

      // It's a new quote, so can't have been published, or be locked
      quote.datePublished = null
      quote.locked = false

      var returnedQuote
      if (quote.quoteOpportunityId === 0) {
        // Create a completely new quote opportunity
        returnedQuote = await this.iQuoteQuoteAPIMixin_CreateQuoteOpportunity(quote)
      } else {
        // Create a new revision to an existing quote opportunity
        returnedQuote = await this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(quote)
      }

      if (returnedQuote.id === undefined) {
        return 'Error saving quote'
      } else {
        quote = returnedQuote

        quote.marginAWS = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        quote.marginIconGateway = 20 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        quote.marginInfrastructure = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        quote.marginGammaSip = 33.3333 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        quote.marginSipMigration = 30 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */
        quote.marginCxNowPS = 50 /* TODO : remove this once DB code has been modified to save against quotes and retrieve this */

        await this.iQuoteDuplicate_LinkAvayaAuraToQuote(quote)
        await this.iQuoteDuplicate_LinkAvayaAACCToQuote(quote)
        await this.iQuoteDuplicate_LinkAvayaEliteToQuote(quote)
        await this.iQuoteDuplicate_LinkMitelUCToQuote(quote)
        await this.iQuoteDuplicate_LinkMitelCCToQuote(quote)
        await this.iQuoteDuplicate_LinkMitelUCE2ToQuote(quote)
        await this.iQuoteDuplicate_LinkCallmediaToQuote(quote)
        await this.iQuoteDuplicate_LinkCallmediaCXNowToQuote(quote)
        await this.iQuoteDuplicate_LinkGammaSipToQuote(quote)
        await this.iQuoteDuplicate_LinkSipMigrationToQuote(quote)
        await this.iQuoteDuplicate_LinkPhoneLinePlusToQuote(quote)
        await this.iQuoteDuplicate_LinkGenesysToQuote(quote)
        await this.iQuoteDuplicate_LinkSycurioToQuote(quote)
        await this.iQuoteDuplicate_LinkUCAnalyticsToQuote(quote)
        await this.iQuoteDuplicate_LinkTeamsConnectorToQuote(quote)
        await this.iQuoteDuplicate_LinkExpansionsToQuote(quote)
        await this.iQuoteDuplicate_LinkIconGatewayToQuote(quote)
        await this.iQuoteDuplicate_LinkRingCentralToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcIconNowToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcIconConnectToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcManagedServiceToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcNewManagedServiceToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcCallsAndLinesToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcHorizonToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcIconSecureToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcSecureHomeworkerToQuote(quote)
        await this.iQuoteDuplicate_LinkExternalCalcTwilioToQuote(quote)
        await this.iQuoteDuplicate_LinkBOMsToQuote(quote)
        await this.iQuoteDuplicate_LinkAvayaAXPToQuote(quote)

        if (clearPriceOverrides) {
          this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
            unitCost.priceOverride = null
          })

          this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
            license.priceOverride = null
          })

          if (this.externalQuotesModelMixin_State.quoteIconConnect.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteIconConnect.costSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          if (this.externalQuotesModelMixin_State.quoteNewManagedService.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteNewManagedService.costSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          if (this.externalQuotesModelMixin_State.quoteManagedService.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteManagedService.costSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          if (this.externalQuotesModelMixin_State.quoteIconSecure.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteIconSecure.costSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          if (this.externalQuotesModelMixin_State.quoteIconNow.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteIconNow.priceSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          if (this.externalQuotesModelMixin_State.quoteTwilio.id !== undefined) {
            this.externalQuotesModelMixin_State.quoteTwilio.priceSummaryEntries.forEach(entry => {
              entry.priceOverride = null
            })
          }

          for (let index = 0; index < this.externalQuotesModelMixin_State.quoteBoms.length; index++) {
            const bom = this.externalQuotesModelMixin_State.quoteBoms[index]

            bom.entries.forEach(entry => {
              entry.priceOverrideOOInternal = null
              entry.priceOverrideOOExternal = null
              entry.priceOverrideARInternal = null
              entry.priceOverrideARExternal = null
            })
          }
        }

        if (this.externalQuotesModelMixin_State.quoteIconConnect.id !== undefined) {
          await this.iQuoteQuoteIconConnectAPIMixin_saveQuoteIconConnectToDB(quote.id, this.externalQuotesModelMixin_State.quoteIconConnect)
        }

        if (this.externalQuotesModelMixin_State.quoteNewManagedService.id !== undefined) {
          await this.iQuoteQuoteNewManagedServiceAPIMixin_saveQuoteNewManagedServiceToDB(quote.id, this.externalQuotesModelMixin_State.quoteNewManagedService)
        }

        if (this.externalQuotesModelMixin_State.quoteManagedService.id !== undefined) {
          await this.iQuoteQuoteManagedServiceAPIMixin_saveQuoteManagedServiceToDB(quote.id, this.externalQuotesModelMixin_State.quoteManagedService)
        }

        if (this.externalQuotesModelMixin_State.quoteIconSecure.id !== undefined) {
          await this.iQuoteQuoteIconSecureAPIMixin_saveQuoteIconSecureToDB(quote.id, this.externalQuotesModelMixin_State.quoteIconSecure)
        }

        if (this.externalQuotesModelMixin_State.quoteIconNow.id !== undefined) {
          await this.iQuoteQuoteIconNowAPIMixin_saveQuoteIconNowToDB(quote.id, this.externalQuotesModelMixin_State.quoteIconNow)
        }

        if (this.externalQuotesModelMixin_State.quoteTwilio.id !== undefined) {
          await this.iQuoteQuoteTwilioAPIMixin_saveQuoteTwilioToDB(quote.id, this.externalQuotesModelMixin_State.quoteTwilio)
        }

        for (let index = 0; index < this.externalQuotesModelMixin_State.quoteBoms.length; index++) {
          const bom = this.externalQuotesModelMixin_State.quoteBoms[index]
          await this.iQuoteQuoteBomsAPIMixin_saveQuoteBomToDB(quote.id, bom)
        }

        if (this.externalQuotesModelMixin_State.quoteSecureHomeworker.id !== undefined) {
          await this.iQuoteQuoteSecureHomeworkerAPIMixin_saveQuoteSecureHomeworkerToDB(quote.id, this.externalQuotesModelMixin_State.quoteSecureHomeworker)
        }

        if (this.externalQuotesModelMixin_State.quoteHorizon.id !== undefined) {
          await this.iQuoteQuoteHorizonAPIMixin_saveQuoteHorizonToDB(quote.id, this.externalQuotesModelMixin_State.quoteHorizon)
        }

        if (this.externalQuotesModelMixin_State.quoteCallsAndLines.id !== undefined) {
          await this.iQuoteQuoteCallsAndLinesAPIMixin_saveQuoteCallsAndLinesToDB(quote.id, this.externalQuotesModelMixin_State.quoteCallsAndLines)
        }

        // Save updated quote details to the local store.  Must do this last, or the externalQuotesModelMixin
        // will detect the quote has changed and reload itself with external quotes that are against that new quote
        // (obviously not finding any)
        this.setQuote(quote)

        this.calculationsCommon_Calculate()

        // Save back to DB, as this may be different to prior numbers
        // if licenses / unitcosts etc have changed prices / costs
        await this.calculationsCommon_saveQuoteCalculationsToDB()
      }

      return quote
    },
    async iQuoteDuplicate_LinkAvayaAuraToQuote (quote) {
      var quoteTechnologyAvayaAura = this.iQuoteStore_GetQuoteTechnologyAvayaAura

      if (quoteTechnologyAvayaAura.id !== undefined) {
        // Attach Avaya Aura technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyAvayaAura.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAura)

        // Update References in the userprofiles and hardware to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyAvayaAura.userProfiles, quoteTechnologyAvayaAura.id)
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyAvayaAura.hardware, quoteTechnologyAvayaAura.id)

        // Update copied adjucts to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyAvayaAura.adjuncts, quote)
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyAvayaAura.options, quote)

        // Save to DB.  This returns the ids that were assigned
        quoteTechnologyAvayaAura.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyAvayaAura.userProfiles)
        quoteTechnologyAvayaAura.hardware = await this.saveQuoteTechnologyHardware(quoteTechnologyAvayaAura.hardware)
        quoteTechnologyAvayaAura.adjuncts = await this.saveQuoteAuraAdjuncts(quoteTechnologyAvayaAura.adjuncts)
        quoteTechnologyAvayaAura.options = await this.saveQuoteOptionsAvayaAura(quoteTechnologyAvayaAura.options)

        this.setQuoteTechnologyAvayaAura(quoteTechnologyAvayaAura)
      }
    },
    async iQuoteDuplicate_LinkAvayaAACCToQuote (quote) {
      var quoteTechnologyAvayaAACC = this.iQuoteStore_GetQuoteTechnologyAvayaAACC

      if (quoteTechnologyAvayaAACC.id !== undefined) {
        // Attach Avaya AACC technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyAvayaAACC.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAACC)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyAvayaAACC.userProfiles, quoteTechnologyAvayaAACC.id)

        // Update copied adjuncts and options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyAvayaAACC.adjuncts, quote)
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyAvayaAACC.options, quote)

        // Save to DB
        quoteTechnologyAvayaAACC.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyAvayaAACC.userProfiles)
        quoteTechnologyAvayaAACC.options = await this.saveQuoteOptionsAvayaAACC(quoteTechnologyAvayaAACC.options)
        quoteTechnologyAvayaAACC.adjuncts = await this.saveQuoteAACCAdjuncts(quoteTechnologyAvayaAACC.adjuncts)

        this.setQuoteTechnologyAvayaAACC(quoteTechnologyAvayaAACC)
      }
    },
    async iQuoteDuplicate_LinkAvayaEliteToQuote (quote) {
      var quoteTechnologyAvayaElite = this.iQuoteStore_GetQuoteTechnologyAvayaElite

      if (quoteTechnologyAvayaElite.id !== undefined) {
        // Attach Avaya Elite technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyAvayaElite.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaElite)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyAvayaElite.userProfiles, quoteTechnologyAvayaElite.id)

        // Update copied adjuncts to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyAvayaElite.adjuncts, quote)

        // Save to DB
        quoteTechnologyAvayaElite.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyAvayaElite.userProfiles)
        quoteTechnologyAvayaElite.adjuncts = await this.saveQuoteEliteAdjuncts(quoteTechnologyAvayaElite.adjuncts)

        this.setQuoteTechnologyAvayaElite(quoteTechnologyAvayaElite)
      }
    },
    async iQuoteDuplicate_LinkMitelUCToQuote (quote) {
      var quoteTechnologyMitelUC = this.iQuoteStore_GetQuoteTechnologyMitelUC

      if (quoteTechnologyMitelUC.id !== undefined) {
        // Attach MitelUC technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyMitelUC.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyMitelUC.userProfiles, quoteTechnologyMitelUC.id)

        // Update copied adjuncts and options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyMitelUC.adjuncts, quote)
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyMitelUC.options, quote)

        // Save to DB
        quoteTechnologyMitelUC.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyMitelUC.userProfiles)
        quoteTechnologyMitelUC.options = await this.saveQuoteOptionsMitelUC(quoteTechnologyMitelUC.options)
        quoteTechnologyMitelUC.adjuncts = await this.saveQuoteMitelUCAdjuncts(quoteTechnologyMitelUC.adjuncts)

        this.setQuoteTechnologyMitelUC(quoteTechnologyMitelUC)
      }
    },
    async iQuoteDuplicate_LinkMitelUCE2ToQuote (quote) {
      var quoteTechnologyMitelUCE2 = this.iQuoteStore_GetQuoteTechnologyMitelUCE2

      if (quoteTechnologyMitelUCE2.id !== undefined) {
        // Attach MitelUC technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyMitelUCE2.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_E2)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyMitelUCE2.userProfiles, quoteTechnologyMitelUCE2.id)

        // Update copied adjuncts and options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyMitelUCE2.options, quote)

        // Save to DB
        quoteTechnologyMitelUCE2.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyMitelUCE2.userProfiles)
        quoteTechnologyMitelUCE2.options = await this.saveQuoteOptionsMitelUCE2(quoteTechnologyMitelUCE2.options)

        this.setQuoteTechnologyMitelUCE2(quoteTechnologyMitelUCE2)
      }
    },
    async iQuoteDuplicate_LinkMitelCCToQuote (quote) {
      var quoteTechnologyMitelCC = this.iQuoteStore_GetQuoteTechnologyMitelCC

      if (quoteTechnologyMitelCC.id !== undefined) {
        // Attach MitelCC technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyMitelCC.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelCC)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyMitelCC.userProfiles, quoteTechnologyMitelCC.id)

        // Update copied adjuncts and options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyMitelCC.adjuncts, quote)
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyMitelCC.options, quote)

        // Save to DB
        quoteTechnologyMitelCC.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyMitelCC.userProfiles)
        quoteTechnologyMitelCC.options = await this.saveQuoteOptionsMitelCC(quoteTechnologyMitelCC.options)
        quoteTechnologyMitelCC.adjuncts = await this.saveQuoteMitelCCAdjuncts(quoteTechnologyMitelCC.adjuncts)

        this.setQuoteTechnologyMitelCC(quoteTechnologyMitelCC)
      }
    },
    async iQuoteDuplicate_LinkCallmediaToQuote (quote) {
      var quoteTechnologyCallmedia = this.iQuoteStore_GetQuoteTechnologyCallmedia

      if (quoteTechnologyCallmedia.id !== undefined) {
        // Attach Callmedia technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyCallmedia.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.callmedia)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyCallmedia.userProfiles, quoteTechnologyCallmedia.id)

        // Update copied adjuncts and options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyCallmedia.adjuncts, quote)
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyCallmedia.options, quote)

        // Save to DB
        quoteTechnologyCallmedia.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyCallmedia.userProfiles)
        quoteTechnologyCallmedia.options = await this.saveQuoteOptionsCallmedia(quoteTechnologyCallmedia.options)
        quoteTechnologyCallmedia.adjuncts = await this.saveQuoteCallmediaAdjuncts(quoteTechnologyCallmedia.adjuncts)

        this.setQuoteTechnologyCallmedia(quoteTechnologyCallmedia)
      }
    },
    async iQuoteDuplicate_LinkCallmediaCXNowToQuote (quote) {
      var quoteTechnologyCallmediaCXNow = this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow

      if (quoteTechnologyCallmediaCXNow.id !== undefined) {
        // Attach Callmedia CX Now technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyCallmediaCXNow.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.callmediaCXNow)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteTechnologyCallmediaCXNow.userProfiles, quoteTechnologyCallmediaCXNow.id)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyCallmediaCXNow.options, quote)

        // Save to DB
        quoteTechnologyCallmediaCXNow.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteTechnologyCallmediaCXNow.userProfiles)
        quoteTechnologyCallmediaCXNow.options = await this.saveQuoteOptionsCallmediaCXNow(quoteTechnologyCallmediaCXNow.options)

        this.setQuoteTechnologyCallmediaCXNow(quoteTechnologyCallmediaCXNow)
      }
    },
    async iQuoteDuplicate_LinkGammaSipToQuote (quote) {
      var quoteGammaSip = this.iQuoteStore_GetGammaSip

      if (quoteGammaSip.id !== undefined) {
        // Attach Gamma SIP to this new quote in the DB
        await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.gammaSIP)

        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteGammaSip, quote)

        // Save to DB
        await this.saveQuoteSipProperties(quoteGammaSip)

        this.setQuoteGammaSIP(quoteGammaSip)
      }
    },
    async iQuoteDuplicate_LinkSipMigrationToQuote (quote) {
      var quoteSipMigration = this.iQuoteStore_GetQuoteSIPMigrationProperties

      if (quoteSipMigration.id !== undefined) {
        // Attach Sip Migration to this new quote in the DB
        await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.sipMigrationOffer)

        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteSipMigration, quote)

        // Save to DB
        await this.saveQuoteSipMigrationProperties(quoteSipMigration)

        this.setQuoteSIPMigrationProperties(quoteSipMigration)
      }
    },
    async iQuoteDuplicate_LinkPhoneLinePlusToQuote (quote) {
      var quotePhoneLinePlus = this.iQuoteStore_GetPhoneLinePlus

      if (quotePhoneLinePlus.id !== undefined) {
        // Attach Phoneline+ to this new quote in the DB, to get an ID for it
        await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.phoneLinePlus)

        this.iQuoteDuplicate_clearIdAndLinkToQuote(quotePhoneLinePlus, quote)

        // Save to DB
        await this.saveQuotePhoneLinePlusProperties(quotePhoneLinePlus)

        this.setQuotePhoneLinePlus(quotePhoneLinePlus)
      }
    },
    async iQuoteDuplicate_LinkTeamsConnectorToQuote (quote) {
      var quoteTeamsConnector = this.iQuoteStore_GetTeamsConnector

      if (quoteTeamsConnector.id !== undefined) {
        // Attach Teams Connector to this new quote in the DB, to get an ID for it
        await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.teamsConnector)

        // Update copied properties to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTeamsConnector, quote)

        // Save to DB
        await this.saveQuoteTeamsConnectorProperties(quoteTeamsConnector)

        this.setQuoteTeamsConnector(quoteTeamsConnector)
      }
    },
    iQuoteDuplicate_addRingCentralBuyPricesToUserProfiles (userProfiles, buyPrices) {
      userProfiles.forEach(item => {
        if (buyPrices?.length) {
          const approvedRCPrice = buyPrices.find(w => w.quoteTechnologyId === item.quoteTechnologyId &&
            w.technologyUserProfileId === item.technologyUserProfileId)
          if (approvedRCPrice !== undefined) {
            item.buyPrice = approvedRCPrice.buyPrice
          }
        }
      })
    },
    async iQuoteDuplicate_LinkRingCentralToQuote (quote) {
      var quoteRingCentral = this.iQuoteStore_GetQuoteRingCentral

      if (quoteRingCentral.ucTechId !== undefined) {
        // Attach Ring Central to this new quote in the DB, to get an ID for it
        quoteRingCentral.ucTechId = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.ringCentralUC)
        quoteRingCentral.ccTechId = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.ringCentralCC)

        // Update References in the userprofiles to be linked to the technologies just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteRingCentral.ccUserProfiles, quoteRingCentral.ccTechId)
        this.updateQuoteTechnologyIdAndClearIds(quoteRingCentral.ucUserProfiles, quoteRingCentral.ucTechId)

        if (quoteRingCentral.ccUserProfilesApprovedRCPrice?.length) {
          this.updateQuoteTechnologyIdAndClearIds(quoteRingCentral.ccUserProfilesApprovedRCPrice, quoteRingCentral.ccTechId)
        }

        if (quoteRingCentral.ucUserProfilesApprovedRCPrice?.length) {
          this.updateQuoteTechnologyIdAndClearIds(quoteRingCentral.ucUserProfilesApprovedRCPrice, quoteRingCentral.ucTechId)
        }

        // Add buy price to the user profiles (if applicable)
        this.iQuoteDuplicate_addRingCentralBuyPricesToUserProfiles(quoteRingCentral.ccUserProfiles, quoteRingCentral.ccUserProfilesApprovedRCPrice)
        this.iQuoteDuplicate_addRingCentralBuyPricesToUserProfiles(quoteRingCentral.ucUserProfiles, quoteRingCentral.ucUserProfilesApprovedRCPrice)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteRingCentral.options, quote)

        // Save to DB
        if (quoteRingCentral.options.ccPricingApprovedByRC) {
          quoteRingCentral.ccUserProfilesApprovedRCPrice = await this.saveQuoteTechnologyUserProfilesApprovedRCPrice(quoteRingCentral.ccUserProfiles)
        }

        if (quoteRingCentral.options.ucPricingApprovedByRC) {
          quoteRingCentral.ucUserProfilesApprovedRCPrice = await this.saveQuoteTechnologyUserProfilesApprovedRCPrice(quoteRingCentral.ucUserProfiles)
        }

        // Need to save the user profiles after the approved RC price, because the returned objects won't have the "buyPrice" property that the saveQuoteTechnologyUserProfilesApprovedRCPrice looks for
        quoteRingCentral.ccUserProfiles = await this.saveQuoteTechnologyUserProfiles(quoteRingCentral.ccUserProfiles)
        quoteRingCentral.ucUserProfiles = await this.saveQuoteTechnologyUserProfiles(quoteRingCentral.ucUserProfiles)

        quoteRingCentral.options = await this.saveQuoteOptionsRingCentral(quoteRingCentral.options)

        this.setQuoteRingCentral(quoteRingCentral)
      }
    },
    async iQuoteDuplicate_LinkGenesysToQuote (quote) {
      var quoteGenesys = this.iQuoteStore_GetQuoteGenesys

      if (quoteGenesys.id !== undefined) {
        // Attach Genesys Now technlogy to this new quote in the DB, to get an ID for it
        quoteGenesys.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.genesys)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteGenesys.userProfiles, quoteGenesys.id)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteGenesys.options, quote)

        // Save to DB
        quoteGenesys.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteGenesys.userProfiles)
        quoteGenesys.options = await this.saveQuoteOptionsGenesys(quoteGenesys.options)

        this.setQuoteGenesys(quoteGenesys)
      }
    },
    async iQuoteDuplicate_LinkSycurioToQuote (quote) {
      var quoteSycurio = this.iQuoteStore_GetQuoteSycurio

      if (quoteSycurio.id !== undefined) {
        // Attach Sycurio technology to this new quote in the DB, to get an ID for it
        quoteSycurio.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.sycurio)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteSycurio.options, quote)

        // Save to DB
        quoteSycurio.options = await this.saveQuoteOptionsSycurio(quoteSycurio.options)

        this.setQuoteSycurio(quoteSycurio)
      }
    },
    async iQuoteDuplicate_LinkUCAnalyticsToQuote (quote) {
      var quoteUCAnalytics = this.iQuoteStore_GetQuoteUCAnalytics

      if (quoteUCAnalytics.id !== undefined) {
        // Attach UCAnalytics technology to this new quote in the DB, to get an ID for it
        quoteUCAnalytics.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.ucAnalytics)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteUCAnalytics.options, quote)

        // Save to DB
        quoteUCAnalytics.options = await this.saveQuoteOptionsUCAnalytics(quoteUCAnalytics.options)

        this.setQuoteUCAnalytics(quoteUCAnalytics)
      }
    },
    async iQuoteDuplicate_LinkAvayaAXPToQuote (quote) {
      var quoteAvayaAXP = this.iQuoteStore_GetQuoteAvayaAXP

      if (quoteAvayaAXP.id !== undefined) {
        // Attach AvayaAXP Now technlogy to this new quote in the DB, to get an ID for it
        quoteAvayaAXP.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.avayaAXP)

        // Update References in the userprofiles to be linked to the technology just added to this new quote
        this.updateQuoteTechnologyIdAndClearIds(quoteAvayaAXP.userProfiles, quoteAvayaAXP.id)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteAvayaAXP.options, quote)

        // Save to DB
        quoteAvayaAXP.userProfiles = await this.saveQuoteTechnologyUserProfiles(quoteAvayaAXP.userProfiles)
        quoteAvayaAXP.options = await this.saveQuoteOptionsAvayaAXP(quoteAvayaAXP.options)

        this.setQuoteAvayaAXP(quoteAvayaAXP)
      }
    },
    async iQuoteDuplicate_LinkExpansionsToQuote (quote) {
      var quoteExpansions = this.iQuoteStore_GetQuoteExpansions

      if (quoteExpansions.id !== undefined) {
        quoteExpansions.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.quoteExpansions)

        quoteExpansions.expansionVirtualMachines.forEach(rvm => {
          rvm.id = -1
        })

        this.setQuoteExpansions(quoteExpansions)

        // Save to DB
        await this.saveQuoteExpansionVirtualMachinesToDB(quote.id, quoteExpansions)
      }
    },
    async iQuoteDuplicate_LinkIconGatewayToQuote (quote) {
      var quoteTechnologyIconGateway = this.iQuoteStore_GetQuoteTechnologyIconGateway

      if (quoteTechnologyIconGateway.id !== undefined) {
        // Attach IconGateway Now technlogy to this new quote in the DB, to get an ID for it
        quoteTechnologyIconGateway.id = await this.addQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.iconGateway)

        // Update copied options to be linked to this new quote, and set id to zero so it will be added as new entry to DB
        this.iQuoteDuplicate_clearIdAndLinkToQuote(quoteTechnologyIconGateway.options, quote)

        // Save to DB
        quoteTechnologyIconGateway.options = await this.saveQuoteOptionsIconGateway(quoteTechnologyIconGateway.options)

        this.setQuoteTechnologyIconGateway(quoteTechnologyIconGateway)
      }
    },
    async iQuoteDuplicate_LinkBOMsToQuote (quote) {
      this.externalQuotesModelMixin_State.quoteBoms.forEach(bom => {
        if (bom.files.length > 0) {
          bom.fileUploadVersionId = bom.files[0].versionList.slice(-1)[0].fileVersionId
        }
        bom.id = 0
        bom.files = []
      })
    },
    async iQuoteDuplicate_LinkExternalCalcCallsAndLinesToQuote (quote) {
      let quoteCallsAndLines = this.externalQuotesModelMixin_State.quoteCallsAndLines
      if (quoteCallsAndLines.id !== undefined) {
        if (quoteCallsAndLines.files.length > 0) {
          quoteCallsAndLines.fileUploadVersionId = quoteCallsAndLines.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteCallsAndLines.id = 0
        quoteCallsAndLines.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcIconNowToQuote (quote) {
      let quoteIconNow = this.externalQuotesModelMixin_State.quoteIconNow
      if (quoteIconNow.id !== undefined) {
        if (quoteIconNow.files.length > 0) {
          quoteIconNow.fileUploadVersionId = quoteIconNow.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteIconNow.id = 0
        quoteIconNow.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcIconConnectToQuote (quote) {
      let quoteIconConnect = this.externalQuotesModelMixin_State.quoteIconConnect
      if (quoteIconConnect.id !== undefined) {
        if (quoteIconConnect.files.length > 0) {
          quoteIconConnect.fileUploadVersionId = quoteIconConnect.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteIconConnect.id = 0
        quoteIconConnect.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcManagedServiceToQuote (quote) {
      let quoteManagedService = this.externalQuotesModelMixin_State.quoteManagedService
      if (quoteManagedService.id !== undefined) {
        if (quoteManagedService.files.length > 0) {
          quoteManagedService.fileUploadVersionId = quoteManagedService.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteManagedService.id = 0
        quoteManagedService.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcNewManagedServiceToQuote (quote) {
      let quoteNewManagedService = this.externalQuotesModelMixin_State.quoteNewManagedService
      if (quoteNewManagedService.id !== undefined) {
        if (quoteNewManagedService.files.length > 0) {
          quoteNewManagedService.fileUploadVersionId = quoteNewManagedService.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteNewManagedService.id = 0
        quoteNewManagedService.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcHorizonToQuote (quote) {
      let quoteHorizon = this.externalQuotesModelMixin_State.quoteHorizon
      if (quoteHorizon.id !== undefined) {
        if (quoteHorizon.files.length > 0) {
          quoteHorizon.fileUploadVersionId = quoteHorizon.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteHorizon.id = 0
        quoteHorizon.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcIconSecureToQuote (quote) {
      let quoteIconSecure = this.externalQuotesModelMixin_State.quoteIconSecure
      if (quoteIconSecure.id !== undefined) {
        if (quoteIconSecure.files.length > 0) {
          quoteIconSecure.fileUploadVersionId = quoteIconSecure.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteIconSecure.id = 0
        quoteIconSecure.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcSecureHomeworkerToQuote (quote) {
      if (this.externalQuotesModelMixin_State.quoteSecureHomeworker.id !== undefined) {
        this.externalQuotesModelMixin_State.quoteSecureHomeworker.id = 0
        this.externalQuotesModelMixin_State.quoteSecureHomeworker.files = []
      }
    },
    async iQuoteDuplicate_LinkExternalCalcTwilioToQuote (quote) {
      let quoteTwilio = this.externalQuotesModelMixin_State.quoteTwilio
      if (quoteTwilio.id !== undefined) {
        if (quoteTwilio.files.length > 0) {
          quoteTwilio.fileUploadVersionId = quoteTwilio.files[0].versionList.slice(-1)[0].fileVersionId
        }
        quoteTwilio.id = 0
        quoteTwilio.files = []
      }
    }
  },
  mounted () {
  }
}
