<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconContact"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select the number of user profiles required:</p>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
          <th>Maximum<br>
            <TooltipIcon text="The maximum number of agents configures the size of the ICON infrastructure and maximum size the customer may grow to.  This value requires to be 20% above the Minimum Commit Users. Adjust to required level" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyCallmedia.userProfiles"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            style="width:150px; text-align:left"
          >{{ userProfile.description }}</td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
            >
          </td>
          <td>
            <input
              v-model.number="userProfile.maxAllowed"
              type="number"
              min="0"
              max="1000000"
            >
          </td>
        </tr>

      </table>
      <br><br>
      <div
        v-if="quoteTechnologyMitelUC.id !== undefined"
      >
        <label
          for="chkDedicatedIVRServerIsRequired"
        >Dedicated IVR Server
          <TooltipIcon
            width="500"
            text="MiContact IVR Can be used without MiContact, for auto-attendant and/or IVR or for larger scale deployments" />

        </label>
        <input
          v-if="quoteTechnologyCallmedia.options !== undefined"
          id="chkDedicatedIVRServerIsRequired"
          v-model.number="quoteTechnologyCallmedia.options.dedicatedIVRServerIsRequired"
          type="checkbox"
        >
        <label
          for="txtIVRPorts"
        >IVR Ports
          <TooltipIcon
            width="500"
            text="Number of ports required for IVR or auto-attendant" />
        </label>
        <input
          id="txtIVRPorts"
          v-model.number="quoteTechnologyCallmedia.options.ivrPorts"
          type="text"
        >
        <br><br>
      </div>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('IconContact')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AdjunctsCallmedia')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfileCallmedia',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      quote: {},
      quoteTechnologyMitelUC: {},
      quoteTechnologyCallmedia: {
        userProfiles: [],
        adjuncts: {},
        options: {
          dedicatedIVRServerIsRequired: false,
          ivrPorts: 0
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconContact () {
      return require(`../../../assets/icons/MaintelCXPrivate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    var quoteTechnologyCallmediaStore = this.$store.getters.quoteTechnologyCallmedia
    this.quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC

    if (quoteTechnologyCallmediaStore.id === undefined || quoteTechnologyCallmediaStore.id === 0) {
      var userProfiles
      var adjuncts
      var options

      this.quoteTechnologyCallmedia.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.callmedia)

      if (this.quoteTechnologyCallmedia.id === 0) {
        this.quoteTechnologyCallmedia.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.callmedia)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.callmedia, this.quoteTechnologyCallmedia.id)
        adjuncts = {
          id: 0,
          quoteId: this.quote.id,
          dpa: false,
          speechAnalysis: false,
          wfm: false,
          avayaWFOOptionId: 1 /* None */
        }
        options = {
          id: 0,
          quoteId: this.quote.id,
          dedicatedIVRServerIsRequired: false,
          ivrPorts: 0
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyCallmedia.id)
        adjuncts = await this.getQuoteCallmediaAdjuncts(this.quote.id)
        options = await this.getQuoteOptionsCallmedia(this.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmedia, 'userProfiles', userProfiles)
      }

      if (adjuncts.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmedia, 'adjuncts', adjuncts)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyCallmedia, 'options', options)
      }

      this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
    } else {
      this.quoteTechnologyCallmedia = quoteTechnologyCallmediaStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyCallmedia'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var TotalUserCount = 0
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.quoteTechnologyCallmedia.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyCallmedia.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        } else {
          TotalUserCount += userProfile.minCommitment
        }
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied (even if pressing back rather than next)
        this.quoteTechnologyCallmedia.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyCallmedia.userProfiles)
        this.quoteTechnologyCallmedia.options = await this.saveQuoteOptionsCallmedia(this.quoteTechnologyCallmedia.options)
        this.quoteTechnologyCallmedia.adjuncts = await this.saveQuoteCallmediaAdjuncts(this.quoteTechnologyCallmedia.adjuncts)
      }

      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (TotalUserCount === 0 && page === 'AdjunctsCallmedia') {
        this.iQuoteFunctions_ShowError('Please enter how many users')
        return
      } else if (TotalUserCount > 1000) {
        // Can't go next if invalid inputs supplied (but can go back)
        if (page === 'IconContact' && TotalUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter less than 1000 users, or enter 0 (this will remove callmedia from the quote)')
        } else if (page === 'AdjunctsCallmedia') {
          this.iQuoteFunctions_ShowError('cannot support over 1,000 users')
        }
        return
      }

      if (TotalUserCount > 0) {
        // update local store
        this.setQuoteTechnologyCallmedia(this.quoteTechnologyCallmedia)
      } else {
        this.deleteQuoteTechnology(this.quoteTechnologyCallmedia.id)
        // delete from local store
        this.setQuoteTechnologyCallmedia({})
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2)
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }
</style>
