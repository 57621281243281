<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <div
        v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined"
      >
        <h3
          :class="{SellPriceMismatch: !quoteUpfrontPriceMatchesDesignPackSellPrice || !quoteRecurringPriceMatchesDesignPackSellPrice}"
          @click="invertShowQuoteDummyData()"
        >
          Designpack <span v-if="iQuoteStore_GetDesignPack.quoteDesignPackId > 0">#{{ iQuoteStore_GetDesignPack.quoteDesignPackId }}</span>
          <TooltipIcon
            v-if="!quoteUpfrontPriceMatchesDesignPackSellPrice || !quoteRecurringPriceMatchesDesignPackSellPrice"
            :text="priceMismatchTooltipText"
            width="500"
          />
          <img
            v-if="!designPackComponent.isQuoteChecked"
            :src="iQuoteImagesMixin_busyAnimation"
            style="width:16px; height: 16px;"
            alt="busy">
        </h3>
      </div>
      <div id="salesforceDetails">
        <br>
        <div
          v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined"
          id="searchOpportunityPrompt"
        >
          <b>Please Search for the opportunity associated with the Design Pack you wish to view or create</b>
          <br>
          <br>
        </div>
        <label
          :class="{InputError: designPackComponent.salesforceOpportunity.salesforceOpportunityId === 0}"
          for="txtSalesforceQuote"
        >Salesforce Opportunity #
          <TooltipIcon
            width="500"
            text="Login to Salesforce and retrieve from Salesforce Opportunity Number" />
        </label>
        <input
          id="txtSalesforceQuote"
          ref="txtSFQuote"
          v-model="designPackComponent.salesforceQuoteNumber"
          :readonly="designPackComponent.loading || designPackComponent.saving"
          type="text"
          @keyup="resetSalesforceOpportunity()"
        >
        <button
          v-if="designPackComponent.salesforceQuoteNumber !== '' && !designPackComponent.loading && !designPackComponent.saving"
          id="btnResetSearch"
          type="button"
          class="iQuoteIconButton"
          title="Reset Search"
          @click="resetSearch()"
        >
          <img
            :src="iQuoteImagesMixin_iconClear"
            style="position:relative;top:5px"
            alt="clear">
        </button>
        <div>
          <div>
            <label for="txtAccount">Account</label>
            <input
              id="txtAccount"
              :value="designPackComponent.salesforceOpportunity.accountName"
              readonly="true"
              type="text"
            >
          </div>
          <div>
            <label for="txtOpportunityName">Opportunity Name</label>
            <input
              id="txtOpportunityName"
              :value="designPackComponent.salesforceOpportunity.opportunityName"
              type="text"
              readonly="true"
            >
          </div>
          <div>
            <label for="txtStageName">Stage</label>
            <input
              id="txtStageName"
              :value="designPackComponent.salesforceOpportunity.stageName"
              type="text"
              readonly="true"
            >
          </div>
          <br>
        </div>
      </div>
      <div
        v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
        id="designPackDetails">
        <br>
        <label
          :class="{InputError: iQuoteStore_GetDesignPack.name === ''}"
          for="txtDesignPackName"
        >Design Pack Name:
          <TooltipIcon
            width="500"
            text="Descriptive name for this design pack.  Do not include the revision number in this name" />
        </label>
        <input
          id="txtDesignPackName"
          v-model="iQuoteStore_GetDesignPack.name"
          :disabled="iQuoteStore_GetDesignPack.isReadOnly"
          type="text"
          @change="onDesignPackNameChanged"
        >
        <label
          for="cboDesignPackVersion"
        >Revision:
          <TooltipIcon
            width="500"
            text="This incremented each time you create a new revision design pack" />
        </label>
        <select
          id="cboDesignPackVersion"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          v-model="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId"
          style=""
          @change="onDesignPackRevisionNumberDropdownClicked($event)"
        >
          <option
            v-for="revision in iQuoteStore_GetDesignPack.revisions"
            :key="revision.id"
            :value="revision.id"
            :title="revision.revisionNumber"
          >{{ revision.revisionNumber }}
          </option>
        </select>
        <input
          v-if="(iQuoteStore_GetDesignPack.revisionNumber === iQuoteStore_GetDesignPack.revisions.length) && iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP"
          id="btnCreateRevision"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          value="Create a new Revision"
          type="button"
          @click="createNewRevisionClicked"
        >
        <input
          v-if="iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP && iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length === 0"
          id="btnDuplicateDesignPack"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          value="Duplicate Design Pack"
          type="button"
          @click="duplicateRevisionClicked()"
        >
        <br>
        <label
          for="chkUseDetailDataForSummaries"
        >Use Detail Data for Summaries
        </label>
        <input
          id="chkUseDetailDataForSummaries"
          v-model="iQuoteStore_GetDesignPack.useDetailDataForSummaries"
          disabled ="true"
          style="margin-top: 2px"
          type="checkbox"
          name=""
          @change="iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(iQuoteStore_GetDesignPack, 'useDetailDataForSummaries changed')">
        <br><br>
        <input
          v-if="downloadQuoteIsAllowed"
          id="downloadQuote"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          value="Download Customer Quote"
          type="button"
          @click="getQuoteClicked()"
        >
        <input
          v-if="!downloadQuoteIsAllowed"
          id="downloadQuoteDisabled"
          disabled="true"
          class="disabledButton"
          value="Download Customer Quote"
          title="Cannot download quote, as this DP is locked or is not the latest revision.  Create a new revision to make further changes and to be able to download again"
          type="button"
        >
        <input
          v-if="iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP && iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length === 0"
          id="downloadDesignPack"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          value="Export Design Pack"
          type="button"
          @click="getDesignPackClicked()"
        >
        <DownloadButton
          v-if="false"
          :download-func="() => UploadsAPIMixin_DownloadFile('iquote', designPackComponent.dpFileVersionId)"
          :default-filename="designPackComponent.dpFileName"
          :is-visible="iQuoteStore_GetDesignPack.quoteDesignPackTypeId === iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          file-type="application/vnd.ms-excel"
          title="Download DP"
        />
        <input
          v-if="designPackComponent.salesforceOpportunity !== null && uploadToSalesForceAllowed"
          id="uploadToSalesforce"
          :value="uploadToSalesforceButtonText"
          :disabled="designPackComponent.uploadingToSalesforce || designPackComponent.loading || designPackComponent.saving"
          :class="{disabledButton: designPackComponent.uploadingToSalesforce }"
          type="button"
          @click="uploadToSalesforceClicked()"
        >
        <input
          v-if="designPackComponent.salesforceOpportunity !== null && !uploadToSalesForceAllowed"
          id="uploadToSalesforce"
          value="Upload To SalesForce"
          disabled="true"
          class="disabledButton"
          type="button"
          title="Cannot upload if DP is locked, is a previous revision, or if Opportunity 'Order Verified' box is ticked in Salesforce"
        >
        <input
          v-if="designPackComponent.salesforceOpportunity !== null &&
            !iQuoteStore_GetDesignPack.isReadOnly &&
            iQuoteStore_GetDesignPack.dpExported &&
            iQuoteStore_GetDesignPack.revisionNumber === iQuoteStore_GetDesignPack.revisions.length &&
          iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP"
          id="'btnUploadFile'"
          :disabled="designPackComponent.loading || designPackComponent.saving"
          value="Upload Modified DP"
          type="button"
          @click="uploadFileButtonClicked('Upload Modified Design Pack Spreadsheet', 0)"
        >
        <input
          v-if="designPackComponent.salesforceOpportunity !== null && !(iQuoteStore_GetDesignPack.isReadOnly || iQuoteStore_GetDesignPack.locked) "
          id="lockQuote"
          style="display:none"
          value="Lock Quote"
          type="button"
          @click="setLockDPDialog(true)"
        >
      </div>
    </div>
    <div
      v-if="(!iQuoteStore_GetDesignPack.isReadOnly && !iQuoteStore_GetDesignPack.locked) && !designPackComponent.loading && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
      id="designPackEditingNotice"
      class="commercialsBanner">
      You have locked this DP for editing.  Other users can only view this DP
      <input
        v-if="(iQuoteStore_GetDesignPack.revisionNumber === iQuoteStore_GetDesignPack.revisions.length)"
        id="btnReleaseRevision"
        :disabled="designPackComponent.loading || designPackComponent.saving"
        value="Return to Read-only Mode"
        type="button"
        @click="releaseRevisionClicked()"
      >
    </div>
    <div
      v-if="(iQuoteStore_GetDesignPack.isReadOnly && !iQuoteStore_GetDesignPack.locked) && !designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
      id="designPackReadonlyNotice"
      class="commercialsBanner">
      This design pack revision is read-only<span v-if="(iQuoteStore_GetDesignPack.revisionNumber !== iQuoteStore_GetDesignPack.revisions.length)"> and is not the latest revision</span>.  You may only view the contents
      <input
        v-if="(iQuoteStore_GetDesignPack.revisionNumber === iQuoteStore_GetDesignPack.revisions.length)"
        id="btnEditRevision"
        value="Edit this Revision"
        type="button"
        @click="editRevisionClicked()"
      >
    </div>
    <div
      v-if="(iQuoteStore_GetDesignPack.isReadOnly && !iQuoteStore_GetDesignPack.locked) &&
        !designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0 &&
      (iQuoteCalculationsDesignPackDetailDataMixin_AllData()).length !==0 && iQuoteStore_GetDesignPack.useDetailDataForSummaries && iQuoteStore_GetDesignPackDetailedDataEntries.length === 0"
      id="designPackReadonlyNotice"
      class="commercialsBanner">
      This design pack revision needs to be recalculated before data will be shown.  Please edit the DP
    </div>
    <div
      v-if="!designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0 && iQuoteStore_GetDesignPack.users.filter(x => x.userId !== this.$store.getters.userid).length > 0"
      id="designPackUsersNotice"
      class="commercialsBanner">
      <div id="designPackUsersBox">
        <div id="designPackUsersHeader">Design Pack Users:</div>
        <ul>
          <li
            v-for="user in iQuoteStore_GetDesignPack.users.filter(x => x.userId !== this.$store.getters.userid)"
            :key="user.userId"
          >
            <span
              class="designPackUserName">
              {{ iQuoteStore_DisplayUserNameFromId(user.userId) + ' (ID: ' + user.userId + ')' }}
            </span>
            &nbsp;&nbsp;&nbsp;
            <span
              v-if="user.isEditing"
              class="designPackUserStatus"
            >
              Status: Editing
            </span>
            <span
              v-if="!user.isEditing"
              class="designPackUserStatus"
            >
              Status: Read-Only
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="(iQuoteStore_GetDesignPack.locked) && !designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
      id="designPackReadonlyNotice"
      class="commercialsBanner">
      This design pack revision is locked.  Create a new revision if further changes are required
    </div>
    <div
      v-if="mandatoryOrderCoordinationNotAdded"
      id="designPackWarningNotice"
      class="commercialsBanner">
      This design pack has an upfront price that exceeds £1000.  Please add Order Co-ordination, Project Coordinator, Delivery Manager or Project Manager to the Designpack
      <input
        id="btnAdd2HrsPC"
        :disabled="designPackComponent.loading || designPackComponent.saving"
        value="Add Order Co-ordination"
        type="button"
        @click="addPC()"
      >
    </div>
    <div
      v-if="mandatoryOrderCoordinationAdded"
      id="designPackWarningNotice"
      class="commercialsBanner">
      You have selected to add Order Co-ordination on the Design Overview tab, but enough PC time has already been added to the quote
      <input
        id="btnAdd2HrsPC"
        :disabled="designPackComponent.loading || designPackComponent.saving"
        value="Remove Order Co-ordination"
        type="button"
        @click="removePCClicked()"
      >
    </div>
    <div
      v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined &&
        !hasCommercialsPermission &&
      designPackHasMarginsOrDiscountsOutOfRange && !designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
      id="designPackWarningNotice"
      class="commercialsBanner">
      Margins and / or discounts have been exceeded - Please contact Commercial team
    </div>
    <div
      v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined &&
        !hasCommercialsPermission &&
      designPackHasPSOverLimit && !designPackComponent.loading && !designPackComponent.saving && designPackComponent.salesforceOpportunity.accountName !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
      id="designPackWarningNotice"
      class="commercialsBanner">
      A PS review is required for this opportunity, please contact the relevant practice lead
    </div>
    <div style="margin-top: 1%">
        <div v-if="validatePSEntries.showDeliveryManager3DaysWarning" class="commercialsBanner psBanner psBannerDeliveryManager3Days">Delivery Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
        <div v-if="validatePSEntries.showDeliveryManager5DaysWarning" class="commercialsBanner psBanner psBannerDeliveryManager5Days">Any more than 5 days requires Project Management not Delivery Manager</div>
        <div v-if="validatePSEntries.showProjectManager3DaysWarning" class="commercialsBanner psBanner psBannerProjectManager3Days">Project Manager minimum requirement is 3 days. Any less than this needs to be agreed with Project Governance</div>
    </div>
    <Spinner
      v-if="designPackComponent.loading || iQuoteStore_GetDesignPack.quoteDesignPackId === 0"
    />
    <div
      v-if="designPackComponent.loadingStatus !== ''"
      style="text-align: center; display: none"
    >
      {{ designPackComponent.loadingStatus }}
    </div>
    <div
      v-show="!designPackComponent.loading"
      id="mainDesignPackContent"
    >
      <div
        v-show="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined && designPackComponent.salesforceOpportunity.accountName !== ''"
        id="selectDesignPackSection">
        <TabStrip
          :tabs="chooseStartTabs"
          :selected-tab="designPackComponent.currentDesignPackSelectScreen"
          @tabChanged="handleStartTabChanged"/>
        <SelectDesignPack
          v-show="designPackComponent.currentDesignPackSelectScreen === 'SelectDesignPack'"
          :salesforce-quote-number="designPackComponent.salesforceQuoteNumber"
          @designpack-selected = "handleCommericalDesignPackComponent_DesignPackSelected"/>
        <CreateDesignPackFromQuote
          v-if="designPackComponent.currentDesignPackSelectScreen === 'CreateDesignPackFromQuote'"
          :salesforce-quote-number="designPackComponent.salesforceQuoteNumber"
          :salesforce-opportunity="designPackComponent.salesforceOpportunity"
          @designpack-selected = "handleCommericalDesignPackComponent_DesignPackSelected"/>
        <CreateStandaloneDesignPack
          v-if="designPackComponent.currentDesignPackSelectScreen === 'CreateStandaloneDesignPack'"
          :salesforce-quote-number="designPackComponent.salesforceQuoteNumber"
          :salesforce-opportunity="designPackComponent.salesforceOpportunity"
          @designpack-selected = "handleCommericalDesignPackComponent_DesignPackSelected"/>
        <CreateDesignPackFromExternalDP
          v-if="designPackComponent.currentDesignPackSelectScreen === 'CreateDesignPackFromExternalDP'"
          :salesforce-quote-number="designPackComponent.salesforceQuoteNumber"
          :salesforce-opportunity="designPackComponent.salesforceOpportunity"
          @designpack-selected = "handleCommericalDesignPackComponent_DesignPackSelected"/>
      </div>
      <div
        v-if="iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined && iQuoteStore_GetDesignPack.quoteDesignPackId > 0"
        id="designPackScreensSection">
        <TabStrip
          :tabs="designPackTabs"
          :selected-tab="designPackComponent.currentPanel"
          @tabChanged="handleDesignPackTabChanged"/>
        <DesignOverview
          v-show="designPackComponent.currentPanel === 'DesignOverview'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
          :salesforce-opportunity="designPackComponent.salesforceOpportunity"
          @showPSTabChanged="handleShowPSTabChanged"
          @showAutotaskTabChanged="handleShowAutotaskTabChanged"
          @showHardwareTabChanged="handleShowHardwareTabChanged"
          @showCircuitsTabChanged="handleShowCircuitsTabChanged"
          @showStandardCaveatsTabChanged="handleShowStandardCaveatsTabChanged"
          @showCeasesTabChanged="handleShowCeasesTabChanged"
        />
        <Iquote
          v-show="designPackComponent.currentPanel === 'Iquote'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <DetailedSummary
          v-show="designPackComponent.currentPanel === 'DetailedSummary'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <Summary
          v-show="designPackComponent.currentPanel === 'Summary'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <ProfessionalServices
          v-show="designPackComponent.currentPanel === 'ProfessionalServices'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <Autotask
          v-if="designPackComponent.currentPanel === 'Autotask'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
          :autotask-cust-acc-no="iQuoteStore_GetDesignPack.autotaskCustAccNo"
          @customerAccNoChanged="handleCustomerAccNoChanged"
        />
        <QuotePreview
          v-if="designPackComponent.currentPanel === 'QuotePreview'"
          :quote-entries="quotePreviewData"
          :autotask-cust-acc-no="iQuoteStore_GetDesignPack.autotaskCustAccNo"
          @customerAccNoChanged="handleCustomerAccNoChanged"
        />
        <Hardware
          v-show="designPackComponent.currentPanel === 'Hardware'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <Circuits
          v-show="designPackComponent.currentPanel === 'Circuits'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <StandardCaveats
          v-show="designPackComponent.currentPanel === 'StandardCaveats'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
        <Ceases
          v-show="designPackComponent.currentPanel === 'Ceases'"
          :is-read-only="iQuoteStore_GetDesignPack.isReadOnly"
        />
      </div>
      <br>
      <br>
      <br>
      <br>
    </div>
    <FileUploadDialogBox
      :dialog="designPackComponent.showFileUploadDialog"
      :dlg-message="designPackComponent.fileUploadDialogMessage"
      :dlg-close-from-top="false"
      :file-upload-catalog-id="designPackComponent.selectedFileUploadCatalogId"
      :checking-file="designPackComponent.fileDialogCheckingFile"
      :selected-file-error="designPackComponent.fileDialogSelectedFileError"
      dlgid="fileUploadDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      @uploadAction="fileUploaded"
      @cancelAction="designPackComponent.showFileUploadDialog = false"
      @fileChosenAction="fileChosen"
    />
    <ConfirmationDialogBox
      :dialog="designPackComponent.showUploadToSalesforceDialog"
      :dlg-message="`Continuing will lock Design Pack`"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="downloadUploadSalesforceDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="Cancel"
      positive-btn-text="Continue"
      @negativeAction="setUploadToSalesforceDialog(false)"
      @positiveAction="uploadDataToSalesForce()"
      @cancelDialog="setUploadToSalesforceDialog(false)"
    />
    <ConfirmationDialogBox
      :dialog="designPackComponent.showDownloadDocDialog"
      :dlg-message="'If you proceed, then this design pack will be locked, and you will need to create a new revision if further changes are required'"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="downloadDocConfirmationDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="Cancel"
      positive-btn-text="Proceed"
      @negativeAction="setDownloadDocDialog(false)"
      @positiveAction="downloadQuoteAutotaskTemplate(true)"
      @cancelDialog="setDownloadDocDialog(false)"
    />
    <ConfirmationDialogBox
      :dialog="designPackComponent.showLockDPDialog"
      :dlg-message="`Do you wish to lock Design Pack and any associated quote?  This cannot be reversed`"
      :dlg-close-from-top="false"
      :hide-buttons-after-press="false"
      dlgid="lockDPConfirmationDialogBox"
      dlg-icon="mdi-alert-circle-outline"
      negative-btn-text="No"
      positive-btn-text="Yes"
      @negativeAction="setLockDPDialog(false)"
      @positiveAction="lockDP()"
      @cancelDialog="setLockDPDialog(false)"
    />
    <DuplicateDesignPackDialogBox
      :dialog="designPackComponent.showDuplicateDesignPackDialog"
      :source-design-pack="iQuoteStore_GetDesignPack"
      :source-quote-number="designPackComponent.salesforceQuoteNumber"
      @negativeAction="setDuplicateDesignPackDialog(false)"
      @positiveAction="handleDesignPackDuplicated"
      @cancelDialog="setDuplicateDesignPackDialog(false)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import DownloadButton from '../../common/downloadButton'
import TabStrip from '../../common/tabStrip'
import TooltipIcon from '../../common/tooltipIcon'
import Spinner from '../../common/spinner'

import SelectDesignPack from '../designPackTabs/selectDesignPack'
import CreateDesignPackFromQuote from '../designPackTabs/createDesignPackFromQuote'
import CreateDesignPackFromExternalDP from '../designPackTabs/createDesignPackFromExternalDP'
import CreateStandaloneDesignPack from '../designPackTabs/createStandaloneDesignPack'
import DesignOverview from '../designPackTabs/designOverview'
import Iquote from '../designPackTabs/iQuote'
import Summary from '../designPackTabs/summary'
import ProfessionalServices from '../designPackTabs/professionalServices'
import Autotask from '../designPackTabs/autotask'
import QuotePreview from '../designPackTabs/quotePreview'
import Hardware from '../designPackTabs/hardware'
import Circuits from '../designPackTabs/circuits'
import StandardCaveats from '../designPackTabs/standardCaveats'
import Ceases from '../designPackTabs/ceases'
import DetailedSummary from '../designPackTabs/detailedSummary'
import ConfirmationDialogBox from '../../common/confirmationDialogBox'
import DuplicateDesignPackDialogBox from '../designPackComponents/duplicateDesignPackDialogBox'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteSalesforceAPIMixin } from '../../../mixins/iQuote/iQuoteSalesforceAPIMixin'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteLoaderMixin } from '../../../mixins/iQuote/utils/iQuoteLoaderMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../../../mixins/iQuote/API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteExportsAPIMixin } from '../../../mixins/iQuote/iQuoteExportsAPIMixin'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteCalculationsDesignPackExportMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackExport'
import { iQuoteCalculationsDesignPackSalesforceUploadMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackSalesforceUpload'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'
import { iQuoteEventBus } from '../../../events/iQuoteEvents'
import FileUploadDialogBox from '../../common/fileUploadDialogBox'
import { iQuoteDuplicateMixin } from '../../../mixins/iQuote/utils/iQuoteDuplicateMixin'
import debounce from 'lodash.debounce'

export default {
  name: 'CommercialDesignPack',
  components: {
    TabStrip,
    TooltipIcon,
    SelectDesignPack,
    CreateDesignPackFromQuote,
    CreateDesignPackFromExternalDP,
    CreateStandaloneDesignPack,
    Summary,
    ProfessionalServices,
    DetailedSummary,
    DesignOverview,
    Iquote,
    Autotask,
    Hardware,
    Circuits,
    StandardCaveats,
    Ceases,
    ConfirmationDialogBox,
    DownloadButton,
    Spinner,
    DuplicateDesignPackDialogBox,
    FileUploadDialogBox,
    QuotePreview
  },
  mixins: [
    iQuoteCalculationsDesignPackMixin,
    iQuoteSalesforceAPIMixin,
    iQuoteLoaderMixin,
    iQuoteStoreMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteFunctions,
    iQuoteExportsAPIMixin,
    externalQuotesModelMixin,
    iQuoteCalculationsDesignPackExportMixin,
    iQuoteCalculationsDesignPackSalesforceUploadMixin,
    iQuoteImagesMixin,
    iQuoteDuplicateMixin
  ],
  data () {
    return {
      designPackComponent: {
        isActive: false,
        customerFacingAutoTaskEntries: [],
        currentDesignPackSelectScreen: 'SelectDesignPack',
        currentPanel: '',
        showDownloadDocDialog: false,
        showLockDPDialog: false,
        showUploadToSalesforceDialog: false,
        showDuplicateDesignPackDialog: false,
        addFakeRecurringQuoteEntry: false,
        addFakeUpfrontQuoteEntry: false,
        salesforceOpportunity: {
          salesforceOpportunityId: 0,
          accountName: '',
          opportunityName: '',
          salesforceOpportunity: ''
        },
        salesforceQuoteNumber: '',
        designPackId: 0,
        dpFileName: '',
        dpFileVersionId: 0,
        designPackQuote: {
          id: 0
        },
        uploadingToSalesforce: false,
        loading: false,
        saving: false,
        loadingStatus: '',
        isQuoteChecked: false,
        showFileUploadDialog: false,
        fileUploadDialogMessage: '',
        selectedFileUploadCatalogId: 0,
        fileDialogCheckingFile: false,
        fileDialogSelectedFileError: ''
      }
    }
  },
  computed: {
    quotePreviewData () {
      var data
      if (!this.iQuoteStore_GetDesignPack.useDetailDataForSummaries) {
        data = this.designPackComponent.customerFacingAutoTaskEntries
      } else {
        data = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.visibleToCustomer === true && x.paused === false).map(x => ({
          categoryName: x.categoryName,
          description: x.quoteDescription === null ? x.description : x.quoteDescription,
          quantity: x.quantity,
          recurringCost: x.recurringCost,
          unitOOPrice: x.unitOOPriceOverride !== null ? x.unitOOPriceOverride : x.unitOOExternalPrice + x.unitOOInternalPrice,
          unitARPrice: x.unitARPriceOverride !== null ? x.unitARPriceOverride : x.unitARExternalPrice + x.unitARInternalPrice,
          itemId: x.itemId
        })).sort((a, b) => (a.categoryName + a.description > b.categoryName + b.description) ? 1 : ((b.categoryName + b.description > a.categoryName + a.description) ? -1 : 0))
      }

      if (this.designPackComponent.addFakeRecurringQuoteEntry) {
        data.push({
          categoryName: 'dummy',
          description: 'dummy recurring entry',
          quantity: 3,
          recurringCost: true,
          unitOOPrice: 0,
          unitARPrice: 100,
          itemId: 'dummy1'
        })
      }

      if (this.designPackComponent.addFakeUpfrontQuoteEntry) {
        data.push({
          categoryName: 'dummy',
          description: 'dummy upfront entry',
          quantity: 2,
          recurringCost: false,
          unitOOPrice: 1000,
          unitARPrice: 0,
          itemId: 'dummy2'
        })
      }

      return data
    },
    uploadToSalesForceAllowed () {
      return !this.designPackComponent.salesforceOpportunity.order_Verified__c
    },
    downloadQuoteIsAllowed () {
      return this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP &&
            this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length === 0 &&
            !this.iQuoteStore_GetDesignPack.locked &&
          this.iQuoteStore_GetDesignPack.revisionNumber === this.iQuoteStore_GetDesignPack.revisions.length
    },
    hasCommercialsPermission () {
      return this.$store.getters.iQuoteCommercialsPermission
    },
    uploadToSalesforceButtonText () {
      if (!this.designPackComponent.uploadingToSalesforce) {
        return 'Upload To SalesForce'
      } else {
        return '...'
      }
    },
    designPackHasPcOrPmTime () {
      var hasPCTime = false

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined) {
        this.iQuoteStore_GetDesignPack.psCoreEntries.filter(x => x.disabled === false).forEach(entry => {
          if ((entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.DELIVERY_MANAGER ||
               entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR ||
               entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_MANAGER) &&
               entry.designPackPsScheduleId > 0 && entry.units > 0) {
            hasPCTime = true
          }
        })
        this.iQuoteStore_GetDesignPack.psOptionsEntries.filter(x => x.disabled === false).forEach(entry => {
          if ((entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.DELIVERY_MANAGER ||
               entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR ||
               entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_MANAGER) &&
               entry.designPackPsScheduleId > 0 && entry.units > 0) {
            hasPCTime = true
          }
        })
        this.iQuoteStore_GetDesignPack.psExpenseEntries.filter(x => x.disabled === false).forEach(entry => {
          if ((entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR ||
               entry.designPackPsRoleProfileId === this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_MANAGER) &&
               entry.units > 0) {
            hasPCTime = true
          }
        })
      }
      let labourProjectManagerDay = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfileSchedules.find(x => x.id === this.iQuoteConstants.designPackPsRoleProfileSchedules.LABOUR_PROJECT_MANAGER_DAY)
      let labourProjectCoordinatorDay = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfileSchedules.find(x => x.id === this.iQuoteConstants.designPackPsRoleProfileSchedules.LABOUR_PROJECT_COORDINATOR_DAY)
      let labourProjectCoordinatorHalfDay = this.iQuoteStore_GetProfessionalServicesLookups.designPackPSRoleProfileSchedules.find(x => x.id === this.iQuoteConstants.designPackPsRoleProfileSchedules.LABOUR_PROJECT_COORDINATOR_HALF_DAY)

      if (this.externalQuotesModelMixin_State.quoteIconConnect.id !== undefined &&
        this.externalQuotesModelMixin_State.quoteIconConnect.autotaskEntries.find(
          x => x.code === 'LABOUR STD - Lead Consultant (Data/Security ITL) (Day)' || // code not found in DesignPackPSRoleProfileSchedules
               x.code === labourProjectManagerDay.code ||
               x.code === labourProjectCoordinatorDay.code
        ) !== undefined) {
        hasPCTime = true
      }

      if (this.externalQuotesModelMixin_State.quoteIconSecure.id !== undefined &&
        this.externalQuotesModelMixin_State.quoteIconSecure.autotaskEntries.find(
          x => x.code === 'LABOUR STD - Lead Consultant (Data/Security ITL) (Day)' || // code not found in DesignPackPSRoleProfileSchedules
          x.code === labourProjectManagerDay.code ||
          x.code === labourProjectCoordinatorDay.code
        ) !== undefined) {
        hasPCTime = true
      }

      if (this.externalQuotesModelMixin_State.quoteManagedService.id !== undefined &&
        this.externalQuotesModelMixin_State.quoteManagedService.autotaskEntries.find(
          x => x.code === 'LABOUR STD - Lead Consultant (Data/Security ITL) (Day)' || // code not found in DesignPackPSRoleProfileSchedules
          x.code === labourProjectManagerDay.code ||
          x.code === labourProjectCoordinatorDay.code
        ) !== undefined) {
        hasPCTime = true
      }

      if (this.externalQuotesModelMixin_State.quoteNewManagedService.id !== undefined &&
        this.externalQuotesModelMixin_State.quoteNewManagedService.autotaskEntries.find(
          x => x.code === 'LABOUR STD - Lead Consultant (Data/Security ITL) (Day)' || // code not found in DesignPackPSRoleProfileSchedules
          x.code === labourProjectManagerDay.code ||
          x.code === labourProjectCoordinatorDay.code
        ) !== undefined) {
        hasPCTime = true
      }

      if (this.iQuoteStore_GetQuoteUnitCostsRequired.filter(x =>
        x.unitCostId === this.iQuoteConstants.unitCosts.UC_ANALYTICS_PROJECT_DELIVERY_MANAGER ||
        x.unitCostId === this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATOR ||
        x.unitCostId === this.iQuoteConstants.unitCosts.TEAMS_PROJECT_COORDINATION_OOH
      ).length > 0) {
        hasPCTime = true
      }

      var projectCoordinationUnitCosts = this.iQuoteStore_GetQuoteUnitCostsRequired.filter(x =>
        this.utilFunctions_GetUnitCostFromId(x.unitCostId).sku === labourProjectCoordinatorHalfDay.code ||
        this.utilFunctions_GetUnitCostFromId(x.unitCostId).sku === labourProjectCoordinatorDay.code
      )
      if (projectCoordinationUnitCosts.length > 0) {
        hasPCTime = true
      }

      return hasPCTime
    },
    mandatoryOrderCoordinationNotAdded () {
      return this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined &&
        !this.iQuoteStore_GetDesignPack.isReadOnly && !this.iQuoteStore_GetDesignPack.locked &&
        !this.iQuoteStore_GetDesignPack.addPCTime && !this.designPackHasPcOrPmTime &&
        this.designPackUpfrontPriceExceedPCThreshold && !this.designPackComponent.loading &&
        this.designPackComponent.salesforceOpportunity.accountName !== undefined && this.iQuoteStore_GetDesignPack.quoteDesignPackId > 0
    },
    mandatoryOrderCoordinationAdded () {
      return this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined &&
        !this.iQuoteStore_GetDesignPack.isReadOnly && !this.iQuoteStore_GetDesignPack.locked &&
        this.iQuoteStore_GetDesignPack.addPCTime && this.designPackHasPcOrPmTime &&
        this.designPackUpfrontPriceExceedPCThreshold && !this.designPackComponent.loading &&
        this.designPackComponent.salesforceOpportunity.accountName !== undefined && this.iQuoteStore_GetDesignPack.quoteDesignPackId > 0
    },
    designPackHasPSOverLimit () {
      return this.iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost + this.iQuoteCalculationsDesignPackMixin_totalPSTabExternalCost > this.iQuoteConstants.limits.DP_PS_MAX_COST_WITHOUT_APPROVAL
    },
    designPackHasMarginsOrDiscountsOutOfRange () {
      var MarginsOrDiscountsOutOfRange = false

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined) {
        this.iQuoteStore_GetDesignPack.margins.forEach(entry => {
          if (
            (entry.upfrontExternalMargin !== null && (entry.upfrontExternalMargin < entry.upfrontExternalIquoteUserMinMargin || entry.upfrontExternalMargin >= 100)) ||
            (entry.upfrontInternalMargin !== null && (entry.upfrontInternalMargin < entry.upfrontInternalIquoteUserMinMargin || entry.upfrontInternalMargin >= 100)) ||
            (entry.recurringExternalMargin !== null && (entry.recurringExternalMargin < entry.recurringExternalIquoteUserMinMargin || entry.recurringExternalMargin >= 100)) ||
            (entry.recurringInternalMargin !== null && (entry.recurringInternalMargin < entry.recurringInternalIquoteUserMinMargin || entry.recurringInternalMargin >= 100))
          ) {
            MarginsOrDiscountsOutOfRange = true
          }
        })
        this.iQuoteStore_GetDesignPack.discounts.forEach(entry => {
          if (
            (entry.upfrontExternalDiscount !== null && (entry.upfrontExternalDiscount < 0 || entry.upfrontExternalDiscount > 10)) ||
            (entry.upfrontInternalDiscount !== null && (entry.upfrontInternalDiscount < 0 || entry.upfrontInternalDiscount > 10)) ||
            (entry.recurringExternalDiscount !== null && (entry.recurringExternalDiscount < 0 || entry.recurringExternalDiscount > 10)) ||
            (entry.recurringInternalDiscount !== null && (entry.recurringInternalDiscount < 0 || entry.recurringInternalDiscount > 10))
          ) {
            MarginsOrDiscountsOutOfRange = true
          }
        })
      }

      return MarginsOrDiscountsOutOfRange
    },
    designPackUpfrontPriceExceedPCThreshold () {
      return this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice > 1000
    },
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    search () {
      return require(`../../../assets/icons/search.png`)
    },
    designPackTabs () {
      var tabItems = []

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP ||
         this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length !== 0) {
        tabItems = [
          { name: 'Summary', text: 'Summary' },
          { name: 'DetailedSummary', text: 'Detailed Summary' }
        ]

        if (this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length !== 0) {
          tabItems.push({ name: 'Autotask', text: 'Autotask' })
        }
        return tabItems
      }

      tabItems = [
        { name: 'DesignOverview', text: 'Design Overview' },
        { name: 'Summary', text: 'Summary' },
        { name: 'DetailedSummary', text: 'Detailed Summary' }
      ]

      if (this.designPackComponent.customerFacingAutoTaskEntries.length > 0) {
        tabItems.push({ name: 'QuotePreview', text: 'Quote Preview' })
      }

      if (this.iQuoteStore_GetDesignPack.quoteId !== null) {
        tabItems.push({ name: 'Iquote', text: 'iQuote' })
      }

      if (this.iQuoteStore_GetDesignPack.showPSTab) {
        tabItems.push({ name: 'ProfessionalServices', text: 'Professional Services' })
      }

      if (this.iQuoteStore_GetDesignPack.showAutotaskTab) {
        tabItems.push({ name: 'Autotask', text: 'Autotask' })
      }

      if (this.iQuoteStore_GetDesignPack.showHardwareTab) {
        tabItems.push({ name: 'Hardware', text: 'Hardware' })
      }

      if (this.iQuoteStore_GetDesignPack.showCircuitsTab) {
        tabItems.push({ name: 'Circuits', text: 'Circuits' })
      }

      if (this.iQuoteStore_GetDesignPack.showStandardCaveatsTab) {
        tabItems.push({ name: 'StandardCaveats', text: 'Quotation Instructions' })
      }

      if (this.iQuoteStore_GetDesignPack.showCeasesTab) {
        tabItems.push({ name: 'Ceases', text: 'Ceases' })
      }

      return tabItems
    },
    chooseStartTabs () {
      return [
        { name: 'SelectDesignPack', text: 'Select Design Pack' },
        { name: 'CreateDesignPackFromQuote', text: 'Create Design Pack From Quote' },
        { name: 'CreateStandaloneDesignPack', text: 'Create Standalone Design Pack' },
        { name: 'CreateDesignPackFromExternalDP', text: 'Create Design Pack from Excel' }
      ]
    },
    quoteUpfrontPrice () {
      var total = 0
      this.quotePreviewData.forEach(entry => {
        if (entry.quantity > 0) {
          total += entry.unitOOPrice * entry.quantity
        } else if (entry.quantity < 0) {
          // If the item is a negative quantity then then it means we need to take away the
          // price (which is already negative in the customerFacingAutotaskEntries list).
          total -= entry.unitOOPrice * entry.quantity
        }
      })

      return total
    },
    quoteRecurringPrice () {
      var total = 0
      this.quotePreviewData.forEach(entry => {
        if (entry.quantity > 0) {
          total += entry.unitARPrice * entry.quantity
        } else {
          total -= entry.unitARPrice * entry.quantity
        }
      })

      return total
    },
    quoteUpfrontPriceMatchesDesignPackSellPrice () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        return true
      }

      if (!this.designPackComponent.isQuoteChecked) {
        return true
      }

      return this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice.toFixed(2) === this.quoteUpfrontPrice.toFixed(2)
    },
    quoteRecurringPriceMatchesDesignPackSellPrice () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        return true
      }

      if (!this.designPackComponent.isQuoteChecked) {
        return true
      }

      return this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice.toFixed(2) === this.quoteRecurringPrice.toFixed(2)
    },
    priceMismatchTooltipText () {
      var tooltipText = ''

      if (!this.quoteUpfrontPriceMatchesDesignPackSellPrice || !this.quoteRecurringPriceMatchesDesignPackSellPrice) {
        tooltipText += '<strong>CHECK CALCULATIONS</strong><br>'

        if (!this.quoteUpfrontPriceMatchesDesignPackSellPrice) {
          tooltipText += 'Quote Upfront Price: £' + this.quoteUpfrontPrice.toFixed(2) + ' vs. £' + this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice.toFixed(2) + ' on Design Pack<br>'
        }

        if (!this.quoteRecurringPriceMatchesDesignPackSellPrice) {
          tooltipText += 'Quote Recurring Price: £' + this.quoteRecurringPrice.toFixed(2) + ' vs. £' + this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice.toFixed(2) + ' on Design Pack'
        }
      }

      return tooltipText
    },
    validatePSEntries () {
      const coreValidationEntries = this.iQuoteCalculationsDesignPackPSMixin_validatePSEntries(this.iQuoteStore_GetDesignPack.psCoreEntries)
      const optionsValidationEntries = this.iQuoteCalculationsDesignPackPSMixin_validatePSEntries(this.iQuoteStore_GetDesignPack.psOptionsEntries)

      const showDeliveryManager3DaysWarning = !coreValidationEntries.validDeliveryManager3Days || !optionsValidationEntries.validDeliveryManager3Days 
      const showDeliveryManager5DaysWarning = !coreValidationEntries.validDeliveryManager5Days || !optionsValidationEntries.validDeliveryManager5Days 
      const showProjectManager3DaysWarning = !coreValidationEntries.validProjectManager3Days || !optionsValidationEntries.validProjectManager3Days

      return {showDeliveryManager3DaysWarning: showDeliveryManager3DaysWarning, showDeliveryManager5DaysWarning: showDeliveryManager5DaysWarning, showProjectManager3DaysWarning: showProjectManager3DaysWarning}
    }
  },
  async mounted () {
    await this.iQuoteCalculationsDesignPackMixin_updateStoreWithLatestCircuitsLookups()

    var designPack = this.iQuoteStore_GetDesignPack

    if (this.$router.currentRoute.path.startsWith('/iquote/designpacks/')) {
      var designPackRevisionId = 0
      if (this.$router.currentRoute.path.startsWith('/iquote/designpacks/' + this.$route.params.designpackid + '/r')) {
        // samplepath: /iquote/designpacks/12345/r5 where 12345 is the DP revision Id and 5 is the revision number

        var dpRevisions = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackRevisionsFromDb(this.$route.params.designpackid)

        dpRevisions.forEach(revision => {
          if (revision.revisionNumber === Number(this.$route.params.designpackRevisionNumber)) {
            designPackRevisionId = revision.id
          }
        })
      } else {
        // samplepath: /iquote/designpacks/12345 where 12345 is the DP revision Id
        designPackRevisionId = this.$route.params.designpackrevisionid
      }

      designPack = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb(designPackRevisionId)
    }

    if (designPack.quoteDesignPackRevisionId !== undefined) {
      var opp = await this.iQuoteQuoteAPIMixin_getOpportunityById(designPack.salesforceOpportunityId)

      if (opp.quoteNumber !== undefined) {
        this.designPackComponent.salesforceQuoteNumber = opp.quoteNumber
        await this.getSalesforceOpportunity() // Query salesforce for all details associated with this opportunity

        this.handleCommericalDesignPackComponent_DesignPackSelected(designPack)

        this.designPackComponent.currentPanel = 'DesignOverview'
      } else {
        this.iQuoteFunctions_ShowError('Unable to load opportunity id:' + designPack.salesforceOpportunityId)
      }
    }

    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        if (this.iQuoteCalculationsDesignPackMixin.designPackId !== newValue.quoteDesignPackRevisionId) {
          this.iQuoteCalculationsDesignPackMixin.designPackId = newValue.quoteDesignPackRevisionId
          if (newValue.quoteDesignPackRevisionId !== undefined) {
            // every time the DP changes we reload the pricing for circuits
            this.iQuoteCalculationsDesignPackMixin_updateStoreWithLatestCircuitsLookups()
          }
        }        
      }
    )    
  },
  async created () {
    setTimeout(this.pollForUsersOnDP, 30000)
    this.designPackComponent.isActive = true

    this.recalcCustomerQuoteDebounced = debounce(this.recalcCustomerQuote, 1000)
    this.createNewRevisionDebounce = debounce(this.createNewRevision, 700)

    iQuoteEventBus.$on('designpack-saved', async (designPack) => {
      this.designPackComponent.saving = false
      this.recalcCustomerQuoteDebounced()
      await this.iQuoteFunctions_delay(2000) // wait until the designpack is fully populated in store before checking if mandatoryOrderCoordinationNotAdded()

      if (this.mandatoryOrderCoordinationNotAdded) {
        if (!this.iQuoteStore_GetDesignPack.removedPCTime) { // if user removed PC time via banner warning, do not add it automatically again
          this.addPC()
        }
      }
    })

    iQuoteEventBus.$on('designpack-saving', async (designPack) => {
      this.designPackComponent.saving = true
    })

    iQuoteEventBus.$on('designpack-modified', async (designPack, reason) => {
      const currentDesignPackId = designPack.quoteDesignPackId
      if (reason === 'duplicate designpack positiveAction') {
        designPack.quoteDesignPackId = 0
      }

      if (designPack.quoteDesignPackRevisionId === 0) {
        // Creating a new revision
        if (designPack.quoteId !== null) {
          var quote = this.iQuoteStore_GetQuote

          console.log('creating new quote revision.  Source Quote ID:' + quote.id)

          // If we are creating a duplicate DP (rather than a new revision), then we will also create a duplicate of the quote
          if (designPack.quoteDesignPackId === 0) {
            this.designPackComponent.loadingStatus = 'Getting Opportunity Data'
            var opp = await this.iQuoteQuoteAPIMixin_getOpportunityById(designPack.salesforceOpportunityId)

            quote.salesforceOpportunityId = opp.opportunityId
            quote.salesforceQuoteNumber = opp.quoteNumber
            quote.salesforceAccountName = opp.accountName
            quote.salesforceContactFirstName = opp.contactFirstName
            quote.salesforceContactLastName = opp.contactLastName
            quote.salesforceContactEmail = opp.contactEmail
            quote.salesforceOpportunityName = opp.opportunityName
            this.designPackComponent.loadingStatus = 'Duplicating Quote'
            quote = await this.iQuoteDuplicate_CreateDuplicateQuote(quote, true, false)
          } else {
            this.designPackComponent.loadingStatus = 'Duplicating Quote'
            quote = await this.iQuoteDuplicate_CreateDuplicateQuote(quote, false, false)
          }

          if (quote.id === undefined) {
            if (designPack.quoteDesignPackId === 0) {
              this.iQuoteFunctions_ShowError('Error creating Quote Duplicate')
            } else {
              this.iQuoteFunctions_ShowError('Error creating new Quote Revision')
            }

            return /* Failed! So don't create new revision */
          } else {
            console.log('New quote revision created.  New Quote ID:' + quote.id)
          }

          designPack.quoteId = quote.id
        }
      }

      this.designPackComponent.loadingStatus = 'Saving Changes to Designpack'
      var designpackSaved = await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'designpack-modified event')
      this.designPackComponent.loadingStatus = ''

      if (designPack.quoteDesignPackId === 0) {
        await this.handleCommericalDesignPackComponent_DesignPackSelected(designpackSaved)
        this.iQuoteFunctions_ShowMessage('Design Pack #' + designpackSaved.quoteDesignPackId + ' has been created')
      } else if (designPack.quoteDesignPackRevisionId === 0) {
        await this.handleCommericalDesignPackComponent_DesignPackSelected(designpackSaved)
        this.iQuoteFunctions_ShowMessage('Revision #' + designpackSaved.revisionNumber + ' has been created')
      }

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId !== this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        this.designPackComponent.dpFileName = ''
        this.designPackComponent.dpFileVersionId = 0
      } else {
        // var fileCatalogEntry = await this.UploadsAPIMixin_GetFileUploadCatalogEntry('iQuote', designpackSaved.externalDesignPackFileUploadCatalogId)
        // this.designPackComponent.dpFileVersionId = 0 // fileCatalogEntry.versionList[designpackSaved.revisionNumber].FileUploadRevisionId
        // this.designPackComponent.dpFileName = await this.UploadsAPIMixin_GetFileDetailsByVersionId('iQuote', this.designPackComponent.dpFileVersionId).name
      }

      this.setDesignPack(designpackSaved)
      if (reason === 'createNewRevisionClicked') {
        await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing('Revision created', designpackSaved.revisionNumber, null)
      } else if (reason === 'duplicate designpack positiveAction') {
        await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing('DP duplicated', designpackSaved.revisionNumber, currentDesignPackId)
      }

      this.designPackComponent.loading = false
    })
  },
  async destroyed () {
    iQuoteEventBus.$off('designpack-modified')
    iQuoteEventBus.$off('designpack-saving')
    iQuoteEventBus.$off('designpack-saved')
    this.designPackComponent.isActive = false
  },
  methods: {
    ...mapActions([
      'setCurrentQuotePage',
      'setQuoteUsers',
      'setDesignPackList',
      'setDesignPack'
    ]),
    invertShowQuoteDummyData () {
      this.designPackComponent.addFakeUpfrontQuoteEntry = !this.designPackComponent.addFakeUpfrontQuoteEntry
      this.designPackComponent.addFakeRecurringQuoteEntry = !this.designPackComponent.addFakeRecurringQuoteEntry
    },
    async pollForUsersOnDP () {
      await this.refreshActivityOnDP()

      // Need to stop this timer if the component is destroyed
      if (this.designPackComponent.isActive) {
        setTimeout(this.pollForUsersOnDP, 30000)
      }
    },
    async recalcCustomerQuote () {
      this.designPackComponent.isQuoteChecked = false
      this.designPackComponent.customerFacingAutoTaskEntries = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackCustomerAutotaskEntriesFromDb(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
      this.designPackComponent.isQuoteChecked = true
    },
    setDownloadDocDialog: function (value) {
      this.designPackComponent.showDownloadDocDialog = value
    },
    setLockDPDialog: function (value) {
      this.designPackComponent.showLockDPDialog = value
    },
    setUploadToSalesforceDialog: function (value) {
      this.designPackComponent.showUploadToSalesforceDialog = value
    },
    setDuplicateDesignPackDialog: function (value) {
      this.designPackComponent.showDuplicateDesignPackDialog = value
    },
    uploadToSalesforceClicked: async function () {
      //      if (this.iQuoteStore_GetDesignPack.locked) {
      await this.uploadDataToSalesForce()
      //      } else {
      //        if (await this.requestToEditDP()) {
      //          this.setUploadToSalesforceDialog(true)
      //        }
      //     }
    },
    getQuoteClicked: async function () {
      if (await this.requestToEditDP()) {
        if (!this.iQuoteStore_GetDesignPack.locked) {
          // It's possible, it may have been locked by another person, whilst we were waiting to get access
          this.setDownloadDocDialog(true)
        } else {
          this.iQuoteFunctions_ShowError('DP has been locked.  Cannot download')
        }
      }
    },
    addPC () {
      this.iQuoteStore_GetDesignPack.addPCTime = true
      this.iQuoteStore_GetDesignPack.priceOverrideOrderCoordination = null
      iQuoteEventBus.$emit('designpack-modified', this.iQuoteStore_GetDesignPack, 'addPC')
    },
    removePCClicked () {
      this.iQuoteStore_GetDesignPack.addPCTime = false
      this.iQuoteStore_GetDesignPack.removedPCTime = true
      this.iQuoteStore_GetDesignPack.priceOverrideOrderCoordination = null
      iQuoteEventBus.$emit('designpack-modified', this.iQuoteStore_GetDesignPack, 'removePCClicked')
    },
    getDesignPackClicked: async function () {
      this.downloadDesignPackExport()
    },
    handleStartTabChanged (newTab) {
      if (newTab.name === 'SelectDesignPack') {
        this.searchDesignPacks()
      }
      this.designPackComponent.currentDesignPackSelectScreen = newTab.name
    },
    async handleDesignPackTabChanged (newTab) {
      this.designPackComponent.currentPanel = newTab.name
      this.refreshActivityOnDP()
      this.setDesignPack(this.iQuoteStore_GetDesignPack)
    },
    async uploadDataToSalesForce () {
      var hadWriteAccess = !this.iQuoteStore_GetDesignPack.isReadOnly
      var requestedWriteAccess = false

      if ((this.iQuoteStore_GetDesignPack.revisionNumber === this.iQuoteStore_GetDesignPack.revisions.length) && !this.iQuoteStore_GetDesignPack.locked) {
        // If this is the latest revision, and it's not locked, then we need to request
        // to edit it, to ensure that nobody else is editing it before uploading to salesforce and to get the latest
        // version from the DB in case anybody else changed it whilst we were viewing.

        if (await this.requestToEditDP()) {
          requestedWriteAccess = true
        } else {
          // requestToEditDP() will show a message if it fails, so no need to do so here. We just return from this function
          return
        }
      }

      this.designPackComponent.uploadingToSalesforce = true

      var nameValuePairs = this.iQuoteCalculationsDesignPackSalesforceUploadMixin_getNameValuePairs()
      var data = await this.iQuoteSalesforceAPIMixin_setSalesForceOpportunityValues(this.designPackComponent.salesforceQuoteNumber, nameValuePairs)

      if (data.result === undefined) {
        this.iQuoteFunctions_ShowError('Error contacting the Salesforce service.  Please inform the system administrator')
      } else if (data.result === 'ok') {
        var salesforceSaveResult = await this.iQuoteSalesforceAPIMixin_setiQuoteLink(this.designPackComponent.salesforceQuoteNumber, window.location.href)

        if (salesforceSaveResult.result !== 'ok') {
          this.iQuoteFunctions_ShowError('Error setting DP link in salesforce: ' + salesforceSaveResult.detail)
        } else {
          this.iQuoteFunctions_ShowMessage('Salesforce Opportunity ' + this.designPackComponent.salesforceQuoteNumber + ' has been updated succesfully.')

          if (requestedWriteAccess) {
            // get latest status of the opportunity, in case it has updated it since DP was loaded
            var opp = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.designPackComponent.salesforceQuoteNumber)

            if (opp.ccaasProvider !== this.iQuoteStore_GetDesignPack.ccaasProvider ||
                opp.ucaasProvider !== this.iQuoteStore_GetDesignPack.ucaasProvider) {
              // Salesforce has been updated since we loaded the DP. So save latest values to DP
              this.iQuoteStore_GetDesignPack.ucaasProvider = opp.ucaasProvider
              this.iQuoteStore_GetDesignPack.ccaasProvider = opp.ccaasProvider

              // No need to wait for the result, as we aren't going to use the result immediately
              this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(this.iQuoteStore_GetDesignPack, 'uploadDataToSalesForce')
            }
          }
        }
      } else {
        this.iQuoteFunctions_ShowError(data.detail)
      }
      this.designPackComponent.uploadingToSalesforce = false

      if (!hadWriteAccess && requestedWriteAccess) {
      // Put user back to not-editing, if they weren't before
        this.requestDPReadonlyAccess(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
      }
    },
    getSalesforceOpportunity: async function () {
      this.designPackComponent.loading = true
      this.designPackComponent.loadingStatus = 'Loading Salesforce Opportunity'

      var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.designPackComponent.salesforceQuoteNumber)

      if (SalesforceOpportunity === null || SalesforceOpportunity.accountName === undefined) {
        this.designPackComponent.salesforceOpportunity = {
          salesforceOpportunityId: 0,
          accountName: '',
          opportunityName: '',
          salesforceOpportunity: ''
        }

        if (SalesforceOpportunity === null) {
          this.iQuoteFunctions_ShowError('Error Querying Salesforce.  Please try again.')
        } else if (SalesforceOpportunity.accountName === undefined) {
          this.iQuoteFunctions_ShowError('Salesforce Opportunity not found.  Please check that you typed it correctly and that it is not deleted from Salesforce.')
        }
      } else {
        this.designPackComponent.salesforceOpportunity = SalesforceOpportunity
        this.designPackComponent.currentDesignPackSelectScreen = 'SelectDesignPack'
        this.searchDesignPacks()
      }
      this.designPackComponent.loading = false
      this.designPackComponent.loadingStatus = ''
    },
    handleShowPSTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showPSTab = newValue
    },
    handleShowAutotaskTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showAutotaskTab = newValue
    },
    handleShowHardwareTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showHardwareTab = newValue
    },
    handleShowCircuitsTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showCircuitsTab = newValue
    },
    handleShowStandardCaveatsTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showStandardCaveatsTab = newValue
    },
    handleShowCeasesTabChanged: function (newValue) {
      this.iQuoteStore_GetDesignPack.showCeasesTab = newValue
    },
    searchDesignPacks: async function () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined) {
        this.iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
        this.setDesignPack({})
      }

      var designPacks = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPacksByOpportunityFromDb(this.designPackComponent.salesforceQuoteNumber)
      this.setDesignPackList(designPacks)
    },
    resetSearch () {
      this.designPackComponent.salesforceQuoteNumber = ''
      this.designPackComponent.salesforceOpportunity.salesforceOpportunityId = 0
      this.designPackComponent.salesforceOpportunity.accountName = ''
      this.designPackComponent.salesforceOpportunity.opportunityName = ''
      this.designPackComponent.loading = false
      this.designPackComponent.loadingStatus = ''

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined) {
        this.iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
        this.setDesignPack({})
      }

      setTimeout(() => {
        this.$refs.txtSFQuote.focus()
      }, 350)
    },
    resetSalesforceOpportunity: async function () {
      this.designPackComponent.salesforceOpportunity.salesforceOpportunityId = 0
      this.designPackComponent.salesforceOpportunity.accountName = ''
      this.designPackComponent.salesforceOpportunity.opportunityName = ''

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined) {
        this.iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)
        this.setDesignPack({})
      }

      if (this.designPackComponent.salesforceQuoteNumber !== undefined && this.designPackComponent.salesforceQuoteNumber.length === 7) {
        this.getSalesforceOpportunity()
      } else {
        this.designPackComponent.salesforceOpportunity.salesforceOpportunityId = 0
      }
    },
    async onDesignPackRevisionNumberDropdownClicked (event) {
      if (this.designPackComponent.loading === true || this.designPackComponent.saving === true) {
        return
      }

      this.designPackComponent.loading = true
      var revisionId = event.target.value

      var currentPanel = this.designPackComponent.currentPanel

      var designPack = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb(revisionId)

      await this.handleCommericalDesignPackComponent_DesignPackSelected(designPack)

      // handleCommericalDesignPackComponent_DesignPackSelected() function will have switched the tab back to overview,
      // but when changing revision we want to keep the tab the same if possible.  So restore it to what it was
      // before and only change it if it's not applicable to this revision
      this.designPackComponent.currentPanel = currentPanel

      if (!designPack.showPSTab && this.designPackComponent.currentPanel === 'ProfessionalServices') {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (!designPack.showAutotaskTab && this.designPackComponent.currentPanel === 'Autotask') {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (!designPack.showHardwareTab && this.designPackComponent.currentPanel === 'Hardware') {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (!designPack.showCircuitsTab && this.designPackComponent.currentPanel === 'Circuits') {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (!designPack.showCeasesTab && this.designPackComponent.currentPanel === 'Ceases') {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (this.iQuoteStore_GetDesignPack.quoteId === null && this.designPackComponent.currentPanel === 'Iquote') {
        // This is not possible at time of writing, as you can't add or remove a quote from a DP, but just in case that's added
        // later, this check must makes sure we cope with that
        this.designPackComponent.currentPanel = 'DesignOverview'
      }
      this.designPackComponent.saving = false
      this.designPackComponent.loading = false
    },
    async handleCommericalDesignPackComponent_DesignPackSelected (newSelectedDesignPack, setLoadingFlag = true) {
      if (setLoadingFlag) {
        this.designPackComponent.loading = true
      }

      this.designPackComponent.isQuoteChecked = false

      this.designPackComponent.loadingStatus = 'Getting Opportunity Data'

      // get latest status of the opportunity, in case the user has updated it since opp was last read
      var opp = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.designPackComponent.salesforceQuoteNumber)

      // Update DP with latest state from salesforce for uc and cc providers
      newSelectedDesignPack.ucaasProvider = opp.ucaasProvider
      newSelectedDesignPack.ccaasProvider = opp.ccaasProvider

      this.setDesignPack(newSelectedDesignPack) // Need to save it to the store before recalculating discounts and margins
      var isNewRevision = newSelectedDesignPack.quoteDesignPackRevisionId === 0

      if (!isNewRevision) {
        history.pushState(
          {},
          null,
          '/iquote/designpacks/' + encodeURIComponent(newSelectedDesignPack.quoteDesignPackId) + '/r' + encodeURIComponent(newSelectedDesignPack.revisionNumber)
        )

        this.designPackComponent.loadingStatus = 'Requesting Permission To Read Designpack'

        await this.iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser(newSelectedDesignPack.quoteDesignPackRevisionId,
          {
            userId: this.$store.getters.userid,
            designPackLockSectionId: 1,
            requestToEdit: !newSelectedDesignPack.isReadOnly
          }
        )
      }

      if (newSelectedDesignPack.quoteId !== null) {
        this.designPackComponent.loadingStatus = 'Loading Quote Into Store'
        await this.iQuoteLoaderMixin_loadQuoteIntoStore(newSelectedDesignPack.quoteId)
        this.designPackComponent.loadingStatus = 'Loading External Calculators'
        await this.externalQuotesModelMixin_loadExternalQuotes(newSelectedDesignPack.quoteId)

        newSelectedDesignPack.ucUsers = this.calcUcUsers()
        newSelectedDesignPack.ccUsers = this.calcCcUsers()
      } else {
        this.iQuoteLoaderMixin_clearQuoteDataFromStore()
      }

      if (newSelectedDesignPack.isReadOnly) {
        this.recalcCustomerQuoteDebounced()

        // Store the last known detail data
        this.setDesignPackDetailedDataEntries(await this.iQuoteQuoteDesignPacksAPIMixin_getDetailedData(newSelectedDesignPack.quoteDesignPackRevisionId))
      } else {
          this.designPackComponent.loadingStatus = 'Updating DP with latest changes based on Quote'

        await this.editRevision('DesignPackSelectedRecalculation')
       
        newSelectedDesignPack = await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(newSelectedDesignPack, 'New DesignPack Selected - Refresh Based on Latest Quote')
      }

      if (newSelectedDesignPack.useDetailDataForSummaries) {
        // Recalc price overrides to match the detail data, just in case they are out of sync
        newSelectedDesignPack.priceOverrides.forEach(entry => {
          var dpSectionDescription = entry.description
          var dpSectionSalesforceCoeCodeId = entry.salesforceCoeCodeId

          newSelectedDesignPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(newSelectedDesignPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringExternalSellPrice')
          newSelectedDesignPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(newSelectedDesignPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringInternalSellPrice')
          newSelectedDesignPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(newSelectedDesignPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontExternalSellPrice')
          newSelectedDesignPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(newSelectedDesignPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontInternalSellPrice')
        })
      }

      if (isNewRevision) {
        history.pushState(
          {},
          null,
          '/iquote/designpacks/' + encodeURIComponent(newSelectedDesignPack.quoteDesignPackId) + '/r' + encodeURIComponent(newSelectedDesignPack.revisionNumber)
        )

        await this.iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser(newSelectedDesignPack.quoteDesignPackRevisionId,
          {
            userId: this.$store.getters.userid,
            designPackLockSectionId: 1,
            requestToEdit: true
          }
        )
      }

      if (newSelectedDesignPack.useDetailDataForSummaries) {
        // Save Recalculated price overrides to match the detail data, just in case they are out of sync

        if (newSelectedDesignPack.isReadOnly) {
          this.setDesignPack(newSelectedDesignPack)
        } else {
          await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(newSelectedDesignPack, 'New DesignPack Selected - Refresh Price Overrides Achieved')
        }
      }

      if (setLoadingFlag) {
        this.designPackComponent.loading = false
      }

      this.designPackComponent.loadingStatus = ''

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP || this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries?.length > 0) {
        this.designPackComponent.currentPanel = 'Summary'
      } else {
        this.designPackComponent.currentPanel = 'DesignOverview'
      }

      if (!this.designPackComponent.saving) {
        const action = 'Viewed'
        await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing(action, this.iQuoteStore_GetDesignPack.revisionNumber, null)
      }

      return newSelectedDesignPack
    },
    calcUcUsers () {
      var count = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
          if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ENTRY_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.PREMIER_UC) {
            count += userProfile.minCommitment
          }
        })
      }

      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId !== undefined) {
        this.iQuoteStore_GetQuoteRingCentral.ucUserProfiles.forEach(userProfile => {
          if (userProfile.userProfileCategoryId === this.iQuoteConstants.RingCentralUCUserProfileCategories.PROFILES) {
            count += userProfile.minCommitment
          }
        })
      }

      if (this.iQuoteStore_GetTeamsConnector.id !== undefined) {
        count += this.iQuoteStore_GetTeamsConnector.numTeamsUsers
      }

      if (this.externalQuotesModelMixin_State.quoteIconNow.id !== undefined) {
        this.externalQuotesModelMixin_State.quoteIconNow.autotaskEntries.forEach(entry => {
          if (entry.code === 'ICON-Now-Basic' ||
            entry.code === 'ICON-Now-Entry' ||
            entry.code === 'ICON-Now-Standard' ||
            entry.code === 'ICON-Now-Premium' ||
            entry.code === 'ICON-Now-Ultimate' ||
            entry.code === 'ICON-Now-Attendant' ||
            entry.code === 'ICON-Now-Agent'
          ) {
            count += entry.quantity
          }
        })
      }

      return count
    },
    calcCcUsers () {
      var count = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.forEach(userProfile => {
          if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX) {
            count += userProfile.minCommitment
          }
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.userProfiles.forEach(userProfile => {
          count += userProfile.minCommitment
        })
      }

      if (this.iQuoteStore_GetQuoteRingCentral.ccTechId !== undefined) {
        this.iQuoteStore_GetQuoteRingCentral.ccUserProfiles.forEach(userProfile => {
          if (userProfile.userProfileCategoryId === this.iQuoteConstants.RingCentralCCUserProfileCategories.PROFILES) {
            count += userProfile.minCommitment
          }
        })
      }

      if (this.iQuoteStore_GetQuoteGenesys.id !== undefined) {
        this.iQuoteStore_GetQuoteGenesys.userProfiles.forEach(userProfile => {
          if (userProfile.userProfileCategoryId === this.iQuoteConstants.GenesysUserProfileCategories.PROFILES) {
            count += userProfile.minCommitment
          }
        })
      }

      return count
    },
    onDesignPackNameChanged () {
      iQuoteEventBus.$emit('designpack-modified', this.iQuoteStore_GetDesignPack, 'onDesignPackNameChanged')
    },
    async requestToEditDP () {
      if (!this.iQuoteStore_GetDesignPack.isReadOnly) {
        // Already have write permission, no need to request
        return true
      }

      const action = 'Edited'
      await this.iQuoteQuoteDesignPacksAPIMixin_addAuditing(action, this.iQuoteStore_GetDesignPack.revisionNumber, null)

      let result = false
      this.designPackComponent.saving = true

      this.iQuoteStore_GetDesignPack.users = await this.iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId,
        {
          userId: this.$store.getters.userid,
          designPackLockSectionId: 1,
          requestToEdit: true
        }
      )

      if (this.iQuoteStore_GetDesignPack.users.find(x => x.userId === this.$store.getters.userid && x.isEditing && x.designPackLockSectionId === 1) !== undefined) {
        var designPack = this.iQuoteStore_GetDesignPack

        if (designPack.locked) {
          // If the DP is locked that means we don't want to edit anything on it.  So we must return a new revision
          designPack.quoteDesignPackRevisionId = 0
          designPack.useDetailDataForSummaries = true
          designPack.locked = false

          // Need to appear as new DP object, so that any watches on designpack are triggered
          var dpCopy = { ...designPack }
          designPack = dpCopy
        } else {
          // DP is not locked.  That means that somebody else could have edited whilst we were viewing.  Get the latest version
          designPack = await this.iQuoteQuoteDesignPacksAPIMixin_getQuoteDesignPackFromDb(designPack.quoteDesignPackRevisionId)

          if (designPack.quoteId !== null) {
            await this.iQuoteLoaderMixin_loadQuoteIntoStore(designPack.quoteId)
            await this.externalQuotesModelMixin_loadExternalQuotes(designPack.quoteId)

            designPack.ucUsers = this.calcUcUsers()
            designPack.ccUsers = this.calcCcUsers()
            this.recalcCustomerQuoteDebounced()
          }

          this.setDesignPackDetailedDataEntries(await this.iQuoteQuoteDesignPacksAPIMixin_getDetailedData(designPack.quoteDesignPackRevisionId))
        }

        designPack.isReadOnly = false
        this.setDesignPack(designPack)

        if (designPack.quoteDesignPackRevisionId === undefined) {
          this.iQuoteFunctions_ShowError('Error getting latest DesignPack version')
        } else {
          result = true
        }
      } else {
        this.iQuoteFunctions_ShowError('Another user is editing this DP, or this is no longer the latest revision')
      }

      this.designPackComponent.saving = false
      return result
    },
    async requestDPReadonlyAccess () {
      var designPack = this.iQuoteStore_GetDesignPack

      designPack.users = await this.iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser(designPack.quoteDesignPackRevisionId,
        {
          userId: this.$store.getters.userid,
          designPackLockSectionId: 1,
          requestToEdit: false
        }
      )

      if (designPack.users.find(x => x.userId === this.$store.getters.userid && !x.isEditing && x.designPackLockSectionId === 1) !== undefined) {
        designPack.isReadOnly = true
        this.setDesignPack(designPack)
      } else {
        this.iQuoteFunctions_ShowError('Failed to release this DP')
      }
    },
    async editRevision (reason) {
        // Update DP to relect latest status with prices / quote items.
        var designPack = this.iQuoteStore_GetDesignPack

        if (designPack.quoteId !== null) {
          this.calculationsCommon_Calculate()

          // Save back to DB, as this may be different to prior numbers
          // if licenses / unitcosts etc have changed prices / costs
          await this.calculationsCommon_saveQuoteCalculationsToDB()
        }

        await this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, reason)
    },
    async editRevisionClicked () {
      if (await this.requestToEditDP()) {
        await this.editRevision('editRevisionClicked')
      }
    },
    async releaseRevisionClicked () {
      await this.requestDPReadonlyAccess()
    },
    async refreshActivityOnDP () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId !== undefined &&
        this.designPackComponent.salesforceOpportunity.accountName !== undefined &&
        this.designPackComponent.salesforceOpportunity.accountName !== '') {
        var usersBefore = this.iQuoteStore_GetDesignPack.users

        this.iQuoteStore_GetDesignPack.users = await this.iQuoteQuoteDesignPacksAPIMixin_requestDesignPackUser(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId,
          {
            userId: this.$store.getters.userid,
            designPackLockSectionId: 1,
            requestToEdit: !this.iQuoteStore_GetDesignPack.isReadOnly
          }
        )

        usersBefore.forEach(userBefore => {
          if (userBefore.userId !== this.$store.getters.userid) {
            var userNow = this.iQuoteStore_GetDesignPack.users.find(x => x.userId === userBefore.userId)

            // If what a DP user was doing with this DP has changed, then say what changed.
            if (userNow !== undefined) {
              if (!userBefore.isEditing && userNow.isEditing) {
                this.iQuoteFunctions_ShowMessage(this.iQuoteStore_DisplayUserNameFromId(userBefore.userId) + ' is now editing this Design Pack')
              } else if (userBefore.isEditing && !userNow.isEditing) {
                this.iQuoteFunctions_ShowMessage(this.iQuoteStore_DisplayUserNameFromId(userBefore.userId) + ' has stopped editing this Design Pack')
              }
            } else {
              this.iQuoteFunctions_ShowMessage(this.iQuoteStore_DisplayUserNameFromId(userBefore.userId) + ' is no longer looking at this Design Pack')
            }
          }
        })

        this.iQuoteStore_GetDesignPack.users.forEach(userNow => {
          if (userNow.userId !== this.$store.getters.userid) {
            var userBefore = usersBefore.find(x => x.userId === userNow.userId)

            // If user wasn't looking at this DP last time we checked, show a message to say what they are doing now
            if (userBefore === undefined) {
              if (userNow.isEditing) {
                this.iQuoteFunctions_ShowMessage(this.iQuoteStore_DisplayUserNameFromId(userNow.userId) + ' is now editing this Design Pack')
              } else {
                this.iQuoteFunctions_ShowMessage(this.iQuoteStore_DisplayUserNameFromId(userNow.userId) + ' is now viewing this Design Pack')
              }
            }
          }
        })
      }
    },
    async createNewRevisionClicked () {
      this.designPackComponent.loading = true
      this.createNewRevisionDebounce()
    },
    async createNewRevision () {
      var designPack = this.iQuoteStore_GetDesignPack
      var wasLocked = designPack.locked
      var quoteDesignPackRevisionIdToBeReleased = designPack.quoteDesignPackRevisionId

      if (await this.requestToEditDP()) {
        var error = ''

        // Get DP from store again, in case requestToEditDP() updated it
        designPack = this.iQuoteStore_GetDesignPack

        if (wasLocked && !designPack.locked && quoteDesignPackRevisionIdToBeReleased !== designPack.quoteDesignPackRevisionId) {
          // This was a locked DP and requestingToEdit it unlocked it and created a new DP revision.  No need to set quoteDesignPackRevision to 0
        } else if (wasLocked && designPack.locked) {
          error = 'requesting to edit locked DP failed to unlock it'
        } else if (wasLocked && quoteDesignPackRevisionIdToBeReleased === designPack.quoteDesignPackRevisionId) {
          error = 'requesting to edit locked DP did not create new revision'
        } else {
          designPack.quoteDesignPackRevisionId = 0
          designPack.useDetailDataForSummaries = true
        }

        if (error === '') {
          iQuoteEventBus.$emit('designpack-modified', designPack, 'createNewRevisionClicked')
        } else {
          this.iQuoteFunctions_ShowMessage(error)
        }
        this.iQuoteQuoteDesignPacksAPIMixin_releaseDesignPackUser(quoteDesignPackRevisionIdToBeReleased)
      }
    },
    async duplicateRevisionClicked () {
      this.setDuplicateDesignPackDialog(true)
    },
    handleCustomerAccNoChanged (newValue) {
      this.iQuoteStore_GetDesignPack.autotaskCustAccNo = newValue
      iQuoteEventBus.$emit('designpack-modified', this.iQuoteStore_GetDesignPack, 'handleCustomerAccNoChanged')
    },
    async lockDP () {
      if (!await this.requestToEditDP()) {
        return
      }

      this.setLockDPDialog(false)

      var designPack = this.iQuoteStore_GetDesignPack

      designPack.locked = true

      var quote = this.iQuoteStore_GetQuote

      if (quote.id !== undefined) {
        quote.locked = true
        quote.lastEditedByUserId = this.$store.getters.userid
        this.iQuoteQuoteAPIMixin_AddOrUpdateQuoteRevision(quote)
        this.setQuote(quote)
      }

      iQuoteEventBus.$emit('designpack-modified', designPack, 'lockDP')

      // After saving to DP, we need to return to read-only mode.
      this.requestDPReadonlyAccess()
    },
    downloadQuoteAutotaskTemplate: async function (lockDP) {
      this.setDownloadDocDialog(false)

      var data = await this.getQuoteAutotaskTemplateDocument(this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)

      if (data.length === 0) {
        this.iQuoteFunctions_ShowError('Error downloading.  Please try again.')
        return
      }

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-word' }))
      a.download = 'quote_' + this.iQuoteStore_GetDesignPack.quoteDesignPackId + '_r' + this.iQuoteStore_GetDesignPack.revisionNumber + '.docx'
      document.body.appendChild(a)
      a.click()

      if (!this.iQuoteStore_GetDesignPack.locked && lockDP) {
        this.lockDP()
      }
    },
    handleDesignPackDuplicated: async function (salesforceQuoteNumber) {
      this.setDuplicateDesignPackDialog(false)

      if (this.designPackComponent.salesforceQuoteNumber !== salesforceQuoteNumber) {
        this.designPackComponent.loading = true
        this.designPackComponent.salesforceQuoteNumber = salesforceQuoteNumber

        var SalesforceOpportunity = await this.iQuoteSalesforceAPIMixin_getSalesforceOpportunityData(this.designPackComponent.salesforceQuoteNumber)
        if (SalesforceOpportunity.accountName === undefined) {
          this.iQuoteFunctions_ShowError('Error Querying Salesforce.  Please try again.')
          this.designPackComponent.salesforceOpportunity = {
            salesforceOpportunityId: 0,
            accountName: '',
            opportunityName: '',
            salesforceOpportunity: ''
          }
        } else {
          this.designPackComponent.salesforceOpportunity = SalesforceOpportunity
        }
        this.designPackComponent.loading = false
      }
    },
    downloadDesignPackExport: async function () {
      var data = await this.iQuoteExportsAPIMixin_getDesignPackExport(this.iQuoteCalculationsDesignPackExportMixin_getDesignPackForExport())

      if (data.length === 0) {
        this.iQuoteFunctions_ShowError('Error downloading.  Please try again.')
        return
      }

      this.iQuoteStore_GetDesignPack.dpExported = true
      iQuoteEventBus.$emit('designpack-modified', this.iQuoteStore_GetDesignPack, 'downloadDesignPackExport')

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      a.download = 'DesignPack_' + this.iQuoteStore_GetDesignPack.quoteDesignPackId + '_r' + this.iQuoteStore_GetDesignPack.revisionNumber + '.xlsx'
      document.body.appendChild(a)
      a.click()
    },
    uploadFileButtonClicked (message, FileUploadCatalogId) {
      this.designPackComponent.fileUploadDialogMessage = message
      this.designPackComponent.fileDialogSelectedFileError = ''
      this.designPackComponent.showFileUploadDialog = true
      this.designPackComponent.selectedFileUploadCatalogId = FileUploadCatalogId
    },
    async fileChosen (newFile) {
      this.designPackComponent.fileDialogCheckingFile = true
      this.designPackComponent.fileDialogSelectedFileError = ''
      var fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && fileExtension !== 'xltm' && fileExtension !== 'xlsm') {
        this.designPackComponent.fileDialogSelectedFileError = 'Only Excel Spreadsheets can be uploaded'
      } else {
        var data = await this.iQuoteExportsAPIMixin_getDesignPackDataFromEditedDesignPack(newFile.contents)
        var expectedRevision = this.iQuoteStore_GetDesignPack.quoteDesignPackId +
          ' Revision ' +
          (this.iQuoteStore_GetDesignPack.revisions[this.iQuoteStore_GetDesignPack.revisions.length - 1].revisionNumber + 1)

        if (data.error === undefined) {
          this.designPackComponent.fileDialogSelectedFileError = 'Error contacting file export service.  Cannot upload.  Please contact your administrator for assistance'
        } else if (data.error !== '') {
          this.designPackComponent.fileDialogSelectedFileError = data.error
        } else if (data.DetailedSummaryBreakdownEntries.length === 0) {
          this.designPackComponent.fileDialogSelectedFileError = 'No Entries found on this spreadsheet'
        } else if (data.DesignOverviewEntry.DPNumber !== expectedRevision) {
          this.designPackComponent.fileDialogSelectedFileError = 'Expected ' + expectedRevision + '.  Received ' + data.DesignOverviewEntry.DPNumber
        } else {
          /* Store parsed data from spreadsheet for saving to DB later.  This data is not saved into DB until you upload
             the file (fileUploaded() method) */
          this.designPackComponent.spreadsheetData = data
        }
      }

      this.designPackComponent.fileDialogCheckingFile = false
    },
    async fileUploaded (newFile) {
      this.designPackComponent.showFileUploadDialog = false

      var designPack = this.iQuoteStore_GetDesignPack
      var isValidData = true

      this.designPackComponent.spreadsheetData.DetailedSummaryBreakdownEntries.forEach(detailSummaryBreakdownEntry => {
        if (detailSummaryBreakdownEntry.SF_COE.trim() !== '' && !this.iQuoteFunctions_isValidSFCoeCode(detailSummaryBreakdownEntry.SF_COE)) {
          isValidData = false

          this.iQuoteFunctions_ShowError(detailSummaryBreakdownEntry.SF_COE + ' is an invalid SF COE Code.  This data cannot be imported')
        }
      })

      if (!isValidData) {
        return
      }

      var designPackEditedDetailedSummaryBreakdownEntries = []
      var designPackEditedAutotaskEntries = []

      var nextId = 0
      designPack.quoteDesignPackRevisionId = 0 // Create a new revision number
      designPack.useDetailDataForSummaries = true

      this.designPackComponent.spreadsheetData.DetailedSummaryBreakdownEntries.forEach(detailSummaryBreakdownEntry => {
        nextId--

        designPackEditedDetailedSummaryBreakdownEntries.push(
          {
            id: nextId,
            description: detailSummaryBreakdownEntry.Description,
            salesforceCoeCodeId: this.iQuoteFunctions_getSF_COE_IdFromName(detailSummaryBreakdownEntry.SF_COE),
            upfrontSellPrice: Number(detailSummaryBreakdownEntry.UpfrontSellPrice),
            upfrontThirdPartyCost: Number(detailSummaryBreakdownEntry.UpfrontExternalCost),
            upfrontInternalCost: Number(detailSummaryBreakdownEntry.UpfrontInternalCost),
            recurringSellPrice: Number(detailSummaryBreakdownEntry.RecurringSellPrice),
            recurringThirdPartyCost: Number(detailSummaryBreakdownEntry.RecurringExternalCost),
            recurringInternalCost: Number(detailSummaryBreakdownEntry.RecurringInternalCost)
          }
        )
      })

      nextId = 0

      this.designPackComponent.spreadsheetData.AutotaskEntries.forEach(autotaskEntry => {
        nextId--

        designPackEditedAutotaskEntries.push(
          {
            id: nextId,
            code: autotaskEntry.Code,
            unitOOPrice: autotaskEntry.UnitOOPrice,
            unitOOCost: autotaskEntry.UnitOOCost,
            unitARPrice: autotaskEntry.UnitARPrice,
            unitARCost: autotaskEntry.UnitARCost,
            unitMRPrice: autotaskEntry.UnitMRPrice,
            unitMRCost: autotaskEntry.UnitMRCost,
            quantity: autotaskEntry.Quantity,
            supplier: autotaskEntry.Supplier,
            quoteRef: autotaskEntry.QuoteRef,
            description: autotaskEntry.Description,
            sellTermMonths: autotaskEntry.SellTermMonths,
            costTermMonths: autotaskEntry.CostTermMonths
          }
        )
      })

      designPack.editedDetailedSummaryBreakdownEntries = designPackEditedDetailedSummaryBreakdownEntries
      designPack.editedAutotaskEntries = designPackEditedAutotaskEntries
      designPack.editedDesignOverviewEntry = {
        id: 0,
        dpNumber: this.designPackComponent.spreadsheetData.DesignOverviewEntry.DPNumber
      }
      designPack.modifiedDPFileUploadVersionID = newFile.fileVersionId

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP || this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries?.length > 0) {
        this.designPackComponent.currentPanel = 'Summary'
      }

      iQuoteEventBus.$emit('designpack-modified', designPack, 'Manually Edited DP Imported')
    }
  }
}
</script>

<style scoped>
.quotePanel {
 width: 100%;
}

#salesforceDetails {
  display: inline-block;
  width: 500px;
}

#designPackDetails {
  display: inline-block;
  width: 700px;
}

#txtDesignPackName {
  width: 350px
}

#mainDesignPackContent {
  clear:both;
}

.hidden {
  display: none;
}

.commercialsBanner {
  margin-bottom:15px;
}

.psBanner {
    padding: 8px;  
    color: #F5F5F5;
    font-size: 0.95em;
    font-weight: 500;
    text-align: center;
  }

.psBannerDeliveryManager3Days {
  background: #005e95;
  
}   

.psBannerDeliveryManager5Days {
  background: #890095;
}   

.psBannerProjectManager3Days {
  background: #2c095a;
}

input[type=button].disabledButton {
  background-color: gray;
}

#designPackReadonlyNotice {
  text-align: center;
  background-color: BlanchedAlmond;
  border: 1px solid silver;
  font-weight: bold;
}

#designPackEditingNotice {
  text-align: center;
  background-color: darkred;
  border: 1px solid silver;
  color: white;
  font-weight: bold;
}

#designPackWarningNotice {
  text-align: center;
  background-color: lightsalmon;
  border: 1px solid silver;
  font-weight: bold;
}

#designPackControlledReleaseWarningNotice {
  text-align: center;
  background-color: darksalmon;
  border: 1px solid silver;
  font-weight: bold;
  vertical-align: top;
}

input[type="button"]{
  background-color: green;
  color: white;
  border: 1px solid black;
  border-radius: 8px;
  font-weight: normal;
}

#cboDesignPackVersion {
  width: 70px;
}

#designPackUsersNotice {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
}

#designPackUsersNotice #designPackUsersBox {
  background-color: white;
  border: 1px solid black;
  width: 400px;
  margin: 0px;
  padding: 5px;
  display: block;
  float: right;
  margin-right: 5vw;
  width: max-content;
}

#designPackUsersHeader {
  font-size: 1.25em;
  font-weight: bold;
}

#designPackUsersNotice #designPackUsersBox ul {
 list-style-type: none;
 display: block;
 float: none;
}

#designPackUsersBox ul li {
  display: flex;
  justify-content: space-between;
}

#designPackUsersNotice #designPackUsersBox ul span.designPackUserName {
  display: inline-block;
  width: max-content;
}

.SellPriceMismatch {
  color: red;
}
</style>
