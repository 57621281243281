<template> 
  <div class="mainContainer"> 
      <img :src="maintelLogo" width="205">
  </div>
</template>

<script>

export default {
  name: 'iQuoteBanner',
  computed: {
    maintelLogo () {      
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/logo.png'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/logo.png`
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .mainContainer {
    display: flex;
    justify-content: center;
    padding-top: 4.5%;
    align-items: center;
  }

  img {  
    margin-right: 3%;
    margin-top: 1.8%;

  }

  h1 {
    font-size: 1.9em;
  }
</style>




<!-- <template> 
  <div class="mainContainer"> 
    <div class="container">
      <img :src="maintelLogo">  
      <span>{{ environment }}</span>
    </div>
    <div style="width: 1200px; margin-top: 3%; margin-left: -5%;">
      <h1>iQuote</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'iQuoteBanner',
  computed: {
    maintelLogo () {      
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/logo.png'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/logo.png`
      }
    },
    environment () {
      const name = this.$store.getters.channelCompany.name
      if (name.includes('Local')) return 'Local'
      else if (name.includes('Staging')) return 'Staging'
      else if (name.includes('External Test')) return 'Ext Test'
      else if (name.includes('Internal Test')) return 'Test'
      else if (name.includes('Development')) return 'Dev'
      else return ''
    },
  },
  methods: {
  }
}
</script>

<style scoped>
  .mainContainer {
    display: flex;
    justify-items: flex-start;
    vertical-align: middle;
  }

  img {
    width: 60px;
    height: 60px;
  }

  .container {
    position: relative;
    text-align: center;
  }

  span {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-48%, -50%);
    color: red;
    font-weight: bold;
    font-size: 0.8em;
  }
</style> -->
