import { render, staticRenderFns } from "./teamsConnector.vue?vue&type=template&id=6b560039&scoped=true"
import script from "./teamsConnector.vue?vue&type=script&lang=js"
export * from "./teamsConnector.vue?vue&type=script&lang=js"
import style0 from "./teamsConnector.vue?vue&type=style&index=0&id=6b560039&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b560039",
  null
  
)

export default component.exports