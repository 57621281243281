<template>
  <div class="quotePanelContainer">
    <div class="quotePanel" >
      <label for="txtiQuoteReference">iQuote</label>
      <!-- <img
        :src="internationalSip"
        class="iconButton"
      > -->
      <span style="border: 1px solid gray; padding: 5px; box-shadow: 1px 1px; ">International SIP</span>
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select required Technology vendor</p>
      <TooltipIcon text="Select Required Technology Vendor - Further vendors to be added" />
      <br>
      <TechnologyTile
        v-for="t in technologies"
        :technology-id="t.id"
        :technology-name="t.technologyName"
        :key="t.id"
      />
      <br><br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SIPServices')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteTechnologyAPIMixin'
import TooltipIcon from '../../common/tooltipIcon'
import TechnologyTile from '../tiles/iQuoteTechnologyTile'
export default {
  name: 'InternationalSip',
  components: {
    TechnologyTile,
    TooltipIcon
  },
  mixins: [iQuoteTechnologyAPIMixin],
  data () {
    return {
      technologies: [],
      quote: {}
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    internationalSip () {
      return require(`../../../assets/icons/internationalSip.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    }
  },
  mounted () {
    this.setService()
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState'
    ]),
    gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    setService: async function () {
      this.quote = this.$store.getters.quote
      this.technologies = await this.getTechnologiesByServiceCategoryId(this.$store.getters.currentServiceCategory)
    }
  }
}
</script>
<style scoped>
</style>
